import React, { ReactNode, useEffect } from 'react';
import Loading from '../misc/Loading';
import Login from './Login';
import { AuthState, silentAuth } from '../../store/AuthAction';
import { getMyModuleTypes, ModuleState } from '../../store/ModuleAction';
import StatusCode from '../../util/StatusCode';
import { useDispatch, useSelector } from 'react-redux';
import { Result } from '../../models/CoreModels';
import { MyModuleTypes } from '../../models/CoreDashboardModels';
import { FlowSyncAccessPolicy, isAuthorized } from '../../models/FlowSyncAccessPolicy';
import Forbidden from './Forbidden';
import Unauthorized from './Unauthorized';

interface PrivateProps {
    children: ReactNode[]
    accessPolicy?: FlowSyncAccessPolicy
}

interface PrivateState {
    auth: AuthState
    module: ModuleState
}

const Private: React.FC<PrivateProps> = (props) => {
    const dispatch = useDispatch();

    const needLogin = useSelector<PrivateState, boolean>(state => state.auth.needLogin);
    const authorizationPolicies = useSelector<PrivateState, FlowSyncAccessPolicy[] | undefined>(state => state.auth.authorizationPolicies);
    const myModuleTypes = useSelector<PrivateState, Result<MyModuleTypes>>(state => state.module.myModuleTypes);

    useEffect(() => {
        if (authorizationPolicies === undefined) {
            silentAuth()(dispatch);
        }
        if (authorizationPolicies !== undefined && myModuleTypes.code === StatusCode.NONE) {
            getMyModuleTypes(true)(dispatch);
        }
    }, [dispatch, authorizationPolicies, myModuleTypes]);

    if (needLogin) {
        return <Login />;
    } else if (authorizationPolicies === undefined || (myModuleTypes.code === StatusCode.NONE || myModuleTypes.code === StatusCode.PENDING)) {
        return <Loading style={{ padding: '40px' }} />;
    } else if (!isAuthorized(authorizationPolicies, FlowSyncAccessPolicy.READ_OVERVIEW)) {
        return <Unauthorized />
    } else if (props.accessPolicy && !isAuthorized(authorizationPolicies, props.accessPolicy)) {
        return <Forbidden />
    }

    return <>{props.children}</>
}

export default Private;
