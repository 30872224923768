import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams, Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { getOneRosterClasses } from '../../store/OneRoster/OneRoster_ClassAction';
import StatusCode from '../../util/StatusCode';
import debounce from 'lodash/debounce';
import * as Helper from '../../util/Helper';
import TableHorizontal from '../misc/TableHorizontal';
import * as OneRosterClassStatus from '../../util/OneRoster/OneRosterClassStatusUtil';
import Search from '../misc/Search';
import SelectInput from '../misc/SelectInput';
import FilterBottomComponent from '../misc/FilterBottomComponent';
import RequestParser from '../../util/RequestParser';


const OneRoster_Classes = () => {
    const dispatch = useDispatch();

    const [search, setSearch] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    const classes = useSelector(state => state.oneroster_class.classes);
    var filter = RequestParser.parseOneRosterClassSearchPageRequest(searchParams);

    useEffect(() => {
        if (filter.search) {
            setSearch(filter.search);
        }
    }, [dispatch]);

    useEffect(() => {
        getOneRosterClasses(filter)(dispatch);
        window.scrollTo(0, 0);
    }, [searchParams]);

    const updateFilter = (name, value) => {
        if (value !== undefined && value !== '' && value !== null) {
            searchParams.set(name, value);
        } else {
            searchParams.delete(name);
        }

        if (name !== 'page') {
            searchParams.set('page', 1);
        }

        setSearchParams(searchParams);
    }

    const debouncedSearch = useCallback(
        debounce(search => {
            updateFilter('search', search);
        }, 300), [searchParams]);

    const onSearch = (e) => {
        e.preventDefault();
        let search = e.target.value;
        setSearch(search);
        debouncedSearch(search);
    }

    const clearFilter = () => {
        setSearch('');
        setSearchParams([]);
    }

    const renderBody = () => {
        const newList = classes.data?.map(c => ({
            id: c.id,
            data: [
                { value: <Link to={`/oneroster/class/${c.id}/information`}>{c.title}</Link> },
                { value: OneRosterClassStatus.statusFromId(c.status) },
                { value: <Link to={`/oneroster/schoolunit/${c.schoolId}/information`} >{c.schoolName}</Link> },
                { value: c.teacherCount },
                { value: c.studentCount },
                { value: Helper.formatDate(c.created), className: 'nowrap' },
                { value: Helper.formatDate(c.modified), className: 'nowrap' },
                { value: Helper.formatDate(c.inactivated), className: 'nowrap' },
                { value: Helper.formatDate(c.archived), className: 'nowrap' },
            ]
        })) ?? [];

        return (
            <TableHorizontal
                header={[{ value: 'Namn' }, { value: 'Status' }, { value: 'Skola' }, { value: 'Lärare' }, { value: 'Elever' }, { value: 'Skapad' }, { value: 'Ändrad' }, { value: 'Inaktiverad' }, { value: 'Arkiverad' }]}
                info={newList}
                error={classes.code === StatusCode.ERROR}
                searching={classes.code === StatusCode.PENDING}
                displayName="Avsnitt"
                noResMsg="Inga avsnitt hittades"
            />
        )
    }

    const renderFilter = () => {
        return (
            <Row>
                <Col sm="6">
                    <Search value={search} onChange={onSearch} placeholder="Sök avsnitt" />
                </Col>
                <Col sm="6">
                    <SelectInput
                        items={OneRosterClassStatus.StatusTypes.map(statusType => ({ value: statusType.id, text: `Filtrera på ${statusType.name}` }))}
                        onChange={(name, value) => updateFilter(name, value)}
                        name="status"
                        selected={filter.status}
                        defaultText="Visa alla oavsett status"
                    />
                </Col>
            </Row>
        )
    }

    const requestState = Helper.convertToCacheRequestState(classes);

    return (
        <div className="container classes_oneroster">
            <div className="header--fixed">
                <h1>Avsnitt</h1>
                {renderFilter()}
            </div>
            <div className="body--scroll padding-40">
                {renderBody()}
            </div>

            <FilterBottomComponent
                clearFilter={clearFilter}
                updateFilter={(value) => updateFilter('page', value)}
                requestState={requestState}
            />

        </div>
    )
}

export default OneRoster_Classes;
