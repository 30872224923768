import { Action, Dispatch, Reducer } from 'redux';
import { CoreActivity, CoreActivityListing, CoreActivityStudentGroupListing, CoreActivitySyllabus, CoreActivityUserListing } from '../models/CoreActivity';
import { PageResult } from '../models/CoreModels';
import { CoreTracking } from '../models/CoreTracking';
import { ActivitySearchPageRequest, SearchPageRequest } from '../models/Requests';
import { CacheRequestState, ExtendedRequestState, RequestState } from '../models/RequestState';
import InstanceClient from '../services/InstanceClient';
import StatusCode from '../util/StatusCode';
import store from './store';

enum ActivityActionType {
    LIST_ACTIVITIES = 'LIST_ACTIVITIES',
    LIST_ACTIVITIES_FILTER = 'LIST_ACTIVITIES_FILTER',
    LIST_ACTIVITIES_UPDATE = 'LIST_ACTIVITIES_UPDATE',
    GET_ACTIVITY = 'GET_ACTIVITY',
    GET_ACTIVITY_TRACKINGS = 'GET_ACTIVITY_TRACKINGS',
    GET_ACTIVITY_GROUPS = 'GET_ACTIVITY_GROUPS',
    GET_ACTIVITY_TEACHERS = 'GET_ACTIVITY_TEACHERS',
    GET_ACTIVITY_STUDENTS = 'GET_ACTIVITY_STUDENTS',
    GET_ACTIVITY_SYLLABUS = 'GET_ACTIVITY_SYLLABUS',
}

export type ActivityStudentGroupRequestState = ExtendedRequestState<PageResult<CoreActivityStudentGroupListing>, { filter: Partial<SearchPageRequest> }>
export type ActivityUsersRequestState = ExtendedRequestState<PageResult<CoreActivityUserListing>, { filter: Partial<SearchPageRequest> }>

export interface ActivityState {
    activities: CacheRequestState<PageResult<CoreActivityListing>>
    activity: RequestState<CoreActivity>
    activityTrackings: RequestState<CoreTracking[]>
    activityGroups: ActivityStudentGroupRequestState
    activityTeachers: ActivityUsersRequestState
    activityStudents: ActivityUsersRequestState
    activitySyllabus: RequestState<CoreActivitySyllabus>
    filter: Partial<ActivitySearchPageRequest>
}


interface Activity_ListActivitiesBase extends Action<ActivityActionType> {
    type: ActivityActionType.LIST_ACTIVITIES
    activities: RequestState<PageResult<CoreActivityListing>>
}

interface Activity_ListActivitiesFilter extends Action<ActivityActionType> {
    type: ActivityActionType.LIST_ACTIVITIES_FILTER
    filter: Partial<ActivitySearchPageRequest>
}

interface Activity_ListActivitiesUpdate extends Action<ActivityActionType> {
    type: ActivityActionType.LIST_ACTIVITIES_UPDATE
}

type Activity_ListActivities = Activity_ListActivitiesBase | Activity_ListActivitiesFilter | Activity_ListActivitiesUpdate;

interface Activity_GetActivity extends Action<ActivityActionType> {
    type: ActivityActionType.GET_ACTIVITY
    activity: RequestState<CoreActivity>
}

interface Activity_GetActivityTrackings extends Action<ActivityActionType> {
    type: ActivityActionType.GET_ACTIVITY_TRACKINGS
    activityTrackings: RequestState<CoreTracking[]>
}

interface Activity_GetActivityGroups extends Action<ActivityActionType> {
    type: ActivityActionType.GET_ACTIVITY_GROUPS
    activityGroups: ActivityStudentGroupRequestState
}

interface Activity_GetActivityTeachers extends Action<ActivityActionType> {
    type: ActivityActionType.GET_ACTIVITY_TEACHERS
    activityTeachers: ActivityUsersRequestState
}

interface Activity_GetActivityStudents extends Action<ActivityActionType> {
    type: ActivityActionType.GET_ACTIVITY_STUDENTS
    activityStudents: ActivityUsersRequestState
}

interface Activity_GetActivitySyllabus extends Action<ActivityActionType> {
    type: ActivityActionType.GET_ACTIVITY_SYLLABUS
    activitySyllabus: RequestState<CoreActivitySyllabus>
}

type ActivityAction = Activity_ListActivities
    | Activity_GetActivity
    | Activity_GetActivityTrackings
    | Activity_GetActivityGroups
    | Activity_GetActivityTeachers
    | Activity_GetActivityStudents
    | Activity_GetActivitySyllabus

export const ListActivities = (filter: Partial<ActivitySearchPageRequest> = {}, update: boolean = false) => (dispatch: Dispatch<Activity_ListActivities>) => {
    dispatch({ type: ActivityActionType.LIST_ACTIVITIES_FILTER, filter: filter });
    if (update) {
        dispatch({ type: ActivityActionType.LIST_ACTIVITIES_UPDATE });
    }

    const url = '/api/core/activity'
    InstanceClient.get<PageResult<CoreActivityListing>>(url, filter)
        .then(res =>
            dispatch({
                type: ActivityActionType.LIST_ACTIVITIES,
                activities: {
                    code: StatusCode.COMPLETE,
                    data: res.data,
                }
            }))
        .catch(error =>
            dispatch({
                type: ActivityActionType.LIST_ACTIVITIES,
                activities: { code: StatusCode.ERROR, error },
            })
        );
};

export const GetActivity = (id: string) => (dispatch: Dispatch<Activity_GetActivity>) => {
    const storeKey = store.getState().activities.activity?.key;
    if (id === storeKey) {
        return;
    }
    dispatch({
        type: ActivityActionType.GET_ACTIVITY,
        activity: { code: StatusCode.PENDING }
    })
    InstanceClient.get<CoreActivity>(`/api/core/activity/${id}`)
        .then(res =>
            dispatch({
                type: ActivityActionType.GET_ACTIVITY,
                activity: { code: StatusCode.COMPLETE, data: res.data, key: id }
            })
        )
        .catch(error =>
            dispatch({
                type: ActivityActionType.GET_ACTIVITY,
                activity: {
                    code: StatusCode.ERROR, error }
            })
        );
};

export const GetActivityTrackings = (id: string) => (dispatch: Dispatch<Activity_GetActivityTrackings>) => {
    const storeKey = store.getState().activities.activityTrackings?.key;
    if (id === storeKey) {
        return;
    }
    dispatch({
        type: ActivityActionType.GET_ACTIVITY_TRACKINGS,
        activityTrackings: { code: StatusCode.PENDING }
    })
    InstanceClient.get<CoreTracking[]>(`/api/core/activity/${id}/trackings`)
        .then(res =>
            dispatch({
                type: ActivityActionType.GET_ACTIVITY_TRACKINGS,
                activityTrackings: { code: StatusCode.COMPLETE, data: res.data, key: id }
            })
        )
        .catch(error =>
            dispatch({
                type: ActivityActionType.GET_ACTIVITY_TRACKINGS,
                activityTrackings: { code: StatusCode.ERROR, error }
            })
        );
};

export const GetActivityGroups = (id: string, filter: Partial<SearchPageRequest> = {}) => (dispatch: Dispatch<Activity_GetActivityGroups>) => {
    const storeKey = store.getState().activities.activityGroups?.key;
    const key = id + '-' + filter.onlyActive + '-' + filter.page;
    if (id === storeKey) {
        return;
    }

    dispatch({
        type: ActivityActionType.GET_ACTIVITY_GROUPS,
        activityGroups: { code: StatusCode.PENDING, filter }
    })

    InstanceClient.get<PageResult<CoreActivityStudentGroupListing>>(`/api/core/activity/${id}/groups`, filter)
        .then(res =>
            dispatch({
                type: ActivityActionType.GET_ACTIVITY_GROUPS,
                activityGroups: {
                    code: StatusCode.COMPLETE,
                    data: res.data,
                    filter,
                    key,
                }
            })
        )
        .catch(error =>
            dispatch({
                type: ActivityActionType.GET_ACTIVITY_GROUPS,
                activityGroups: { code: StatusCode.ERROR, error, filter }
            })
        );
};

export const GetActivityTeachers = (id: string, filter: Partial<SearchPageRequest> = {}) => (dispatch: Dispatch<Activity_GetActivityTeachers>) => {
    const storeKey = store.getState().activities.activityTeachers?.key;
    const key = id + '-' + filter.onlyActive + '-' + filter.page;
    if (id === storeKey) {
        return;
    }

    dispatch({
        type: ActivityActionType.GET_ACTIVITY_TEACHERS,
        activityTeachers: { code: StatusCode.PENDING, filter }
    })

    InstanceClient.get<PageResult<CoreActivityUserListing>>(`/api/core/activity/${id}/teachers`, filter)
        .then(res =>
            dispatch({
                type: ActivityActionType.GET_ACTIVITY_TEACHERS,
                activityTeachers: {
                    code: StatusCode.COMPLETE,
                    data: res.data,
                    key: key,
                    filter,
                }
            })
        )
        .catch(error =>
            dispatch({
                type: ActivityActionType.GET_ACTIVITY_TEACHERS,
                activityTeachers: { code: StatusCode.ERROR, error, filter }
            })
        );
};

export const GetActivityStudents = (id: string, filter: Partial<SearchPageRequest> = {}) => (dispatch: Dispatch<Activity_GetActivityStudents>) => {
    const storeKey = store.getState().activities.activityStudents?.key;
    const key = id + '-' + filter.onlyActive + '-' + filter.page;
    if (id === storeKey) {
        return;
    }

    dispatch({
        type: ActivityActionType.GET_ACTIVITY_STUDENTS,
        activityStudents: { code: StatusCode.PENDING, filter }
    })

    InstanceClient.get<PageResult<CoreActivityUserListing>>(`/api/core/activity/${id}/students`, filter)
        .then(res =>
            dispatch({
                type: ActivityActionType.GET_ACTIVITY_STUDENTS,
                activityStudents: {
                    code: StatusCode.COMPLETE,
                    data: res.data,
                    filter,
                    key,
                }
            })
        )
        .catch(error =>
            dispatch({
                type: ActivityActionType.GET_ACTIVITY_STUDENTS,
                activityStudents: { code: StatusCode.ERROR, error, filter }
            })
        );
};

export const GetActivitySyllabus = (id: string) => (dispatch: Dispatch<Activity_GetActivitySyllabus>) => {
    dispatch({
        type: ActivityActionType.GET_ACTIVITY_SYLLABUS,
        activitySyllabus: { code: StatusCode.PENDING },
    });

    InstanceClient.get<CoreActivitySyllabus>(`/api/core/activity/${id}/syllabus`)
        .then(resp => dispatch({
            type: ActivityActionType.GET_ACTIVITY_SYLLABUS,
            activitySyllabus: { code: StatusCode.COMPLETE, data: resp.data }
        }))
        .catch(error => dispatch({
            type: ActivityActionType.GET_ACTIVITY_SYLLABUS,
            activitySyllabus: { code: StatusCode.ERROR, error }
        }));
};

const initialState: ActivityState = {
    activities: { code: StatusCode.PENDING },
    activity: { code: StatusCode.PENDING },
    activityTrackings: { code: StatusCode.PENDING },
    activityGroups: { code: StatusCode.PENDING, filter: {} },
    activityTeachers: { code: StatusCode.PENDING, filter: {} },
    activityStudents: { code: StatusCode.PENDING, filter: {} },
    activitySyllabus: { code: StatusCode.PENDING },
    filter: {},
}

export const reducer: Reducer<ActivityState, ActivityAction> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case ActivityActionType.LIST_ACTIVITIES:
            return {
                ...state,
                activities: action.activities,
            }
        case ActivityActionType.LIST_ACTIVITIES_FILTER:
            return {
                ...state,
                filter: action.filter,
            }
        case ActivityActionType.LIST_ACTIVITIES_UPDATE:
            return {
                ...state,
                activities: { ...state.activities, code: StatusCode.PENDING }
            }
        case ActivityActionType.GET_ACTIVITY:
            return {
                ...state,
                activity: action.activity,
            };
        case ActivityActionType.GET_ACTIVITY_TRACKINGS:
            return {
                ...state,
                activityTrackings: action.activityTrackings,
            };
        case ActivityActionType.GET_ACTIVITY_GROUPS:
            return {
                ...state,
                activityGroups: action.activityGroups,
            };
        case ActivityActionType.GET_ACTIVITY_STUDENTS:
            return {
                ...state,
                activityStudents: action.activityStudents
            }
        case ActivityActionType.GET_ACTIVITY_TEACHERS:
            return {
                ...state,
                activityTeachers: action.activityTeachers,
            }
        case ActivityActionType.GET_ACTIVITY_SYLLABUS:
            return {
                ...state,
                activitySyllabus: action.activitySyllabus,
            }
        default:
            return state;
    }
};
