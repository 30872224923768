import InstanceClient from '../../services/InstanceClient';
import StatusCode from '../../util/StatusCode';
import store from '../store';

const ONEROSTER_USERS = 'ONEROSTER_USERS';
const ONEROSTER_USERS_FILTER = 'ONEROSTER_USERS_FILTER';
const ONEROSTER_USERS_UPDATE = 'ONEROSTER_USERS_UPDATE';
const ONEROSTER_USER = 'ONEROSTER_USER';
const ONEROSTER_USER_ORG = 'ONEROSTER_USER_ORG';
const ONEROSTER_USER_CLASS = 'ONEROSTER_USER_CLASS';
const ONEROSTER_USER_ROLES = 'ONEROSTER_USER_ROLES';

export const getOneRosterUsers = (filter = {}, update = false) => dispatch => {
    dispatch({ type: ONEROSTER_USERS_FILTER, filter: filter });
    if (update) {
        dispatch({ type: ONEROSTER_USERS_UPDATE });
    }

    const url = '/api/core/oneroster/user';
    InstanceClient.get(url, filter)
        .then(res => {
            dispatch({
                type: ONEROSTER_USERS,
                users: {
                    code: StatusCode.COMPLETE,
                    data: res.data.values,
                    totalCount: res.data.totalCount,
                    pagination: {
                        pageSize: res.data.pageSize,
                        currentPage: res.data.currentPage,
                        totalPages: res.data.totalPages,
                    }
                },
            })
        })
        .catch(error =>
            dispatch({
                type: ONEROSTER_USERS,
                users: { code: StatusCode.ERROR, error: error }
            })
        )
}

export const getOneRosterUser = (id) => dispatch => {
    const storeKey = store.getState().oneroster_user.user.key;
    const key = id;
    if (key === storeKey) {
        return;
    }

    const url = '/api/core/oneroster/user/' + id;
    InstanceClient.get(url)
        .then(res => {
            dispatch({
                type: ONEROSTER_USER,
                user: { code: StatusCode.COMPLETE, data: res.data, key: key },
            })
        })
        .catch(error =>
            dispatch({
                type: ONEROSTER_USER,
                user: { code: StatusCode.ERROR, error: error.response.status }
            })
        )
}

export const getOneRosterUserOrgs = (id, filter = {}) => dispatch => {
    const storeKey = store.getState().oneroster_user.userSchool.key;
    const key = id + '-' + filter.onlyActive + '-' + filter.page;
    if (key === storeKey) {
        return;
    }

    dispatch({
        type: ONEROSTER_USER_ORG,
        userSchool: { code: StatusCode.PENDING, filter: filter },
    })

    const url = `/api/core/oneroster/user/${id}/school`;
    InstanceClient.get(url, filter)
        .then(res => {
            dispatch({
                type: ONEROSTER_USER_ORG,
                userSchool: { code: StatusCode.COMPLETE, data: res.data, key: key, filter: filter },
            })
        })
        .catch(error =>
            dispatch({
                type: ONEROSTER_USER_ORG,
                userSchool: { code: StatusCode.ERROR, error }
            })
        )
}

export const getOneRosterUserClasses = (id, filter = {}) => dispatch => {
    const storeKey = store.getState().oneroster_user.userClasses.key;
    const key = id + '-' + filter.status + '-' + filter.page;
    if (key === storeKey) {
        return;
    }

    dispatch({
        type: ONEROSTER_USER_CLASS,
        userClasses: { code: StatusCode.PENDING, filter: filter },
    })

    const url = `/api/core/oneroster/user/${id}/class`;
    InstanceClient.get(url, filter)
        .then(res => {
            dispatch({
                type: ONEROSTER_USER_CLASS,
                userClasses: { code: StatusCode.COMPLETE, data: res.data, key: key, filter: filter },
            })
        })
        .catch(error =>
            dispatch({
                type: ONEROSTER_USER_CLASS,
                userClasses: { code: StatusCode.ERROR, error }
            })
        )
}

export const getOneRosterRoles = () => dispatch => {
    const url = '/api/core/oneroster/user/roles';
    InstanceClient.get(url)
        .then(res => {
            dispatch({
                type: ONEROSTER_USER_ROLES,
                roles: { code: StatusCode.COMPLETE, data: res.data },
            })
        })
}

const initialState = {
    users: { code: StatusCode.PENDING },
    user: { code: StatusCode.PENDING },
    filter: {},
    userSchool: { code: StatusCode.PENDING },
    userClasses: { code: StatusCode.PENDING },
    roles: { code: StatusCode.PENDING },
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case ONEROSTER_USERS:
            return {
                ...state,
                users: action.users,
            }
        case ONEROSTER_USERS_FILTER:
            return {
                ...state,
                filter: action.filter
            }
        case ONEROSTER_USERS_UPDATE:
            return {
                ...state,
                users: { ...state.users, code: StatusCode.PENDING }
            }
        case ONEROSTER_USER:
            return {
                ...state,
                user: action.user
            }
        case ONEROSTER_USER_ORG:
            return {
                ...state,
                userSchool: action.userSchool,
            }
        case ONEROSTER_USER_CLASS:
            return {
                ...state,
                userClasses: action.userClasses,
            }
        case ONEROSTER_USER_ROLES:
            return {
                ...state,
                roles: action.roles,
            }
        default:
            return state;
    }
}

