import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface FontAwesomeButtonProps {
    title: string
    icon: IconProp
    onClick: React.MouseEventHandler<SVGSVGElement>
    disabled?: boolean
}

const FontAwesomeButton: React.FC<FontAwesomeButtonProps> = (props) => {
    if (props.disabled) {
        return <FontAwesomeIcon className="fa-button-disabled" icon={props.icon} title={props.title} />;
    } else {
        return <FontAwesomeIcon className="fa-button" icon={props.icon} onClick={props.onClick} title={props.title} />;
    }
}

export default FontAwesomeButton;