import React from 'react';
import { FormGroup, Label, Input, Card, CardHeader, CardBody, Collapse, Button, FormFeedback } from 'reactstrap';
import ConfigurationComponent from '../ConfigurationComponent';
import EnumInput from '../../../misc/EnumInput';

const sensitiveFieldOptions = [
    { value: 1, text: "Aktivera utläsning av adress" },
    { value: 2, text: "Aktivera utläsning av personnummer" },
    { value: 4, text: "Aktivera utläsning av privat e-postadress" },
    { value: 8, text: "Aktivera utläsning av vårdnadshavare" },
];

class EduCloud extends ConfigurationComponent {
    state = {
        open: true,
        onlyWithMunicipalityCode: this.props.configuration.municipalityCode ? true : false
    }

    warnings(updates) {
        const warningMessages = [];

        if (updates.includeSensitiveFields !== undefined && (updates.includeSensitiveFields & 8) !== 8 && (this.props.configuration.includeSensitiveFields & 8) === 8) {
            warningMessages.push('Tänk på att om utläsning av vårdnadshavare inaktiveras så kommer dessa att tas bort från system som vi skickar vårdnadshavare till');
        }

        return warningMessages;
    }

    validate(configuration, active, onlyWithMunicipalityCode) {
        const validation = [];

        if (active) {
            if (!configuration.customerId || configuration.customerId === "") {
                validation.push('customerId');
            }
            if (!configuration.clientId || configuration.clientId === "") {
                validation.push('clientId');
            }
            if (!configuration.clientSecret || configuration.clientSecret === "") {
                validation.push('clientSecret');
            }
            if (configuration.schoolOrganizationType === 0) {
                validation.push('schoolOrganizationType');
            }
            if (configuration.activityNaming === 0) {
                validation.push('activityNaming');
            }
        }

        if (onlyWithMunicipalityCode && !configuration.municipalityCode) {
            validation.push('municipalityCode')
        }

        return validation;
    }
     
    updateEduCloudConfiguration = (prop, value) => {
        var newUpdates = { ...this.props.updates };
        
        if (prop === 'onlyWithMunicipalityCode') {
            this.setState({ onlyWithMunicipalityCode: !this.state.onlyWithMunicipalityCode })
        }

        if (this.props.configuration[prop] === value) {
            delete newUpdates[prop];
        } else {
            newUpdates[prop] = value;
        }

        if (prop === 'onlyWithMunicipalityCode' && value === false) {
            if (this.props.configuration.municipalityCode) {
                newUpdates.municipalityCode = '';
            }
            if (newUpdates.municipalityCode) {
                delete newUpdates.municipalityCode;
            }
        }

        if ((prop === 'onlyWithMunicipalityCode' && value === true) && (this.props.configuration.municipalityCode)) {
            newUpdates.municipalityCode = this.props.configuration.municipalityCode;
        }
        if (prop === 'onlyWithMunicipalityCode') {
            delete newUpdates.onlyWithMunicipalityCode;
        }

        this.notifyUpdates(newUpdates);
        this.updateData(newUpdates);
    }

   
    render() {
        const { onlyWithMunicipalityCode } = this.state;
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);
        const validation = this.validate(configuration, this.props.active, onlyWithMunicipalityCode);

        return (
            <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    {this.props.moduleDisplayName}
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>
                        <div style={{ margin: '1rem 0' }}>
                            <FormGroup>
                                <Label for="customerId">Kund id</Label>
                                <Input type="text" name="customerId" id="customerId" placeholder="Kund id" value={configuration.customerId || ''} disabled={this.props.disableInput} onChange={(e) => this.updateEduCloudConfiguration('customerId', e.target.value)} invalid={validation.indexOf('customerId') !== -1} autoComplete="off" />
                                <FormFeedback>Kund id måste anges för att kunna aktivera importmodulen</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="clientId">Klient id (Client Id)</Label>
                                <Input type="text" name="clientId" id="clientId" placeholder="Klient id" value={configuration.clientId || ''} disabled={this.props.disableInput} onChange={(e) => this.updateEduCloudConfiguration('clientId', e.target.value)} invalid={validation.indexOf('clientId') !== -1} autoComplete="off" />
                                <FormFeedback>Klient id måste anges för att kunna aktivera importmodulen</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="clientSecret">Klient hemlighet (Client Secret)</Label>
                                <Input type="text" name="clientSecret" id="clientSecret" placeholder="Klient hemlighet" value={configuration.clientSecret || ''} disabled={this.props.disableInput} onChange={(e) => this.updateEduCloudConfiguration('clientSecret', e.target.value)} invalid={validation.indexOf('clientSecret') !== -1} autoComplete="off" />
                                <FormFeedback>Klient hemlighet måste anges för att kunna aktivera importmodulen</FormFeedback>
                            </FormGroup>
                           
                            <FormGroup>
                                <Label for="onlyWithMunicipalityCode">Välj skolor utifrån kommunkod</Label>
                                <FormGroup check name="onlyWithMunicipalityCode">
                                    <Label check style={{ display: 'block', marginBottom: '8px' }}>
                                        <Input type="checkbox" name="onlyWithMunicipalityCode" id="onlyWithMunicipalityCode" disabled={this.props.disableInput} checked={this.state.onlyWithMunicipalityCode} onChange={(e) => this.updateEduCloudConfiguration('onlyWithMunicipalityCode', e.target.checked)} />
                                        Ta enbart med skolor med kommunkod
                                    </Label>
                                    <span>
                                        <Input invalid={validation.indexOf('municipalityCode') !== -1} type="text" disabled={this.props.disableInput} placeholder="Ange kommunkod" name="municipalityCode" id="municipalityCode" value={configuration.municipalityCode || ''} onChange={(e) => this.updateEduCloudConfiguration('municipalityCode', e.target.value)} />
                                        <FormFeedback>Ange kommunkod</FormFeedback>
                                    </span>
                                </FormGroup>
                            </FormGroup>

                            <EnumInput
                                title='Databehandling'
                                titleSmall='true'
                                value={configuration.includeSensitiveFields}
                                options={sensitiveFieldOptions}
                                onChange={this.updateEduCloudConfiguration}
                                disabled={this.props.disableInput}
                                name='includeSensitiveFields'
                                multi='true'
                            />

                            <FormGroup tag="fieldset">
                                <Label>Basera utläsning på:</Label>
                                <FormGroup check>
                                    <Input type="radio" id="type1" name="schoolOrganizationType" value={1} checked={configuration.schoolOrganizationType === 1} disabled={this.props.disableInput} onChange={(e) => this.updateEduCloudConfiguration('schoolOrganizationType', 1)} invalid={validation.indexOf('schoolOrganizationType') !== -1} />
                                    <Label for="type1" check>Skola</Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Input type="radio" id="type2" name="schoolOrganizationType" value={2} checked={configuration.schoolOrganizationType === 2} disabled={this.props.disableInput} onChange={(e) => this.updateEduCloudConfiguration('schoolOrganizationType', 2)} invalid={validation.indexOf('schoolOrganizationType') !== -1} />
                                    <Label for="type2" check>Skolenhet</Label>
                                    <FormFeedback>Basera utläsning måste konfigureras för att kunna aktivera modulen</FormFeedback>
                                </FormGroup>
                            </FormGroup>

                            <FormGroup tag="fieldset2">
                                <Label>Namnge aktiviteter efter:</Label>
                                <FormGroup check>
                                    <Input type="radio" id="namingtype1" name="activityNaming" value={1} checked={configuration.activityNaming === 1} disabled={this.props.disableInput} onChange={(e) => this.updateEduCloudConfiguration('activityNaming', 1)} invalid={validation.indexOf('activityNaming') !== -1} />
                                    <Label for="namingtype1" check>Aktivitet</Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Input type="radio" id="namingtype2" name="activityNaming" value={2} checked={configuration.activityNaming === 2} disabled={this.props.disableInput} onChange={(e) => this.updateEduCloudConfiguration('activityNaming', 2)} invalid={validation.indexOf('activityNaming') !== -1} />
                                    <Label for="namingtype2" check>Grupp</Label>
                                    <FormFeedback>Namnge aktiviteter måste konfigureras för att kunna aktivera modulen</FormFeedback>
                                </FormGroup>
                            </FormGroup>

                        </div>
                    </CardBody>
                </Collapse>
            </Card>)
    }
}

export default EduCloud;
