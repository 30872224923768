import InstanceClient from '../../services/InstanceClient';
import StatusCode from '../../util/StatusCode';
import store from '../store';

const ONEROSTER_CLASSES = 'ONEROSTER_CLASSES';
const ONEROSTER_CLASSES_FILTER = 'ONEROSTER_CLASSES_FILTER';
const ONEROSTER_CLASSES_UPDATE = 'ONEROSTER_CLASSES_UPDATE';
const ONEROSTER_CLASS = 'ONEROSTER_CLASS';
const ONEROSTER_CLASS_TEACHER = 'ONEROSTER_CLASS_TEACHER';
const ONEROSTER_CLASS_STUDENT = 'ONEROSTER_CLASS_STUDENT';


export const getOneRosterClasses = (filter = {}, update = false) => dispatch => {
    dispatch({ type: ONEROSTER_CLASSES_FILTER, filter: filter });

    if (update) {
        dispatch({ type: ONEROSTER_CLASSES_UPDATE });
    }

    const url = '/api/core/oneroster/class';
    InstanceClient.get(url, filter)
        .then(res => {
            dispatch({
                type: ONEROSTER_CLASSES,
                classes: {
                    code: StatusCode.COMPLETE,
                    data: res.data.values,
                    totalCount: res.data.totalCount,
                    pagination: {
                        currentPage: res.data.currentPage,
                        totalPages: res.data.totalPages,
                    }
                }
            })
        })
        .catch(error =>
            dispatch({
                type: ONEROSTER_CLASSES,
                classes: { code: StatusCode.ERROR, error: error }
            })
        )
}

export const getOneRosterClass = (id) => dispatch => {
    const storeKey = store.getState().oneroster_class.classInfo.key;
    const key = id;
    if (key === storeKey) {
        return;
    }

    const url = '/api/core/oneroster/class/' + id;
    InstanceClient.get(url)
        .then(res => {
            dispatch({
                type: ONEROSTER_CLASS,
                classInfo: { code: StatusCode.COMPLETE, data: res.data, key: key },
            })
        })
        .catch(error =>
            dispatch({
                type: ONEROSTER_CLASS,
                classInfo: { code: StatusCode.ERROR, error: error.response.status }
            })
        )
}

export const getOneRosterTeachersByClass = (id, filter = {}) => dispatch => {
    const storeKey = store.getState().oneroster_class.teachersByClass.key;
    const key = id + '-' + filter.onlyActive + '-' + filter.page;
    if (key === storeKey) {
        return;
    }

    dispatch({
        type: ONEROSTER_CLASS_TEACHER,
        teachersByClass: { code: StatusCode.PENDING, filter: filter },
    });

    const url = `/api/core/oneroster/class/${id}/teacher`;
    InstanceClient.get(url, filter)
        .then(res => {
            dispatch({
                type: ONEROSTER_CLASS_TEACHER,
                teachersByClass: { code: StatusCode.COMPLETE, data: res.data, filter: filter, key: key },
            })
        })
        .catch(error =>
            dispatch({
                type: ONEROSTER_CLASS_TEACHER,
                teachersByClass: { code: StatusCode.ERROR, error }
            })
        )
}

export const getOneRosterStudentsByClass = (id, filter = {}) => dispatch => {
    const storeKey = store.getState().oneroster_class.studentsByClass.key;
    const key = id + '-' + filter.onlyActive + '-' + filter.page;
    if (key === storeKey) {
        return;
    }

    dispatch({
        type: ONEROSTER_CLASS_STUDENT,
        studentsByClass: { code: StatusCode.PENDING, filter: filter },
    });

    const url = `/api/core/oneroster/class/${id}/student`;
    InstanceClient.get(url, filter)
        .then(res => {
            dispatch({
                type: ONEROSTER_CLASS_STUDENT,
                studentsByClass: { code: StatusCode.COMPLETE, data: res.data, filter: filter, key: key },
            })
        })
        .catch(error =>
            dispatch({
                type: ONEROSTER_CLASS_STUDENT,
                studentsByClass: { code: StatusCode.ERROR, error }
            })
        )
}


const initialState = {
    classInfo: { code: StatusCode.PENDING },
    teachersByClass: { code: StatusCode.PENDING },
    studentsByClass: { code: StatusCode.PENDING },
    classes: { code: StatusCode.PENDING },
    filter: {},
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case ONEROSTER_CLASSES:
            return {
                ...state,
                classes: action.classes,
            }
        case ONEROSTER_CLASSES_FILTER:
            return {
                ...state,
                filter: action.filter
            }
        case ONEROSTER_CLASSES_UPDATE:
            return {
                ...state,
                classes: { ...state.classes, code: StatusCode.PENDING }
            }
        case ONEROSTER_CLASS:
            return {
                ...state,
                classInfo: action.classInfo,
            }
        case ONEROSTER_CLASS_TEACHER:
            return {
                ...state,
                teachersByClass: action.teachersByClass,
            }
        case ONEROSTER_CLASS_STUDENT:
            return {
                ...state,
                studentsByClass: action.studentsByClass,
            }
        default:
            return state;
    }
}

