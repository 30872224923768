import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Navigate} from 'react-router-dom'
import { GetDuty, DutyState } from '../../../store/DutyAction';
import NotFound from '../components/NotFound';
import { CoreDuty } from '../../../models/CoreDuty';
import { RequestState } from '../../../models/RequestState';
import StatusCode from '../../../util/StatusCode';
import Loading from '../../misc/Loading';

interface DutyViewState {
    duties: DutyState
}

const DutyView = () => {
    const { dutyId } = useParams();
    const dispatch = useDispatch();
    const duty = useSelector<DutyViewState, RequestState<CoreDuty>>(state => state.duties.duty);

    useEffect(() => {
        if (dutyId !== undefined) {
            GetDuty(dutyId)(dispatch);
        }
    }, [dispatch, dutyId]);

    if (dutyId === undefined) {
        return;
    }

    switch (duty.code) {
        case StatusCode.COMPLETE:
            return (
                <Navigate to={`/user/${duty.data.user?.id}/schoolUnits`} replace={true} />
            );
        case StatusCode.ERROR:
            if (duty.error.response?.status === 404) {
                return (
                    <NotFound
                        header="Duty"
                        name="Dutyn"
                        link="/duty"
                    />
                );
            } else {
                return <p>{duty.error.response?.statusText}</p>
            }
        default:
            return (
                <Loading />
            );
    }
}

export default DutyView;
