import React, {FC, PropsWithChildren} from 'react';
import { Row } from 'reactstrap';
import NavMenu from './NavMenu';
import Top from './Top';

interface LayoutProps extends PropsWithChildren {
    navSection?: string,
}

const Layout: FC<LayoutProps> = props => <div>
    <Row className="layout">
        <div className="nav-left">
            <NavMenu activeSection={props.navSection} />
        </div>
        <div className="children">
            <Top />
            {props.children}
        </div>
    </Row>
</div>;
    
export default Layout;