import React, { useState } from 'react';
import { IDictionary, OnChangeCallback } from '../../../../../util/UtilityTypes';
import { TeamsFix } from '../../../../../models/TeamsFixTypes';
import { update } from '../../../../../util/Helper';
import BoolInput from '../../../../misc/BoolInput';
import NullableIntInput from '../../../../misc/NullableIntInput';
import { Button, Card, CardBody, CardHeader, Col, Collapse, Row, Alert } from 'reactstrap';
import TeamsFixStandardSettings from './TeamsFixStandardSettings';
import TeamsFixArchivedSettings from './TeamsFixArchivedSettings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface TeamsFixProps {
    teamsFix: TeamsFix
    disabled: boolean
    onChange: OnChangeCallback<TeamsFix>
    teamsFixEnabledSaved: boolean
    validation: string[]
}

const initialIsOpen: IDictionary<string, boolean> = {
    active: true,
    archived: true,
}

const TeamsFixComponent: React.FC<TeamsFixProps> = (props) => {
    const [isOpen, setOpen] = useState(initialIsOpen);

    return (<Card>
        <CardHeader tag="h5" style={{ padding: '1rem', flexDirection: 'row' }}>
            TeamsFix
        </CardHeader>
        <CardBody>
            {!props.teamsFixEnabledSaved && props.teamsFix.enabled &&
                <Alert style={{ display: 'inline-block' }} color="info"><FontAwesomeIcon icon="exclamation-circle" className="me-2" /> När du slår igång TeamsFix kan du också behöva acceptera nya behörigheter för inloggningsapplikationen och servicekontot.</Alert>
            }
            <BoolInput
                title="Slå på Teamsfix"
                name="enabled"
                value={props.teamsFix.enabled}
                disabled={props.disabled}
                updateValue={(n, v) => update('teamsFix', props.teamsFix, { enabled: v }, props.onChange)}
            />

            {props.teamsFix.enabled && <>
                <h5>Tidszon</h5>
                <NullableIntInput
                    title={<>Tidszon-id <a href="https://learn.microsoft.com/en-us/previous-versions/office/sharepoint-csom/jj171282(v=office.15)" target="_blank" rel="noreferrer">https://learn.microsoft.com/en-us/previous-versions/office/sharepoint-csom/jj171282(v=office.15)</a></>}
                    name="timeZoneId"
                    value={props.teamsFix.timeZoneId}
                    updateValue={(n, v) => update('teamsFix', props.teamsFix, { timeZoneId: v }, props.onChange)}
                    disabled={props.disabled}
                    defaultValue={4}
                    invalidFeedback="Ange antal tecken"
                    invalid={props.validation.indexOf('timeZoneId') !== -1}
                />

                <Row>
                    <Col xl={6}>
                        <Card className="mb-3">
                            <CardHeader tag="h5">
                                <Button outline color="secondary" style={{ width: '30px', float: 'right', lineHeight: '0.8', padding: '0.5rem' }} onClick={() => setOpen({ ...isOpen, active: !isOpen.active })}>{isOpen.active ? '-' : '+'}</Button>
                                Aktiva team
                            </CardHeader>
                            <Collapse isOpen={isOpen["active"]}>
                                <CardBody>
                                    <TeamsFixStandardSettings
                                        name='standard'
                                        disabled={props.disabled}
                                        teamsFixSettings={props.teamsFix.standardSettings}
                                        onChange={x => update('teamsFix', props.teamsFix, { standardSettings: x }, props.onChange)}
                                    />
                                </CardBody>
                            </Collapse>
                        </Card>
                    </Col>
                    <Col xl={6}>
                        <Card>
                            <CardHeader tag="h5">
                                <Button outline color="secondary" style={{ width: '30px', float: 'right', lineHeight: '0.8', padding: '0.5rem' }} onClick={() => setOpen({ ...isOpen, archived: !isOpen.archived })}>{isOpen.archived ? '-' : '+'}</Button>
                                Arkiverade team
                            </CardHeader>
                            <Collapse isOpen={isOpen["archived"]}>
                                <CardBody>
                                    <TeamsFixArchivedSettings
                                        name='archived'
                                        disabled={props.disabled}
                                        teamsFixSettings={props.teamsFix.archivedSettings}
                                        onChange={x => update('teamsFix', props.teamsFix, { archivedSettings: x }, props.onChange)}
                                    />
                                </CardBody>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
            </>}

        </CardBody>
    </Card >);
}

export default TeamsFixComponent;
