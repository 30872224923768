import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetGroup, GroupState } from '../../../store/GroupAction';
import * as Tab from '../../../util/TabValue';
import { useParams, Link, Route, Routes, Navigate } from 'react-router-dom'
import RenderTabs from '../../misc/Tabs';
import GroupViewObjectLogs from './GroupViewObjectLogs';
import GroupViewInfo from './GroupViewInfo';
import GroupViewMembers from './GroupViewMembers';
import GroupViewActivities from './GroupViewActivities';
import GroupViewTrackings from './GroupViewTrackings';
import NotFound from '../components/NotFound';
import { CoreStudentGroup } from '../../../models/CoreGroup';
import { RequestState } from '../../../models/RequestState';
import StatusCode from '../../../util/StatusCode';
import Loading from '../../misc/Loading';

interface GroupViewState {
    groups: GroupState
}


const GroupView = () => {
    const { groupId } = useParams();
    const dispatch = useDispatch();
    const group = useSelector<GroupViewState, RequestState<CoreStudentGroup>>(state => state.groups.group);

    useEffect(() => {
        if (groupId !== undefined) {
            GetGroup(groupId)(dispatch);
        }
    }, [dispatch, groupId]);

    const tabs = [
        Tab.Tabs.Information,
        Tab.Tabs.ObjectLogs,
        Tab.Tabs.Members,
        Tab.Tabs.Activities,
        Tab.Tabs.Trackings,
    ];

    if (groupId === undefined) {
        return;
    }

    switch (group.code) {
        case StatusCode.COMPLETE:
            return (
                <div className="padding-40 container single">
                    <h1><Link to="/group">Grupp</Link> {group.data.title}</h1>

                    <RenderTabs
                        tabs={tabs}
                        id={groupId}
                        url="group" />

                    <Routes>
                        <Route path={Tab.Tabs.Information.url} element={<GroupViewInfo />} />
                        <Route path={Tab.Tabs.ObjectLogs.url} element={<GroupViewObjectLogs />} />
                        <Route path={Tab.Tabs.Members.url} element={<GroupViewMembers />} />
                        <Route path={Tab.Tabs.Activities.url} element={<GroupViewActivities />} />
                        <Route path={Tab.Tabs.Trackings.url} element={<GroupViewTrackings />} />
                        <Route path="*" element={<Navigate replace={true} to={`/group/${groupId}/${Tab.Tabs.Information.url}`} />} />
                    </Routes>
                </div>
            );
        case StatusCode.ERROR:
            if (group.error.response?.status === 404) {
                return (
                    <NotFound
                        header="Grupp"
                        name="Gruppen"
                        link="/group"
                    />
                );
            } else {
                return <p>{group.error.response?.statusText}</p>
            }
        default:
            return (
                <Loading />
            );
    }
}

export default GroupView;
