import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StatusCode from "../../../util/StatusCode";
import ActiveToggle from "../../misc/ActiveToggle";
import TableHorizontalPagedToggle, { InfoObject } from "../../misc/TableHorizontalPagedToggle";
import * as Helper from '../../../util/Helper';
import * as GroupType from '../../../util/GroupTypeUtil';
import * as AssignmentRole from '../../../util/AssignmentRoleTypeUtil';
import { GetStudentGroups, GetTeacherGroups, UserGroupsRequestState, UserState } from "../../../store/UserAction";

interface UserViewGroupState {
    users: UserState
}

const UserViewGroups = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const [show, setShow] = useState({ student: true, teacher: true });

    const teacherGroups = useSelector<UserViewGroupState, UserGroupsRequestState>(state => state.users.teacherGroups);
    const studentGroups = useSelector<UserViewGroupState, UserGroupsRequestState>(state => state.users.studentGroups);

    useEffect(() => {
        if (userId) {
            GetTeacherGroups(userId, {})(dispatch);
            GetStudentGroups(userId, {})(dispatch);
        }
    }, [dispatch, userId]);

    const updateTeacherGroupsPage = (value: number) => {
        if (userId) {
            GetTeacherGroups(userId, { ...teacherGroups.filter, page: value })(dispatch);
        }
    }

    const updateStudentGroupsPage = (value: number) => {
        if (userId) {
            GetStudentGroups(userId, { ...studentGroups.filter, page: value })(dispatch);
        }
    }

    const updateOnlyActive = (value: boolean) => {
        if (userId) {
            GetStudentGroups(userId, { ...studentGroups.filter, onlyActive: value, page: 1 })(dispatch);
            GetTeacherGroups(userId, { ...teacherGroups.filter, onlyActive: value, page: 1 })(dispatch);
        }
    }

    const mapTeacherGroups = (groups: UserGroupsRequestState): InfoObject[] => {
        if (groups.code !== StatusCode.COMPLETE) {
            return [];
        }

        return groups.data.values.map(x => ({
            id: x.id,
            data: [
                { value: <Link to={`/group/${x.id}/information`}>{x.title}</Link> },
                { value: <Link to={`/schoolunit/${x.schoolUnit.id}/information`}>{x.schoolUnit.title}</Link> },
                { value: GroupType.groupTypeFromId(x.groupType.id) },
                { value: AssignmentRole.assignmentRoleTypeFromId(x.role) },
                { value: Helper.active(x.active) },
                { value: Helper.formatDateOnly(x.startDate) },
                { value: Helper.formatDateOnly(x.endDate) },
            ]
        }));
    }

    const mapStudentGroups = (groups: UserGroupsRequestState): InfoObject[] => {
        if (groups.code !== StatusCode.COMPLETE) {
            return [];
        }

        return groups.data.values.map(x => ({
            id: x.id,
            data: [
                { value: <Link to={`/group/${x.id}/information`}>{x.title}</Link> },
                { value: <Link to={`/schoolunit/${x.schoolUnit.id}/information`}>{x.schoolUnit.title}</Link> },
                { value: GroupType.groupTypeFromId(x.groupType.id) },
                { value: Helper.active(x.active) },
                { value: Helper.formatDateOnly(x.startDate) },
                { value: Helper.formatDateOnly(x.endDate) },
            ]
        }));
    }

    const renderTeacherGroups = () => {
        const teacherInfo = mapTeacherGroups(teacherGroups);
        const count = Helper.getTotalCountText(teacherGroups);
        return (
            <TableHorizontalPagedToggle
                header={[{ value: `Personalkoppling ${count}` }, { value: 'Skola' }, { value: 'Grupptyp' }, { value: 'Roll' }, { value: 'Aktiv' }, { value: 'Startdatum' }, { value: 'Slutdatum' }]}
                mobileHeader={['Namn', 'Skola', 'Grupptyp', 'Roll', 'Aktiv', 'Startdatum', 'Slutdatum']}
                info={teacherInfo}
                show={show.teacher}
                toggle={() => setShow({ ...show, teacher: !show.teacher })}
                displayName={`Personalkopplingar ${count}`}
                onChangePage={updateTeacherGroupsPage}
                noResultMsg="Personen saknar personalkopplingar till grupper"
                requestState={teacherGroups}
            />
        )
    }

    const renderStudentGroups = () => {
        const studentInfo = mapStudentGroups(studentGroups);
        const count = Helper.getTotalCountText(studentGroups);
        return (
            <TableHorizontalPagedToggle
                header={[{ value: `Elevkoppling ${count}` }, { value: 'Skola' }, { value: 'Grupptyp' }, { value: 'Aktiv' }, { value: 'Startdatum' }, { value: 'Slutdatum' }]}
                mobileHeader={['Namn', 'Skola', 'Grupptyp', 'Aktiv', 'Startdatum', 'Slutdatum']}
                info={studentInfo}
                show={show.student}
                toggle={() => setShow({ ...show, student: !show.student })}
                displayName={`Elevkoppling ${count}`}
                onChangePage={updateStudentGroupsPage}
                noResultMsg="Personen saknar elevkopplingar till grupper"
                requestState={studentGroups}
            />
        )
    }

    return (<>
        <div className="header_member_table large">Grupper
            <span className="right">
                <ActiveToggle
                    onlyActive={studentGroups.filter.onlyActive ?? true}
                    update={updateOnlyActive}
                    disabled={studentGroups.code === StatusCode.PENDING || teacherGroups.code === StatusCode.PENDING}
                />
            </span></div>
        {renderTeacherGroups()}
        {renderStudentGroups()}
    </>);
}

export default UserViewGroups;
