import { Action, Dispatch, Reducer } from 'redux';
import { CoreSyllabusFilter, RoleFilter } from '../models/CoreModels';
import InstanceClient from '../services/InstanceClient';

export enum FilterActionType {
    GET_ROLES = 'GET_ROLES',
    GET_SYLLABUSES = 'GET_SYLLABUSES',
}

export interface FilterState {
    roles: RoleFilter[]
    syllabuses: CoreSyllabusFilter[]
}

interface Syllabuses extends Action<FilterActionType> {
    type: FilterActionType.GET_SYLLABUSES
    syllabuses: CoreSyllabusFilter[]
}

interface Roles extends Action<FilterActionType> {
    type: FilterActionType.GET_ROLES
    roles: RoleFilter[]
}


type FilterAction = Syllabuses | Roles

export const getRoles = () => (dispatch: Dispatch<Roles> ) => {
    const url = '/api/core/filter/roles';
    InstanceClient.get<RoleFilter[]>(url)
        .then(res => {
            dispatch({
                type: FilterActionType.GET_ROLES,
                roles: res.data,
            })
        });
};

export const getSyllabuses = (schoolUnitIds: string[]) => (dispatch: Dispatch<Syllabuses>) => {
    const url = `/api/core/filter/syllabuses`;
    InstanceClient.get<CoreSyllabusFilter[]>(url, { schoolUnitIds })
        .then(res =>
            dispatch({
                type: FilterActionType.GET_SYLLABUSES,
                syllabuses: res.data,
            }));
}

const initialState: FilterState = {
    roles: [],
    syllabuses: [],
}

export const reducer: Reducer<FilterState, FilterAction> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case FilterActionType.GET_ROLES:
            return {
                ...state,
                roles: action.roles,
            };
        case FilterActionType.GET_SYLLABUSES:
            return {
                ...state,
                syllabuses: action.syllabuses,
            };
        default:
            return state;
    }
};
