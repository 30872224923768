import React, { useState } from 'react';
import { Card, CardHeader, CardBody, Collapse, Button } from 'reactstrap';
import { OnChangeCallback, IDictionary, OptionItem } from '../../../../../../util/UtilityTypes'
import BoolInput from '../../../../../misc/BoolInput';
import NullableIntInputCheckboxText from '../../../../../misc/NullableIntInputCheckboxText';
import * as GoogleUser from '../../../../../../util/GoogleUserExportTypes';
import GoogleUserEmailAddressPolicy from './GoogleUserEmailAddressPolicy';
import GoogleUserStudentOrganizationalUnitPolicy from './GoogleUserStudentOrganizationalUnitPolicy';
import GoogleUserPasswordPolicy from './GoogleUserPasswordPolicy';
import GoogleUserGeneralSettings from './GoogleUserGeneralSettings';
import { update } from '../../../../../../util/Helper';
import GoogleUserCustomAttributes from './GoogleUserCustomAttributes';

const NAME = 'studentConfiguration';

interface GoogleUserExportStudentProps {
    configuration: GoogleUser.StudentConfiguration
    configurationType: string
    update: OnChangeCallback<GoogleUser.StudentConfiguration>
    validation: string[]
    disableInput: boolean
    sourceSystemOptions: OptionItem<string>[]
}

const GoogleUserExportStudent: React.FC<GoogleUserExportStudentProps> = (props) => {
    const [isOpen, setOpen] = useState(initialIsOpen);

    const renderCard = (component: React.ReactNode, type: string, displayName: string) => {
        return (
            <Card>
                <CardHeader tag="h5">
                    <Button outline color="secondary" style={{ width: '30px', float: 'right', lineHeight: '0.8', padding: '0.5rem' }} onClick={() => setOpen({ ...isOpen, [type]: !isOpen[type] })}>{isOpen[type] ? '-' : '+'}</Button>
                    {displayName}
                </CardHeader>
                <Collapse isOpen={isOpen[type]}>
                    <CardBody>
                        {component}
                    </CardBody>
                </Collapse>
            </Card>
        );
    }

    return (
        <Card className="mb-3">
            <CardHeader tag="h4">
                <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => setOpen({ ...isOpen, student: !isOpen.student })}>{isOpen["student"] ? '-' : '+'}</Button>
                Elever
            </CardHeader>
            <Collapse isOpen={isOpen["student"]}>
                <CardBody className="multiple-cards">

                    <BoolInput
                        name={`${props.configurationType}-enabled`}
                        value={props.configuration.enabled}
                        disabled={props.disableInput}
                        title="Synkronisera elevkonton"
                        updateValue={(v, k) => update(NAME, props.configuration, { enabled: k }, props.update)}
                    />

                    {props.configuration.enabled && <>
                        {renderCard(
                            <GoogleUserGeneralSettings
                                generalSettings={{
                                    displayNamePolicy: props.configuration.displayNamePolicy,
                                    includeHomePhone: props.configuration.includeHomePhone,
                                    includeMobilePhone: props.configuration.includeMobilePhone,
                                    includeWorkPhone: props.configuration.includeWorkPhone, 
                                    includeHomeAddress: props.configuration.includeHomeAddress,
                                    includeUserOrganisations: props.configuration.includeUserOrganisations,
                                }}
                                onChange={(n, v) => update(NAME, props.configuration, { ...v }, props.update)}
                                disabled={props.disableInput}
                                validation={props.validation}
                                suffixOptions={GoogleUser.studentDisplayNameSuffixes}
                                configurationType={props.configurationType}
                                
                            />
                            , "displayName", "Allmänt")}

                        {renderCard(
                            <GoogleUserEmailAddressPolicy
                                emailAddressPolicy={props.configuration.emailAddressPolicy}
                                onChange={(n, v) => update(NAME, props.configuration, { emailAddressPolicy: v }, props.update)}
                                disabled={props.disableInput}
                                validation={props.validation}
                                configurationType={props.configurationType}
                            />
                            , "email", "E-postadress policy")}

                        {renderCard(
                            <GoogleUserStudentOrganizationalUnitPolicy
                                organizationalUnitPolicy={props.configuration.organizationalUnitPolicy}
                                onChange={(n, v) => update(NAME, props.configuration, { organizationalUnitPolicy: v }, props.update)}
                                disabled={props.disableInput}
                                validation={props.validation}
                                configurationType={props.configurationType}
                            />
                            , "ou", "OU policy")}

                        {renderCard(
                            <GoogleUserPasswordPolicy
                                passwordPolicy={props.configuration.passwordPolicy}
                                onChange={(n, v) => update(NAME, props.configuration, { passwordPolicy: v }, props.update)}
                                disabled={props.disableInput}
                                validation={props.validation}
                                configurationType={props.configurationType}
                            />
                            , "password", "Lösenordspolicy")}

                        {renderCard(
                            <GoogleUserCustomAttributes
                                customAttributes={props.configuration.customAttributes}
                                onChange={(n, v) => update(NAME, props.configuration, { customAttributes: v }, props.update)}
                                disabled={props.disableInput}
                                validation={props.validation}
                                configurationType={props.configurationType}
                                sourceSystemOptions={props.sourceSystemOptions}
                            />
                            , "attribute", "Anpassade attribut")}
                    </>}

                    {renderCard(<>
                        <h5>Radering</h5>
                        <NullableIntInputCheckboxText
                            name={`${props.configurationType}-deleteAfterDays`}
                            value={props.configuration.deleteAfterDays}
                            disabled={props.disableInput}
                            title="Radera X antal dagar efter inaktivering"
                            defaultValue={365}
                            onChange={(n, v) => update(NAME, props.configuration, { deleteAfterDays: v }, props.update)}
                            checkboxTitle="Ingen radering"
                            invalidFeedback="Ange dagar till radering"
                            invalid={props.validation.indexOf(`${props.configurationType}.deleteAfterDays`) !== -1}
                        />
                        </>
                        , "lifecycle", "Livscykelhantering")}

                </CardBody>
            </Collapse>
        </Card>);
}

const initialIsOpen: IDictionary<string, boolean> = {
    student: true,
    displayName: true,
    email: true,
    ou: true,
    password: true,
    attribute: true,
    lifecycle: true,
}

export default GoogleUserExportStudent; 
