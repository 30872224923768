import { Component } from 'react';
import { equvivalent } from '../../../util/Helper'

class ConfigurationComponent extends Component {
    propertyName = 'configuration'

    validate() { return []; }

    warnings() { return []; }

    getAdditionalAppTokenScopes(merged) { return []; }

    componentDidMount() {
        const merged = this.mergeWithUpdates(this.props[this.propertyName], this.props.updates);
        this.setInvalid(merged, this.props.active);
    }

    componentDidUpdate(prevProps) {
        if (this.props.active !== prevProps.active) {
            const merged = this.mergeWithUpdates(this.props[this.propertyName], this.props.updates);
            this.setInvalid(merged, this.props.active);
        }
    }

    setInvalid(configuration, active) {
        const validation = this.validate(configuration, active);
        this.props.invalid(this.result(validation.length > 0));
    }

    mergeWithUpdates(module, updates) {
        const merged = { ...module };
        Object.keys(updates).forEach(key => {
            merged[key] = updates[key];
        });
        return merged;
    }

    // This acts onChange, needs to be lambda to preserve "this"
    updateProperty = (e) => {
        const prop = e.target.name;
        const value = this.getValue(e);
        this.updatePropertyValue(prop, value)
    }

    // This acts onChange, needs to be lambda to preserve "this"
    updatePropertyValue = (prop, value) => {
        const newUpdates = { ...this.props.updates };

        if (equvivalent(this.props[this.propertyName][prop], value)) {
            delete newUpdates[prop];
        } else {
            newUpdates[prop] = value;
        }

        if (this.updateState) {
            this.updateState(newUpdates, prop, value);
        }

        this.notifyUpdates(newUpdates);
        this.updateData(newUpdates);
    }

    notifyUpdates(updates) {
        const warnings = this.warnings(updates);
        this.props.onUpdate({
            updates: this.result(updates),
            warnings: this.result(warnings),
        });
    }

    getValue(e) {
        let value = "";

        switch (e.target.type) {
            case 'checkbox':
                value = e.target.checked;
                break;
            case 'number':
                if (e.target.value) {
                    value = parseInt(e.target.value, 10);
                }
                break;
            case 'radio':
                value = parseInt(e.target.value, 10);
                break;
            default:
                value = e.target.value;
                break;
        }

        if (value === "") {
            value = null;
        }

        return value;
    }

    updateData(newUpdates) {
        var merged = this.mergeWithUpdates(this.props[this.propertyName], newUpdates);
        let additionalScopes = this.getAdditionalAppTokenScopes(merged);
        this.props.setAdditionalAppTokenScopes(additionalScopes)
        this.props.mergeUpdate(this.result(merged));
        this.setInvalid(merged, this.props.active);
    }

    result(obj) {
        const res = {};
        res[this.propertyName] = obj;
        return res;
    }
}

export default ConfigurationComponent;