import { Action, Dispatch, Reducer } from "redux";
import { CoreAppToken, CoreAppTokenValidation } from "../models/CoreAppToken";
import { CoreAppTokenRequest } from "../models/CoreAppToken";
import { RequestState } from "../models/RequestState";
import InstanceClient from "../services/InstanceClient";
import StatusCode from "../util/StatusCode";

enum AppTokenActionType {
    VALIDATE = 'VALIDATE',
    TOKEN = 'TOKEN',
}

interface StoreAppTokenAction extends Action<AppTokenActionType> {
    type: AppTokenActionType.TOKEN
    appToken: RequestState<CoreAppToken>
}

interface ValidateAppTokenAction extends Action<AppTokenActionType> {
    type: AppTokenActionType.VALIDATE
    appTokenValidate: RequestState<CoreAppTokenValidation>
}

type AppTokenAction = StoreAppTokenAction | ValidateAppTokenAction;

export interface AppTokenState {
    appTokenValidation: RequestState<CoreAppTokenValidation>,
    appToken: RequestState<CoreAppToken>
}

export const validateAppToken = (appTokenId: string, additionalScopes?: string[]) => (dispatch: Dispatch<ValidateAppTokenAction>) => {
    dispatch({
        type: AppTokenActionType.VALIDATE,
        appTokenValidate: {
            code: StatusCode.PENDING,
        }
    })

    return InstanceClient.get<CoreAppTokenValidation>(`/api/core/apptoken/${appTokenId}/validate`, { additionalScopes: additionalScopes })
        .then(res => {
            dispatch({
                type: AppTokenActionType.VALIDATE,
                appTokenValidate: {
                    code: StatusCode.COMPLETE,
                    data: res.data
                }
            })
        })
        .catch(err => {
            dispatch({
                type: AppTokenActionType.VALIDATE,
                appTokenValidate: {
                    code: StatusCode.COMPLETE,
                    data: err.response.data
                }
            })
        })
}

export const clearAppToken = (appTokenId: string) => async (dispatch: Dispatch<StoreAppTokenAction>) => {
    try {
        var res = await InstanceClient.put<CoreAppToken, void>(`/api/core/apptoken/${appTokenId}/clearAppToken`);
        dispatch({
            type: AppTokenActionType.TOKEN,
            appToken: {
                code: StatusCode.COMPLETE,
                data: res.data
            }
        });
    }
    catch (err: any) {
        dispatch({
            type: AppTokenActionType.TOKEN,
            appToken: {
                code: StatusCode.ERROR,
                error: err
            }
        });
    }
}

export const updateAppToken = (appTokenId: string, request: CoreAppTokenRequest) => (dispatch: Dispatch<StoreAppTokenAction>) => {
    return InstanceClient.put<CoreAppToken, CoreAppTokenRequest>(`/api/core/apptoken/${appTokenId}`, request)
        .then(res =>
            dispatch({
                type: AppTokenActionType.TOKEN,
                appToken: {
                    code: StatusCode.COMPLETE,
                    data: res.data
                }
            })
        )
        .catch(err =>
            dispatch({
                type: AppTokenActionType.TOKEN,
                appToken: {
                    code: StatusCode.ERROR,
                    error: err
                }
            })
        )
};

export const microsoftDelegated = (appTokenId: string, code: string) => async (dispatch: Dispatch<StoreAppTokenAction>) => {
    try {
        var res = await InstanceClient.put<CoreAppToken, void>(`/api/core/apptoken/${appTokenId}/code/microsoftDelegated?code=${code}`);
        dispatch({
            type: AppTokenActionType.TOKEN,
            appToken: {
                code: StatusCode.COMPLETE,
                data: res.data
            }
        })
    } catch (err: any) {
        dispatch({
            type: AppTokenActionType.TOKEN,
            appToken: {
                code: StatusCode.ERROR,
                error: err
            }
        })
    }
};

export const setAppToken = (appToken: CoreAppToken) => (dispatch: Dispatch<StoreAppTokenAction>) => {
    dispatch({
        type: AppTokenActionType.TOKEN,
        appToken: {
            code: StatusCode.COMPLETE,
            data: appToken
        }
    })
} 

const initialState: AppTokenState = {
    appTokenValidation: { code: StatusCode.NONE },
    appToken: { code: StatusCode.NONE },
}

export const reducer: Reducer<AppTokenState, AppTokenAction> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case AppTokenActionType.VALIDATE:
            return {
                ...state,
                appTokenValidation: action.appTokenValidate,
            }
        case AppTokenActionType.TOKEN:
            return {
                ...state,
                appToken: action.appToken
            }
        default:
            return state;
    }
};