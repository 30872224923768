import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CoreActivitySyllabus } from '../../../models/CoreActivity';
import { RequestState } from '../../../models/RequestState';
import { ActivityState, GetActivitySyllabus } from '../../../store/ActivityAction';
import StatusCode from '../../../util/StatusCode';
import TableVertical, { TableVerticalInfoObject } from '../../misc/TableVertical';
import * as Helper from '../../../util/Helper';
import * as SchoolTypeUtil from '../../../util/SchoolTypeUtil';
import * as CurriculumUtil from '../../../util/CurriculumUtil';

interface ActivitySyllabusViewState {
    activities: ActivityState
}

const ActivitySyllabusView: React.FC = () => {
    const { activityId } = useParams();
    const dispatch = useDispatch();
    const syllabus = useSelector<ActivitySyllabusViewState, RequestState<CoreActivitySyllabus>>(state => state.activities.activitySyllabus);

    useEffect(() => {
        if (activityId) {
            GetActivitySyllabus(activityId)(dispatch)
        }
    }, [activityId, dispatch]);

    const isError = syllabus.code === StatusCode.ERROR;
    const isPending = syllabus.code === StatusCode.PENDING;
    const syllabusInfo: TableVerticalInfoObject[] = [];

    if (syllabus.code === StatusCode.COMPLETE) {
        syllabusInfo.push(
            { header: 'Titel', content: syllabus.data.specialisationCourseContent?.title },
            { header: 'Beskrivning', content: syllabus.data.specialisationCourseContent?.description },
            { header: 'Engelsk titel', content: syllabus.data.specialisationCourseContent?.titleEnglish },
            { header: 'Engelsk beskrivning', content: syllabus.data.specialisationCourseContent?.descriptionEnglish },
            { header: 'Ämneskod', content: syllabus.data.subjectCode },
            { header: 'Ämnesnamn', content: syllabus.data.subjectName },
            { header: 'Ämneskategori', content: syllabus.data.subjectDesignation },
            { header: 'Kurskod', content: syllabus.data.courseCode },
            { header: 'Kursnamn', content: syllabus.data.courseName },
            { header: 'Id', content: syllabus.data.id },
            { header: 'Aktiv', content: Helper.active(syllabus.data.active) },
            { header: 'Officiell', content: Helper.active(syllabus.data.official) },
            { header: 'Startår', content: syllabus.data.startSchoolYear },
            { header: 'Slutår', content: syllabus.data.endSchoolYear },
            { header: 'Poäng', content: syllabus.data.points },
            { header: 'Språkkod', content: syllabus.data.languageCode },
            { header: 'Skolform', content: SchoolTypeUtil.getTypes(syllabus.data.schoolType) },
            { header: 'Läroplan', content: CurriculumUtil.getName(syllabus.data.curriculum) },
            { header: 'Skapad', content: Helper.formatDate(syllabus.data.created) },
            { header: 'Ändrad', content: Helper.formatDate(syllabus.data.modified) },
            { header: 'Raderad', content: Helper.formatDate(syllabus.data.removed) },
        )
    }

    return (<TableVertical header='Kursplan' info={syllabusInfo} error={isError} searching={isPending} />)
}

export default ActivitySyllabusView;