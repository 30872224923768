const swedishEventDescriptions = {
    10001: "Ny modul skapad",
    10002: "Modulen uppdaterades",
    10003: "Modulen förfrågades att starta",
    10100: "Behörighet uppdaterad",
    10110: "Licens borttagen",
    10111: "Licens uppdaterad",
    10112: "Ny licens skapad",
    10120: "Grupp borttagen",
    10121: "Ny grupp skapad",
    10122: "Grupp uppdaterad",
    10200: "Användare tillagd",
    10300: "Kunds servicekonto uppdaterat",
    10400: "Apptoken borttagen",
    10401: "Apptoken konfigurerades",
    10500: "Ny clientId/clientSecret skapad",
    10501: "Rensning/återställning av sftp-fingeravtrycket för apple school manager modulen utfördes",
    10502: "Rensning/återställning av sftp-fingeravtrycket för alvis import modulen utfördes",
    10600: "Primär skola för person valdes",
    10601: "Primär skola för person togs bort",
    10700: "Aktivitetskoppling uppdaterad",
    10800: "En koppling till ett Google konto för en person uppdaterades",
    10801: "En koppling till ett Google konto för en person inaktiverades",
    10802: "En koppling till ett Google konto för en person återställdes",
    10803: "En koppling till ett Google konto för en person skapades",
}

const dictionaries = {
    'sv-SE': swedishEventDescriptions,
}

export const translateEvent = (event, languageCode) => {
    return translateEventId(event.eventId, event.description, languageCode);
}

export const translateEventId = (eventId, fallback, languageCode) => {
    if (!languageCode) {
        throw new Error('No languageCode supplied, unable to translate event.')
    }

    var dictionary = dictionaries[languageCode];
    if (dictionary) {
        var description = dictionary[eventId];
        if (description) {
            return description;
        }
    }

    return fallback;
}

const sourceTypes = {
    0: "Okänd",
    1: "Modul",
    2: "Apptoken",
    3: "Licens",
    4: "Grupp",
    5: "Användare",
    6: "Kund",
    7: "Skoladmin Användare",
}

export const sourceTypeFromId = (id) => {
    const sourceType = sourceTypes[id];
    if (sourceType) {
        return sourceType;
    }
    return id.toString();
}
