import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetModuleInstancePhases, ModuleInstancePhasesSubscribe, ModuleInstancePhaseState } from '../../../../store/ModuleInstancePhaseAction';
import * as Helper from '../../../../util/Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatusCode from '../../../../util/StatusCode';
import * as InstancePhaseStatus from './InstancePhaseStatus';
import { useParams } from 'react-router-dom';
import Loading from '../../../misc/Loading';
import { CoreModuleInstancePhase } from '../../../../models/CoreModels';
import { RequestState } from '../../../../models/RequestState';

interface InstancePhaseTableProps {
    completed: boolean
}

interface InstancePhaseTableState {
    instancePhase: ModuleInstancePhaseState
}

const InstancePhaseTable: React.FC<InstancePhaseTableProps> = (props) => {
    const { moduleInstanceId } = useParams();
    const dispatch = useDispatch();
    const instancePhases = useSelector<InstancePhaseTableState, RequestState<CoreModuleInstancePhase[]>> (state => state.instancePhase.instancePhases);
    const [show, setShow] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        if (moduleInstanceId) {
            GetModuleInstancePhases(moduleInstanceId)(dispatch);
        }
    }, [dispatch, moduleInstanceId]);

    useEffect(() => {
        if (props.completed) {
        } else if (moduleInstanceId) {
            return ModuleInstancePhasesSubscribe(moduleInstanceId)(dispatch);
        }
    }, [dispatch, moduleInstanceId, props.completed]);

    const renderTable = () => {
        const header = ['Fas', 'Starttid', 'Tid', 'Sluttid'];

        return (
            <div className="complete-phase-table pl-40 pr-40 pb-40">
                <div className="head">
                    {header.map((h, i) => (
                        <div key={i}>{h}</div>
                    ))}
                </div>
                {renderBody()}
            </div>);
    }

    const renderBody = () => {
        switch (instancePhases.code) {
            case StatusCode.PENDING:
                return <Loading />;
            case StatusCode.ERROR:
                return <p>Kunde inte hämta statistik för den här körningen</p>
            case StatusCode.COMPLETE:
                if (instancePhases.data.length > 0) {
                    return renderTableBody(instancePhases.data);
                } else {
                    return (<p className="no-result">Statistik saknas för den här körningen</p>);
                }
            default: return;
        }
    }

    const renderTableBody = (phases: CoreModuleInstancePhase[]) => {
        const miniHeader = ['Objekt', 'Hanterade', 'Nya', 'Förändrade', 'Inaktiverade', 'Arkiverade', 'Borttagna', 'Misslyckades', 'Totalt'];

        return (
            <div className="body">
                {phases?.map(ip => (
                        <div key={ip.id} className={(show[ip.id] ? 'show-phase-data' : '') + " phase-row"}>
                            <div onClick={() => ip.moduleInstancePhaseStats.length && setShow({ ...show, [ip.id]: !show[ip.id] })} className="phase-header">
                                <span data-label="Fas" className="info">
                                    <span className="icon" title={InstancePhaseStatus.getStatus(ip.status)}>{InstancePhaseStatus.getIcon(ip.status)}</span>
                                    <span>{ip.phaseName}</span>
                                    {ip.moduleInstancePhaseStats.length ? <span className="right">{show[ip.id] ? <FontAwesomeIcon icon="chevron-down" /> : <FontAwesomeIcon icon="chevron-right" />}</span> : null}
                                </span>
                                <span className="dates">
                                    <span data-label="Starttid">{Helper.formatDate(ip.startedAt)}</span>
                                    <span data-label="Tid">{InstancePhaseStatus.getDuration(ip.startedAt, ip.completedAt)}</span>
                                    <span data-label="Sluttid">{Helper.formatDate(ip.completedAt)}</span>
                                </span>
                            </div>

                            {ip.moduleInstancePhaseStats.length ?
                                <div className="body">
                                    <span className="phase-mini-header">
                                        {show[ip.id] && miniHeader.map((mh, i) => (
                                            <span key={i}>{mh}</span>
                                        ))}
                                    </span>

                                    {show[ip.id] && ip.moduleInstancePhaseStats.map((mips, i) => (
                                        <span key={mips.id} className="phase-data">
                                            <span data-label={miniHeader[i]}>{mips.objectType}</span>
                                            <span data-label={miniHeader[i]}>{mips.handled}</span> 
                                            <span data-label={miniHeader[i]}>{mips.created}</span>
                                            <span data-label={miniHeader[i]}>{mips.updated}</span>
                                            <span data-label={miniHeader[i]}>{mips.inactivated}</span>
                                            <span data-label={miniHeader[i]}>{mips.archived}</span>
                                            <span data-label={miniHeader[i]}>{mips.deleted}</span>
                                            <span data-label={miniHeader[i]}>{mips.failed}</span>
                                            <span data-label={miniHeader[i]}>{mips.total}</span>
                                        </span>
                                    ))}
                                </div>
                                : null}
                        </div>
                    ))}
            </div>
        )
    }

    return (<>
        {renderTable()}
    </>);
}

export default InstancePhaseTable;
