import React from 'react';
import { FormGroup, Label, Input, Card, CardHeader, CardBody, Collapse, Button, FormFeedback } from 'reactstrap';
import ConfigurationComponent from '../ConfigurationComponent';
import BoolInput from '../../../misc/BoolInput';
import HaldorClassSetting from './components/Haldor/HaldorClassSetting'
import HaldorEducationGroupSetting from './components/Haldor/HaldorEducationGroupSetting'
import HaldorMentorGroupSetting from './components/Haldor/HaldorMentorGroupSetting'


class Haldor extends ConfigurationComponent {
    state = {
        open: true,
    }

    validate(configuration, active) {
        const validation = [];

        if (active) {
            if (!configuration.blobContainerUrl || configuration.blobContainerUrl === "") {
                validation.push('blobContainerUrl');
            }
            if (!configuration.blobSourceKey || configuration.blobSourceKey === "") {
                validation.push('blobSourceKey');
            }
        }
        return validation;
    }

    render() {
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);
        const validation = this.validate(configuration, this.props.active);
                       
        return (
            <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    {this.props.moduleDisplayName}
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>
                        <div style={{ margin: '1rem 0' }}>
                                                    
                            <HaldorClassSetting
                                title='CLASS hantering'
                                name='classSettings'
                                value={configuration.classSettings}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                            />

                            <HaldorEducationGroupSetting
                                title='EDUCATION_GROUP hantering'
                                name='educationGroupSettings'
                                value={configuration.educationGroupSettings}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                            />

                            <HaldorMentorGroupSetting
                                title='MENTOR_GROUP hantering'
                                name='mentorGroupSettings'
                                value={configuration.mentorGroupSettings}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                            />

                            <BoolInput
                                title='Inkludera personnummer'
                                name='includeCivicNo'
                                value={configuration.includeCivicNo}
                                updateValue={(prop, value) => this.updatePropertyValue(prop, value)}
                                disabled={this.props.disableInput}
                            />
                            <BoolInput
                                title='Inkludera vårdnadshavare'
                                name='includeCustodians'
                                value={configuration.includeCustodians}
                                updateValue={(prop, value) => this.updatePropertyValue(prop, value)}
                                disabled={this.props.disableInput}
                            />

                            <FormGroup>
                                <h5>Åtkomst</h5>
                                <FormGroup>
                                    <Label for="blobContainerUrl">Länk till Container</Label>
                                    <Input type="text" name="blobContainerUrl" id="blobContainerUrl" placeholder="Länk till Container" disabled={this.props.disableInput} value={configuration.blobContainerUrl || ''} onChange={this.updateProperty} invalid={validation.indexOf('blobContainerUrl') !== -1} autoComplete="off" />
                                    <FormFeedback>Länk till Container måste anges för att kunna aktivera modulen</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="blobSourceKey">Åtkomstnyckel</Label>
                                    <Input type="text" name="blobSourceKey" id="blobSourceKey" placeholder="Åtkomstnyckel" value={configuration.blobSourceKey || ''} disabled={this.props.disableInput} onChange={this.updateProperty} invalid={validation.indexOf('blobSourceKey') !== -1} autoComplete="off" />
                                    <FormFeedback>Åtkomstnyckel måste anges för att kunna aktivera modulen</FormFeedback>
                                </FormGroup>
                            </FormGroup>

                        </div>
                    </CardBody>
                </Collapse>
            </Card>)
    }
}

export default Haldor;
