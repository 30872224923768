export enum SchoolType {
    /// <summary>
    /// Ingen
    /// </summary>
    NONE = 0x00000,

    /// <summary>
    /// Förskola
    /// </summary>
    PRESCHOOL = 0x00001,

    /// <summary>
    /// Förskoleklass
    /// </summary>
    PRESCHOOLCLASS = 0x00002,

    /// <summary>
    /// Fritidshem
    /// </summary>
    LEISURETIMECENTER = 0x00004,

    /// <summary>
    /// Grundskola
    /// </summary>
    COMPULSORY = 0x00008,

    /// <summary>
    /// Grundsärskola
    /// </summary>
    COMPULSORYSPECIALNEED = 0x00010,

    /// <summary>
    /// Specialskola
    /// </summary>
    SPECIALNEED = 0x00020,

    /// <summary>
    /// Sameskola
    /// </summary>
    SAMI = 0x00040,

    /// <summary>
    /// Gymnasieskola
    /// </summary>
    UPPERSECONDARY = 0x00080,

    /// <summary>
    /// Gymnasiesärskola
    /// </summary>
    UPPERSECONDARYSPECIALNEED = 0x00100,

    /// <summary>
    /// Vuxenutbildning
    /// </summary>
    ADULTEDUCATION = 0x00200,

    /// <summary>
    /// Särskild utbildning för vuxna
    /// </summary>
    ADULTEDUCATIONSPECIALNEED = 0x00400,

    /// <summary>
    /// Yrkeshögskola
    /// </summary>
    WORKHIGHERVOCATIONAL = 0x00800,

    /// <summary>
    /// Folkhögskola
    /// </summary>
    FOLKHIGH = 0x01000,

    /// <summary>
    /// Högskola
    /// </summary>
    HIGHERVOCATIONAL = 0x02000,

    /// <summary>
    /// Universitet
    /// </summary>
    UNIVERSITY = 0x04000,

    /// <summary>
    /// Svenska för invandrare (SFI)
    /// </summary>
    ADULTEDUCATIONSWEDISH = 0x08000,

    /// <summary>
    /// Pedagogisk omsorg
    /// </summary>
    PRESCHOOLLEISURETIMECENTER = 0x10000,

    /// <summary>
    /// Annan undervisning
    /// </summary>
    OTHER = 0x20000,
}