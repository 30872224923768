import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import TrackingsModule from '../components/TrackingsModule';
import { ActivityState, GetActivityTrackings } from '../../../store/ActivityAction';
import { RequestState } from '../../../models/RequestState';
import { CoreTracking } from '../../../models/CoreTracking';

interface ActivityViewTrackingsState {
    activities: ActivityState
}

const ActivityViewTrackings = () => {
    const { activityId } = useParams();
    const dispatch = useDispatch();
    const trackings = useSelector<ActivityViewTrackingsState, RequestState<CoreTracking[]>>(state => state.activities.activityTrackings);

    useEffect(() => {
        if (activityId !== undefined) {
            GetActivityTrackings(activityId)(dispatch);
        }
    }, [dispatch, activityId]);

    return (<TrackingsModule
        trackings={trackings}
        noResMsg="Den här aktiviteten saknar källor"
    />)
}

export default ActivityViewTrackings;
