import React, { useState } from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { OptionItem, OnChangeCallback } from '../../util/UtilityTypes'

interface NullableSelectProps {
    title: string
    checkboxTitle: string
    value: number | null
    name: string
    disabled?: boolean
    invalid: boolean
    invalidFeedback: string
    options: OptionItem<number>[]
    defaultValue: OptionItem<number>
    onChange: OnChangeCallback<number | null>
}

const NullableSelect: React.FC<NullableSelectProps> = (props) => {
    const [checked, setChecked] = useState(Number.isFinite(props.value));
    const [previous, setPrevious] = useState(props.value || props.defaultValue.value);

    const toggle = (shouldBeChecked: boolean) => {
        setChecked(shouldBeChecked);
        const value = shouldBeChecked ? previous : null;

        props.onChange(props.name, value)
    }

    const onChangeSelect = (input: string) => {
        const newValue = parseInt(input, 10);
        setPrevious(newValue);
        props.onChange(props.name, newValue);
    }

    const onClickDisabled = () => {
        if (!props.disabled && !checked) {
            toggle(true);
        }
    }

    return (
        <FormGroup>
            <Label style={{ display: 'block' }}>{props.title}</Label>
            <FormGroup check>
                <Input type="checkbox" style={{ marginTop: '12px' }} title={props.checkboxTitle}
                    disabled={props.disabled}
                    checked={checked}
                    onChange={(e) => toggle(e.target.checked)}
                />
                <FormGroup onClick={() => onClickDisabled()} style={{ marginLeft: '10px' }}>
                    <Input className="md-w-50 lg-w-25" type="select" value={props.value || props.defaultValue.value} name={props.name} id={props.name}
                        disabled={props.disabled || !checked}
                        onChange={(e) => onChangeSelect(e.target.value)}
                        invalid={props.invalid}>

                        <option value={props.defaultValue.value} disabled>{props.defaultValue.text}</option>
                        {props.options.map(opt => (<option key={opt.value} value={opt.value}>{opt.text}</option> ))}

                    </Input>
                    <FormFeedback>{props.invalidFeedback}</FormFeedback>
                </FormGroup>
            </FormGroup>
        </FormGroup>
    );
}

export default NullableSelect;
