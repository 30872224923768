import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { CoreDnpExportConfiguration, CoreDnpExportSchoolUnit, DnpEnvironment } from "../../../../../../models/CoreDnpModels";
import { CoreConfigurationSchoolUnit } from "../../../../../../models/CoreSchoolUnit";
import { ClearDnpOrganization, downloadFileForMoa, GetDnpOrganization } from "../../../../../../store/DnpAction";
import { OptionItem } from "../../../../../../util/UtilityTypes";
import EnumInput from "../../../../../misc/EnumInput";
import Loading from "../../../../../misc/Loading";
import DnpSchoolUnits from "./DnpSchoolUnits";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BoolInput from "../../../../../misc/BoolInput";

interface DnpExportFormProps {
    moduleId: string
    configuration: CoreDnpExportConfiguration
    updates: Partial<CoreDnpExportConfiguration>
    schoolUnits: CoreConfigurationSchoolUnit[]
    validation: string[]
    disableInput: boolean
    updatePropertyValue: (name: string, value: null | boolean | string | number | CoreDnpExportSchoolUnit[]) => void;
}

const environmentOptions: OptionItem<DnpEnvironment>[] = [
    { value: DnpEnvironment.TEST, text: 'Verifieringstest' },
    { value: DnpEnvironment.PROD, text: 'Produktion' },
]

const DnpExportForm: React.FC<DnpExportFormProps> = (props) => {
    const { validation, configuration } = props;
    const { organizationNumber, environment } = configuration;
    const dispatch = useDispatch();
    const [downloadingMetadata, setDownloadingMetadata] = useState<boolean>(false);

    useEffect(() => {
        if (props.validation.indexOf('organizationNumber') === -1) {
            if (organizationNumber) {
                GetDnpOrganization(environment, organizationNumber)(dispatch);
            }
        } else {
            props.updatePropertyValue('organizationName', null);
            ClearDnpOrganization()(dispatch);
        }
    }, [dispatch, organizationNumber, environment]);

    const downloadFile = async () => {
        if (downloadingMetadata) {
            return;
        }

        setDownloadingMetadata(true);
        await downloadFileForMoa(props.moduleId)();
        setDownloadingMetadata(false);
    }

    return (<div style={{ margin: '1rem 0' }}>
        <EnumInput
            title='Målmiljö'
            value={configuration.environment}
            options={environmentOptions}
            onChange={props.updatePropertyValue}
            disabled={props.disableInput}
            name='environment'
            invalid={validation.indexOf('environment') !== -1}
            invalidFeedback='Typ av import måste anges för att kunna aktivera modulen'
        />

        <FormGroup>
            <Label tag="h5" for="organizationNumber">Organisationsnummer {configuration.organizationName && <>({configuration.organizationName})</>}</Label>
            <Input
                type="text"
                name="organizationNumber"
                id="organizationNumber"
                placeholder="Organisationsnummer"
                value={configuration.organizationNumber || ''}
                disabled={props.disableInput}
                onChange={(e) => props.updatePropertyValue(e.target.name, e.target.value)}
                invalid={validation.indexOf('organizationNumber') !== -1 || validation.indexOf('organizationNotFound') !== -1}
                autoComplete="off" />
            {validation.indexOf('organizationNumber') !== -1 && <FormFeedback>Organisationsnummer måste anges för att kunna läsa ut skolenheter från Skolverket. Anges med 10 siffror.</FormFeedback>}
            {validation.indexOf('organizationNotFound') !== -1 && <FormFeedback>Organisationen hittades inte. Kontrollera att organisationsnumret är korrekt.</FormFeedback>}
        </FormGroup>

        <BoolInput
            title='Skicka alla tjänstgöringar från kopplade skolenheter'
            value={configuration.includeAllDuties}
            updateValue={props.updatePropertyValue}
            disabled={props.disableInput}
            name='includeAllDuties'
        />

        <DnpSchoolUnits
            savedDnpSchoolUnits={configuration.dnpSchoolUnits}
            mergedDnpSchoolUnits={configuration.dnpSchoolUnits}
            schoolUnits={props.schoolUnits}
            disabled={props.disableInput}
            updatePropertyValue={props.updatePropertyValue}
            orgNum={configuration.organizationNumber}
            environment={configuration.environment}
            validation={validation}
            dnpExportId={configuration.id}
        />

        <FormGroup className="mt-4">
            <h5>Metadata för Moa <a href="https://flowsync.se/help/digitala-nationella-prov#metadata-for-moa" target="_blank"><FontAwesomeIcon className="documentation-icon fs-color" title="Visa hjälpavsnitt" icon="question-circle" /></a></h5>
            <p>Generering av metadata för Moa sker baserat på den senaste sparade versionen av konfigurationen. Ny metadata-fil behöver endast laddas upp om organisationsnummer, certifikat eller mallen för metadata har uppdaterats.</p>
            {!configuration.organizationNumber &&
                <p className="blue"><i>Spara modulen med ett korrekt organisationsnummer för att ladda ner metadata.</i></p>}
            {configuration.organizationNumber &&
                (props.updates.organizationNumber || props.updates.organizationName) &&
                validation.indexOf('organizationNumber') === -1 &&
                validation.indexOf('organizationNotFound') === -1 &&
                <p className="blue"><i>Spara nuvarande version av modulen för att ändringen av organisationsnummer ska reflekteras i det nedladdade metadatat.</i></p>}
            <Button
                title="Hämta metadata för Moa"
                disabled={props.disableInput || !props.configuration.organizationNumber}
                onClick={downloadFile}>
                Hämta metadata för Moa
            </Button>
            {downloadingMetadata && <Loading style={{ display: 'inline-block', marginLeft: '10px' }} />}
        </FormGroup>
    </div>);
}

export default DnpExportForm;