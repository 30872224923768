import { CoreDisplayableRef, CoreEnumeration } from "./CoreModels"

export enum DnpEnvironment {
    UNKNOWN = 0,
    TEST = 1,
    PROD = 2,
}

export interface CoreDnpExportConfiguration {
    id: string,
    organizationNumber?: string
    organizationName?: string
    grades: number
    environment: DnpEnvironment,
    includeAllDuties: boolean,
    dnpSchoolUnits: CoreDnpExportSchoolUnit[]
}

export interface DnpActivity {
    activityType: string
    displayName: string
    id: string
    schoolType: string
    schoolYear: number
}

export interface CoreDnpActivityListing {
    active: boolean
    displayName: string
    endDate: string
    id: string
    startDate: string
    subjectTitle?: string
    courseTitle?: string
    schoolYear: SchoolYear
    dnpActivityMappings: CoreDnpExportActivityMapping[]
    schoolUnit: CoreDisplayableRef
}

export interface CoreDnpActivityMappingView
{
    schoolUnits: CoreDnpActivityMappingSchoolUnit[]
    dnpSchoolUnitId: string
    dnpSchoolUnitDisplayName: string
    dnpSchoolUnitCode: string
    dnpActivityMappings: CoreDnpExportActivityMapping[]
    dnpEnvironment: DnpEnvironment
}

export interface CoreDnpActivityMappingSchoolUnit {
    schoolUnitId: string
    schoolUnitDisplayName: string
    schoolTypes: CoreEnumeration
}

export interface CoreDnpExportActivityMapping {
    id?: string
    dnpExportId: string
    dnpActivityId: string
    dnpActivityTitle?: string
    flowSyncActivityId: string
    valid: boolean
    dnpActivitySchoolType?: string
    dnpSchoolUnitId: string
}

export enum DnpOrganisationStatus {
    NONE = "None",
    NEW = "New",
    ACTIVE = "Active",
    EXPIRED = "Expired"
}

export interface DnpOrganization {
    organizationNumber: string
    displayName: string
    schoolUnits: DnpOrganizationSchoolUnit[]
    id: string
    countryCode: string
    status: boolean
    organizationType: string
}

export interface DnpOrganizationSchoolUnit {
    displayName: string
    dnpStatus: boolean
    id: string
    municipalityCode: string
    municipalityName: string
    schoolUnitCode: string
    status: DnpOrganisationStatus
    parentOrganization: {
        displayName: string
        id: string
        organizationNumber: string
    }
    schoolTypes: DnpOrganizationSchoolUnitSchoolTypes[]
}

export interface DnpOrganizationSchoolUnitSchoolTypes {
    schoolType: string
    dnpStatus: boolean
    schoolYears: DnpOrganizationSchoolUnitSchoolTypesSchoolYears[]
}

export interface DnpOrganizationSchoolUnitSchoolTypesSchoolYears {
    schoolYear: number
    dnpStatus: boolean
}

export interface CoreDnpExportSchoolUnit {
    dnpSchoolUnitId: string
    dnpSchoolUnitDisplayName: string
    dnpSchoolUnitCode: string
    mappedSchoolUnitIds?: string[]
}

export interface DnpSchoolType {
    "skv:title": string
    "skv:identifier": string
}

export interface SchoolYear {
    startSchoolYear: number
    endSchoolYear: number
}
