import React from 'react';
import { FormGroup, Label, Input, Card, CardHeader, CardBody, Collapse, Button, FormFeedback } from 'reactstrap';
import ConfigurationComponent from '../ConfigurationComponent';
import StorageContainerExplorer from '../../../storageContainer/StorageContainerExplorer';
import InstanceClient from '../../../../services/InstanceClient';
import StatusCode from '../../../../util/StatusCode';
import Loading from '../../../misc/Loading';

class SDSv21CsvImportConfig extends ConfigurationComponent {
    state = {
        open: true,
        expectedFiles: { code: StatusCode.PENDING }
    }

    componentDidMount() {
        super.componentDidMount();
        InstanceClient.get('/api/core/sdscsvimportconfiguration/requiredfiles')
            .then(response => this.setState({
                expectedFiles: {
                    code: StatusCode.COMPLETE,
                    data: response.data,
                }
            }))
            .catch(error => {
                console.error(error);
                this.setState({
                    expectedFiles: {
                        code: StatusCode.COMPLETE,
                        data: [],
                    }
                });
            });
    }

    validate(configuration, active) {
        const validation = [];

        if (active) {
            if (!configuration.blobContainerUrl || configuration.blobContainerUrl === "") {
                validation.push('blobContainerUrl');
            }
            if (!configuration.blobSourceKey || configuration.blobSourceKey === "") {
                validation.push('blobSourceKey');
            }
        }

        return validation;
    }

    render() {
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);
        const validation = this.validate(configuration, this.props.active);

        if (this.state.expectedFiles.code === StatusCode.COMPLETE) {
            return (
                <Card className="edit_module">
                    <CardHeader tag="h2">
                        <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                        {this.props.moduleDisplayName}
                    </CardHeader>
                    <Collapse isOpen={this.state.open}>
                        <CardBody>
                            <div style={{ margin: '1rem 0' }}>
                                <FormGroup>
                                    <h5>Åtkomst</h5>
                                    <FormGroup>
                                        <Label for="blobContainerUrl">Länk till Container</Label>
                                        <Input type="text" name="blobContainerUrl" id="blobContainerUrl" placeholder="Länk till Container" disabled={this.props.disableInput} value={configuration.blobContainerUrl || ''} onChange={this.updateProperty} invalid={validation.indexOf('blobContainerUrl') !== -1} autoComplete="off" />
                                        <FormFeedback>Länk till Container måste anges för att kunna aktivera modulen</FormFeedback>
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="blobSourceKey">Åtkomstnyckel</Label>
                                        <Input type="text" name="blobSourceKey" id="blobSourceKey" placeholder="Åtkomstnyckel" value={configuration.blobSourceKey || ''} disabled={this.props.disableInput} onChange={this.updateProperty} invalid={validation.indexOf('blobSourceKey') !== -1} autoComplete="off" />
                                        <FormFeedback>Åtkomstnyckel måste anges för att kunna aktivera modulen</FormFeedback>
                                    </FormGroup>
                                    <StorageContainerExplorer blobContainerUrl={configuration.blobContainerUrl} blobSourceKey={configuration.blobSourceKey} expectedFiles={this.state.expectedFiles.data} />
                                </FormGroup>
                            </div>
                        </CardBody>
                    </Collapse>
                </Card>);
        } else {
            return <Loading />
        }
    }
}

export default SDSv21CsvImportConfig;
