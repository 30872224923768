import React from 'react';
import { FormGroup, Label, Input, Card, CardHeader, CardBody, Collapse, Button, FormFeedback } from 'reactstrap';
import ConfigurationComponent from '../ConfigurationComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BlobConnection from '../../../storageContainer/BlobConnection';

class LocalADSchedule extends ConfigurationComponent {
    state = { open: true }

    validate(configuration, active) {
        const validation = [];

        if (active) {
            if (!configuration.blobContainerUrl || configuration.blobContainerUrl === "") {
                validation.push('blobContainerUrl');
            }
            if (!configuration.blobName || configuration.blobName === "") {
                validation.push('blobName');
            }
            if (!configuration.blobSourceKey || configuration.blobSourceKey === "") {
                validation.push('blobSourceKey');
            }
        }
        return validation;
    }

    render() {
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);
        const validation = this.validate(configuration, this.props.active);

        return (
            <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    <span>
                        {this.props.moduleDisplayName}
                        <a href="https://flowsync.se/help/localad/general" target="_blank"><FontAwesomeIcon className="documentation-icon" title="Visa hjälpavsnitt" icon="question-circle" /></a>
                    </span>
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>
                        <div style={{ margin: '1rem 0' }}>
                            <FormGroup>
                                <Label for="blobContainerUrl">Länk till Container</Label>
                                <Input type="text" name="blobContainerUrl" id="blobContainerUrl" placeholder="Länk till Container" disabled={this.props.disableInput} value={configuration.blobContainerUrl || ''} onChange={this.updateProperty} invalid={validation.indexOf('blobContainerUrl') !== -1} autoComplete="off" />
                                <FormFeedback>Länk till Container måste anges för att kunna aktivera importmodulen</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="blobName">Namn</Label>
                                <Input type="text" name="blobName" id="blobName" placeholder="Namn" value={configuration.blobName || ''} disabled={this.props.disableInput} onChange={this.updateProperty} invalid={validation.indexOf('blobName') !== -1} autoComplete="off" />
                                <FormFeedback>Namn måste anges för att kunna aktivera importmodulen</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="blobSourceKey">Åtkomstnyckel</Label>
                                <Input type="text" name="blobSourceKey" id="blobSourceKey" placeholder="Åtkomstnyckel" value={configuration.blobSourceKey || ''} disabled={this.props.disableInput} invalid={validation.indexOf('blobSourceKey') !== -1} onChange={this.updateProperty} autoComplete="off" />
                                <FormFeedback>Åtkomstnyckel måste anges för att kunna aktivera importmodulen</FormFeedback>
                            </FormGroup>
                            <BlobConnection
                                blob={{
                                    blobContainerUrl: configuration.blobContainerUrl,
                                    blobName: configuration.blobName,
                                    blobSourceKey: configuration.blobSourceKey,
                                }}
                            />
                        </div>
                    </CardBody>
                </Collapse>
            </Card>)
    }
}

export default LocalADSchedule;
