import React from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { OnChangeCallback } from '../../util/UtilityTypes'

interface IntInputProps {
    title: string
    name: string
    value: number
    disabled?: boolean
    invalid?: boolean
    invalidFeedback?: string
    updateValue: OnChangeCallback<number>
}

const IntInput: React.FC<IntInputProps> = (props) => {
    const onChangeInput = (input: string) => {
        let value = 0;

        if (input !== '') {
            value = parseInt(input, 10);
        }

        props.updateValue(props.name, value);
    }

    return (
        <FormGroup>
            <Label style={{ display: 'block' }}>{props.title}</Label>
            <Input className="md-w-50 lg-w-25" type="number" name={props.name} id={props.name} min="0" step="1" pattern="[0-9]"
                value={props.value}
                onChange={(e) => onChangeInput(e.target.value)}
                disabled={props.disabled}
                invalid={props.invalid}
                onWheel={(e) => (e.target as HTMLInputElement).blur()}
            />
            <FormFeedback>{props.invalidFeedback}</FormFeedback>
        </FormGroup>
    );
}

export default IntInput;
