import React from 'react';
import { CoreGoogleUserTrackingConflict, CoreGoogleUserTrackingConflictType } from '../../../../models/CoreTrackingOperation';
import { Link } from 'react-router-dom';

interface GoogleUserTrackingOperationsValidationResultProps {
    googleId?: string
    primaryEmail?: string
    conflicts: CoreGoogleUserTrackingConflict[]
}

const GoogleUserTrackingOperationsValidationResult: React.FC<GoogleUserTrackingOperationsValidationResultProps> = (props) => {
    const conflictLookup = new Map<CoreGoogleUserTrackingConflictType, CoreGoogleUserTrackingConflict[]>();
    props.conflicts.forEach((conflict) => {
        const conflicts = conflictLookup.get(conflict.type);
        if (conflicts) {
            conflicts.push(conflict);
        } else {
            conflictLookup.set(conflict.type, [conflict]);
        }
    });

    return <>{Array.from(conflictLookup).sort((a, b) => a[0] - b[0]).map((entry) => (<>
        <p>{getText(entry[0], props.googleId, props.primaryEmail)}</p>
        {showUserLinks(entry[0]) && <ul>
            {entry[1].map((conflict) => (<li key={conflict.trackingId}><Link target="_blank" to={`/user/${conflict.flowSyncId}/trackings`}>{conflict.displayName}</Link></li>))}
        </ul>}
    </>))}
    </>;
}

const getText = (conflictType: CoreGoogleUserTrackingConflictType, googleId?: string, primaryEmail?: string): string => {
    switch (conflictType) {
        case CoreGoogleUserTrackingConflictType.TrackingKey:
            return `Det finns redan en koppling mellan denna FlowSync person och Google kontot: ${googleId}. Du bör istället återställa eller redigera den kopplingen.`;
        case CoreGoogleUserTrackingConflictType.SourceObjectType:
            return 'Det finns redan en aktiv koppling för denna FlowSync person och roll.';
        case CoreGoogleUserTrackingConflictType.Identifier:
            return `Andra personer i FlowSync är redan kopplade till google kontot med id: ${googleId}.`;
        case CoreGoogleUserTrackingConflictType.EmailAddress:
            return `Andra personer i FlowSync använder redan e-postadressen som primär epost i Google: ${primaryEmail}.`;
        default:
            return 'Något gick fel';
    }
}

const showUserLinks = (conflictType: CoreGoogleUserTrackingConflictType): boolean => {
    switch (conflictType) {
        case CoreGoogleUserTrackingConflictType.TrackingKey:
        case CoreGoogleUserTrackingConflictType.SourceObjectType:
            return false;
        case CoreGoogleUserTrackingConflictType.Identifier:
        case CoreGoogleUserTrackingConflictType.EmailAddress:
            return true;
        default:
            return false;
    }
}

export default GoogleUserTrackingOperationsValidationResult;
