import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useParams } from 'react-router-dom';
import { Row, Col, Button } from 'reactstrap';
import { getInstanceLogs, getEventIds, downloadInstanceLogs, getModuleBasicInfo, ModuleState } from '../../../store/ModuleAction';
import * as Helper from '../../../util/Helper';
import { Link } from 'react-router-dom';
import StatusCode from '../../../util/StatusCode';
import * as InstanceLogsUtil from '../../../util/InstanceLogsUtil';
import debounce from 'lodash/debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Search from '../../misc/Search';
import FilterBottomComponent from '../../misc/FilterBottomComponent';
import TableHorizontal from '../../misc/TableHorizontal';
import SelectInput from '../../misc/SelectInput';
import * as LogTypes from '../../../util/FlowSyncLoggerTypes';
import ModuleInstanceFilesModal from "../../storageContainer/ModuleInstanceFilesModal";
import RequestParser from '../../../util/RequestParser';
import { CoreModule, CoreModuleInstanceLogEntry, PaginationResult, Result } from '../../../models/CoreModels';
import Copy from '../../misc/Copy';

interface LogsState {
    module: ModuleState
}

const Logs = () => {
    const { moduleId, moduleInstanceId } = useParams();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);

    const instanceLogs = useSelector<LogsState, PaginationResult<CoreModuleInstanceLogEntry[]>>(state => state.module.instanceLogs);
    const eventIds = useSelector<LogsState, Result<number[]>>(state => state.module.eventIds);
    const module = useSelector<LogsState, Result<CoreModule>>(state => state.module.moduleBasicInfo);

    const filter = RequestParser.parseInstancelogSearchPageRequest(searchParams);

    useEffect(() => {
        if (filter.search) {
            setSearch(filter.search);
        }

        if (moduleId) {
            if (moduleInstanceId) {
                getInstanceLogs(moduleId, moduleInstanceId, { ...filter, pageSize: 100 })(dispatch);
                getEventIds(moduleId, moduleInstanceId)(dispatch);
            }
            getModuleBasicInfo(moduleId)(dispatch);
        }
    }, [dispatch, moduleId, moduleInstanceId]);

    useEffect(() => {
        if (moduleId && moduleInstanceId) {
            getInstanceLogs(moduleId, moduleInstanceId, filter, true)(dispatch);
        }
        window.scrollTo(0, 0);
    }, [dispatch, searchParams, moduleId, moduleInstanceId]);

    const updateFilter = useCallback((name: string, value ?: string) => {
        searchParams.set('page', '1');
        if (value) {
            searchParams.set(name, value);
        } else {
            searchParams.delete(name);
        }
        setSearchParams(searchParams)
    }, [searchParams, setSearchParams])

    const debouncedSearch = debounce((search?: string) => updateFilter('search', search), 300);

    if (!moduleId || !moduleInstanceId) {
        return null;
    }

    const onChangePage = (page: number) => {
        searchParams.set('page', page.toString())
        setSearchParams(searchParams);
    }

    const onSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        let search = e.target.value;
        setSearch(search);
        debouncedSearch(search);
    }

    const clearFilter = () => {
        setSearch('');
        setSearchParams([]);
    }

    const downloadFile = () => {
        downloadInstanceLogs(moduleId, moduleInstanceId, { ...filter, pageSize: undefined, page: undefined })(dispatch);
    }

    const getObjectType = (log: CoreModuleInstanceLogEntry): JSX.Element | string | undefined => {
        if (log.objectSource === LogTypes.Flowsync.SourceName) {
            if (log.objectType === LogTypes.Flowsync.User) {
                return <Link to={`/user/${log.objectId}`} target="_blank">{log.objectType}</Link>;
            }
            if (log.objectType === LogTypes.Flowsync.Duty) {
                return <Link to={`/duty/${log.objectId}`} target="_blank">{log.objectType}</Link>;
            }
            if (log.objectType === LogTypes.Flowsync.Group) {
                return <Link to={`/group/${log.objectId}`} target="_blank">{log.objectType}</Link>;
            }
            if (log.objectType === LogTypes.Flowsync.Activity) {
                return <Link to={`/activity/${log.objectId}`} target="_blank">{log.objectType}</Link>;
            }
            if (log.objectType === LogTypes.Flowsync.Module) {
                return <Link to={`/module/${log.objectId}/status`} target="_blank">{log.objectType}</Link>;
            }
            if (log.objectType === LogTypes.Flowsync.OneRosterClass) {
                return <Link to={`/oneroster/class/${log.objectId}`} target="_blank">{log.objectType}</Link>;
            }
            if (log.objectType === LogTypes.Flowsync.ScheduleUser) {
                return <Link to={`/schedulesync/user/${log.objectId}`} target="_blank">{log.objectType}</Link>;
            }
            if (log.objectType === LogTypes.Flowsync.ScheduleEvent) {
                return <Link to={`/schedulesync/event/${log.objectId}`} target="_blank">{log.objectType}</Link>;
            }
        }
        return log.objectType;
    }

    const getObjectId = (log: CoreModuleInstanceLogEntry): JSX.Element | string | undefined => {
        if (!log.objectId) {
            return undefined;
        }

        let text = log.objectId;
        if (text.length > 20) {
            text = `${text.substring(0, 17)}...`
        }

        return (<span title={log.objectId} style={{ whiteSpace: 'pre' }}>{text}<Copy value={log.objectId} /></span>)
    }

    const renderInstanceLogs = () => {
        const x = instanceLogs.data?.map((l, i) => {
            return ({
                id: i.toString(),
                data: [
                    { value: InstanceLogsUtil.severityById(l.severity) },
                    { value: l.eventId },
                    { value: l.message },
                    { value: getObjectId(l) },
                    { value: getObjectType(l) },
                    { value: l.objectSource },
                    { value: <span style={{ whiteSpace: 'pre' }}>{Helper.formatDate(l.timestamp)}</span> },
                ]
            })
        }) ?? [];

        return (
            <TableHorizontal
                header={[{ value: 'Allvarlighetsgrad' }, { value: 'Event-id', className: "nowrap" }, { value: 'Meddelande' }, { value: 'Objektid' }, { value: 'Objekttyp' }, { value: 'Objektkälla' }, { value: 'Tid' }]}
                info={x}
                error={instanceLogs.code === StatusCode.ERROR}
                searching={instanceLogs.code === StatusCode.PENDING}
                noResMsg="Inga loggrader hittades"
            />
        )
    }

    const renderFilter = () => (<>
        <Row className="s-switch">
            <Col sm="6">
                <Search className="margin-bottom1" value={search} onChange={onSearch} placeholder="Sök i meddelande" />
            </Col>
            <Col sm="6" className="flex-row-right mb-2">
                <Button title="Ladda ner till csv" onClick={() => downloadFile()} disabled={!instanceLogs?.totalCount}>Hämta loggar <FontAwesomeIcon icon="file-download" className="icon ms-2" /></Button>
                {module.data?.fileDownloadEnabled && <Button title="Ladda ner till csv" className="ms-2" onClick={() => setOpen(true)}>Hämta filer <FontAwesomeIcon icon="folder" color="orange" className="icon ms-2" /></Button>}
            </Col>
        </Row>
        <Row>
            <Col sm="6">
                <SelectInput
                    items={InstanceLogsUtil.severityArray.map(severity => ({ value: severity.id, text: `Filtrera på ${severity.name}` }))}
                    onChange={(name, value) => updateFilter(name, value?.toString())}
                    name='severity'
                    selected={filter?.severity}
                    defaultText='Visa alla oavsett allvarlighetsgrad'
                />
            </Col>
            <Col sm="6">
                <SelectInput
                    items={eventIds.data?.map(e => ({ value: e, text: `Filtrera på ${e}` }))}
                    onChange={(name, value) => updateFilter(name, value?.toString())}
                    name='eventId'
                    selected={filter.eventId}
                    defaultText='Visa alla event-id'
                />
            </Col>
        </Row>
    </>);

    const requestState = Helper.convertToCacheRequestState<CoreModuleInstanceLogEntry>(instanceLogs);

    return (
        <div className="mt-2">
            <div className="large-filter pl-40 pr-40">
                {renderFilter()}
            </div>
            <div className="pr-40 pl-40 mt-1">
                {renderInstanceLogs()}
                {open && <ModuleInstanceFilesModal
                    moduleInstanceId={moduleInstanceId}
                    isOpen={open}
                    toggleModal={() => setOpen(!open)}
                />}
            </div>

            <FilterBottomComponent
                requestState={requestState}
                clearFilter={clearFilter}
                updateFilter={onChangePage}
            />

        </div>
    )
}

export default Logs;
