import React, { CSSProperties } from 'react';

interface LoadingProps {
    className?: string
    style?: CSSProperties
}

const Loading: React.FC<LoadingProps> = (props) => <div style={props.style}>
    <div className={props.className + " spinner spinner-slow"}>
    </div>
</div>;

export default Loading;
