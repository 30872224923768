import React, { ReactNode, useState } from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { OnChangeCallback } from '../../util/UtilityTypes'

interface NullableIntInputProps {
    title: string | ReactNode
    name: string
    value?: number | null
    defaultValue?: number
    checkboxTitle?: string
    disabled?: boolean
    invalid?: boolean
    invalidFeedback?: string
    updateValue: OnChangeCallback<number | null>
}

const NullableIntInput: React.FC<NullableIntInputProps> = (props) => {
    const defaultValue = props.defaultValue ?? NaN;
    const initialValue = props.value ?? null;

    const [checked, setChecked] = useState(props.value !== null || false);
    const [previous, setPrevious] = useState(Number.isInteger(props.value) ? initialValue : defaultValue);

    const toggle = (shouldBeChecked: boolean) => {
        setChecked(shouldBeChecked);
        if (shouldBeChecked) {
            props.updateValue(props.name, previous);
        } else {
            setPrevious(props.value ?? null)
            props.updateValue(props.name, null);
        }
    }

    const onChangeInput = (input: string) => {
        const value = input === '' ? NaN : parseInt(input, 10);
        setPrevious(value);
        props.updateValue(props.name, value);
    }

    const getValue = (): string | number => {
        if (props.value === null || props.value === undefined) {
            return '';
        }

        if (Number.isNaN(props.value)) {
            return '';
        }

        return props.value;
    }

    return (
        <FormGroup>
            <Label style={{ display: 'block' }}>{props.title}</Label>
            <FormGroup check>
                <Input
                    type="checkbox"
                    style={{ marginTop: '10px' }}
                    title={props.checkboxTitle}
                    disabled={props.disabled}
                    checked={checked}
                    onChange={(e) => toggle(e.target.checked)}
                />
                <FormGroup style={{ marginLeft: '10px' }}>
                    <Input
                        className="md-w-50 lg-w-25"
                        type="number"
                        name={props.name}
                        id={props.name}
                        min="0"
                        step="1"
                        pattern="[0-9]"
                        value={getValue()}
                        onChange={(e) => onChangeInput(e.target.value)}
                        disabled={props.disabled || !checked}
                        invalid={props.invalid}
                        onWheel={(e) => (e.target as HTMLInputElement).blur()}
                    />
                    <FormFeedback>{props.invalidFeedback}</FormFeedback>
                </FormGroup>
            </FormGroup>
        </FormGroup>
    );
}

export default NullableIntInput;
