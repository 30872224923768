
const parentRelationTypes = new Map<number, string>([
    [0, "Odefinierad"],
    [1, "Vårdnadshavare"],
    [2, "Annan ansvarig"],
    [3, "God man"],
    [4, "Utsedd behörig"],
    [5, "Mentor"],
]);

const childRelationTypes = new Map<number, string>([
    [0, "Odefinierad"],
    [1, "Barn"],
    [2, "Barn"],
    [3, "Barn"],
    [4, "Barn"],
    [5, "Elev"],
]);

export const relationTypeById = (id: number, isChild: boolean): string => {
    return (isChild ? childRelationTypes.get(id) : parentRelationTypes.get(id)) ?? id.toString();
}
