import React, { ReactNode, useState } from 'react';
import { Table } from 'reactstrap';
import Loading from './Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


interface InfoObjectDataItem {
    value: ReactNode
    className?: string
}

export interface InfoObject {
    id: string,
    data: InfoObjectDataItem[],
    expandableContent?: ReactNode
}

export interface Label { value: string | ReactNode, className?: string, colSpan?: number, dataLabel?: string };

interface TableHorizontalProps {
    searching: boolean
    info: InfoObject[]
    displayName?: string
    header: Label[]
    error: boolean
    errorMsg?: string
    className?: string
    noResMsg?: string
    showHeader?: boolean
    displayToggle?: boolean
    toggle?: () => void
    show?: boolean
}

const TableHorizontal: React.FC<TableHorizontalProps> = (props: TableHorizontalProps) => {
    const [show, setShow] = useState<{ [key: string]: boolean }>({})
    const hasExpandableContent = props.info.some(x => x.expandableContent !== undefined);

    const renderBody = () => (<>
        {props.searching && props.info.length > 0 && <Loading className="spinner-update" />}
        <Table className={`overwiew-table ${props.className}`}>
            <thead>
                <tr>
                    {hasExpandableContent && <th><FontAwesomeIcon icon="up-right-and-down-left-from-center" /></th> }
                    {props.header.map((h, i) => (
                        <th className={h.className} key={i}>{h.value}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {props.info && props.info.map(row => {
                    const rowHasExpandableContent = row.expandableContent !== undefined;
                    const isExpanded = rowHasExpandableContent && show[row.id];

                    return <>
                        <tr className={"list_table" + (isExpanded ? " expanded" : "") + (rowHasExpandableContent ? " expandable" : "")} key={row.id} onClick={y => setShow({ ...show, [row.id]: !show[row.id]})}>
                            {hasExpandableContent &&
                                <td>
                                    {rowHasExpandableContent && (
                                        isExpanded ? <FontAwesomeIcon icon="chevron-down" /> : <FontAwesomeIcon icon="chevron-right" />
                                    ) }
                                </td>}
                            {row.data.map((d, i) => {
                                const dataLabel = props.header[i].dataLabel ? props.header[i].dataLabel : props.header[i].value;
                                return (<td key={i} data-label={dataLabel} className={d.className}>{d.value}</td>)
                            })}
                        </tr>
                        {isExpanded && rowHasExpandableContent &&
                            <tr>
                                <td></td> {/*for indentation*/}
                                <td className="p-0" colSpan={3214568}>
                                    {row.expandableContent}
                                </td>
                            </tr>
                        }
                    </>
                }
                )}
            </tbody>
        </Table>
        {props.searching && props.info.length === 0 && <Loading />}
        {props.errorMsg && <p>{props.errorMsg}</p>}
        {!props.errorMsg && props.error && <p>Kunde inte hämta</p>}
        {!props.error && !props.searching && !props.info.length && props.noResMsg && <p>{props.noResMsg}</p>}
        {!props.error && !props.searching && !props.info.length && !props.noResMsg && <p>Resultat saknas</p>}
    </>);

    return (
        <div>
            {props.displayName && <div className={(props.showHeader ? 'header-table' : 'mobile-header-table') + (props.displayName && !props.show ? " mb-3" : '')}>{props.displayName}
                {props.displayToggle ?
                    <div onClick={props.toggle} className="c-pointer right">
                        {props.show ? <FontAwesomeIcon title="Dölj information" icon="chevron-down" /> : <FontAwesomeIcon title="Visa information" icon="chevron-right" />}
                    </div> : null}
            </div>}

            {props.displayToggle ?
                props.show && renderBody()
                :
                renderBody()}

        </div>
    )
}

export default TableHorizontal;
