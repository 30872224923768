import InstanceClient from '../services/InstanceClient';
import GoogleApi from '../services/GoogleApi';
import MsalApi from '../services/MsalApi';
import { Action, Reducer, Dispatch } from 'redux';
import { CoreLoggedInUser } from '../models/CoreLoggedInUser';
import { FlowSyncAccessPolicy } from '../models/FlowSyncAccessPolicy';

export enum AuthActionType {
    UPDATE_AUTH_STATUS = 'UPDATE_AUTH_STATUS',
    RESET_AUTH_STATUS = 'RESET_AUTH_STATUS'
}

export interface AuthState {
    needLogin: boolean,
    authority?: string,
    authorizationPolicies?: FlowSyncAccessPolicy[]
}

type UpdateAction = Action<AuthActionType.UPDATE_AUTH_STATUS> & AuthState
type ResetAction = Action<AuthActionType.RESET_AUTH_STATUS>

export type AuthAction = UpdateAction | ResetAction

const updateGoogleAuthStatus = async (dispatch: Dispatch<AuthAction>, token: string): Promise<string> => {
    if (!token) {
        dispatch({
            type: AuthActionType.UPDATE_AUTH_STATUS,
            needLogin: true
        });
    }

    localStorage.setItem('AuthAuthority', 'google');

    const authorizationPolicies = await getAuthorizationPolicies(token);
    dispatch({
        type: AuthActionType.UPDATE_AUTH_STATUS,
        needLogin: false,
        authorizationPolicies: authorizationPolicies,
        authority: 'google'
    });
    return token;
}

export const silentAuth = () => async (dispatch: Dispatch<AuthAction>): Promise<string | undefined> => {
    const type = localStorage.getItem('AuthAuthority');
    switch (type) {
        case 'microsoft':
            try {
                const tokenResponse = await MsalApi.idTokenSilent();
                const token = tokenResponse.idToken.rawIdToken;
                const authorized = await getAuthorizationPolicies(token);
                dispatch({
                    type: AuthActionType.UPDATE_AUTH_STATUS,
                    authorizationPolicies: authorized,
                    needLogin: false,
                    authority: 'microsoft'
                });
                return token;
            } catch (err) {
                console.error(err)
                dispatch({
                    type: AuthActionType.UPDATE_AUTH_STATUS,
                    needLogin: true
                });
                return;
            }
        case 'google':
            try {
                const token = await GoogleApi.token();
                return await updateGoogleAuthStatus(dispatch, token);
            }
            catch (err) {
                console.error(err)
                dispatch({
                    type: AuthActionType.UPDATE_AUTH_STATUS,
                    needLogin: true
                });
                return;
            }
        default:
            dispatch({
                type: AuthActionType.UPDATE_AUTH_STATUS,
                needLogin: true
            });
    }
};

const getAuthorizationPolicies = async (token: string): Promise<FlowSyncAccessPolicy[]> => {
    const url = '/api/core/me';
    let authorizedAccessPolicies: FlowSyncAccessPolicy[] = [];

    if (token) {
        try {
            var res = await InstanceClient.get<CoreLoggedInUser>(url);
            authorizedAccessPolicies = res.data.authorizedAccessPolicies
        } catch (e) {
            console.error(e);
        }
    }

    return authorizedAccessPolicies;
}


export const googleAuth = (token: string) => async (dispatch: Dispatch<AuthAction>): Promise<void> => {
    window.localStorage.setItem('AuthAuthority', 'google');
    await updateGoogleAuthStatus(dispatch, token);
};

export const microsoftAuth = () => async (dispatch: Dispatch<AuthAction>): Promise<void> => {
    window.localStorage.setItem('AuthAuthority', 'microsoft');

    const tokenResponse = await MsalApi.idTokenPopup();
    var token = tokenResponse.idToken.rawIdToken;
    const authorized = await getAuthorizationPolicies(token);

    await dispatch({
        type: AuthActionType.UPDATE_AUTH_STATUS,
        authorizationPolicies: authorized,
        needLogin: false,
        authority: 'microsoft'
    });
};

export const signOut = () => async (dispatch: Dispatch<AuthAction>): Promise<void> => {
    const authAuthority = localStorage.getItem('AuthAuthority');
    localStorage.removeItem('AuthAuthority');
    switch (authAuthority) {
        case 'google':
            GoogleApi.logout();
            break;
        case 'microsoft':
            await MsalApi.logout();
            break;
        default: 
    }

    dispatch({
        type: AuthActionType.RESET_AUTH_STATUS
    });
};

const initialState = {
    needLogin: false,
    authority: undefined,
    authorizationPolicies: undefined
}

export const reducer: Reducer<AuthState, AuthAction> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case AuthActionType.UPDATE_AUTH_STATUS:
            return {
                ...state,
                needLogin: action.needLogin,
                authority: action.authority,
                authorizationPolicies: action.authorizationPolicies
            };
        case AuthActionType.RESET_AUTH_STATUS:
            return {
                ...state,
                needLogin: true,
                authority: undefined,
                authorizationPolicies: undefined,
            };
        default:
            return state;
    }

};
