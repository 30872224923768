import React, { PropsWithChildren } from 'react';

interface GridListColSpan {
    startColumn: number
    columnCount: number
}

interface GridListComponentBaseProps extends PropsWithChildren {
    span?: GridListColSpan
    className?: string
}

const GridListComponentBase = (props: GridListComponentBaseProps, isHeader: boolean): React.ReactElement<GridListComponentBaseProps> => {
    const maxChildCount = props.span?.columnCount ?? 1;
    const childCount = React.Children.count(props.children);

    if (childCount > maxChildCount) {
        throw new Error("Can not have more children than columns in span");
    }

    let className = isHeader ? 'gridListHeader' : 'gridListItem';
    if (props.className) {
        className += ` ${props.className}`
    }

    if (!props.span) { // NOTE: Previous checks ensure that props.children only contain one element.
        return <div className={className}>{props.children}</div>;
    } else if (childCount === 0) {
        const gridColumnEnd = props.span.startColumn + props.span.columnCount;
        const wrapperStyle: React.CSSProperties = {
            gridColumn: `${props.span.startColumn} / ${gridColumnEnd}`
        }

        return <div className={className} style={wrapperStyle}></div>;
    }

    const baseSpanLength = Math.floor(maxChildCount / childCount);
    const additionalColumnCount = maxChildCount % childCount;

    let currentColumn = props.span.startColumn;
    const wrappers = React.Children.map(props.children, (child, index) => {
        const additionalColumn = Number(additionalColumnCount > index);
        const gridColumnEnd = currentColumn + baseSpanLength + additionalColumn;
        const wrapperStyle: React.CSSProperties = {
            gridColumn: `${currentColumn} / ${gridColumnEnd}`
        }

        currentColumn = gridColumnEnd; // NOTE: Set column start index for next item.

        return <div key={index} className={className} style={wrapperStyle}>{child}</div>
    });

    return <React.Fragment>{wrappers}</React.Fragment>
}

export const GridListHeader: React.FC<GridListComponentBaseProps> = (props) => GridListComponentBase(props, true);
export const GridListItem: React.FC<GridListComponentBaseProps> = (props) => GridListComponentBase(props, false);
