import React from 'react';
import { Row, Col } from 'reactstrap';
import { OnChangeCallback } from '../../../../../../util/UtilityTypes'
import EnumInput from '../../../../../misc/EnumInput';
import BoolInput from '../../../../../misc/BoolInput';
import NullableInput from '../../../../../misc/NullableInput';
import IntInput from '../../../../../misc/IntInput';
import NullableIntInput from '../../../../../misc/NullableIntInput';
import * as GoogleUser from '../../../../../../util/GoogleUserExportTypes';
import { update } from '../../../../../../util/Helper';

interface GoogleUserEmailAddressPolicyProps {
    emailAddressPolicy: GoogleUser.EmailAddressPolicy
    configurationType: string
    disabled?: boolean
    disableInput?: boolean
    validation: string[]
    onChange: OnChangeCallback<GoogleUser.EmailAddressPolicy>
}

const GoogleUserEmailAddressPolicy: React.FC<GoogleUserEmailAddressPolicyProps> = (props) => {
    const renderOptions = () => {
        switch (props.emailAddressPolicy.type) {
            case GoogleUser.EmailAddressPolicyType.GENERATED: {
                return (<>
                    <hr />
                    <h5>Inställningar för genererad e-postadress</h5>
                    <BoolInput
                        title='Separera namnen med punkt'
                        name={`${props.configurationType}-emailAddressPolicy.separateNamesByDots`}
                        value={props.emailAddressPolicy.separateNamesByDots}
                        updateValue={(n, v) => update(n, props.emailAddressPolicy, { separateNamesByDots: v }, props.onChange)}
                        disabled={props.disabled}
                    />

                    <h5>Förnamn</h5>
                    <Row>
                        <Col sm="2">
                            <BoolInput
                                title='Inkludera förnamn'
                                name={`${props.configurationType}-emailAddressPolicy.includeGivenName`}
                                value={props.emailAddressPolicy.includeGivenName}
                                updateValue={(n, v) => update(n, props.emailAddressPolicy, { includeGivenName: v }, props.onChange)}
                                disabled={props.disabled}
                                titleSmall={true}
                            />
                        </Col>
                        <Col sm="10">
                            <NullableIntInput
                                title="Begränsa antal tecken i förnamn till:"
                                name={`${props.configurationType}-emailAddressPolicy.givenNameCharacterCount`}
                                value={props.emailAddressPolicy.givenNameCharacterCount}
                                disabled={props.disabled || !props.emailAddressPolicy.includeGivenName}
                                updateValue={(n, v) => update(n, props.emailAddressPolicy, { givenNameCharacterCount: v }, props.onChange)}
                                invalidFeedback="Ange antal tecken"
                                invalid={props.validation.indexOf(`${props.configurationType}.emailAddressPolicy.givenNameCharacterCount`) !== -1}
                            />
                        </Col>
                    </Row>

                    <h5>Efternamn</h5>
                    <Row>
                        <Col sm="2">
                            <BoolInput
                                title='Inkludera efternamn'
                                name={`${props.configurationType}-emailAddressPolicy.includeFamilyName`}
                                value={props.emailAddressPolicy.includeFamilyName}
                                updateValue={(n, v) => update(n, props.emailAddressPolicy, { includeFamilyName: v }, props.onChange)}
                                disabled={props.disabled}
                                titleSmall={true}
                            />
                        </Col>
                        <Col sm="10">
                            <NullableIntInput
                                title="Begränsa antal tecken i efternamn till:"
                                name={`${props.configurationType}-emailAddressPolicy.familyNameCharacterCount`}
                                value={props.emailAddressPolicy.familyNameCharacterCount}
                                disabled={props.disabled || !props.emailAddressPolicy.includeFamilyName}
                                updateValue={(n, v) => update(n, props.emailAddressPolicy, { familyNameCharacterCount: v }, props.onChange)}
                                invalidFeedback="Ange antal tecken"
                                invalid={props.validation.indexOf(`${props.configurationType}.emailAddressPolicy.familyNameCharacterCount`) !== -1}
                            />
                        </Col>
                    </Row>

                    {props.validation.indexOf(`${props.configurationType}.emailAddressPolicy.nameCharacterTotalCount`) !== -1 &&
                        <p className="red small">Förnamn och efternamn måste innehålla minst 4 tecken tillsammans</p>}

                    <h5>Löpnummer</h5>
                    <Row>
                        <Col sm="2">
                            <BoolInput
                                title='Använd löpnummer'
                                name={`${props.configurationType}-emailAddressPolicy.useSerialNumber`}
                                value={props.emailAddressPolicy.useSerialNumber}
                                updateValue={(n, v) => update(n, props.emailAddressPolicy, { useSerialNumber: v }, props.onChange)}
                                disabled={true}
                                titleSmall={true}
                            />
                        </Col>
                        <Col sm="10">
                            <NullableInput
                                title="Prefix för löpnummer"
                                name={`${props.configurationType}-emailAddressPolicy.serialNumberDelimiter`}
                                value={props.emailAddressPolicy.serialNumberDelimiter}
                                onChange={(n, v) => update(n, props.emailAddressPolicy, { serialNumberDelimiter: v }, props.onChange)}
                                disabled={props.disabled || !props.emailAddressPolicy.useSerialNumber}
                                invalidFeedback="Ange prefix för löpnummer"
                                invalid={props.validation.indexOf(`${props.configurationType}.emailAddressPolicy.serialNumberDelimiter`) !== -1}
                            />
                        </Col>
                    </Row>
                </>);
            };
            case GoogleUser.EmailAddressPolicyType.ANONYMOUS: {
                return (<>
                    <hr />
                    <h5>Inställningar för anonymiserad e-postadress</h5>
                    <IntInput
                        title="Antal slumpmässiga tecken:"
                        name={`${props.configurationType}-emailAddressPolicy.randomCharacterCount`}
                        value={props.emailAddressPolicy.randomCharacterCount}
                        disabled={props.disabled}
                        updateValue={(n, v) => update(n, props.emailAddressPolicy, { randomCharacterCount: v }, props.onChange)}
                        invalidFeedback="Antal tecken måste vara minst 0"
                        invalid={props.validation.indexOf(`${props.configurationType}.emailAddressPolicy.randomCharacterCount`) !== -1}
                    />
                    <IntInput
                        title="Antal slumpmässiga siffror:"
                        name={`${props.configurationType}-emailAddressPolicy.randomNumberCount`}
                        value={props.emailAddressPolicy.randomNumberCount}
                        disabled={props.disabled}
                        updateValue={(n, v) => update(n, props.emailAddressPolicy, { randomNumberCount: v }, props.onChange)}
                        invalidFeedback="Antal siffror måste vara minst 0"
                        invalid={props.validation.indexOf(`${props.configurationType}.emailAddressPolicy.randomNumberCount`) !== -1}
                    />

                    {props.validation.indexOf(`${props.configurationType}.emailAddressPolicy.randomTotalCount`) !== -1 &&
                        <p className="red small">Minsta antal tecken måste vara 6 totalt</p>}

                </>);
            };
            default: return null;
        }
    }

    return (<>
        <EnumInput
            title='E-postadress policy'
            name={`${props.configurationType}-emailAddressPolicy.type`}
            value={props.emailAddressPolicy.type}
            options={GoogleUser.emailAddressPolicyTypeOptions}
            onChange={(n, v) => update(n, props.emailAddressPolicy, { type: v }, props.onChange)}
            disabled={props.disableInput}
        />

        {renderOptions()}

        <hr />
        <NullableInput
            title="Använd specifikt domännamn"
            titleSmall={false}
            onChange={(n, v) => update(n, props.emailAddressPolicy, { domainName: v }, props.onChange)}
            value={props.emailAddressPolicy.domainName}
            disabled={props.disableInput}
            name={`${props.configurationType}-emailAddressPolicy.domainName`}
            invalidFeedback="Ange domännamn"
            invalid={props.validation.indexOf(`${props.configurationType}.emailAddressPolicy.domainName`) !== -1}
        />
    </>);
}

export default GoogleUserEmailAddressPolicy; 
