import React from 'react';
import { FormGroup} from 'reactstrap';
import { OnChangeCallback } from '../../../../../../util/UtilityTypes'
import HaldorUnifiedSetting from './HaldorUnifiedSetting';
import { CoreHaldorClassSetting } from '../../../../../../models/CoreHaldorModels';
import { CoreHaldorUnifiedSetting } from '../../../../../../models/CoreHaldorModels';

interface HaldorClassSettingsProps {
    title: string    
    name: string
    value: CoreHaldorClassSetting
    onChange: OnChangeCallback<CoreHaldorClassSetting>
    disabled?: boolean
}

const HaldorClassSetting: React.FC<HaldorClassSettingsProps> = (props) => {

    const onChange: OnChangeCallback<CoreHaldorUnifiedSetting> = (prop, value) => {
        props.onChange(props.name, { classUnifiedGroup: value })
    }

    return (        
        <FormGroup tag="fieldset">

            <h5>{props.title}</h5>

            <HaldorUnifiedSetting 
                name='classUnifiedGroup'
                basedOnText='klass'
                value={props.value.classUnifiedGroup}
                onChange={onChange}
                disabled={props.disabled}
                disableEnableSetting={true}
            />

        </FormGroup>
    )
}

export default HaldorClassSetting;