import React from 'react';
import Select, { ActionMeta, CSSObjectWithLabel, MultiValue, PropsValue } from 'react-select';
import { FormFeedback, FormGroup } from 'reactstrap';
import { OnChangeCallback, SearchableOptionItem } from '../../util/UtilityTypes'

interface MultiSelectInputProps<TValue,> {
    onChange: OnChangeCallback<TValue[] | undefined | null>
    items: SearchableOptionItem<TValue>[]
    defaultText: string
    disabled?: boolean
    name: string
    disableSort?: boolean
    selected?: TValue[]
    keySelector?: (value: TValue) => string | number
    invalid?: boolean
    invalidFeedback?: string
}


const comparer = <TValue,>(a: SearchableOptionItem<TValue>, b: SearchableOptionItem<TValue>): number => {
    return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
}

const MultiSelectInput = <TValue,>(props: MultiSelectInputProps<TValue>) => {
    const getKey = (v: TValue): string | number => {
        if (props.keySelector) {
            return props.keySelector(v);
        }

        switch (typeof (v)) {
            case 'number':
                return v;
            case 'string':
                return v;
            default:
                throw new Error("The prop keySelector is required when the typevariable supplied is not a string or number.")
        }
    }

    const handleChange = (option: MultiValue<SearchableOptionItem<TValue>>, action: ActionMeta<SearchableOptionItem<TValue>>) => {
        props.onChange(props.name, option.map(x => x.value))
    }

    let items = props.items;
    if (!props.disableSort && items) {
        items = items.sort(comparer);
    }

    let selectedItems: PropsValue<SearchableOptionItem<TValue>> = null;
    if (props.selected !== undefined) {
        selectedItems = items.filter(x => props.selected?.some(y => getKey(y) === getKey(x.value)));
    }

    return (
        <FormGroup>
            <Select
                value={selectedItems}
                onChange={handleChange}
                options={items}
                isOptionDisabled={(item) => item.disabled === true}
                placeholder={props.defaultText}
                noOptionsMessage={() => "Resultat saknas"}
                isDisabled={!items.length || props.disabled}
                isClearable
                isMulti
                styles={{
                    control: (baseStyles, state): CSSObjectWithLabel =>
                    ({
                        ...baseStyles,
                        boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(13, 110, 253, 0.25)' : undefined,
                        backgroundColor: state.isDisabled ? '#e9ecef' : '#fff',
                        borderColor: '#ced4da',
                        textAlign: 'left',
                    }),
                    option: (base, state): CSSObjectWithLabel =>
                    ({
                        ...base,
                        padding: '0 0.75rem',
                        backgroundColor: state.isFocused ? '#1967d2' : undefined,
                        color: state.isFocused ? '#fff' : state.isDisabled ? 'lightgray' : undefined,
                        lineHeight: '1.5',
                    }),
                    placeholder: (base) => ({
                        ...base,
                        color: '#212529'
                    }),
                    dropdownIndicator: (base) => ({
                        ...base,
                        color: '#212529',
                    }),
                }}
            />

            {props.invalid && <FormFeedback style={{ display: 'block' }}>{props.invalidFeedback}</FormFeedback>}
        </FormGroup>
    );
}

export default MultiSelectInput;
