import React from 'react';
import { Card, CardHeader, CardBody, Collapse, Button } from 'reactstrap';
import ConfigurationComponent from '../ConfigurationComponent';
import { withRouter } from '../../../../components/withRouter';
import { connect } from 'react-redux';
import StatusCode from '../../../../util/StatusCode';
import DnpExportForm from './components/DnpExport/DnpExportForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DnpOrganisationStatus } from '../../../../models/CoreDnpModels';

class DNP extends ConfigurationComponent {
    state = {
        open: true
    }

    warnings(update) {
        const warningMessages = [];

        if (update.dnpSchoolUnits) {
            this.props.configuration.dnpSchoolUnits.forEach(x => {
                if (x.hasDnpActivityMapping && !update.dnpSchoolUnits.some(y => y.schoolUnitId === x.schoolUnitId)) {
                    warningMessages.push("Om du tar bort skolan så kommer skolan samt aktiviteter, grupper, elever och tjänstgöringar kopplade till skolan raderas")
                }
            })
        }
        return warningMessages;
    }

    validate(configuration, active) {
        const validation = [];

        //Wrong org num
        if (!configuration.organizationNumber || configuration.organizationNumber.length !== 10) {
            validation.push('organizationNumber')
        }

        //Org not found
        if (this.props.dnpOrganization.code === StatusCode.ERROR && this.props.dnpOrganization.error.request.status === 404) {
            validation.push('organizationNotFound')
        }

        //Mapped schoolUnits which cannot be found at skolverket
        if (this.props.dnpOrganization.code === StatusCode.COMPLETE) {
            configuration.dnpSchoolUnits.forEach(dsu => {
                if (!this.props.dnpOrganization.data?.schoolUnits?.find(s => s.status === DnpOrganisationStatus.ACTIVE && s.id === dsu.dnpSchoolUnitId)) {
                    validation.push(`schoolUnitMissingFromSkolverket-${dsu.dnpSchoolUnitId}`)
                }
            })
        }

        //No active flowsync schoolunit found
        if (configuration.dnpSchoolUnits.forEach(x => {
            if (x.mappedSchoolUnitIds === undefined || x.mappedSchoolUnitIds.length === 0) {
                validation.push(`mappedSchoolUnitIds-${x.dnpSchoolUnitId}`)
                return;
            }

            if (x.mappedSchoolUnitIds.some(schoolUnitId => !this.props.schoolUnits.filter(su => su.isActive).map(su => su.id).includes(schoolUnitId))) {
                validation.push(`inactiveSchoolUnit-${x.dnpSchoolUnitId}`)
            }
        }));

        return validation;
    }

    render() {
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);
        const validation = this.validate(configuration, this.props.active);

        return (
            <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    <span>
                        {this.props.moduleDisplayName}
                        <a href="https://flowsync.se/help/digitala-nationella-prov#konfiguration-digitala-nationella-prov" target="_blank"><FontAwesomeIcon className="documentation-icon" title="Visa hjälpavsnitt" icon="question-circle" /></a>
                    </span>
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>
                        <DnpExportForm
                            moduleId={this.props.params.moduleId}
                            configuration={configuration}
                            updates={this.props.updates}
                            schoolUnits={this.props.schoolUnits}
                            validation={validation}
                            disableInput={this.props.disableInput}
                            updatePropertyValue={this.updatePropertyValue}
                        />
                    </CardBody>
                </Collapse>
            </Card >)
    }
}

const mapStateToProps = state => ({
    dnpOrganization: state.dnp.dnpOrganization,
});

export default withRouter(connect(mapStateToProps, {})(DNP));
