import { Action, Dispatch, Reducer } from 'redux';
import { EmptyRequestState, RequestState } from '../models/RequestState';
import { CoreGoogleUserTrackingUpdate, CoreGoogleUserTrackingConflict, CoreGoogleUserTrackingMapping } from '../models/CoreTrackingOperation';
import StatusCode from '../util/StatusCode';
import InstanceClient from '../services/InstanceClient';

enum TrackingOperationType {
    UPDATE_GOOGLE_USER_TRACKING = 'UPDATE_GOOGLE_USER_TRACKING',
    INACTIVATE_GOOGLE_USER_TRACKING = 'INACTIVATE_GOOGLE_USER_TRACKING',
    RESTORE_GOOGLE_USER_TRACKING = 'RESTORE_GOOGLE_USER_TRACKING',
    MAP_GOOGLE_USER_TRACKING = 'MAP_GOOGLE_USER_TRACKING',
}

export interface TrackingOperationState {
    updateGoogleUserTracking: RequestState<CoreGoogleUserTrackingConflict[]>
    inactivateGoogleUserTracking: EmptyRequestState
    restoreGoogleUserTracking: RequestState<CoreGoogleUserTrackingConflict[]>
    mapGoogleUserTracking: RequestState<CoreGoogleUserTrackingConflict[]>
}

interface Tracking_UpdateGoogleUserTracking extends Action<TrackingOperationType> {
    type: TrackingOperationType.UPDATE_GOOGLE_USER_TRACKING
    updateGoogleUserTracking: RequestState<CoreGoogleUserTrackingConflict[]>
}

interface Tracking_InactivateGoogleUserTracking extends Action<TrackingOperationType> {
    type: TrackingOperationType.INACTIVATE_GOOGLE_USER_TRACKING
    inactivateGoogleUserTracking: EmptyRequestState
}

interface Tracking_RestoreGoogleUserTracking extends Action<TrackingOperationType> {
    type: TrackingOperationType.RESTORE_GOOGLE_USER_TRACKING
    restoreGoogleUserTracking: RequestState<CoreGoogleUserTrackingConflict[]>
}

interface Tracking_MapGoogleUserTracking extends Action<TrackingOperationType> {
    type: TrackingOperationType.MAP_GOOGLE_USER_TRACKING
    mapGoogleUserTracking: RequestState<CoreGoogleUserTrackingConflict[]>
}

type TrackingOperation = Tracking_UpdateGoogleUserTracking
    | Tracking_InactivateGoogleUserTracking
    | Tracking_RestoreGoogleUserTracking
    | Tracking_MapGoogleUserTracking

export const UpdateGoogleUserTracking = (update: CoreGoogleUserTrackingUpdate, trackingId: string, dryRun: boolean) => (dispatch: Dispatch<Tracking_UpdateGoogleUserTracking>) => {
    dispatch({
        type: TrackingOperationType.UPDATE_GOOGLE_USER_TRACKING,
        updateGoogleUserTracking: { code: StatusCode.PENDING },
    });

    const url = `/api/core/trackingoperation/googleuser/${trackingId}/update?dryrun=${dryRun}`
    InstanceClient.post<CoreGoogleUserTrackingConflict[], CoreGoogleUserTrackingUpdate>(url, update)
        .then((res) => dispatch({
            type: TrackingOperationType.UPDATE_GOOGLE_USER_TRACKING,
            updateGoogleUserTracking: { code: StatusCode.COMPLETE, data: res.data }
        }))
        .catch((error) => dispatch({
            type: TrackingOperationType.UPDATE_GOOGLE_USER_TRACKING,
            updateGoogleUserTracking: { code: StatusCode.ERROR, error },
        }));
}

export const ClearUpdateGoogleUserTracking = () => (dispatch: Dispatch<Tracking_UpdateGoogleUserTracking>) => {
    dispatch({
        type: TrackingOperationType.UPDATE_GOOGLE_USER_TRACKING,
        updateGoogleUserTracking: { code: StatusCode.NONE },
    });
}

export const InactivateGoogleUserTracking = (trackingId: string) => (dispatch: Dispatch<Tracking_InactivateGoogleUserTracking>) => {
    dispatch({
        type: TrackingOperationType.INACTIVATE_GOOGLE_USER_TRACKING,
        inactivateGoogleUserTracking: { code: StatusCode.PENDING },
    });

    const url = `/api/core/trackingoperation/googleuser/${trackingId}/inactivate`;
    InstanceClient.post<any, any>(url)
        .then((_) => dispatch({
            type: TrackingOperationType.INACTIVATE_GOOGLE_USER_TRACKING,
            inactivateGoogleUserTracking: { code: StatusCode.COMPLETE }
        }))
        .catch((error) => dispatch({
            type: TrackingOperationType.INACTIVATE_GOOGLE_USER_TRACKING,
            inactivateGoogleUserTracking: { code: StatusCode.ERROR, error },
        }));
}

export const RestoreGoogleUserTracking = (trackingId: string, dryRun: boolean) => (dispatch: Dispatch<Tracking_RestoreGoogleUserTracking>) => {
    dispatch({
        type: TrackingOperationType.RESTORE_GOOGLE_USER_TRACKING,
        restoreGoogleUserTracking: { code: StatusCode.PENDING },
    });

    const url = `/api/core/trackingoperation/googleuser/${trackingId}/restore?dryrun=${dryRun}`;
    InstanceClient.post<CoreGoogleUserTrackingConflict[], undefined>(url)
        .then((res) => dispatch({
            type: TrackingOperationType.RESTORE_GOOGLE_USER_TRACKING,
            restoreGoogleUserTracking: { code: StatusCode.COMPLETE, data: res.data }
        }))
        .catch((error) => dispatch({
            type: TrackingOperationType.RESTORE_GOOGLE_USER_TRACKING,
            restoreGoogleUserTracking: { code: StatusCode.ERROR, error },
        }));
}

export const ClearRestoreGoogleUserTracking = () => (dispatch: Dispatch<Tracking_RestoreGoogleUserTracking>) => {
    dispatch({
        type: TrackingOperationType.RESTORE_GOOGLE_USER_TRACKING,
        restoreGoogleUserTracking: { code: StatusCode.NONE },
    });
}

export const MapGoogleUserTracking = (update: CoreGoogleUserTrackingMapping, trackingId: string, dryRun: boolean) => (dispatch: Dispatch<Tracking_MapGoogleUserTracking>) => {
    dispatch({
        type: TrackingOperationType.MAP_GOOGLE_USER_TRACKING,
        mapGoogleUserTracking: { code: StatusCode.PENDING },
    });

    const url = `/api/core/trackingoperation/googleuser/${trackingId}/map?dryrun=${dryRun}`
    InstanceClient.post<CoreGoogleUserTrackingConflict[], CoreGoogleUserTrackingMapping>(url, update)
        .then((res) => dispatch({
            type: TrackingOperationType.MAP_GOOGLE_USER_TRACKING,
            mapGoogleUserTracking: { code: StatusCode.COMPLETE, data: res.data }
        }))
        .catch((error) => dispatch({
            type: TrackingOperationType.MAP_GOOGLE_USER_TRACKING,
            mapGoogleUserTracking: { code: StatusCode.ERROR, error },
        }));
}

export const ClearMapGoogleUserTracking = () => (dispatch: Dispatch<Tracking_MapGoogleUserTracking>) => {
    dispatch({
        type: TrackingOperationType.MAP_GOOGLE_USER_TRACKING,
        mapGoogleUserTracking: { code: StatusCode.NONE },
    });
}

const initialState: TrackingOperationState = {
    updateGoogleUserTracking: { code: StatusCode.NONE },
    inactivateGoogleUserTracking: { code: StatusCode.NONE },
    restoreGoogleUserTracking: { code: StatusCode.NONE },
    mapGoogleUserTracking: { code: StatusCode.NONE },
}

export const reducer: Reducer<TrackingOperationState, TrackingOperation> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case TrackingOperationType.UPDATE_GOOGLE_USER_TRACKING:
            return {
                ...state,
                updateGoogleUserTracking: action.updateGoogleUserTracking,
            };
        case TrackingOperationType.INACTIVATE_GOOGLE_USER_TRACKING:
            return {
                ...state,
                inactivateGoogleUserTracking: action.inactivateGoogleUserTracking,
            };
        case TrackingOperationType.RESTORE_GOOGLE_USER_TRACKING:
            return {
                ...state,
                restoreGoogleUserTracking: action.restoreGoogleUserTracking,
            };
        case TrackingOperationType.MAP_GOOGLE_USER_TRACKING:
            return {
                ...state,
                mapGoogleUserTracking: action.mapGoogleUserTracking,
            };
        default:
            return state;
    }
}