import * as SchoolTypeUtil from './SchoolTypeUtil';
import * as CoreModels from '../models/CoreModels'
import * as UserRelationUtil from './UserRelationUtil';
import { ModuleTypeInfo, OnChangeCallback } from './UtilityTypes';
import StatusCode from './StatusCode';
import { CoreUserListing } from '../models/CoreUser';
import { FlowSyncProduct, PageResult, PaginationResult } from '../models/CoreModels';
import { CacheRequestState } from '../models/RequestState';
import { CoreActivityListing } from '../models/CoreActivity';

// --- Functions ---
export const formatDate = (formatDate?: string, includeSeconds: boolean = true): string => formatDate ? new Date(formatDate).toLocaleString('sv-SE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: includeSeconds ? '2-digit' : undefined
}) : '-';

export const formatDateOnly = (formatDate?: string): string => formatDate ? new Date(formatDate).toLocaleDateString('sv-SE') : '-';

export const active = (active: boolean): string => active ? 'Ja' : 'Nej';

export const schoolTypes = (schoolTypes: { id: number } | undefined) => schoolTypes ? SchoolTypeUtil.getTypes(schoolTypes.id) : 'Saknar utbildningsform';

export const getISODate = (date: Date) => date.toLocaleDateString("sv-SE", { year: 'numeric', month: "2-digit", day: '2-digit' });

export const update = <T>(name: string, source: T, change: Partial<T>, callback: OnChangeCallback<T>) => callback(name, {
    ...source,
    ...change
});

//Activity
export const presentStudentGroups = (activity: CoreActivityListing): string => activity.groupAssignments?.map(ga => ga.title).join(', ') ?? 'Saknas';

//User
export const presentRole = (user: CoreUserListing): string => {
    const roles: string[] = [];

    if (user.enrollments && user.enrollments.length) roles.push('Elev');
    if (user.duties) {
        for (let i = 0; i < user.duties.length; i++) {
            const roleName = user.duties[i]?.dutyRole?.name;
            if (roleName && roles.indexOf(roleName) === -1) {
                roles.push(roleName);
            }
        }
    }

    if (user.userRelations) {
        for (let i = 0; i < user.userRelations.length; i++) {
            const roleName = UserRelationUtil.relationTypeById(user.userRelations[i].relationType, false)
            if (roles.indexOf(roleName) === -1) {
                roles.push(roleName);
            }
        }
    }

    if (roles.length > 0) {
        return roles.join(', ');
    }

    return 'Saknas';
};

export const copyToClipboard = (value: string) => {
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute("id", "clipboard_dummy");
    const clipboard = getElementById<HTMLInputElement>("clipboard_dummy");
    if (clipboard) {
        clipboard.value = value;
    }
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
}

export const equvivalent = <T>(a: T, b: T): boolean => {
    const type = typeof (a);

    // Is type equal and handle special cases.
    if (type !== typeof (b)) {
        return false;
    } else if (type === 'number') {
        return a === b || (a !== a && b !== b);
    } else if (type !== 'object') {
        return a === b;
    } else if (a === null) {
        return b === null;
    } else if (b === null) {
        return false;
    }

    // If array check if items are equal, order is not considered.
    if (Array.isArray(a)) {
        if (Array.isArray(b)) {
            if (a.length !== b.length) {
                return false;
            }

            const indiciesB: number[] = [];
            a.forEach((itemA) => {
                const indexB = b.findIndex((itemB: any, i: number) => !indiciesB.some(x => x === i) && equvivalent(itemA, itemB));
                if (indexB !== -1) {
                    indiciesB.push(indexB);
                }
            })

            return indiciesB.length === a.length;
        }

        return false;
    }

    if (typeof a === 'object' && typeof b === 'object' && equvivalent(Object.keys(a), Object.keys(b))) {
        return Object.keys(a).every(key => equvivalent(a[key as keyof T], b[key as keyof T]))
    }

    return false;
}

export const formatText = (raw: string) => {
    try {
        const json = JSON.parse(raw);
        if (typeof json === "object") {
            return JSON.stringify(json, null, 2)
        }
    }
    catch (e) {
        return raw;
    }
}

const getElementById = <TElement extends HTMLElement>(elementId: string): TElement | null => {
    var element = document.getElementById(elementId);
    if (element) {
        return element as TElement;
    }

    return null;
}

export const distinctSchoolAdminModuleTypes = (moduleTypes: CoreModels.Result<CoreModels.CoreModule[]>): ModuleTypeInfo[] => {
    if (moduleTypes.code === StatusCode.COMPLETE) {
        return moduleTypes.data
            .filter(x => x.product === FlowSyncProduct.SCHOOL_ADMIN)
            .filter((value, index, self) => self.findIndex(m => m.moduleTypeName === value.moduleTypeName) === index)
            .map(m => ({
                moduleType: m.moduleType,
                moduleTypeDisplayName: m.moduleTypeDisplayName,
                moduleTypeName: m.moduleTypeName
            }));
    }
    return [];
};

export const getModuleTypeObj = (modules: ModuleTypeInfo[], moduleType: string) => {
    return modules.find(m => m.moduleTypeName === moduleType);
}

export const activityType = (activityType: string): string => {
    switch (activityType) {
        case 'Other': return 'Annan';
        case 'Education': return 'Utbildning';
        case 'StudentActivity': return 'Elevaktivitet';
        case 'TeacherActivity': return 'Läraraktivitet';
        case 'Class': return 'Klass';
        default: return '-';
    }
}

export const ensureArray = <T>(array?: T[]): T[] => {
    if (array) {
        return array;
    }

    return new Array<T>(0);
}

/**
 * Should be migrated to using RequestState<PageResult<T>> directly.
 * @param paginationResult
 * @returns
 */
export const convertToCacheRequestState = <T>(paginationResult: PaginationResult<T[]>): CacheRequestState<PageResult<T>> => {
    switch (paginationResult.code) {
        case StatusCode.COMPLETE:
            return {
                code: StatusCode.COMPLETE,
                data: {
                    currentPage: paginationResult.pagination.currentPage,
                    totalCount: paginationResult.totalCount ?? 0,
                    pageSize: paginationResult.pagination.pageSize,
                    totalPages: paginationResult.pagination.totalPages,
                    values: paginationResult.data
                }
            };
        case StatusCode.ERROR:
            return {
                code: StatusCode.ERROR,
                error: {
                    ...paginationResult.error,
                    isAxiosError: false,
                    toJSON: () => ({}),
                }
            };
        case StatusCode.PENDING:
        case StatusCode.NONE:
            return {
                code: paginationResult.code
            };
    }
}

export const getTotalCount = <T>(requestState?: CacheRequestState<PageResult<T>>): number | undefined => {
    if (requestState === undefined || requestState.code === StatusCode.NONE || requestState.code === StatusCode.ERROR) {
        return undefined;
    }

    return requestState.data?.totalCount;
}

export const getTotalCountText = <T>(requestState: CacheRequestState<PageResult<T>>): string => {
    const count = getTotalCount(requestState);

    if (count === undefined) {
        return '';
    }

    return `(${count} st)`;
}

export const distinctBy = <T, TKey extends number | string>(array: T[], keySelector: (item: T) => TKey): T[] => {
    const usedKeys = new Set<TKey>();
    const result: T[] = [];

    for (let i = 0; i < array.length; i++) {
        const key = keySelector(array[i]);
        if (!usedKeys.has(key)) {
            result.push(array[i]);
            usedKeys.add(key);
        }
    }

    return result;
}

export const compareStrings = (a: string, b: string): number => {
    if (a === b) {
        return 0;
    } else if (a < b) {
        return -1;
    } else {
        return 1;
    }
}
