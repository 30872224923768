export enum FlowSyncAccessPolicy {
    ADMIN = "AdminAccess",
    READ = "ReadAccess",
    MAP = "MapAccess",
    EXPORT_UNEXPORTED_PASSWORD = "ExportUnexportedPassword",
    EXPORT_EXPORTED_PASSWORD = "ExportExportedPassword",
    READ_OVERVIEW = "ReadOverviewAccess"
}

export const getPolicyName = (policy: FlowSyncAccessPolicy) => {
    if (policy === FlowSyncAccessPolicy.ADMIN) {
        return "Administrera";
    }

    if (policy === FlowSyncAccessPolicy.READ) {
        return "Läsa";
    }

    if (policy === FlowSyncAccessPolicy.READ_OVERVIEW) {
            return "Läsa översikt";
    }

    if (policy === FlowSyncAccessPolicy.MAP) {
        return "Koppla";
    }

    if (policy === FlowSyncAccessPolicy.EXPORT_EXPORTED_PASSWORD) {
        return "Administrera lösenord";
    }

    if (policy === FlowSyncAccessPolicy.EXPORT_UNEXPORTED_PASSWORD) {
        return "Exportera lösenord";
    }
}

export const isAuthorized = (authorizedPolicies: FlowSyncAccessPolicy[], policy: FlowSyncAccessPolicy) => {
    return authorizedPolicies.some(p => p === policy);
}