import { Input, Button } from 'reactstrap';
import FontAwesomeButton from '../../../../../misc/FontAwesomeButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OnChangeCallback } from '../../../../../../util/UtilityTypes';
import React from 'react';
import { AlvisFile } from '../../../../../../models/CoreAlvisModels';

interface AlvisSftpFilesProps {
    name: string
    files: AlvisFile[]
    editable: boolean
    disabled?: boolean
    onChange: OnChangeCallback<AlvisFile[]> | null
}

const defaultFile: AlvisFile = {
    sftpFilename: '',
}

const AlvisSftpFiles: React.FC<AlvisSftpFilesProps> = (props) => {
    const files = props.files.length > 0 ? [...props.files] : [{ ...defaultFile }] 

    const removeFilename = (index: number | null) => {
        if (index === null) {
            return;
        }
        const remaining = files.filter((_, i) => i !== index);
        notifyChange(remaining);
    }
    
    const addFilename = (): void => {
        notifyChange([...files, { ...defaultFile }]);        
    }

    const updateFilename = (index: number | null, filename: string): void => {
        if (index === null) {
            return;
        }        

        files[index] = { ...files[index], sftpFilename: filename };
        notifyChange(files);
    }

    const notifyChange = (updatedFiles: AlvisFile[]): void => {
        if (props.onChange == null) {
            return;
        }

        props.onChange(props.name, updatedFiles);
    }

    const renderRow = (index: number | null, file: AlvisFile): React.ReactNode | null => {

        if (props.editable) {
            return (<React.Fragment key={index}>
                <div style={{ marginBottom: '0.15rem' }}>
                    <Input type="text" placeholder="Filnamn" autoComplete="off" value={file.sftpFilename} autoFocus={files.length>1 && index === files.length-1} 
                        onChange={(e) => updateFilename(index, e.target.value ) }
                    />                    
                </div>
                <div className="gridListCenterContent"><FontAwesomeButton icon="trash" onClick={() => removeFilename(index)} disabled={props.disabled || files.length < 2} title="Radera filnamn" /></div>
            </React.Fragment>)
        } else {
            return (<React.Fragment key={index}>
                <span title={file.sftpFilename}>
                    <FontAwesomeIcon icon="file" color="gray" className="icon" />
                    {' ' + file.sftpFilename}
                </span>
                <div/>
            </React.Fragment>);
        }
    }      

    return (
        <React.Fragment>
            <div className="gridList" style={{ gridTemplateColumns: '12fr 2fr' }}>
                {files.map((file, index) => renderRow(index, file))}
                {props.editable &&
                    <Button
                        className="flowsync-btn"
                        title="Lägg till ett nytt filnamn"
                        onClick={() => addFilename()}
                    >
                        <FontAwesomeIcon icon="plus" /> Nytt filnamn
                    </Button>
                }
            </div>
        </React.Fragment>
    )    
}

export default AlvisSftpFiles;