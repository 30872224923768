import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { GetPersonAddress, GetPersonEPPN, GetPersonEmail, GetPersonPhoneNumber, GetPersonSex } from "../../../util/PersonTypes";
import StatusCode from "../../../util/StatusCode";
import TableVertical from "../../misc/TableVertical";
import * as Helper from '../../../util/Helper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableHorizontal from "../../misc/TableHorizontal";
import { CoreUser } from "../../../models/CoreUser";
import { Result } from "../../../models/CoreModels";
import { GetUser } from "../../../store/UserAction";


interface UserState {
    user: Result<CoreUser>;
}

interface State {
    users: UserState
}

const UserViewInfo = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const user = useSelector<State, Result<CoreUser>>(state => state.users.user);
    const [show, setShow] = useState({ info: true, eppns: true, emails: true, addresses: true, phoneNumbers: true })

    useEffect(() => {
        if (userId) {
            GetUser(userId)(dispatch);
        }
    }, [dispatch, userId]);

    const renderUserInfo = () => {
        let userInfo = [];
        if (user.code === StatusCode.COMPLETE) {
            const u = user.data;
            userInfo.push(
                { header: 'Visningsnamn', content: u.displayName },
                { header: 'Aktiv', content: Helper.active(u.active) },
                { header: 'Skapad', content: Helper.formatDate(u.created) },
                { header: 'Ändrad', content: Helper.formatDate(u.modified) },
                { header: 'Raderad', content: Helper.formatDate(u?.removed) },
                { header: 'Sekretessmarkering', content: u.securityMarking ? 'Ja' : 'Nej' },
                { header: 'Förnamn', content: u.givenName },
                { header: 'Mellannamn', content: u.middleName },
                { header: 'Efternamn', content: u.familyName },
                { header: 'Kön', content: GetPersonSex(u.sex) },
                { header: 'Id', content: u.id },
                { header: 'Personnummer', content: u.civicNo },
                { header: 'Födelsedatum', content: Helper.formatDateOnly(u.birthDate) },
                { header: 'Person-status', content: u.personStatus },
                { header: 'Foto', content: u.photo && <Link to={u.photo} target="_blank"><>Visa foto <FontAwesomeIcon icon="arrow-up-right-from-square" className="icon" /></></Link> },
            )
        }
        return (
            <TableVertical
                header='Information'
                info={userInfo}
                error={user.code === StatusCode.ERROR}
                searching={user.code === StatusCode.PENDING}
                displayToggle={true}
                show={show.info}
                toggle={() => setShow({ ...show, info: !show.info })}
            />
        )
    }

    const renderUserEPPNs = () => {
        const eppnList = user.data?.eduPrincipalNames?.map((u, i) => ({
            id: i.toString(),
            data: [
                { value: GetPersonEPPN(u.type) },
                { value: u.value },
            ]
        })) ?? [];
        return (
            <TableHorizontal
                header={[{ value: "Typ" }, { value: "EduPersonPrincipalName" }]}
                info={eppnList}
                error={user.code === StatusCode.ERROR}
                searching={user.code === StatusCode.PENDING}
                displayName="EduPersonPrincipalName"
                showHeader={true}
                noResMsg="Inga eduPersonPrincipalNames hittades"
                displayToggle={true}
                show={show.eppns}
                toggle={() => setShow({ ...show, eppns: !show.eppns })}
            />
        )
    }

    const renderUserEmails = () => {
        const emailsList = user.data?.emails?.map((u, i) => ({
            id: i.toString(),
            data: [
                { value: GetPersonEmail(u.type) },
                { value: u.value },
            ]
        })) ?? [];
        return (
            <TableHorizontal
                header={[{ value: "Typ" }, { value: "E-postadress" }]}
                info={emailsList}
                error={user.code === StatusCode.ERROR}
                searching={user.code === StatusCode.PENDING}
                displayName="E-postadresser"
                showHeader={true}
                noResMsg="Inga e-postadresser hittades"
                displayToggle={true}
                show={show.emails}
                toggle={() => setShow({ ...show, emails: !show.emails })}
            />
        )
    }

    const renderUserAddresses = () => {
        const addressList = user.data?.addresses?.map((u, i) => ({
            id: i.toString(),
            data: [
                { value: GetPersonAddress(u.type) },
                { value: u.streetAddress },
                { value: u.postalCode },
                { value: u.locality },
            ]
        })) ?? [];
        return (
            <TableHorizontal
                header={[{ value: "Typ" }, { value: "Gatuadress" }, { value: "Postkod" }, { value: "Stad" }]}
                info={addressList}
                error={user.code === StatusCode.ERROR}
                searching={user.code === StatusCode.PENDING}
                displayName="Adresser"
                showHeader={true}
                noResMsg="Inga adresser hittades"
                displayToggle={true}
                show={show.addresses}
                toggle={() => setShow({ ...show, addresses: !show.addresses })}
            />
        )
    }

    const renderUserPhoneNumbers = () => {
        const phoneNumberList = user.data?.phoneNumbers?.map((u, i) => ({
            id: i.toString(),
            data: [
                { value: GetPersonPhoneNumber(u.type) },
                { value: u.value },
                { value: Helper.active(u.mobile) },
            ]
        })) ?? [];
        return (
            <TableHorizontal
                header={[{ value: "Typ" }, { value: "Telefonnummer" }, { value: "Mobil" }]}
                info={phoneNumberList}
                error={user.code === StatusCode.ERROR}
                searching={user.code === StatusCode.PENDING}
                displayName="Telefonnummer"
                showHeader={true}
                noResMsg="Inga telefonnummer hittades"
                displayToggle={true}
                show={show.phoneNumbers}
                toggle={() => setShow({ ...show, phoneNumbers: !show.phoneNumbers })}
            />
        )
    }

    return (<>
        {renderUserInfo()}
        {renderUserEPPNs()}
        {renderUserEmails()}
        {renderUserAddresses()}
        {renderUserPhoneNumbers()}
    </>)
}

export default UserViewInfo;
