import React, { useState, useEffect, ReactNode } from 'react'
import { useSelector } from 'react-redux';
import { DnpState } from '../../../../../../store/DnpAction';
import StatusCode from '../../../../../../util/StatusCode';
import Loading from '../../../../../misc/Loading';
import CheckboxListItem from '../../../../../misc/CheckboxListItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom'
import ModalComponent from '../../../../../misc/ModalComponent';
import { DnpOrganisationStatus, DnpOrganization, DnpOrganizationSchoolUnit, CoreDnpExportSchoolUnit, DnpEnvironment } from '../../../../../../models/CoreDnpModels';
import { CoreConfigurationSchoolUnit } from '../../../../../../models/CoreSchoolUnit';
import { RequestState } from '../../../../../../models/RequestState';
import MultiSelectInput from '../../../../../misc/MultiSelectInput';
import { SearchableOptionItem } from '../../../../../../util/UtilityTypes';

interface MappedSchoolUnit {
    dnpSchoolUnitId: string
    displayName: string
    schoolUnitCode: string
    mappedSchoolUnitIds?: string[]
    dnpStatus?: boolean
}

interface DnpSchoolUnitsProps {
    updatePropertyValue: (name: string, value: null | string | CoreDnpExportSchoolUnit[]) => void;
    validation: string[]
    orgNum?: string
    environment: DnpEnvironment
    mergedDnpSchoolUnits: CoreDnpExportSchoolUnit[]
    disabled: boolean
    schoolUnits: CoreConfigurationSchoolUnit[]
    dnpExportId: string
    savedDnpSchoolUnits: CoreDnpExportSchoolUnit[]
}

interface RemoveMappedSchoolUnit {
    schoolUnitId: string
    dnpSchoolUnitId: string
    dnpSchoolUnitDisplayName: string
}

interface DnpSchoolUnitsState {
    dnp: DnpState
}

const DnpSchoolUnitsComponent: React.FC<DnpSchoolUnitsProps> = (props) => {
    const dnp = useSelector<DnpSchoolUnitsState, RequestState<DnpOrganization>>(state => state.dnp.dnpOrganization);
    const [schoolUnit, setSchoolUnit] = useState<{ id: string, displayName: string, invalid: boolean } | undefined>(undefined);
    const [schoolUnitMappingsToRemove, setSchoolUnitMappingsToRemove] = useState<RemoveMappedSchoolUnit[] | undefined>(undefined);

    const activeDnpSchoolUnits = dnp.code === StatusCode.COMPLETE ? dnp.data.schoolUnits
        .filter(x => x.status === DnpOrganisationStatus.ACTIVE) : [];

    const dnpSchoolUnitLookup =
        activeDnpSchoolUnits.reduce((map, dnpSchoolUnit) => {
            map.set(dnpSchoolUnit.id, dnpSchoolUnit);
            return map;
        }, new Map<string, DnpOrganizationSchoolUnit>());

    useEffect(() => {
        if (dnp.code === StatusCode.COMPLETE && dnp.data.organizationNumber !== props.orgNum) {
            props.updatePropertyValue('organizationName', dnp.data.displayName);
        }
    }, [dnp.code === StatusCode.COMPLETE]);

    const onChangeSchoolUnit = (e: React.ChangeEvent<HTMLInputElement>, mappedSchoolUnit: MappedSchoolUnit, invalid: boolean): void => {
        const dnpSchoolUnitId = e.target.id;
        const dnpSchoolUnit = dnpSchoolUnitLookup.get(dnpSchoolUnitId);
        const mergedSchoolUnit = schoolUnitChecked(dnpSchoolUnitId);

        if (e.target.checked) {
            if (dnpSchoolUnit) {
                addSchoolUnit(dnpSchoolUnit);
            }
        }
        else {
            if (invalid && mergedSchoolUnit) {
                setSchoolUnit({
                    id: mergedSchoolUnit.dnpSchoolUnitId,
                    displayName: mergedSchoolUnit?.dnpSchoolUnitDisplayName,
                    invalid: invalid
                })
            } else if (!invalid && dnpSchoolUnit) {
                setSchoolUnit({
                    id: dnpSchoolUnit.id,
                    displayName: dnpSchoolUnit.displayName,
                    invalid: invalid
                })
            }
        }
    };

    const removeSchoolUnit = (dnpSchoolUnitId: string): void => {
        let mergedDnpSchoolUnits = [...props.mergedDnpSchoolUnits];
        mergedDnpSchoolUnits = mergedDnpSchoolUnits.filter(s => s.dnpSchoolUnitId !== dnpSchoolUnitId);
        setSchoolUnit(undefined);
        props.updatePropertyValue('dnpSchoolUnits', mergedDnpSchoolUnits);
    }

    const removeSchoolUnitMappings = (): void => {
        if (schoolUnitMappingsToRemove === undefined) {
            return;
        }

        var toRemoveLookup: { [key: string]: string[] } = {};
        schoolUnitMappingsToRemove.forEach(x => {
            if (toRemoveLookup[x.dnpSchoolUnitId]) {
                toRemoveLookup[x.dnpSchoolUnitId].push(x.schoolUnitId);
            } else {
                toRemoveLookup[x.dnpSchoolUnitId] = [x.schoolUnitId];
            }
        });

        const mergedDnpSchoolUnits = props.mergedDnpSchoolUnits.map(dnpSchoolUnit => {
            var toRemove = toRemoveLookup[dnpSchoolUnit.dnpSchoolUnitId];
            if (toRemove) {
                const filteredSchoolUnits = dnpSchoolUnit.mappedSchoolUnitIds?.filter(x => !toRemove.some(y => y === x));

                return {
                    ...dnpSchoolUnit, mappedSchoolUnitIds: filteredSchoolUnits
                };
            } else {
                return dnpSchoolUnit;
            }
        });

        setSchoolUnitMappingsToRemove(undefined);
        props.updatePropertyValue('dnpSchoolUnits', mergedDnpSchoolUnits);
    }

    const addSchoolUnit = (dnpSchoolUnit: DnpOrganizationSchoolUnit): void => {
        let mergedDnpSchoolUnits: CoreDnpExportSchoolUnit[] = [...props.mergedDnpSchoolUnits];
        const mappedSchoolUnitIds = findMatchingFlowSyncSchoolUnitId(dnpSchoolUnit.id, dnpSchoolUnit.schoolUnitCode)

        mergedDnpSchoolUnits.push({
            mappedSchoolUnitIds: mappedSchoolUnitIds,
            dnpSchoolUnitId: dnpSchoolUnit.id,
            dnpSchoolUnitDisplayName: dnpSchoolUnit.displayName,
            dnpSchoolUnitCode: dnpSchoolUnit.schoolUnitCode,
        })
        props.updatePropertyValue('dnpSchoolUnits', mergedDnpSchoolUnits);
    }

    const onChangeFlowSyncSchoolUnit = (newSchoolUnitIds: string[] | null | undefined, flowsyncDnpSchoolUnitId: string): void => {
        if (newSchoolUnitIds === undefined || newSchoolUnitIds === null) {
            return;
        }

        const mergedDnpSchoolUnits = props.mergedDnpSchoolUnits.map(dnpSchoolUnit => {
            if (dnpSchoolUnit.dnpSchoolUnitId === flowsyncDnpSchoolUnitId) {
                const removedSchoolUnits = dnpSchoolUnit.mappedSchoolUnitIds?.filter(x => !newSchoolUnitIds.some(y => y === x)).map(x => ({
                    schoolUnitId: x,
                    dnpSchoolUnitId: dnpSchoolUnit.dnpSchoolUnitId,
                    dnpSchoolUnitDisplayName: dnpSchoolUnit.dnpSchoolUnitDisplayName,
                })) ?? [];

                if (removedSchoolUnits.length > 0) {
                    setSchoolUnitMappingsToRemove(removedSchoolUnits);
                }

                const mergedMappings = [...newSchoolUnitIds, ...removedSchoolUnits.map(x => x.schoolUnitId)]

                return {
                    ...dnpSchoolUnit, mappedSchoolUnitIds: mergedMappings
                };
            } else {
                return dnpSchoolUnit;
            }
        });

        props.updatePropertyValue('dnpSchoolUnits', mergedDnpSchoolUnits);
    }

    const schoolUnitChecked = (dnpSchoolUnitId: string): CoreDnpExportSchoolUnit | undefined => {
        return props.mergedDnpSchoolUnits.find(x => x.dnpSchoolUnitId === dnpSchoolUnitId);
    }

    const findMatchingFlowSyncSchoolUnitId = (dnpSchoolUnitId: string, dnpSchoolUnitCode: string): string[] => {
        const mappedSchool = props.mergedDnpSchoolUnits.find(x => x.dnpSchoolUnitId === dnpSchoolUnitId);
        const schoolBySchoolUnitCode = props.schoolUnits.find(x => x.schoolUnitCode === dnpSchoolUnitCode);
        if (mappedSchool?.mappedSchoolUnitIds !== undefined) {
            return mappedSchool.mappedSchoolUnitIds
        }
        else if (schoolBySchoolUnitCode) {
            return [schoolBySchoolUnitCode.id];
        }
        else {
            return [];
        }
    };

    const renderSchoolUnits = (schoolUnits: MappedSchoolUnit[], disabled: boolean): ReactNode => {
        const isInvalidSchoolUnit = (id: string): boolean =>
            props.validation.indexOf(`schoolUnitMissingFromSkolverket-${id}`) !== -1 ||
            props.validation.indexOf(`mappedSchoolUnitIds-${id}`) !== -1 ||
            props.validation.indexOf(`inactiveSchoolUnit-${id}`) !== -1;
        const hasSavedSchoolUnits = (dnpSchoolUnitId: string, mappedSchoolUnitIds: string[] | undefined): boolean => {
            if (!mappedSchoolUnitIds) {
                return false;
            }

            const found = props.savedDnpSchoolUnits.find(x => x.dnpSchoolUnitId === dnpSchoolUnitId && x.mappedSchoolUnitIds === mappedSchoolUnitIds)
            if (found) {
                return true;
            }

            return false;
        }

        const header = ['Skolenhet från skolenhetsregistret', 'Dnp-Status', 'Skolenhet från FlowSync', 'Aktivitetskoppling'];

        return (
            <div className="dnp-schoolUnits grid-table">
                <div className="--header">
                    {header.map((head, i) => (
                        <div key={i}>{head}</div>
                    ))}
                </div>
                {schoolUnits
                    .sort((a, b) => a.displayName > b.displayName ? 1 : -1)
                    .map(s => {
                        const invalid = isInvalidSchoolUnit(s.dnpSchoolUnitId);

                        const schoolUnitItems: SearchableOptionItem<string>[] = props.schoolUnits
                            ?.filter(x => x.isActive || (findMatchingFlowSyncSchoolUnitId(s.dnpSchoolUnitId, s.schoolUnitCode)?.includes(x.id) ?? false))
                            ?.map(m => ({
                                value: m.id,
                                label: `${m.title}${m.schoolUnitCode ? ` (${m.schoolUnitCode})` : ''}${!m.isActive ? ' [INAKTIV]' : ''}`
                            }))

                        return (
                            <div key={s.dnpSchoolUnitId} >
                                <div className={(invalid ? 'red-border ' : '') + "--row"}>
                                    <div className="displayName"><CheckboxListItem text={s.displayName}
                                        value={Boolean(schoolUnitChecked(s.dnpSchoolUnitId))}
                                        id={s.dnpSchoolUnitId}
                                        name={`schoolUnitChecked-${s.dnpSchoolUnitId}`}
                                        onChange={(e) => onChangeSchoolUnit(e, s, invalid)}
                                        disabled={disabled || props.disabled || props.validation.indexOf('organizationNumber') !== -1}
                                    /></div>
                                    {s.dnpStatus === undefined ? null :
                                        (s.dnpStatus ?
                                            <FontAwesomeIcon title="Skolenheten är aktiverad för Digitala Nationella Prov" color="green" icon="toggle-on" />
                                            : <FontAwesomeIcon title="Skolenheten är inte aktiverad för Digitala Nationella Prov" color="lightgray" icon="toggle-off" />
                                        )}

                                    <MultiSelectInput
                                        items={schoolUnitItems}
                                        onChange={(_, value) => onChangeFlowSyncSchoolUnit(value, s.dnpSchoolUnitId)}
                                        defaultText='Välj en skola'
                                        name={`schoolUnit-${s.dnpSchoolUnitId}`}
                                        selected={schoolUnitChecked(s.dnpSchoolUnitId) && findMatchingFlowSyncSchoolUnitId(s.dnpSchoolUnitId, s.schoolUnitCode)}
                                        invalid={props.validation.indexOf(`mappedSchoolUnitIds-${s.dnpSchoolUnitId}`) !== -1 || props.validation.indexOf(`inactiveSchoolUnit-${s.dnpSchoolUnitId}`) !== -1}
                                        invalidFeedback={props.validation.indexOf(`mappedSchoolUnitIds-${s.dnpSchoolUnitId}`) !== -1 ? 'Ingen skola med den aktuella skolenhetskoden kunde hittas. Välj en skola manuellt.' : 'En av de valda skolorna är inaktiva.'}
                                        disabled={disabled || props.disabled || props.validation.indexOf(`schoolUnitMissingFromSkolverket-${s.dnpSchoolUnitId}`) !== -1 || !schoolUnitChecked(s.dnpSchoolUnitId)}
                                    />
                                    {hasSavedSchoolUnits(s.dnpSchoolUnitId, s.mappedSchoolUnitIds) && !invalid && <Link to={`/mapping/${s.dnpSchoolUnitId}/${props.dnpExportId}`} target="_blank">Aktivitetskoppling<FontAwesomeIcon className="ms-3" icon="arrow-up-right-from-square" /></Link>}
                                </div>
                                {props.validation.indexOf(`schoolUnitMissingFromSkolverket-${s.dnpSchoolUnitId}`) !== -1 && <span className="red ps-3">Skolan saknas från Skolverket.</span>}
                            </div>
                        )
                    })}
            </div>);
    };

    const renderBody = (): ReactNode => {
        const mappedSchoolUnits = props.mergedDnpSchoolUnits.map(x => ({
            dnpSchoolUnitId: x.dnpSchoolUnitId,
            displayName: x.dnpSchoolUnitDisplayName,
            schoolUnitCode: x.dnpSchoolUnitCode,
            mappedSchoolUnitIds: x.mappedSchoolUnitIds,
            dnpStatus: dnpSchoolUnitLookup.get(x.dnpSchoolUnitId)?.dnpStatus ?? false
        }));

        switch (dnp.code) {
            case StatusCode.NONE: {
                let text = "Visar upp redan kopplade skolor";
                return (<>
                    <p>{text}</p>
                    {renderSchoolUnits(mappedSchoolUnits, true)}
                </>);
            };
            case StatusCode.PENDING: return <Loading />;
            case StatusCode.COMPLETE: {
                const notMappedSchoolUnits = activeDnpSchoolUnits
                    .filter(x => !props.mergedDnpSchoolUnits.find(y => y.dnpSchoolUnitId === x.id))
                    .map(x => ({
                        dnpSchoolUnitId: x.id,
                        displayName: x.displayName,
                        schoolUnitCode: x.schoolUnitCode,
                        schoolUnitId: undefined,
                        hasDnpActivityMapping: false,
                        dnpStatus: x.dnpStatus
                    }));

                return (<>
                    <p>Visar skolenheter från Skolverkets skolenhetsregister</p>
                    {renderSchoolUnits([...mappedSchoolUnits, ...notMappedSchoolUnits], schoolUnitMappingsToRemove !== undefined)}
                </>);
            };
            case StatusCode.ERROR: {
                let text = "Ett fel uppstod vid hämtande från Skolverket. Visar upp redan kopplade skolor.";
                if (dnp.error.response?.status === 404) {
                    text = "Kunde inte hitta organisationen. Visar upp redan kopplade skolor.";
                };
                if (!props.mergedDnpSchoolUnits.length) {
                    text = "Kunde inte hitta organisationen.";
                }

                return (<>
                    <p>{text}</p>
                    {renderSchoolUnits(mappedSchoolUnits, true)}
                </>);
            }
            default: return;
        }
    }

    const renderModal = (): ReactNode => {
        if (schoolUnit === undefined) {
            return;
        }

        return (
            <ModalComponent
                isOpen={schoolUnit !== undefined}
                toggleModal={() => setSchoolUnit(undefined)}
                header="Ta bort skola"
                size="sm"
                cancel="Nej"
                confirm="Ja"
                update={() => removeSchoolUnit(schoolUnit.id)}
            >
                <div>
                    {schoolUnit.invalid ?
                        <p>{`Den här skolan finns inte i det här organisationsnumret och har aktivitetskopplingar.
                        Är du säker på att du vill ta bort ${schoolUnit.displayName} samt dess aktivitetskopplingar?`}</p>
                        :
                        <p>{`Är du säker på att du vill ta bort ${schoolUnit.displayName} samt dess aktivitetskopplingar?`}</p>
                    }
                </div>
            </ModalComponent>
        )
    }

    const renderUnmapSchoolUnitModal = (): ReactNode => {
        if (schoolUnitMappingsToRemove === undefined) {
            return;
        }

        const mappings = schoolUnitMappingsToRemove.map(x => {
            const schoolUnit = props.schoolUnits?.find(y => y.id === x.schoolUnitId);

            let schoolUnitName = 'Okänd';
            if (schoolUnit) {
                const schoolUnitCode = schoolUnit.schoolUnitCode ?? '';
                schoolUnitName = `${schoolUnit.title} ${schoolUnitCode}`;
            }

            return {
                name: `${x.dnpSchoolUnitDisplayName} - ${schoolUnitName}`,
                key: `${x.dnpSchoolUnitId}${x.schoolUnitId}`,
            };
        });

        return (
            <ModalComponent
                isOpen={schoolUnitMappingsToRemove !== undefined}
                toggleModal={() => setSchoolUnitMappingsToRemove(undefined)}
                header="Ta bort skolkopplingar"
                size="sm"
                cancel="Nej"
                confirm="Ja"
                update={removeSchoolUnitMappings}
            >
                <div>
                    <p>{`Är du säker på att du vill ta bort följande skolkopplingar samt dess aktivitetskopplingar?`}</p>
                    {mappings.map(x => (<p key={x.key}>{x.name}</p>))}
                </div>
            </ModalComponent>
        )
    }

    return (<div className="dnp mt-4">
        <h5>Koppla skolenheter</h5>
        {renderBody()}
        {renderModal()}
        {renderUnmapSchoolUnitModal()}
    </div>);
}

export default DnpSchoolUnitsComponent;
