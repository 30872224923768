import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatusCode from '../../util/StatusCode';
import Loading from './Loading';

interface SaveStatusProps {
    saveStatus: StatusCode,
    successMsg?: string,
}

const SaveStatus: React.FC<SaveStatusProps> = ({ saveStatus, successMsg }) => {
    switch (saveStatus) {
        case StatusCode.PENDING: return <Loading />;
        case StatusCode.ERROR: return <>
            <FontAwesomeIcon icon="exclamation-triangle" style={{ fontSize: '22px', verticalAlign: 'middle', color: '#ffc107' }} />
            <span style={{ marginLeft: '0.5rem' }}>Något gick fel</span>
        </>;
        case StatusCode.COMPLETE: return <>
            <FontAwesomeIcon icon="check" style={{ fontSize: '22px', verticalAlign: 'middle', color: '#27807d' }} />
            <span style={{ marginLeft: '0.5rem' }}>{successMsg}</span>
        </>;
        default: return null;
    }
}

export default SaveStatus;