import React, { ReactNode } from 'react';
import { Table } from 'reactstrap';
import Loading from './Loading';
import Pagination from './Pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CacheRequestState } from '../../models/RequestState';
import { PageResult } from '../../models/CoreModels';
import StatusCode from '../../util/StatusCode';

export interface InfoObject {
    id: string
    data: TdContent[]
}

interface TdContent { value: ReactNode, className?: string }

type OnChangePageCallback = (value: number) => void;

interface TableHorizontalPagedToggleProps<T> {
    info: InfoObject[]
    displayName?: string
    toggle: () => void
    show: boolean
    header: TdContent[]
    mobileHeader?: string[]
    errorMsg?: string
    noResultMsg: string
    onChangePage: OnChangePageCallback,
    requestState: CacheRequestState<PageResult<T>>
}

const TableHorizontalPagedToggle = <T,>(props: TableHorizontalPagedToggleProps<T>) => {
    const searching = props.requestState.code === StatusCode.PENDING;
    const error = props.requestState.code === StatusCode.ERROR;

    return (
        <div>
            {searching && props.info.length > 0 && <Loading className="spinner-update" />}
            {props.displayName &&
                <div className="mobile-header-table">{props.displayName}
                    <div onClick={props.toggle} className="right c-pointer">
                        {props.show ? <FontAwesomeIcon title="Dölj information" icon="chevron-down" /> : <FontAwesomeIcon title="Visa information" icon="chevron-right" />}
                    </div>
                </div>
            }
            <Table className="overwiew-table">
                <thead onClick={props.toggle}>
                    <tr>
                        {props.header.map((h, i) => (
                            <th className={h.className} key={i}>{h.value}</th>
                        ))}
                        <th className="mw-1 c-pointer">{props.show ? <FontAwesomeIcon title="Dölj information" icon="chevron-down" /> : <FontAwesomeIcon title="Visa information" icon="chevron-right" />}</th>
                    </tr>
                </thead>
                {props.show &&
                    <tbody>
                        {props.info && props.info.map(row =>
                            <tr className="list_table" key={row.id}>
                                {row.data.map((d, i) => {
                                    const dataLabel = props.mobileHeader ? props.mobileHeader[i] : props.header[i];
                                    return (<td key={i} data-label={dataLabel} className={d.className ? d.className : ""}>{d.value}</td>);
                                })}
                                <td className="mobile-hide"></td>
                            </tr>
                        )}
                    </tbody>}
            </Table>
            {props.show && <>
                {searching && props.info.length === 0 && <Loading />}
                {props.errorMsg && <p>{props.errorMsg}</p>}
                {!props.errorMsg && error && <p>Kunde inte hämta</p>}
                {!error && !searching && !props.info.length && props.noResultMsg && <p>{props.noResultMsg}</p>}
                {!error && !searching && !props.info.length && !props.noResultMsg && <p>Resultat saknas</p>}

                <Pagination className="mb-3" requestState={props.requestState} onChangePage={(p) => props.onChangePage(p)} />
            </>}
        </div>
    )
}

export default TableHorizontalPagedToggle;
