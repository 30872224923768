import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Nav, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';
import ModuleInstanceFilesModal from '../../storageContainer/ModuleInstanceFilesModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { downloadInstanceLogs } from '../../../store/ModuleAction';


const DownloadFilesForInstance = (props) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState({});

    const downloadFile = (moduleInstanceId) => {
        downloadInstanceLogs(props.moduleId, moduleInstanceId, {})(dispatch);
    }

    const renderDropdownMenu = (moduleInstanceId, completed) => {
        const fileDownloadEnabled = props.fileDownloadEnabled;
        return (
            <Nav className="right">
                {(completed || fileDownloadEnabled) &&
                    <Dropdown nav isOpen={dropdownOpen[moduleInstanceId]} toggle={() => setDropdownOpen({ [moduleInstanceId]: !dropdownOpen[moduleInstanceId] })}>
                        <DropdownToggle nav style={{ display: 'inline' }}>
                            <FontAwesomeIcon icon="ellipsis" className="icon" title="Hämta loggar och filer" />
                        </DropdownToggle>
                        <DropdownMenu>
                            {completed && <DropdownItem header><span className="pointer" onClick={() => downloadFile(moduleInstanceId)}>Hämta loggar <FontAwesomeIcon icon="file-download" className="icon" /></span></DropdownItem>}
                            {fileDownloadEnabled && <DropdownItem header><span className="pointer" onClick={() => setOpen(true)}> Hämta filer <FontAwesomeIcon icon="folder" color="orange" className="icon" /></span></DropdownItem>}
                        </DropdownMenu>
                    </Dropdown>
                }
            </Nav>
        )
    }

    const renderModal = () => (
        <ModuleInstanceFilesModal
            moduleInstanceId={props.moduleInstanceId}
            isOpen={open}
            toggleModal={() => setOpen(!open)}
        />);

    return (<>
        {renderDropdownMenu(props.moduleInstanceId, props.completed)}
        {open && renderModal()}
    </>);
}

export default DownloadFilesForInstance;
