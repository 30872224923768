import React, { Component } from 'react';
import { Input, Form, Button } from 'reactstrap';
import FontAwesomeButton from '../../../../misc/FontAwesomeButton';
import ModalComponent from '../../../../misc/ModalComponent';
import { GridListItem, GridListHeader } from '../../../../misc/GridListComponents';

const filterEquals = (a, b) => a === b;

class SchoolSoftFilterInput extends Component {
    state = {
        filters: this.props.filters,
        currentIndex: null,
        currentFilter: '',
        isOpen: false,
        deleteFilter: {},
    }

    submitFilter = (e) => {
        e.preventDefault()
        const filters = this.calculateUpdatedFilters();
        if (filters) {
            this.setState({ filters, currentIndex: null, currentFilter: '' });
            this.notifyChange(filters);
        } else {
            this.setState({ currentIndex: null, currentFilter: '' });
        }
    }

    calculateUpdatedFilters = () => {
        if (Number.isFinite(this.state.currentIndex)) {
            return this.state.filters.map((x, i) => i === this.state.currentIndex ? this.state.currentFilter : x);
        } else if (!this.state.filters.some(x => filterEquals(x, this.state.currentFilter))) {
            return [...this.state.filters, this.state.currentFilter];
        } else {
            return null;
        }
    }

    editFilter = (index) => {
        this.setState({
            currentIndex: index,
            currentFilter: this.state.filters[index],
        });
    }

    removeFilter(index) {
        this.setState({ isOpen: true, deleteFilter: { name: this.state.filters[index], index: index } })
    }

    renderModal() {
        return (
            <ModalComponent
                isOpen={this.state.isOpen}
                header="Radera filter"
                disabled={this.props.disabled}
                confirm="Ja"
                cancel="Nej"
                toggleModal={this.toggleModal}
                update={this.updateFilter}
            >
                <div>
                    <p>Vill du verkligen radera filtret "{this.state.deleteFilter.name}"?</p>
                    <p>Om du ångrar dig senare måste du lägga till ett nytt filter med exakt samma värde.</p>
                </div>
            </ModalComponent>
        )
    }

    abort = () => {
        this.setState({ currentIndex: null, currentFilter: '' })
    }

    toggleModal = () => {
        this.setState({ isOpen: false, deleteFilter: {} })
    }

    updateFilter = () => {
        const filters = this.state.filters.filter((_, i) => i !== this.state.deleteFilter.index);
        this.setState({ filters });
        this.notifyChange(filters);
        this.toggleModal();
    }

    valid = () => {
        if (!this.state.currentFilter.length) {
            return false;
        }
        return true;
    }

    notifyChange = (filters) => {
        this.props.onChange({ target: { name: this.props.name, value: filters } });
    }

    updateValue = (e) => {
        this.setState({ currentFilter: e.target.value });
    }

    render() {
        return (
            <React.Fragment>
                <Form className="gridList" style={{ gridTemplateColumns: '16fr 1.5fr 1.5fr' }} onSubmit={(e) => this.submitFilter(e)}>
                    <GridListHeader>Ämne</GridListHeader>
                    <GridListHeader span={{ startColumn: 2, columnCount: 2 }} />
                    {this.state.filters.map((filter, index) => this.renderRow(index, filter))}
                    {this.renderRow(null, this.state.currentFilter)}
                </Form>
                {this.state.isOpen && this.renderModal()}
            </React.Fragment>);
    }

    renderRow(index, filter) {
        const editing = Number.isFinite(this.state.currentIndex);

        if (index === this.state.currentIndex) {
            return (<React.Fragment key={index}>
                <GridListItem>
                    <Input type="text" disabled={this.props.disabled} placeholder="Ange värde som ska filtreras bort" autoComplete="off" value={this.state.currentFilter} onChange={this.updateValue} />
                </GridListItem>
                {editing ? <GridListItem span={{ startColumn: 2, columnCount: 2 }}>
                    <Button style={{ width: '100%' }} color="primary" type="submit" disabled={this.props.disabled || !this.valid()}>Spara</Button>
                    <Button style={{ width: '100%' }} color="danger" onClick={() => this.abort()}>Avbryt</Button>
                </GridListItem> : <GridListItem span={{ startColumn: 2, columnCount: 2 }}>
                    <Button style={{ width: '100%' }} color="primary" type="submit" disabled={this.props.disabled}>Lägg till</Button>
                </GridListItem>}
            </React.Fragment>)
        } else if (Number.isFinite(index)) {
            return (<React.Fragment key={index}>
                <GridListItem>{filter}</GridListItem>
                <GridListItem className="gridListCenterContent">
                    <FontAwesomeButton icon="pen" onClick={() => this.editFilter(index)} disabled={this.props.disabled || editing} title="Redigera filter" />
                </GridListItem>
                <GridListItem className="gridListCenterContent">
                    <FontAwesomeButton icon="trash" onClick={() => this.removeFilter(index)} disabled={this.props.disabled || editing} title="Radera filter" />
                </GridListItem>
            </React.Fragment>);
        } else {
            return null;
        }
    }
}

export default SchoolSoftFilterInput;
