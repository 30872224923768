import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Form, Button, FormGroup, Input } from 'reactstrap';
import { Link } from 'react-router-dom'
import ActiveToggle from '../misc/ActiveToggle';
import * as Query from '../../util/UpdateQuery';
import { ScheduleSyncUsers } from '../../store/ScheduleSync/ScheduleSync_User';
import StatusCode from '../../util/StatusCode';
import TableHorizontal from '../misc/TableHorizontal';
import * as Helper from '../../util/Helper';
import SelectInput from '../misc/SelectInput';
import * as ScheduleSyncRoleTypes from '../../util/ScheduleSync/ScheduleSyncRoleTypeUtil';
import { withRouter } from '../../components/withRouter';
import FilterBottomComponent from '../misc/FilterBottomComponent';


const initialFilter = {
    search: '',
    socialnumber: '',
    onlyActive: true,
    role: undefined,
}

class Users_ScheduleSync extends Component {
    state = {
        filter: initialFilter
    }

    componentDidMount() {
        let userFilter = Query.getQuery(document.location.search);

        var updateFilter = {};
        Object.entries(userFilter).map(([key, value]) => {
            updateFilter[key] = value;
        })
        this.setState({ filter: { ...this.state.filter, ...updateFilter } })

        this.props.ScheduleSyncUsers(userFilter);
    }

    updateQuery = (list) => {
        let query = Query.setQuery(list)
        this.props.navigate(window.location.pathname + query);
        window.scrollTo(0, 0);
    }

    updateFilter = (name, value) => {
        let filter = { ...this.state.filter }
        filter[name] = value;
        filter['page'] = 1;
        this.setState({ filter: filter })
    }

    onPageChange = (name, value) => {
        let filter = { ...this.props.filter };
        filter[name] = value;
        this.updateQuery(filter);
        this.props.ScheduleSyncUsers(filter, true);
    }

    updateSearchFilter = (e) => {
        e.preventDefault();
        this.updateFilter(e.target.name, e.target.value);
    }

    onSearch = (e) => {
        e.preventDefault();
        this.updateQuery(this.state.filter);
        this.props.ScheduleSyncUsers(this.state.filter, true);
    }

    clearFilter = () => {
        this.setState({ filter: initialFilter })
        this.updateQuery(initialFilter);
        this.props.ScheduleSyncUsers({}, true);
    }

    renderFilter() {
        return (
            <Form autoComplete="off" onSubmit={e => this.onSearch(e)}>
                <Row className="search-box">
                    <Col lg="6">
                        <FormGroup className="search-form">
                            <Input type="text" name="search" value={this.state.filter.search || ''} onChange={this.updateSearchFilter} placeholder="Sök användare" />
                        </FormGroup>
                        <FormGroup className="search-form">
                            <Input type="text" name="socialnumber" value={this.state.filter.socialnumber || ''} onChange={this.updateSearchFilter} placeholder="Sök på personnummer" />
                        </FormGroup>
                    </Col>

                    <Col lg="3">
                        <SelectInput
                            items={Object.entries(ScheduleSyncRoleTypes.RoleTypes).map(([key, value]) => ({ value: key, text: `Filtrera på ${value}` }))}
                            onChange={(name, value) => this.updateFilter(name, value)}
                            name='role'
                            selected={this.state.filter.role}
                            defaultText='Visa alla roller'
                        />
                    </Col>

                    <Col lg="3" className="flex dir-column-lg space-between">
                        <ActiveToggle
                            onlyActive={this.state.filter.onlyActive ?? null}
                            update={(active) => this.updateFilter('onlyActive', active)}
                            disabled={this.props.users.code === StatusCode.PENDING}
                        />
                        <div className="button-box">
                            <Button color="primary" onSubmit={this.onSearch}>Sök</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        )
    }

    renderBody() {
        const { users } = this.props;

        const newList = users.data?.map(u => ({
            id: u.userId,
            data: [
                { value: <Link to={`/schedulesync/user/${u.userId}`}>{u.displayName}</Link> },
                { value: u.emailAddress },
                { value: ScheduleSyncRoleTypes.userRoles({ organizer: u.hasOrganizerRole, participant: u.hasParticipantRole }) },
                { value: Helper.active(u.active) },
            ]
        })) ?? [];

        return (
            <TableHorizontal
                header={[{ value: 'Namn' }, { value: 'Epost' }, { value: 'Roll' }, { value: 'Aktiv' }]}
                info={newList}
                error={users.code === StatusCode.ERROR}
                errorMsg={users.code === StatusCode.ERROR ? users.error.response.data.description : null}
                searching={users.code === StatusCode.PENDING}
                displayName="Användare"
                noResMsg="Inga användare hittades"
            />
        )
    }

    render() {
        const requestState = Helper.convertToCacheRequestState(this.props.users);
        return (
            <div className="container users_schedulesync">
                <div className="header--fixed large-filter">
                    <h1>Användare</h1>
                    {this.renderFilter()}
                </div>
                <div className="body--scroll padding-40">
                    {this.renderBody()}
                </div>

                <FilterBottomComponent
                    requestState={requestState}
                    clearFilter={this.clearFilter}
                    updateFilter={(value) => this.onPageChange('page', value)}
                />

            </div>
        )
    }
}


const mapStateToProps = state => ({
    users: state.schedulesync_users.users,
    filter: state.schedulesync_users.filter,
})

export default withRouter(connect(mapStateToProps, { ScheduleSyncUsers })(Users_ScheduleSync));
