import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import TrackingsModule from '../components/TrackingsModule';
import { GetGroupTrackings, GroupState } from '../../../store/GroupAction';
import { RequestState } from '../../../models/RequestState';
import { CoreTracking } from '../../../models/CoreTracking';

interface State {
    groups: GroupState
}


const GroupViewTrackings = () => {
    const { groupId } = useParams();
    const dispatch = useDispatch();
    const trackings = useSelector<State, RequestState<CoreTracking[]>>(state => state.groups.groupTrackings);

    useEffect(() => {
        if (groupId !== undefined) {
            GetGroupTrackings(groupId)(dispatch);
        }
    }, [dispatch, groupId]);

    return (<>
        <TrackingsModule
            trackings={trackings}
            noResMsg="Den här gruppen saknar källor"
        />
    </>)
}

export default GroupViewTrackings;
