import React, { Component } from 'react';
import { FormGroup, Label, Input, Table, Button } from 'reactstrap';
import { getSelectableModuleTriggers, resetModuleTriggers } from '../../../store/ModuleAction';
import { connect } from 'react-redux';
import * as ModuleTrigger from '../../../util/ModuleTriggerUtil';
import Loading from '../../misc/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValidCron } from 'cron-validator';

class TriggerConfiguration extends Component {
    state = {
        trigger: undefined,
        triggerValue: undefined
    }

    componentDidMount() {
        this.props.getSelectableModuleTriggers(this.props.id);
    }

    componentWillUnmount() {
        this.props.resetModuleTriggers(this.props.id);
    }

    setTriggerValue = (e) => {
        this.setState({ triggerValue: e.target.value })
    }

    addTrigger = (type) => {
        const { triggerValue } = this.state;
        let triggerObject;
        if (type === 1) {
            triggerObject = { triggerType: 1, cronSchedule: triggerValue }
        }
        if (type === 2) {
            triggerObject = { triggerType: type, runAfterModuleId: triggerValue }
        }
        this.updateTrigger('add', triggerObject, type)
    }

    deleteTrigger = (i) => {
        this.updateTrigger('remove', i)
    }

    updateTrigger = (trigger, triggerObject, type) => {
        let moduleTriggers = [...this.props.moduleTriggers];
        if (trigger === 'add') {
            if (type === 1) {
                const isCronValid = (cronSchedule) => isValidCron(cronSchedule, { seconds: true, alias: true, allowBlankDay: true });
                if (this.props.moduleTriggers.some(trigger => trigger.cronSchedule === triggerObject.cronSchedule)) {
                    alert('Det finns redan en schemaläggning med det här cronuttrycket')
                    return;
                } else if (!isCronValid(triggerObject.cronSchedule)) {
                    alert('Det här är inte ett giltigt cronuttryck')
                    return;
                }
            } else if (type === 2 && this.props.moduleTriggers.some(trigger => trigger.runAfterModuleId === triggerObject.runAfterModuleId)) {
                alert('Det finns redan en schemaläggning av den modulen')
                return;
            }

            moduleTriggers.push(triggerObject)
        } else if (trigger === 'remove') {
            moduleTriggers.splice(triggerObject, 1)
        }

        this.props.onUpdateTriggers(moduleTriggers)
        this.setState({ triggerValue: undefined, trigger: undefined })
    }

    renderSelectableModules() {
        if (this.props.modules === undefined || this.props.modules.code === 'PENDING') {
            return <Loading />;
        } else if (this.props.modules.code === 'COMPLETE') {
            return <select id="moduletrigger" value={this.state.triggerValue || ""} onChange={this.setTriggerValue} className="form-control">
                <option disabled value="">Välj modul</option>
                {this.props.modules.data.map(module =>
                    <option value={module.id} key={module.id}>{module.title}</option>
                )}
            </select>
        } else {
            return 'Kunde inte hämta moduler';
        }
    }

    renderModuleSelector() {
        if (this.state.trigger === 'runAfterModuleId')
        {
            return <FormGroup className="trigger_module">
                <Label for="moduletrigger">Schemalägg start efter modul:</Label>
                {this.renderSelectableModules()}
                <Button onClick={() => this.addTrigger(2)}>Lägg till</Button>
            </FormGroup>;
        }

        return null;
    }

    renderModuleTrigger(trigger, index) {
        let title = trigger.runAfterModuleTitle;
        if (trigger.triggerType === 1) {
            title = trigger.cronSchedule;
        } else if (!title) {
            if (trigger.triggerType === 2 && this.props.modules.code === 'COMPLETE') {
                title = this.props.modules.data.find(m => m.id === trigger.runAfterModuleId).title;
            } else if (this.props.modules.code === 'ERROR') {
                title = 'Kunde inte hitta namn';
            } else {
                title = 'Loading...';
            }
        }

        return (
            <tr key={index}>
                <td>{ModuleTrigger.moduleTriggerId(trigger.triggerType)}</td>
                <td>{title}</td>
                {!this.props.disableInput && <td onClick={() => this.deleteTrigger(index)}><FontAwesomeIcon icon="trash" title="Ta bort schemaläggning" /></td>}
            </tr>
        )
    }

    renderModuleTriggers() {
        if (this.props.moduleTriggers.length > 0) {
            return <Table>
                <tbody>
                    {this.props.moduleTriggers.map((t, i) => this.renderModuleTrigger(t, i))}
                </tbody>
            </Table>
        } else {
            return 'Schemaläggning saknas';
        }
    }

    render() {
        return (
            <FormGroup className="trigger_configuration">
                <h5>Schemaläggning</h5>
                {this.renderModuleTriggers()}

                <FormGroup className="add_trigger">
                    <Label for="trigger">Lägg till schemaläggning</Label>
                    <select disabled={this.props.disableInput} onChange={e => this.setState({ trigger: e.target.value })} value={this.state.trigger || ''} id="trigger" className="form-control">
                        <option disabled value="">Välj typ</option>
                        <option value='cronSchedule'>Tidsbaserad schemaläggning</option>
                        <option value='runAfterModuleId'>Modulbaserad schemaläggning</option>
                    </select>
                </FormGroup>

                {this.state.trigger === 'cronSchedule' &&
                    <FormGroup className="trigger_module">
                    <Label for="cronSchedule">Cronuttryck</Label>
                    <Input type="text" name="cronSchedule" id="cronSchedule" placeholder="Ange cronuttryck" value={this.state.triggerValue || ''} onChange={this.setTriggerValue} disabled={this.props.disableInput} />
                        Testa skapa din crontab <a href="https://crontab.guru/" target="_blank" rel="noopener noreferrer">här</a>.<br />
                        Notera att tiden måste anges i tidszonen för UTC. <br />
                        Schemaläggningen kan inte anges oftare än en gång i timmen.
                    <Button color="primary" disabled={!this.state.triggerValue} onClick={() => this.addTrigger(1)}>Lägg till</Button>
                    </FormGroup>
                }

                {this.renderModuleSelector()}
            </FormGroup>
        )
    }
}

const mapStateToProps = state => ({
    modules: state.module.selectableTriggers
})

export default connect(mapStateToProps, { getSelectableModuleTriggers, resetModuleTriggers })(TriggerConfiguration);
