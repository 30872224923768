import { Dispatch } from 'react';
import { Action, Reducer } from 'redux';
import { PageResult } from '../models/CoreModels';
import { CoreSchoolUnit } from '../models/CoreSchoolUnit';
import { CoreTracking } from '../models/CoreTracking';
import { FilterModuleSearchPageRequest } from '../models/Requests';
import { CacheRequestState, RequestState } from '../models/RequestState';
import InstanceClient from '../services/InstanceClient';
import StatusCode from '../util/StatusCode';
import store from './store';

enum SchoolUnitActionType {
    LIST_SCHOOLS = 'LIST_SCHOOLS',
    LIST_SCHOOLS_FILTER = 'LIST_SCHOOLS_FILTER',
    LIST_SCHOOLS_UPDATE = 'LIST_SCHOOLS_UPDATE',
    GET_SCHOOL = 'GET_SCHOOL',
    GET_SCHOOL_TRACKINGS = 'GET_SCHOOL_TRACKINGS',
}

export interface SchoolUnitState {
    schools: CacheRequestState<PageResult<CoreSchoolUnit>>
    school: RequestState<CoreSchoolUnit>
    schoolTrackings: RequestState<CoreTracking[]>
    filter: Partial<FilterModuleSearchPageRequest>
}

interface SchoolUnit_ListSchoolUnitsBase extends Action<SchoolUnitActionType> {
    type: SchoolUnitActionType.LIST_SCHOOLS
    schools: RequestState<PageResult<CoreSchoolUnit>>
}

interface SchoolUnit_ListSchoolUnitsFilter extends Action<SchoolUnitActionType> {
    type: SchoolUnitActionType.LIST_SCHOOLS_FILTER
    filter: Partial<FilterModuleSearchPageRequest>
}

interface SchoolUnit_ListSchoolUnitsUpdate extends Action<SchoolUnitActionType> {
    type: SchoolUnitActionType.LIST_SCHOOLS_UPDATE
}

type SchoolUnit_ListSchoolUnits = SchoolUnit_ListSchoolUnitsBase
    | SchoolUnit_ListSchoolUnitsFilter
    | SchoolUnit_ListSchoolUnitsUpdate

interface SchoolUnit_GetSchoolUnit extends Action<SchoolUnitActionType> {
    type: SchoolUnitActionType.GET_SCHOOL
    school: RequestState<CoreSchoolUnit>
}

interface SchoolUnit_GetSchoolUnitTrackings extends Action<SchoolUnitActionType> {
    type: SchoolUnitActionType.GET_SCHOOL_TRACKINGS
    schoolTrackings: RequestState<CoreTracking[]>
}

type SchoolUnitAction = SchoolUnit_ListSchoolUnits
    | SchoolUnit_GetSchoolUnit
    | SchoolUnit_GetSchoolUnitTrackings;

export const ListSchoolUnits = (filter: Partial<FilterModuleSearchPageRequest> = {}, update: boolean = false) => (dispatch: Dispatch<SchoolUnit_ListSchoolUnits>) => {
    dispatch({ type: SchoolUnitActionType.LIST_SCHOOLS_FILTER, filter });
    if (update) {
        dispatch({ type: SchoolUnitActionType.LIST_SCHOOLS_UPDATE });
    }

    const url = '/api/core/schoolunit'
    InstanceClient.get<PageResult<CoreSchoolUnit>>(url, filter)
        .then(res =>
            dispatch({
                type: SchoolUnitActionType.LIST_SCHOOLS,
                schools: { code: StatusCode.COMPLETE, data: res.data, }
            })
        )
        .catch(error =>
            dispatch({
                type: SchoolUnitActionType.LIST_SCHOOLS,
                schools: { code: StatusCode.ERROR, error }
            })
        );
};

export const GetSchoolUnit = (id: string) => (dispatch: Dispatch<SchoolUnit_GetSchoolUnit>) => {
    const storeKey = store.getState().schools.school?.key;
    const key = id;

    if (key === storeKey) {
        return;
    }

    dispatch({ type: SchoolUnitActionType.GET_SCHOOL, school: { code: StatusCode.PENDING } });

    InstanceClient.get<CoreSchoolUnit>(`/api/core/schoolunit/${id}`)
        .then(res =>
            dispatch({
                type: SchoolUnitActionType.GET_SCHOOL,
                school: { code: StatusCode.COMPLETE, data: res.data, key: key }
            })
        )
        .catch(error =>
            dispatch({
                type: SchoolUnitActionType.GET_SCHOOL,
                school: { code: StatusCode.ERROR, error }
            })
    );
};

export const GetSchoolUnitTrackings = (id: string) => (dispatch: Dispatch<SchoolUnit_GetSchoolUnitTrackings>) => {
    const storeKey = store.getState().schools.schoolTrackings?.key;
    const key = id;

    if (key === storeKey) {
        return;
    }

    const type = SchoolUnitActionType.GET_SCHOOL_TRACKINGS;
    const name = "schoolTrackings";

    dispatch({ type: type, [name]: { code: StatusCode.PENDING } });

    InstanceClient.get<CoreTracking[]>(`/api/core/schoolunit/${id}/trackings`)
        .then(res =>
            dispatch({
                type: type,
                [name]: { code: StatusCode.COMPLETE, data: res.data, key: key }
            })
        )
        .catch(err =>
            dispatch({
                type: type,
                [name]: { code: StatusCode.ERROR, error: err.response.data.status }
            })
        );
};


const initialState: SchoolUnitState = {
    schools: { code: StatusCode.PENDING },
    school: { code: StatusCode.PENDING },
    schoolTrackings: { code: StatusCode.PENDING },
    filter: {},
}

export const reducer: Reducer<SchoolUnitState, SchoolUnitAction> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case SchoolUnitActionType.LIST_SCHOOLS:
            return {
                ...state,
                schools: action.schools,
            }
        case SchoolUnitActionType.LIST_SCHOOLS_UPDATE:
            return {
                ...state,
                schools: { ...state.schools, code: StatusCode.PENDING },
            }
        case SchoolUnitActionType.LIST_SCHOOLS_FILTER:
            return {
                ...state,
                filter: action.filter
            }
        case SchoolUnitActionType.GET_SCHOOL:
            return {
                ...state,
                school: action.school,
            };
        case SchoolUnitActionType.GET_SCHOOL_TRACKINGS:
            return {
                ...state,
                schoolTrackings: action.schoolTrackings,
            };
        default:
            return state;
    }
};

