import React from 'react';
import { OnChangeCallback } from '../../../../../../util/UtilityTypes'
import BoolInput from '../../../../../misc/BoolInput';
import EnumInput from '../../../../../misc/EnumInput';
import * as GoogleUser from '../../../../../../util/GoogleUserExportTypes';
import { update } from '../../../../../../util/Helper';

interface GoogleUserStaffOrganizationalUnitPolicyProps {
    organizationalUnitPolicy: GoogleUser.StaffOrganizationalUnitPolicy
    configurationType: string
    disabled?: boolean
    onChange: OnChangeCallback<GoogleUser.StaffOrganizationalUnitPolicy>
    validation: string[]
}

const GoogleUserStaffOrganizationalUnitPolicy: React.FC<GoogleUserStaffOrganizationalUnitPolicyProps> = (props) => {
    return (<>
        <BoolInput
            title='Skapa nya OU:n om de inte finns'
            name={`${props.configurationType}-organizationalUnitPolicy.createMissingOrganisationalUnits`}
            value={props.organizationalUnitPolicy.createMissingOrganisationalUnits}
            updateValue={(n, v) => update(n, props.organizationalUnitPolicy, { createMissingOrganisationalUnits: v }, props.onChange)}
            disabled={props.disabled}
        />

        <EnumInput
            title='OU-struktur'
            name={`${props.configurationType}-organizationalUnitPolicy.organisationalUnit`}
            value={props.organizationalUnitPolicy.organisationalUnit}
            options={GoogleUser.staffOrganizationalUnit}
            onChange={(n, v) => update(n, props.organizationalUnitPolicy, { organisationalUnit: v }, props.onChange)}
            disabled={props.disabled}
        />

        <EnumInput
            title='OU-struktur för inaktiva användare'
            name={`${props.configurationType}-organizationalUnitPolicy.suspendedUsersOrganisationalUnit`}
            value={props.organizationalUnitPolicy.suspendedUsersOrganisationalUnit}
            options={GoogleUser.staffSuspendedOrganizationalUnit}
            onChange={(n, v) => update(n, props.organizationalUnitPolicy, { suspendedUsersOrganisationalUnit: v }, props.onChange)}
            disabled={props.disabled}
        />
    </>
    );
}

export default GoogleUserStaffOrganizationalUnitPolicy;
