import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOneRosterClass, getOneRosterTeachersByClass, getOneRosterStudentsByClass } from '../../store/OneRoster/OneRoster_ClassAction';
import { useParams, Link, Route, Routes, Navigate } from 'react-router-dom'
import StatusCode from '../../util/StatusCode';
import * as Helper from '../../util/Helper';
import TableVertical from '../misc/TableVertical';
import TableHorizontalPagedToggle from '../misc/TableHorizontalPagedToggle';
import * as OneRosterClassStatus from '../../util/OneRoster/OneRosterClassStatusUtil';
import RenderTabs from '../misc/Tabs';
import * as Tab from '../../util/TabValue';
import ActiveToggle from '../misc/ActiveToggle';


export default () => {
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const classInfo = useSelector(state => state.oneroster_class.classInfo);

    useEffect(() => {
        getOneRosterClass(eventId)(dispatch);
    }, [dispatch]);

    const tabs = [
        Tab.Tabs.Information,
        Tab.Tabs.Members,
    ];

    const displayName = classInfo?.data?.title ? ' / ' + classInfo.data.title : '';

    if (classInfo?.error === 400 || classInfo?.error === 404) {
        return <NotFound />;
    }

    return (
        <div className="padding-40 container single">
            <h1><Link to="/oneroster/class">Avsnitt</Link> {displayName}</h1>

            <RenderTabs
                tabs={tabs}
                id={eventId}
                url="oneroster/class" />

            <Routes>
                <Route path={Tab.Tabs.Information.url} element={<Info />} />
                <Route path={Tab.Tabs.Members.url} element={<Members />} />
                <Route path="*" element={<Navigate replace={true} to={`/oneroster/class/${eventId}/${Tab.Tabs.Information.url}`} />} />
            </Routes>
        </div>
    );
}

export const NotFound = () => {
    return (
        <div className="padding-40 container single">
            <h1><Link to="/oneroster/class">Avsnitt</Link></h1>
            <p>Avsnittet hittades inte. Gör en ny <Link to='/oneroster/class'>sökning</Link></p>
        </div>
    );
}

export const Info = () => {
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const classInfo = useSelector(state => state.oneroster_class.classInfo);

    useEffect(() => {
        getOneRosterClass(eventId)(dispatch);
    }, [dispatch]);

    const renderClassInfo = () => {
        let newList = [];
        if (classInfo.code === StatusCode.COMPLETE) {
            const c = classInfo.data;
            newList.push(
                { header: 'Namn', content: c.title },
                { header: 'SIS ID', content: c.id },
                { header: 'Status', content: OneRosterClassStatus.statusFromId(c.status) },
                { header: 'Skola', content: <Link to={`/oneroster/schoolunit/${c.schoolId}/information`}>{c.schoolName}</Link> },
                { header: 'Skapad', content: Helper.formatDate(c.created), classname: 'nowrap' },
                { header: 'Ändrad', content: Helper.formatDate(c.modified), classname: 'nowrap' },
                { header: 'Arkiverad', content: Helper.formatDate(c.archived), classname: 'nowrap' },
                { header: 'Inaktiverad', content: Helper.formatDate(c.inactivated), classname: 'nowrap' },
            )
        }
        return (
            <TableVertical
                header='Information'
                info={newList}
                error={classInfo.code === StatusCode.ERROR}
                searching={classInfo.code === StatusCode.PENDING}
            />
        )
    }

    const renderAzureInfo = () => {
        let newList = [];
        if (classInfo.code === StatusCode.COMPLETE) {
            const c = classInfo.data.classInfo;
            newList.push(
                { header: 'Objekt-ID', content: c.groupId ? <Link to={`https://portal.azure.com/#view/Microsoft_AAD_IAM/GroupDetailsMenuBlade/~/Overview/groupId/${c.groupId}`}>{ c.groupId }</Link> : c.groupId },
                { header: 'Är ett team', content: Helper.active(c.isTeam), link: c.isTeam ? `https://admin.teams.microsoft.com/teams/manage/${c.groupId}` : null },
                { header: 'Är arkiverat', content: Helper.active(c.isArchived) },
                { header: 'Är permanent borttaget', content: Helper.active(c.isPermanentlyDeleted) },
                { header: 'Nästa analys', content: Helper.formatDate(c.nextAnalysisAt), classname: 'nowrap' },
            )
        }
        return (
            <TableVertical
                header='Azure-information'
                info={newList}
                error={classInfo.code === StatusCode.ERROR}
                searching={classInfo.code === StatusCode.PENDING}
            />
        )
    }

    return (<>
        {renderClassInfo()}
        {renderAzureInfo()}
    </>)
}

export const Members = () => {
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const [show, setShow] = useState({ students: true, teachers: true });

    const students = useSelector(state => state.oneroster_class.studentsByClass);
    const teachers = useSelector(state => state.oneroster_class.teachersByClass);

    useEffect(() => {
        getOneRosterTeachersByClass(eventId, { ...teachers?.filter })(dispatch);
        getOneRosterStudentsByClass(eventId, { ...students?.filter })(dispatch);
    }, [dispatch]);

    const updateFilter = (value, name, key) => {
        if (key === 'students') {
            getOneRosterStudentsByClass(eventId, { ...students.filter, [name]: value })(dispatch);
        }
        if (key === "assignmentRoles") {
            getOneRosterTeachersByClass(eventId, { ...teachers.filter, [name]: value })(dispatch);
        }
        if (name === "onlyActive") {
            getOneRosterStudentsByClass(eventId, { ...students.filter, [name]: value, page: 1 })(dispatch);
            getOneRosterTeachersByClass(eventId, { ...teachers.filter, [name]: value, page: 1 })(dispatch);
        }
    }

    const renderStudents = () => {
        const studentInfo = students.data?.values.map(x => ({
            id: x.id,
            data: [
                { value: <Link to={`/oneroster/user/${x.id}/information`}>{`${x.givenName} ${x.familyName}`}</Link> },
                { value: x.email },
                { value: Helper.active(x.active) },
                { value: x.userName },
            ]
        })) ?? [];
        const count = Helper.getTotalCountText(students);
        return (
            <TableHorizontalPagedToggle
                header={[{ value: `Elever ${count}` }, { value: 'E-postadress' }, { value: 'Aktiv' }, { value: 'Användarnamn' }]}
                mobileHeader={['Namn', 'E-postadress', 'Aktiv', 'Användarnamn']}
                info={studentInfo}
                show={show.students}
                toggle={() => setShow({ ...show, students: !show.students })}
                displayName={`Elever ${count}`}
                onChangePage={(p) => updateFilter(p, 'page', 'students')}
                noResultMsg="Det här avsnittet saknar elever"
                requestState={students}
            />
        )
    }

    const renderTeachers = () => {
        const teachersInfo = teachers.data?.values.map(x => ({
            id: x.id,
            data: [
                { value: <Link to={`/oneroster/user/${x.id}/information`}>{`${x.givenName} ${x.familyName}`}</Link> },
                { value: x.email },
                { value: Helper.active(x.active) },
                { value: x.userName },
            ]
        })) ?? [];

        const count = Helper.getTotalCountText(teachers);
        return (
            <TableHorizontalPagedToggle
                header={[{ value: `Lärare ${count}` }, { value: 'E-postadress' }, { value: 'Aktiv' }, { value: 'Användarnamn' }]}
                mobileHeader={['Namn', 'E-postadress', 'Aktiv', 'Användarnamn']}
                info={teachersInfo}
                show={show.teachers}
                toggle={() => setShow({ ...show, teachers: !show.teachers })}
                displayName={`Lärare ${count}`}
                onChangePage={(p) => updateFilter(p, 'page', 'teachers')}
                noResultMsg="Det här avsnittet saknar lärare"
                requestState={teachers}
            />
        )
    }

    return (<>
        <div className="header_member_table large">Medlemmar
            <span className="right">
                <ActiveToggle
                    onlyActive={students.filter?.onlyActive ?? null}
                    update={(active) => updateFilter(active, 'onlyActive')}
                    disabled={students.code === StatusCode.PENDING || teachers.code === StatusCode.PENDING}
                />
            </span></div>
        {renderTeachers()}
        {renderStudents()}
    </>);
}
