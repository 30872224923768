import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetModuleInstancePhases, ClearModuleInstancePhases, ModuleInstancePhasesSubscribe, ModuleInstancePhaseState } from '../../../../store/ModuleInstancePhaseAction';
import * as Helper from '../../../../util/Helper';
import * as InstancePhaseStatus from './InstancePhaseStatus';
import { Tooltip } from 'reactstrap';
import Loading from '../../../misc/Loading';
import StatusCode from '../../../../util/StatusCode';
import { Link } from 'react-router-dom'
import { CoreModuleInstancePhase } from '../../../../models/CoreModels';
import { RequestState } from '../../../../models/RequestState';

interface InstancePhaseProps {
    moduleId: string
    moduleInstanceId: string
    completed: boolean
}

interface InstancePhaseState {
    instancePhase: ModuleInstancePhaseState
}

const InstancePhase: React.FC<InstancePhaseProps> = (props) => {
    const dispatch = useDispatch();
    const instancePhases = useSelector<InstancePhaseState, RequestState<CoreModuleInstancePhase[]>>(state => state.instancePhase.instancePhases);
    const [isOpen, setIsOpen] = useState<{ [key: string]: boolean }>({});
    const [useAnimation, setUseAnimation] = useState(false);

    useEffect(() => {
        if (props.moduleInstanceId) {
            GetModuleInstancePhases(props.moduleInstanceId)(dispatch);
        }
    }, [dispatch, props.moduleInstanceId]);

    useEffect(() => {
        if (props.completed) {
            setUseAnimation(false);
        } else {
            setUseAnimation(true);
            ClearModuleInstancePhases()(dispatch);
            return ModuleInstancePhasesSubscribe(props.moduleInstanceId)(dispatch);
        }
    }, [dispatch, props.completed, props.moduleInstanceId]);

    const renderPhases = (phases: CoreModuleInstancePhase[]) => {
        return <><div className="instance-phase-chart-container">
            {phases.map(ip => (
                <div key={ip.id} className={"instance-phase-chart" + (useAnimation ? " animation" : '')} id={"Tooltip-" + ip.id}>
                    <div>
                        <span className="icon">{InstancePhaseStatus.getIcon(ip.status)}</span>
                    </div>
                    <span className="phase-title">{ip.phaseName}</span>
                    <Tooltip placement="bottom-start" isOpen={isOpen[ip.id]} target={"Tooltip-" + ip.id} toggle={() => setIsOpen({ [ip.id]: !isOpen[ip.id] })}>
                        <p><b>{ip.phaseName}</b></p>
                        <span><span className="title">Startades</span> {Helper.formatDate(ip.startedAt)}</span>
                        <span><span className="title">Status</span> {InstancePhaseStatus.getStatus(ip.status)}</span>
                        <span><span className="title">Tid</span> {InstancePhaseStatus.getDuration(ip.startedAt, ip.completedAt)}</span>
                    </Tooltip>

                </div>
            ))}
        </div>
            {phases.length > 1 &&
                <p style={{ fontSize: '14px', marginTop: '1rem' }}><Link to={`/module/${props.moduleId}/instance/${props.moduleInstanceId}/phaseStatistics`}>Se fullständig fasstatistik</Link></p>
            }
        </>
    }

    const renderBody = () => {
        if (!props.moduleInstanceId) {
            return "Den här modulen har ingen statistik ännu";
        } else {
            switch (instancePhases.code) {
                case StatusCode.PENDING:
                    return <Loading />;
                case StatusCode.ERROR:
                    return "Kunde inte hämta statistik för den här körningen";
                case StatusCode.COMPLETE:
                    if (instancePhases.data.length || !props.completed) {
                        return renderPhases(instancePhases.data);
                    } else {
                        return "Den här modulen har ingen statistik ännu";
                    }
                default: return;
            }
        }
    };

    return (
        <div className="mt-4">
            <h5>Statistik</h5>
            {renderBody()}
        </div>);
}

export default InstancePhase;
