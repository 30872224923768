import React, { useState, useEffect, useCallback } from 'react'
import { Button } from 'reactstrap';
import SelectInput from '../../../../../misc/SelectInput';
import Search from '../../../../../misc/Search';
import StatusCode from '../../../../../../util/StatusCode';
import debounce from 'lodash/debounce';
import { useDispatch, useSelector } from 'react-redux';
import SearchableSelectInput from '../../../../../misc/SearchableSelectInput';
import { getSyllabuses } from '../../../../../../store/FilterAction';
import { CoreDnpActivityMappingSchoolUnit, DnpActivity, DnpEnvironment, DnpOrganizationSchoolUnit } from '../../../../../../models/CoreDnpModels'
import { OnChangeCallback, SearchableOptionItem } from '../../../../../../util/UtilityTypes';
import { GetDnpSchoolUnit } from '../../../../../../store/DnpAction';
import MultiSelectInput from '../../../../../misc/MultiSelectInput';
import { RequestState } from '../../../../../../models/RequestState';
import { CoreSyllabusFilter } from '../../../../../../models/CoreModels';


interface DnpActivitiesFilterProps {
    schoolUnitIds?: string[]
    updateFilter: OnChangeCallback<string | undefined | null | number | string[] | number[]>
    clearFilter: () => void
    filter: {
        syllabusId?: string
        schoolType?: string
        schoolYear?: number[]
        schoolUnitIds?: string[]
    }
    dnpActivities: RequestState<DnpActivity[]>
    translateSchoolType: (schoolType: string) => string
    defaultSchoolType?: string
    dnpSchoolUnitId?: string
    schoolUnits: CoreDnpActivityMappingSchoolUnit[]
    environment: DnpEnvironment
}

interface DnpState {
    dnpSchoolUnit: RequestState<DnpOrganizationSchoolUnit>
}

interface StateFilter {
    syllabuses: CoreSyllabusFilter[],
}
interface DnpActivitiesFilterState {
    filter: StateFilter
    dnp: DnpState
}

const DnpActivitiesFilter = (props: DnpActivitiesFilterProps) => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');

    const syllabusFilter = useSelector<DnpActivitiesFilterState, CoreSyllabusFilter[]>(state => state.filter.syllabuses);
    const dnpSchoolUnit = useSelector<DnpActivitiesFilterState, RequestState<DnpOrganizationSchoolUnit>>(state => state.dnp.dnpSchoolUnit)

    useEffect(() => {
        if (props.dnpSchoolUnitId) {
            GetDnpSchoolUnit(props.environment, props.dnpSchoolUnitId)(dispatch);
        }
    }, [dispatch, props.dnpSchoolUnitId, props.environment]);

    useEffect(() => {
        if (props.schoolUnitIds !== undefined) {
            getSyllabuses(props.schoolUnitIds)(dispatch);
        }
    }, [props.schoolUnitIds, dispatch])

    const debouncedSearch = useCallback(
        debounce((search: string) => {
            props.updateFilter('search', search);
        }, 300), [props.updateFilter]);

    const onSearch = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        let search = e.currentTarget.value;
        setSearch(search);
        debouncedSearch(search);
    };

    const clearFilter = () => {
        setSearch('');
        props.clearFilter();
    }

    const distinctschoolTypes = (dnpActivities: RequestState<DnpActivity[]>) => {
        if (dnpActivities.code === StatusCode.COMPLETE && dnpActivities.data) {
            return dnpActivities.data.filter((value, index, self) => self.findIndex(s => s.schoolType === value.schoolType) === index);
        };
        return [];
    };

    const dnpSchoolUnitSchoolYears = (): number[] => {
        let years: number[] = [];
        if (dnpSchoolUnit.code === StatusCode.COMPLETE && dnpSchoolUnit.data) {
            dnpSchoolUnit.data.schoolTypes.map(schoolType => {
                if (schoolType.dnpStatus && schoolType.schoolYears) {
                    schoolType.schoolYears.map(schoolYear => {
                        if (schoolYear.dnpStatus) {
                            years.push(schoolYear.schoolYear)
                        }
                    })
                }
            })
        }
        return years.sort();
    }

    const distinctSchoolYears = (): SearchableOptionItem<number>[] => {
        const dnpSchoolYears: number[] = dnpSchoolUnitSchoolYears();
        const distinctSchoolYears = props.dnpActivities.code === StatusCode.COMPLETE ? props.dnpActivities.data.filter((value, index, self) => {
            return self.findIndex(v => v.schoolYear && v.schoolYear === value.schoolYear) === index;
        }) : [];
        const defaultSchoolYear = distinctSchoolYears.map(x => x.schoolYear).filter(y => !dnpSchoolYears.includes(y))

        let schoolYearList: SearchableOptionItem<number>[] = [];

        dnpSchoolYears.map(x => {
            schoolYearList.push({ value: x, label: x.toString() })
        })
        schoolYearList.push({ value: 0, label: "====", disabled: true })
        defaultSchoolYear?.map(x => {
            schoolYearList.push({ value: x, label: x.toString() })
        })

        return schoolYearList;
    }

    const getSyllabusTitle = (syllabus: CoreSyllabusFilter) => {
        let name = "";
        if (syllabus.courseName) {
            name = syllabus.courseName;
        }
        else if (syllabus.subjectName) {
            name = syllabus.subjectName;
        }
        else if (syllabus.subjectDesignation) {
            name = syllabus.subjectDesignation;
        }

        if (syllabus.startSchoolYear !== undefined && syllabus.endSchoolYear !== undefined && syllabus.startSchoolYear !== syllabus.endSchoolYear) {
            if (syllabus.startSchoolYear !== syllabus.endSchoolYear) {
                name = `${name} (Åk ${syllabus.startSchoolYear}-${syllabus.startSchoolYear})`
            }
        } else if (syllabus.startSchoolYear !== undefined) {
            name = `${name} (Åk ${syllabus.startSchoolYear})`
        }

        return name;
    }

    return (
        <div className="onelinesearch">
            <span>
                <span className="bold">Sök (FlowSync)</span>
                <Search value={search} onChange={onSearch} placeholder="Sök aktivitet" />
            </span>
            <span>
                <span className="bold">Kursplan (FlowSync)</span>
                <SearchableSelectInput
                    items={syllabusFilter.map(s => ({ value: s.id, label: getSyllabusTitle(s) }))}
                    onChange={(name, value) => props.updateFilter(name, value)}
                    name='syllabusId'
                    selected={props.filter.syllabusId}
                    defaultText='Filtrera på kursplan'
                    disabled={!syllabusFilter.length}
                />
            </span>

            <span>
                <span className="bold">Skola (FlowSync)</span>
                <SelectInput
                    items={props.schoolUnits.map(s => ({ value: s.schoolUnitId, text: s.schoolUnitDisplayName }))}
                    onChange={(name, value) => props.updateFilter(name, (value !== undefined ? value : props.schoolUnitIds))}
                    name='schoolUnitIds'
                    selected={props.filter.schoolUnitIds?.length === 1 ? props.filter.schoolUnitIds[0] : undefined}
                    defaultText='Visa alla'
                    disabled={props.schoolUnits.length <= 1}
                />
            </span>

            <span>
                <span className="bold">Skolform (Skolverket)</span>
                <SelectInput
                    items={distinctschoolTypes(props.dnpActivities).map(s => ({ value: s.schoolType, text: `${props.translateSchoolType(s.schoolType)}` }))}
                    onChange={(name, value) => props.updateFilter(name, value)}
                    name='schoolType'
                    selected={props.filter.schoolType || props.defaultSchoolType}
                    defaultText='Filtrera på skolform'
                    disabled={props.dnpActivities.code !== StatusCode.COMPLETE}
                />
            </span>
            <span>
                <span className="bold">Årskurs (Skolverket)</span>
                <MultiSelectInput
                    items={distinctSchoolYears()}
                    onChange={(name, value) => props.updateFilter(name, value)}
                    name='schoolYear'
                    selected={props.filter.schoolYear}
                    defaultText='Filtrera på årskurs'
                    disabled={dnpSchoolUnit.code !== StatusCode.COMPLETE}
                    disableSort={true}
                />
            </span>
            <span>
                <Button onClick={() => clearFilter()}>Nollställ filter</Button>
            </span>
        </div>
    )
}

export default DnpActivitiesFilter;
