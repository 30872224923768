import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import ModalComponent from '../misc/ModalComponent';
import { GetBlobFile, DownloadBlobFile, BlobContainerState, ClearBlobContent } from '../../store/BlobContainerAction';
import StatusCode from '../../util/StatusCode';
import Loading from '../misc/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CoreStorageContainerBlob } from '../../models/Requests';
import { RequestState } from '../../models/RequestState';

interface BlobConnectionProps {
    blob: CoreStorageContainerBlob
}

interface BlobConnectionState {
    blob: BlobContainerState
}

const feedbackOnClick = (status: RequestState<string>) => {
    switch (status.code) {
        case StatusCode.PENDING:
            return <Loading />;
        case StatusCode.ERROR: {
            var list = status.error.response?.data;
            if (list) {
                const valuesList = Object.values(list).flatMap(value => value)
                return <span><FontAwesomeIcon icon="exclamation-triangle" color="red" style={{ height: '1.5rem', marginRight: '0.5rem' }} /> {valuesList.join(', ')}</span>
            } else {
                return <span><FontAwesomeIcon icon="exclamation-triangle" color="red" style={{ height: '1.5rem', marginRight: '0.5rem' }} />Något gick fel</span>
            }
        }
        default:
            return null;
    }
}

const BlobConnection: React.FC<BlobConnectionProps> = (props) => {
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    const blobContent = useSelector<BlobConnectionState, RequestState<string>>(state => state.blob.blob.content);

    useEffect(() => () => ClearBlobContent()(dispatch), [dispatch])

    const disabled = Object.values(props.blob).some(x => x === null);

    const viewFileContent = () => {
        GetBlobFile(props.blob)(dispatch);
        setOpen(true);
    }

    const downloadBlobFile = () => {
        DownloadBlobFile(props.blob)(dispatch);
    }

    return (<>
        <div className="flex children-mr-3 align-items-center mt-4">
            <Button disabled={disabled} onClick={viewFileContent}>Visa filinnehåll</Button>
            <Button disabled={disabled} onClick={() => downloadBlobFile()}>Ladda ner</Button>
            {feedbackOnClick(blobContent)}
        </div>
        {open && blobContent.code === StatusCode.COMPLETE && <ModalComponent
            isOpen={open}
            toggleModal={() => (setOpen(false))}
            header={props.blob.blobName}
            size="xl"
            cancel="Stäng"
        >
            <div>
                <pre>{blobContent.data}</pre>
            </div>
        </ModalComponent>}
    </>);
}

export default BlobConnection;
