import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { GetObjectLogs } from '../../../store/ModuleAction';
import * as LogTypes from '../../../util/FlowSyncLoggerTypes';
import ObjectLogs from '../../misc/ObjectLogs';
import { Result } from '../../../models/CoreModels';
import { CoreObjectLogs } from '../../../models/CoreObjectLogs';

interface ObjectLogsState {
    objectLogs: Result<CoreObjectLogs[]>
}

interface State {
    module: ObjectLogsState
}


const GroupViewObjectLogs = () => {
    const { groupId } = useParams();
    const dispatch = useDispatch();
    const objectLogs = useSelector<State, Result<CoreObjectLogs[]>>(state => state.module.objectLogs);

    useEffect(() => {
        GetObjectLogs({ objectId: groupId, objectSource: LogTypes.Flowsync.SourceName, objectType: LogTypes.Flowsync.Group })(dispatch);
    }, [dispatch, groupId]);

    return (<>
        <ObjectLogs
            objectLogs={objectLogs}
            noResMsg="Det finns inga loggrader för denna grupp den senaste körningen"
        />
    </>);
}

export default GroupViewObjectLogs;
