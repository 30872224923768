import React from 'react';
import { Tab } from '../../util/TabValue';
import { Link } from 'react-router-dom'
import { NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface RenderTabsProps {
    tabs: Tab[]
    id: string
    url: string
    id2?: string
    url2?: string
}

const RenderTabs: React.FC<RenderTabsProps> = (props) => {
    return (
        <div className="tabs">
            {props.tabs.map((tab, i) => {
                let PATH = !props.id2 ?
                    `/${props.url}/${props.id}/${tab.url}` :
                    `/${props.url}/${props.id}/${props.url2}/${props.id2}/${tab.url}`;
                let active = window.location.pathname === PATH;
                return (<div key={i}>
                    <NavLink key={`lg-${tab.value}`} active={active} className="tab lg" tag={Link} to={PATH}>{tab.text}</NavLink>
                    <NavLink key={`sm-${tab.value}`} active={active} className="tab sm" tag={Link} to={PATH}><FontAwesomeIcon icon={tab.icon} /></NavLink>
                </div>)
            })}
        </div>
    )
}

export default RenderTabs;
