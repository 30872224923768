import React from 'react';
import { FormGroup, Label, Card, CardBody, CardHeader, Button, Collapse } from 'reactstrap';
import ConfigurationComponent from './ConfigurationComponent';
import { ImportSchoolUnitCheckbox, ExportSchoolUnitCheckbox } from './schoolUnit/SchoolUnitInput';
import ModuleType from '../../misc/ModuleType';

class SchoolUnitsConfiguration extends ConfigurationComponent {
    propertyName = 'schoolUnits';

    state = { open: true };

    mergeWithUpdates(schoolUnits, updatedSchoolUnits) {
        return schoolUnits.map(schoolUnit => {
            const updatedSchoolUnit = updatedSchoolUnits.find(updated => updated.id === schoolUnit.id);
            return updatedSchoolUnit ? { ...updatedSchoolUnit } : { ...schoolUnit };
        });
    };

    validate(schoolUnits) {
        const validation = [];
        schoolUnits.forEach(su => {
            if (!su.isActive && su.shouldImport) {
                validation.push(`inactive-${su.id}`)
            }
            if (this.props.moduleType === ModuleType.Import && !su.shouldImport && su.exportModules.length >= 1) {
                validation.push(`references-${su.id}`)
            }
        })
        return validation;
    };

    warnings(updates) {
        const warningMessages = [];

        if (updates.some(su => !su.shouldImport)) {
            warningMessages.push('Om du tar bort skolor kommer data kopplad till dessa att inaktiveras');
        }

        return warningMessages;
    }

    updateProperty = (schoolUnit) => {
        const currentUpdates = this.props.updates;
        let newUpdates;

        if (currentUpdates && currentUpdates.some(su => su.id === schoolUnit.id)) {
            newUpdates = currentUpdates.filter(su => su.id !== schoolUnit.id);
        } else {
            newUpdates = currentUpdates.slice(0);
            newUpdates.push(schoolUnit);
        }

        this.notifyUpdates(newUpdates);
        this.updateData(newUpdates);
    }

    updateData(newUpdates) {
        var merged = this.mergeWithUpdates(this.props[this.propertyName], newUpdates);
        this.props.mergeUpdate(this.typedMerge(merged));
        this.setInvalid(merged, this.props.active);
    }

    typedMerge(merge) {
        switch (this.props.moduleType) {
            case ModuleType.Import: return { importSchoolUnits: merge };
            case ModuleType.Export: return { exportSchoolUnits: merge };
            default: throw new Error(`Unknown module type: ${this.props.moduleType}`);
        }
    }

    renderCheckbox(schoolUnit, validation) {
        switch (this.props.moduleType) {
            case ModuleType.Import:
                return <ImportSchoolUnitCheckbox
                    isAdmin={this.props.isAdmin}
                    schoolUnit={schoolUnit}
                    onChange={this.updateProperty}
                    validation={validation}
                />;
            case ModuleType.Export:
                return <ExportSchoolUnitCheckbox
                    isAdmin={this.props.isAdmin}
                    schoolUnit={schoolUnit}
                    onChange={this.updateProperty}
                    validation={validation}
                />;
            default: return `Okänd modultyp: ${this.props.moduleType}`;
        }
    }

    render() {
        if (this.props.schoolUnits && this.props.schoolUnits.length > 0 && this.props.showSchoolUnits) {
            const schoolUnits = this.mergeWithUpdates(this.props.schoolUnits, this.props.updates);
            const validation = this.validate(schoolUnits);

            return <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    Skolor
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>
                        <ul className="schoolunit_list">
                            {schoolUnits.map(schoolUnit =>
                                <li lg={3} key={schoolUnit.id}>
                                    <FormGroup check title={schoolUnit.title} className="checkbox-list-items">
                                        <Label check>
                                            {this.renderCheckbox(schoolUnit, validation)}
                                        </Label>
                                    </FormGroup>
                                </li>
                            )}
                        </ul>
                    </CardBody>
                </Collapse>
            </Card>
        }

        return null;
    }
}

export default SchoolUnitsConfiguration;