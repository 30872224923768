import { Button, Form, Input } from 'reactstrap';
import FontAwesomeButton from '../../../../../misc/FontAwesomeButton';
import { OnChangeCallback } from '../../../../../../util/UtilityTypes';
import React, { useState } from 'react';
import SelectInput from '../../../../../misc/SelectInput';
import { AlvisSchoolUnit } from '../../../../../../models/CoreAlvisModels';
import AlvisSftpFiles from './AlvisSftpFiles';
import * as SchoolTypeUtil from '../../../../../../util/SchoolTypeUtil';
import { GridListItem, GridListHeader } from '../../../../../misc/GridListComponents';
import { SchoolType } from '../../../../../../models/SchoolType';

interface AlvisSchoolProps {
    name: string
    schoolUnits: AlvisSchoolUnit[]
    validation: string[]
    disabled?: boolean
    onChange: OnChangeCallback<AlvisSchoolUnit[]>
}

const defaultSchoolUnit: AlvisSchoolUnit = {
    displayName: '',
    schoolUnitCode: '',
    schoolType: 0,
    sftpFiles: []
}

const types = [
    //SchoolType.PRESCHOOL,
    //SchoolType.PRESCHOOLCLASS,
    //SchoolType.LEISURETIMECENTER,
    //SchoolType.COMPULSORY,
    //SchoolType.COMPULSORYSPECIALNEED,
    //SchoolType.SPECIALNEED,
    //SchoolType.SAMI,
    //SchoolType.UPPERSECONDARY,
    //SchoolType.UPPERSECONDARYSPECIALNEED,
    SchoolType.ADULTEDUCATION,
    SchoolType.ADULTEDUCATIONSPECIALNEED,
    SchoolType.WORKHIGHERVOCATIONAL,
    SchoolType.FOLKHIGH,
    SchoolType.HIGHERVOCATIONAL,
    SchoolType.UNIVERSITY,
    SchoolType.ADULTEDUCATIONSWEDISH,
    //SchoolType.PRESCHOOLLEISURETIMECENTER,
    SchoolType.OTHER,
];
const schoolTypes = types.map((x) => { return { value: x, text: SchoolTypeUtil.getTypes(x) }} );

const schoolUnitEquals = (a: AlvisSchoolUnit, b: AlvisSchoolUnit): boolean => {
    if (a.displayName !== b.displayName) {
        return false;
    }
        
    if (a.schoolUnitCode !== b.schoolUnitCode) {
        return false;
    }

    if (a.schoolType !== b.schoolType) {
        return false;
    }
    
    if (a.sftpFiles.length !== b.sftpFiles.length) {
        return false;
    }

    a.sftpFiles.forEach((filename) => {
        if (!b.sftpFiles.find(x => x === filename)) {
            return false;
        }
    })
    b.sftpFiles.forEach((filename) => {
        if (!a.sftpFiles.find(x => x === filename)) {
            return false;
        }
    })

    return a.sftpFiles === b.sftpFiles;
}

const AlvisSchools: React.FC<AlvisSchoolProps> = (props) => {
    const [schoolUnits, setSchoolUnits] = useState<AlvisSchoolUnit[]>(props.schoolUnits);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);
    const [currentSchoolUnit, setCurrentSchoolUnit] = useState<AlvisSchoolUnit>({ ...defaultSchoolUnit })

    const calculateUpdatedSchoolUnits = (): AlvisSchoolUnit[] | null => {
        currentSchoolUnit.sftpFiles = currentSchoolUnit.sftpFiles.filter((f) => { // remove empty filenames
            return f.sftpFilename != null && f.sftpFilename.length > 0 
        })

        currentSchoolUnit.sftpFiles.forEach((file) => {
            file.sftpFilename = file.sftpFilename.trim();
        });

        if (Number.isFinite(currentIndex)) {
            return schoolUnits.map((x, i) => i === currentIndex ? currentSchoolUnit : x);
        } else if (!schoolUnits.some(x => schoolUnitEquals(x, currentSchoolUnit))) {
            return [...schoolUnits, currentSchoolUnit];
        } else {
            return null;
        }
    }

    const submitSchoolUnit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault()
        const updatedSchoolUnits = calculateUpdatedSchoolUnits();

        setCurrent(null);

        if (updatedSchoolUnits) {
            notifyChange(updatedSchoolUnits);
        }                
    }

    const setCurrent = (index: number | null): void => {
        setCurrentIndex(index);

        if (index || index === 0) {
            setCurrentSchoolUnit({ ...schoolUnits[index] })
        } else {
            setCurrentSchoolUnit({ ...defaultSchoolUnit })
        }
    }

    const removeSchoolUnit = (index: number | null): void => {
        if (index === null) {
            return;
        }

        const remaining = schoolUnits.filter((_, i) => i !== index);
        notifyChange(remaining);
    }

    const notifyChange = (updatedSchoolUnits: AlvisSchoolUnit[]): void => {
        setSchoolUnits(updatedSchoolUnits);
        props.onChange(props.name, updatedSchoolUnits);
    }

    const valid = (): boolean => {
        if (!currentSchoolUnit) {
            return false;
        }

        if (currentSchoolUnit.displayName.length < 1) {
            return false;
        }

        if (currentSchoolUnit.schoolUnitCode.length < 1) {
            return false;
        }

        if (currentSchoolUnit.schoolType < 1) {
            return false;
        }

        if (currentSchoolUnit.sftpFiles.length < 1) {
            return false;
        }

        return true;
    }


    const renderRow = (index: number | null, schoolUnit: AlvisSchoolUnit): React.ReactNode | null => {
        const editing = Number.isFinite(currentIndex);

        if (index === currentIndex) {
            return (<React.Fragment key={index}>
                <GridListItem>
                    <Input                        
                        type="text"
                        disabled={props.disabled}
                        placeholder="Skolnamn"
                        autoComplete="off"
                        value={currentSchoolUnit.displayName}
                        onChange={(e) => setCurrentSchoolUnit({ ...currentSchoolUnit, displayName: e.target.value })}
                        />
                </GridListItem>
                <GridListItem>
                    <Input
                        type="text" disabled={props.disabled}                        
                        placeholder="Skolenheteskod"
                        autoComplete="off"
                        value={currentSchoolUnit.schoolUnitCode}
                        onChange={(e) => setCurrentSchoolUnit({ ...currentSchoolUnit, schoolUnitCode: e.target.value })}
                    />
                </GridListItem>
                <GridListItem>
                    <SelectInput
                        items={schoolTypes}                                                
                        selected={currentSchoolUnit.schoolType}
                        name={`schoolType`}
                        onChange={(n, v) => setCurrentSchoolUnit({ ...currentSchoolUnit, schoolType: v ?? SchoolType.NONE })}
                        defaultText="Välj skolform"
                        defaultValue={SchoolType.NONE}
                        disabled={props.disabled}                                                
                    />
                </GridListItem>
                <GridListItem>
                    <AlvisSftpFiles
                        name="SftpFiles"
                        files={currentSchoolUnit.sftpFiles}
                        editable={true}
                        onChange={(n, v) => setCurrentSchoolUnit({ ...currentSchoolUnit, sftpFiles: v })}
                    />
                </GridListItem>
                {editing ? <GridListItem span={{ startColumn: 5, columnCount: 2 }}>
                    <Button style={{ width: '100%' }} color="primary" type="submit" disabled={props.disabled || !valid()}>Spara</Button>
                    <Button style={{ width: '100%' }} color="danger" onClick={() => setCurrent(null)}>Avbryt</Button>
                </GridListItem> : <GridListItem span={{ startColumn: 5, columnCount: 2 }}>
                    <Button style={{ width: '100%' }} color="primary" type="submit" disabled={props.disabled || !valid()}>Lägg till</Button>
                </GridListItem>}
            </React.Fragment>)
        } else if (Number.isFinite(index)) {
            return (<React.Fragment key={index}>
                <GridListItem>{schoolUnit.displayName}</GridListItem>
                <GridListItem>{schoolUnit.schoolUnitCode}</GridListItem>
                <GridListItem>{SchoolTypeUtil.getTypes(schoolUnit.schoolType)}</GridListItem>
                <GridListItem>
                    <AlvisSftpFiles
                        name="SftpFiles"
                        files={schoolUnit.sftpFiles}
                        editable={false}
                        onChange={null}
                    />
                </GridListItem>
                <GridListItem className="gridListCenterContent">
                    <FontAwesomeButton icon="pen" onClick={() => setCurrent(index)} disabled={props.disabled || editing} title="Redigera skola" />
                </GridListItem>
                <GridListItem className="gridListCenterContent">
                    <FontAwesomeButton icon="trash" onClick={() => removeSchoolUnit(index)} disabled={props.disabled || editing} title="Radera skola" />
                </GridListItem>
            </React.Fragment>);
        } else {
            return null;
        }
    }

    return (<>
        <Form className="gridList" style={{ gridTemplateColumns: '3fr 3fr 3fr 3fr 1.5fr 1.5fr' }} onSubmit={(e) => submitSchoolUnit(e)}>
            <GridListHeader>Namn</GridListHeader>
            <GridListHeader>Enhetskod</GridListHeader>
            <GridListHeader>Skolform</GridListHeader>
            <GridListHeader>Filnamn</GridListHeader>
            <GridListHeader span={{ startColumn: 5, columnCount: 2 }} />
            {schoolUnits.map((schoolUnit, index) => renderRow(index, schoolUnit))}
            {renderRow(null, currentSchoolUnit)}
        </Form>
    </>);
}

export default AlvisSchools;