import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loggedInUser, MeState } from '../store/MeAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { signOut } from '../store/AuthAction';
import { getPolicyName } from '../models/FlowSyncAccessPolicy';
import { CoreLoggedInUser } from '../models/CoreLoggedInUser';

interface TopStoreState {
    me: MeState
}

const isUserInfoElement = (element: HTMLElement) => {
    let isUserInfo = false;

    while (element) {
        if (element.id === 'user-info') {
            isUserInfo = true;
            break;
        }
        element = element.parentElement as HTMLElement;
    }

    return isUserInfo;
};

const Top: FC<{}> = () => {
    const dispatch = useDispatch();
    const [showInfo, setShowInfo] = useState(false);
    const me = useSelector<TopStoreState, CoreLoggedInUser | undefined>(state => state.me.me);

    useEffect(() => {
        loggedInUser()(dispatch);
        return () => {
            window.removeEventListener('scroll', hideOnScroll)
            document.removeEventListener('click', hideOnClick);
        }
    }, [dispatch])

    useEffect(() => {
        if (!me?.customer) {
            document.title = 'FlowSync';
            return;
        }
        document.title = `${me?.customer} - FlowSync`;
    }, [me?.customer])
    
    const hideOnScroll: (this: Window, event: Event) => void = useCallback((event) => {
        hideInfo();
    }, []);

    const hideOnClick: (this: Document, event: Event) => void = useCallback((event) => {
        if (isUserInfoElement(event.target as HTMLElement)) {
            return;
        }

        hideInfo();
    }, [])

    const hideInfo = useCallback(() => {
        setShowInfo(false);

        window.removeEventListener('scroll', hideOnScroll)
        document.removeEventListener('click', hideOnClick);
    }, []);

    const showUserInfo = useCallback(() => {
        if (showInfo) {
            hideInfo();
            return;
        }

        setShowInfo(true);
        window.addEventListener('scroll', hideOnScroll, {once: true})
        document.addEventListener('click', hideOnClick)
    }, [showInfo]);

    return <div className="top">
        {me !== undefined && <React.Fragment>
            <div>{me.customer}</div>
            <div className="user" id="user-info">
                <div className="flex">
                    <div className="displayname">{me.displayName} </div>
                    <div className="icon_box"><FontAwesomeIcon onClick={showUserInfo} icon="user" /></div>
                </div>
                {showInfo &&
                    <div className="user-info">
                        <div>{me.email}</div>
                        <div className="mt-3">
                            <strong>Roller:</strong>
                            <ul>
                                { me.roles.sort().map(x => <li key={x}>{x}</li>) }
                            </ul>
                        </div>
                        <div className="log_out" onClick={() => signOut()(dispatch)}><FontAwesomeIcon icon="sign-in-alt" className="nav_icon" />Logga ut</div>
                    </div>
                }
            </div>
        </React.Fragment>}
    </div>;
}

export default Top;
