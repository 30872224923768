import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { OnChangeCallback } from '../../util/UtilityTypes'

interface BoolInputProps {
    title: string
    name: string
    value: boolean
    disabled?: boolean
    updateValue: OnChangeCallback<boolean>
    titleSmall?: boolean
}

const values = [
    { value: true, text: "Ja" },
    { value: false, text: "Nej" },
];

const BoolInput: React.FC<BoolInputProps> = (props) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const prop = e.target.name;
        const value = e.target.value === 'true' ? true : false;
        props.updateValue(prop, value)
    }

    return (<FormGroup tag="fieldset">
        {props.titleSmall ? <Label>{props.title}</Label> : <h5>{props.title}</h5>}
        {values.map(opt =>
            <FormGroup check key={opt.value.toString()}>
                <Input type="radio" id={`${props.name}-${opt.value}`} name={props.name} value={opt.value.toString()} defaultChecked={props.value === opt.value} disabled={props.disabled} onChange={(e) => onChange(e)} />
                <Label for={`${props.name}-${opt.value}`} check>{opt.text}</Label>
            </FormGroup>
        )}
    </FormGroup>);
}

export default BoolInput;
