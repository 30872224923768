import React from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { OnChangeCallback } from '../../util/UtilityTypes'

interface TextInputProps {
    title: string
    name: string
    value: string
    disabled?: boolean
    invalid?: boolean
    invalidFeedback?: string
    updateValue: OnChangeCallback<string>
}

const TextInput: React.FC<TextInputProps> = (props) => {
    const onChangeInput = (value: string) => {
        props.updateValue(props.name, value);
    }

    return (
        <FormGroup>
            <Label style={{ display: 'block' }}>{props.title}</Label>
            <Input className="md-w-50 lg-w-25" type="text" name={props.name} id={props.name}
                value={props.value}
                onChange={(e) => onChangeInput(e.target.value)}
                disabled={props.disabled}
                invalid={props.invalid} />
            <FormFeedback>{props.invalidFeedback}</FormFeedback>
        </FormGroup>
    );
}

export default TextInput;
