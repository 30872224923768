import { Reducer, Dispatch, Action } from 'redux';

enum MenuActionType {
    TOGGLEMENU = 'TOGGLEMENU'
}

export interface MenuState {
    open: { [key:string]: boolean }
}

interface ToggleMenuAction extends Action<MenuActionType.TOGGLEMENU> {
    name: string
}

type MenuAction = ToggleMenuAction;

export const ToggleMenu = (name: string) => (dispatch: Dispatch<ToggleMenuAction>) => {
    dispatch({
        type: MenuActionType.TOGGLEMENU,
        name: name
    })
}

const initialState: MenuState = {
    open: {},
}

export const reducer: Reducer<MenuState, MenuAction> = (state, action) => {
    state = state || initialState;
    
    switch (action.type) {
        case MenuActionType.TOGGLEMENU:
            return {
                ...state,
                open: {
                    ...state.open,
                    [action.name]: !state.open[action.name]
                }
            }
        default:
            return state;
    }
} 