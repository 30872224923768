import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface AppTokenModalProps {
    isOpen: boolean,
    disabled: boolean
    header: string,
    body: string,
    toggleModal: () => void,
    confirm: () => void,
}

const AppTokenModal = (props: AppTokenModalProps) => (
    <Modal isOpen={props.isOpen} toggle={props.toggleModal}>
        <ModalHeader>{props.header}</ModalHeader>
        <ModalBody>{props.body}</ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={props.toggleModal}>Nej</Button>
            <Button color="primary" onClick={props.confirm} disabled={props.disabled}>Ja</Button>
        </ModalFooter>
    </Modal>
)

export default AppTokenModal;
