import React, { useState } from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { OnChangeCallback } from '../../util/UtilityTypes'

interface NullableIntInputCheckboxTextProps {
    title: string
    name: string
    value?: number | null
    defaultValue: number
    checked?: boolean
    checkboxTitle: string
    disabled?: boolean
    invalid?: boolean
    invalidFeedback?: string
    onChange: OnChangeCallback<number | null>
}

const NullableIntInputCheckboxText: React.FC<NullableIntInputCheckboxTextProps> = (props) => {
    const [previous, setPrevious] = useState(Number.isInteger(props.value) ? props.value ?? null : props.defaultValue)

    const toggle = (active: boolean) => {
        if (active) {
            setPrevious(props.value ?? null);
            props.onChange(props.name, null);
        } else {
            props.onChange(props.name, previous);
        }
    }

    const onChange = (input: string) => {
        const value = input === '' ? NaN : parseInt(input, 10);
        setPrevious(value)
        props.onChange(props.name, value)
    }

    const getValue = (): string | number => {
        if (props.value === null || props.value === undefined) {
            return '';
        }

        if (Number.isNaN(props.value)) {
            return '';
        }

        return props.value;
    }

    return (
        <FormGroup>
            <Label for={props.name}>{props.title}</Label>
            <Input
                className="md-w-50 lg-w-25"
                type="number"
                name={props.name}
                id={props.name}
                min="0"
                step="1"
                pattern="[0-9]"
                value={getValue()}
                onChange={(e) => onChange(e.target.value)}
                disabled={props.disabled || props.value === null}
                invalid={props.invalid}
                onWheel={(e) => (e.target as HTMLInputElement).blur()}
            />
            <FormFeedback>{props.invalidFeedback}</FormFeedback>
            <FormGroup check>
                <Label>
                    <Input
                        type="checkbox"
                        name={`${props.name}Check2`}
                        id={`${props.name}Check2`}
                        disabled={props.disabled}
                        checked={props.checked || props.value === null}
                        onChange={(e) => toggle(e.target.checked)}
                    />
                    {props.checkboxTitle}
                </Label>
            </FormGroup>
        </FormGroup>
    )
}

export default NullableIntInputCheckboxText;
