import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActivityState, GetActivity } from '../../../store/ActivityAction';
import { useParams, Link, Route, Routes, Navigate } from 'react-router-dom'
import * as Tab from '../../../util/TabValue';
import RenderTabs from '../../misc/Tabs';
import ActivityViewInfo from './ActivityViewInfo';
import ActivityViewGroups from './ActivityViewGroups';
import ActivityViewTrackings from './ActivityViewTrackings';
import ActivityViewObjectLogs from './ActivityViewObjectLogs';
import ActivityViewMembers from './ActivityViewMembers';
import ActivityViewSyllabus from './ActivityViewSyllabus';
import NotFound from '../components/NotFound';
import { CoreActivity } from '../../../models/CoreActivity';
import { RequestState } from '../../../models/RequestState';
import StatusCode from '../../../util/StatusCode';
import Loading from '../../misc/Loading';

interface State {
    activities: ActivityState
}

const ActivityView = () => {
    const { activityId } = useParams();
    const dispatch = useDispatch();
    const activity = useSelector<State, RequestState<CoreActivity>>(state => state.activities.activity);

    useEffect(() => {
        if (activityId !== undefined) {
            GetActivity(activityId)(dispatch);
        }
    }, [dispatch, activityId]);

    const tabs = [
        Tab.Tabs.Information,
        Tab.Tabs.ObjectLogs,
        Tab.Tabs.Members,
        Tab.Tabs.Groups,
        Tab.Tabs.Syllabus,
        Tab.Tabs.Trackings,
    ];

    if (activityId === undefined) {
        return;
    }

    switch (activity.code) {
        case StatusCode.COMPLETE:
            return (
                <div className="padding-40 container single">
                    <h1><Link to="/activity">Aktivitet</Link> {activity.data.displayName}</h1>

                    <RenderTabs
                        tabs={tabs}
                        id={activityId}
                        url="activity" />

                    <Routes>
                        <Route path={Tab.Tabs.Information.url} element={<ActivityViewInfo />} />
                        <Route path={Tab.Tabs.ObjectLogs.url} element={<ActivityViewObjectLogs />} />
                        <Route path={Tab.Tabs.Members.url} element={<ActivityViewMembers />} />
                        <Route path={Tab.Tabs.Groups.url} element={<ActivityViewGroups />} />
                        <Route path={Tab.Tabs.Syllabus.url} element={<ActivityViewSyllabus /> } />
                        <Route path={Tab.Tabs.Trackings.url} element={<ActivityViewTrackings />} />
                        <Route path="*" element={<Navigate replace={true} to={`/activity/${activityId}/${Tab.Tabs.Information.url}`} />} />
                    </Routes>
                </div>
            );
        case StatusCode.ERROR:
            if (activity.error.response?.status === 404) {
                return (
                    <NotFound
                        header="Aktivitet"
                        name="Aktiviteten"
                        link="/activity"
                    />
                );
            } else {
                return <p>{activity.error.response?.statusText}</p>
            }
        default:
            return (
                <Loading />
            );
    }
}

export default ActivityView;
