import { faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button } from 'reactstrap';

const Turbo: React.FC = () => {
    var [animation, setAnimation] = useState<NodeJS.Timeout | undefined>(undefined)
    var [counter, setCounter] = useState<number>(0)

    const getInterval = (): number => {
        const deciseconds = 20 - Math.floor(Math.cbrt(counter));

        if (deciseconds < 1) {
            return 100;
        }

        return 100 * deciseconds;
    }

    const turbo = () => {
        setCounter(counter + 1)
        if (animation) {
            clearTimeout(animation);
        }

        const interval = getInterval();
        const className = `turbo${interval}`
        const previousClassName = `turbo${interval + 100}`;

        if (interval === 2000) {
            document.body.classList.add(className);
        } else if (!document.body.classList.contains(className)) {
            document.body.classList.replace(previousClassName, className);
        }

        var timeout = setTimeout(() => {
            Array.from(document.body.classList.values()).forEach(item => {
                if (item.startsWith('turbo')) {
                    document.body.classList.remove(item)
                }
            });

            setAnimation(undefined);
            setCounter(0)
        }, 8000) 
        setAnimation(timeout);
    }

    var date = new Date();
    var dayOfMonth = date.getDate();
    var month = date.getMonth();
    if (dayOfMonth < 7 && month === 3) {
        return (<div className="turbo-wrapper">
            <Button color="primary" onClick={() => turbo()}>Turbo <FontAwesomeIcon icon={faRocket} /></Button> { counter === 0 ? null : counter }
            </div>)
    } else {
        return null;
    }
}

export default Turbo;