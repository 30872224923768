import React from 'react';

interface SubFooterProps {
    className: string
}

const SubFooter: React.FC<SubFooterProps> = (props) => {
    return (
        <div className={props.className + " subfooter"}>
            <p>En produkt av</p>
            <a href="https://www.eventful.se/" target="_blank" rel="noopener noreferrer"><img src="https://eventfulcdn.blob.core.windows.net/flowsync/eventful_logo_light.svg" alt="Eventful" />
            </a>
        </div>
    )
}

export default SubFooter;