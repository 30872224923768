import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { FlowSyncAccessPolicy, isAuthorized } from '../../models/FlowSyncAccessPolicy';
import { useSelector } from 'react-redux';
import { MeState } from '../../store/MeAction';

interface AuthorizedLinkProps extends React.PropsWithChildren {
    accessPolicy: FlowSyncAccessPolicy,
    className?: string,
    to: string,
}

interface AuthorizedLinkStateStore {
    me: MeState
}

const AuthorizedLink: FC<AuthorizedLinkProps> = 
    (props) => {
        const authorizedAccessPolicies = useSelector<AuthorizedLinkStateStore, FlowSyncAccessPolicy[]>(state => state.me.me?.authorizedAccessPolicies ?? []);

        if (!isAuthorized(authorizedAccessPolicies, props.accessPolicy)) {
            return <span className={props.className}>{props.children}</span>
        }

        return <Link to={props.to} className={props.className}>{props.children}</Link>
    }

export default AuthorizedLink;