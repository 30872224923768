import React, { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface EnumInputProps {
    explanation?: string
}

const Explanation: React.FC<EnumInputProps> = (props) => {
    var ref = useRef(null);

    if (!props.explanation) {
        return null;
    }

    return (<>
        <FontAwesomeIcon icon="question-circle" className="explanation__icon ms-2" ref={ref} />
        <UncontrolledTooltip target={ref} autohide={false} className="mr-3">
            {props.explanation}
        </UncontrolledTooltip>
    </>);
}

export default Explanation;