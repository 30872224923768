import React, { useCallback } from 'react';
import { googleAuth, microsoftAuth } from '../../store/AuthAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Login.css';
import GoogleApi from '../../services/GoogleApi.js'
import { useDispatch } from 'react-redux';

const Login: React.FC = () => {
    const dispatch = useDispatch();

    const googleRef = useCallback(async (elem: HTMLDivElement | null) => {
        if (!elem) {
            return;
        }

        await GoogleApi.renderButton(elem, async token => {
            await googleAuth(token)(dispatch);
        });
    }, [dispatch]);

    const loginMsft = async () => {
        await microsoftAuth()(dispatch);
    }

    return (
        <div className="login">
            <div>
                <div className="login__logo-container">
                    <img className="login__logo" src="/FlowSync_lg_dark.svg" alt="FlowSync" /> 
                </div>
                <p>Välj inloggningsmetod</p>
                <div>
                    <div className='login__provider--google' ref={googleRef}></div>
                    <div>
                        <button className="login__provider--microsoft" onClick={loginMsft}>
                            <FontAwesomeIcon className="login__provider-icon" icon={['fab', 'microsoft']} /> Microsoft
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
