const STORE_TOKEN_KEY = "googleAuthToken";

let gsiClientPromise;
let credentialCallbacks = [];
let ongoingPrompt = null;

const waitForGsiClient = (cb) => {
    if (window.google?.accounts !== undefined) {
        return cb(window.google);
    }

    setTimeout(() => { waitForGsiClient(cb) }, 100);
}

const parseJwtClaims = (jwtToken) => {
    const base64Url = jwtToken.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

const gsiClient = () => {
    if (!gsiClientPromise) {
        gsiClientPromise = new Promise((resolve) => {
            const script = document.createElement("script");
            script.onload = () => {
                waitForGsiClient((google) => {
                    google.accounts.id.initialize({
                        client_id: '845322921324-l6u2qbt6371o6q8n56j061p0oao2db50.apps.googleusercontent.com',
                        callback: data => {
                            const claims = parseJwtClaims(data.credential);
                            localStorage.setItem(STORE_TOKEN_KEY, JSON.stringify({ exp: claims.exp, credential: data.credential }));
                            const cbs = [...credentialCallbacks];
                            credentialCallbacks = [];
                            cbs.forEach((cb) => cb(data.credential));
                        },
                        auto_select: true,
                        state_cookie_domain: 'flowsync.se',
                        use_fedcm_for_prompt: true
                    });

                    resolve(google);
                });
            };
            script.src = "https://accounts.google.com/gsi/client";

            document.body.appendChild(script);
        });
    }

    return gsiClientPromise;
}

class GoogleApi {
    static async renderButton(element, tokenCallback) {
        var google = await gsiClient();
        google.accounts.id.renderButton(element, {
            theme: 'outline',
            size: 'large',
            width: 210,
            click_listener: () => {
                credentialCallbacks.push((credential) => {
                    tokenCallback(credential);
                })
            }
        });
    }

    static token() {
        if (ongoingPrompt) {
            return ongoingPrompt;
        }

        ongoingPrompt = new Promise(async (resolve, reject) => {
            const currentToken = JSON.parse(localStorage.getItem(STORE_TOKEN_KEY));
            if (currentToken && currentToken.exp > ((new Date().getTime() / 1000) + 60)) {
                resolve(currentToken.credential);
                return;
            }
            const google = await gsiClient();
            credentialCallbacks.push((credential) => {
                resolve(credential);
            });

            google.accounts.id.prompt(notification => {
                if (notification.isSkippedMoment()) {
                    reject();
                }
            });
        });

        let prompt = ongoingPrompt;
        prompt.finally(() => {
            ongoingPrompt = null
        });
        return prompt;
    }

    static logout() {
        localStorage.removeItem(STORE_TOKEN_KEY);
    }
}

export default GoogleApi;