import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppTokenState, updateAppToken } from '../../../../store/AppTokenAction';
import Loading from '../../../misc/Loading';
import * as AppTokenUtil from '../../../../util/AppTokenUtil';
import { Button, Form, FormFeedback, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CoreAppToken, CoreAppTokenValidation } from '../../../../models/CoreAppToken';
import StatusCode from '../../../../util/StatusCode';
import { RequestState } from '../../../../models/RequestState';
import Copy from '../../../misc/Copy';
import { OperationResult } from '../../../../models/OperationResult';

interface AppTokenRootState {
    token: AppTokenState
}

interface AppTokenMicrosoftApplicationProps {
    appToken: CoreAppToken,
    disableInput: boolean
}

const verifyTenantId = async (tenantId: string): Promise<OperationResult<string>> => {
    if (tenantId === undefined || tenantId === '') {
        return { ok: false, error: { name: "TenantIdMissing", message: 'Tenant ID eller domän måste anges' } };
    }

    const res = await fetch(`https://login.microsoftonline.com/${tenantId}/v2.0/.well-known/openid-configuration`);
    const wellKnownInfo = await res.json();

    const tokenEndpoint = wellKnownInfo?.token_endpoint;
    if (tokenEndpoint === undefined) {
        return { ok: false, error: { name: "NoTokenEndpoint", message: 'Kunde inte verifiera Azure-miljöns Tenant ID eller domän' } };
    }

    const verifiedTenantId = tokenEndpoint.replace(/.*\/([^-]{8}-[^-]{4}-[^-]{4}-[^-]{4}-[^-]{12})\/.*/, "$1");

    return { ok: true, value: verifiedTenantId };
}

const AppTokenMicrosoftApplication = (props: AppTokenMicrosoftApplicationProps) => {
    const appTokenValidationRequestState = useSelector<AppTokenRootState, RequestState<CoreAppTokenValidation>>(x => x.token.appTokenValidation);
    const dispatch = useDispatch();
    const [tenantId, setTenantId] = useState(props.appToken.tenant ?? '');
    const [tenantIdError, setTenantIdError] = useState<string | undefined>(undefined);
    const [updatingApptoken, setUpdatingAppToken] = useState(false);
    const [verifiedTenantId, setVerifiedTenantId] = useState(false);

    useEffect(() => {
        if (!props.appToken.tenant) {
            return;
        }

        verifyTenantId(props.appToken.tenant)
            .then(verifiedTenantId => {
                if (verifiedTenantId.ok) {
                    setVerifiedTenantId(true);
                }
                else {
                    setVerifiedTenantId(false);
                }
            });
    }, [props.appToken.tenant])

    const login = async (appTokenValidate: CoreAppTokenValidation) => {
        let uri = appTokenValidate.flowSyncUXUri;
        let login = await AppTokenUtil.authWindow(uri ?? '');

        if (login.adminConsent && login.tenant) {
            await generateMicrosoftApplication(props.appToken.id, login.tenant);
        } 
    }

    const generateMicrosoftApplication = async (appTokenId: string, tenant: string) => {
        let appTokenBody = {
            appRegistration: props.appToken.appRegistration,
            tenant: tenant
        }

        await updateAppToken(appTokenId, appTokenBody)(dispatch);
    }

    const submitTenantId: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        setUpdatingAppToken(true);
        const verifiedTenantId = await verifyTenantId(tenantId);

        if (verifiedTenantId.ok) {
            await generateMicrosoftApplication(props.appToken.id, verifiedTenantId.value);
        }
        else {
            setTenantIdError(verifiedTenantId.error.message);
        }

        setUpdatingAppToken(false);
    }

    const inputTenantId: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setVerifiedTenantId(false);
        setTenantId(e.target.value.trim());
    }

    if (appTokenValidationRequestState.code === StatusCode.NONE || appTokenValidationRequestState.code === StatusCode.PENDING) {
        return (<Loading />);
    }

    if (appTokenValidationRequestState.code === StatusCode.ERROR) {
        return (
            <div>
                <p>Misslyckades</p>
                <p>Servern svarade med '{appTokenValidationRequestState.error.code}'</p>
            </div>)
    }

    const appTokenValidation = appTokenValidationRequestState.data;

    if (!appTokenValidation.valid) {
        return (
            <div>
                <p><FontAwesomeIcon icon="triangle-exclamation" className="apptoken-configuration__status-icon--warning" />Kontot behöver konfigureras</p>
                <div>
                    <strong>Alternativ 1: Acceptera behörigheter direkt</strong>
                    <div className="apptoken-configuration__button-container">
                        <Button color="primary" disabled={props.disableInput} onClick={() => login(appTokenValidation)}>Logga in och acceptera behörigheter</Button>
                    </div>
                </div>
                <div className="push--top">
                    <strong>Alternativ 2: Be en administratör godkänna behörigheterna</strong>
                    <div>
                        <Form onSubmit={submitTenantId}>
                            <div className="push--top">
                                <Label for="userName">1. Ange Azure-miljöns Tenant ID eller domän: </Label>
                                <Input disabled={props.disableInput} onChange={inputTenantId} value={tenantId} invalid={tenantIdError !== undefined} valid={verifiedTenantId} />
                                <FormFeedback>{tenantIdError}</FormFeedback>
                            </div>
                            <div className="push--top">
                                <Label for="copyLinkUri">2. Be en administratör godkänna applikationen genom att följa nedanstående länk: <Copy value={appTokenValidation.copyLinkUri ?? ''} /></Label>
                                <Input name="copyLinkUri" disabled value={appTokenValidation.copyLinkUri} />
                            </div>
                            <div className="apptoken-configuration__button-container">
                                <Button color="primary" type="submit">Spara och validera inloggning</Button>
                                {updatingApptoken && <Loading className="push--left" />}
                            </div>
                        </Form>
                    </div>
                </div>
            </div>)
    }

    return (<div>
        <div className='apptoken-configuration__status'>
            <FontAwesomeIcon icon="check" className="apptoken-configuration__status-icon--ok" />Kontot är konfigurerat
        </div>
        <div className='apptoken-configuration__status'>
            <FontAwesomeIcon icon="school" className="apptoken-configuration__status-icon" />Tenant ID: {props.appToken.tenant}
        </div>
    </div>);
}

export default AppTokenMicrosoftApplication;