import React from 'react';
import { FormGroup, Label, Input, Card, CardBody, FormFeedback, Button, Collapse, Col, Row, CardHeader } from 'reactstrap';
import AppTokenConfiguration from './AppTokenConfiguration';
import ConfigurationComponent from './ConfigurationComponent';
import TriggerConfiguration from './TriggerConfiguration';

class BaseConfiguration extends ConfigurationComponent {
    propertyName = 'baseModule';

    state = { appTokenInvalid: false, open: true }

    setInvalid(configuration, active) {
        const validation = this.validate(configuration, active);
        this.props.invalid(this.result(validation.length > 0 || this.state.appTokenInvalid));
    }

    validate(baseModule) {
        const validation = [];
        if (!baseModule.title) {
            validation.push('title');
        }
        return validation;
    };

    updateData(newUpdates) {
        var merged = this.mergeWithUpdates(this.props.baseModule, newUpdates);
        this.props.mergeUpdate(this.cleanMerge(merged));
        this.setInvalid(merged);
    }

    cleanMerge(merge) {
        const { importSchoolUnits, exportSchoolUnits, configuration, ...cleanedMerge } = merge;
        return cleanedMerge;
    }

    render() {
        const baseModule = this.mergeWithUpdates(this.props.baseModule, this.props.updates);
        const validationErrors = this.validate(baseModule);
        const disableInput = !this.props.isAdmin;

        return (
            <Card className="base_module edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    Basmodul
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                <Row>
                    <Col sm={this.props.appToken ? "6" : "12"}>
                        <CardBody>
                            <FormGroup check>
                                <div style={({ display: 'grid', gridTemplateColumns: '1fr 1fr' })}>
                                    <Label check>
                                        <Input type="checkbox" name="active" id="active" checked={baseModule.active} disabled={disableInput} onChange={this.updateProperty} /> Aktiv
                                        </Label>
                                        {(baseModule.dryRunEnabled || baseModule.dryRun) && <Label check>
                                        <Input type="checkbox" name="dryRun" id="dryRun" checked={baseModule.dryRun} disabled={disableInput} onChange={this.updateProperty} /> DryRun
                                    </Label>}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label for="title">Titel</Label>
                                <Input type="text" name="title" id="title" placeholder="Titel" value={baseModule.title || ''} disabled={disableInput} onChange={this.updateProperty} invalid={validationErrors.indexOf('title') !== -1} autoComplete="off" />
                                <FormFeedback>Titel måste anges</FormFeedback>
                            </FormGroup>
                            {baseModule.sourceEnvironment &&
                                <FormGroup>
                                    <Label for="sourceEnvironemt">Källsystemets identifierare</Label>
                                    <Input type="text" name="sourceEnvironment" id="sourceEnvironment" value={baseModule.sourceEnvironment} disabled={true} autoComplete="off" />
                                </FormGroup>
                            }
                            {this.props.runnable &&
                                <TriggerConfiguration
                                    moduleTriggers={baseModule.moduleTriggers || []}
                                    disableInput={disableInput}
                                    onUpdateTriggers={triggers => this.updatePropertyValue('moduleTriggers', triggers)}
                                    id={this.props.id}
                                />
                            }

                        </CardBody>
                    </Col>
                    {this.props.appToken && <AppTokenConfiguration
                        appToken={this.props.appToken}
                        active={this.props.active}
                        additionalScopes={this.props.additionalScopes}
                        invalid={(invalid) => this.setState({ appTokenInvalid: invalid }, () => this.setInvalid(baseModule))}
                        disableInput={disableInput}
                    />}
                    </Row>
                </Collapse>
            </Card>
        )
    }
}

export default BaseConfiguration;
