import React from 'react'
import { Link } from 'react-router-dom'
import * as Helper from '../../../../../../util/Helper';
import StatusCode from '../../../../../../util/StatusCode';
import { DnpActivity, CoreDnpActivityListing, CoreDnpExportActivityMapping } from '../../../../../../models/CoreDnpModels';
import { SearchableOptionItem } from '../../../../../../util/UtilityTypes';
import { GetSchoolYear } from './DnpActivitiesUtil';
import { CacheRequestState, RequestState } from '../../../../../../models/RequestState';
import { PageResult } from '../../../../../../models/CoreModels';
import MultiSelectInput from '../../../../../misc/MultiSelectInput';

interface UnmappedDnpActivitiesProps {
    dnpExportId: string
    dnpSchoolUnitId: string
    dnpActivities: RequestState<DnpActivity[]>
    updatedMappedActivities: CoreDnpExportActivityMapping[] 
    onUpdate: (activities: CoreDnpExportActivityMapping[]) => void
    activitiesBySchool: CacheRequestState<PageResult<CoreDnpActivityListing>>
    disabled: boolean
    filter: {
        schoolType?: string
        schoolYear?: number[]
    }
}

const UnmappedDnpActivities = (props: UnmappedDnpActivitiesProps) => {
    const updateActivity = (dnpActivityIds: string[] | undefined | null, flowSyncActivity: CoreDnpActivityListing) => {
        if (props.dnpActivities.code !== StatusCode.COMPLETE) {
            return; // can't update mappings if no dnp activities has been loaded
        }

        const dnpActivities = props.dnpActivities.data
            .filter(x => dnpActivityIds && dnpActivityIds.indexOf(x.id) !== -1);

        const updatedMappings: CoreDnpExportActivityMapping[] = dnpActivities.map(dnpActivity => ({
            dnpExportId: props.dnpExportId,
            dnpSchoolUnitId: props.dnpSchoolUnitId,
            dnpActivityId: dnpActivity.id,
            dnpActivityTitle: dnpActivity.displayName,
            dnpActivitySchoolType: dnpActivity.schoolType,
            flowSyncActivityId: flowSyncActivity.id,
            valid: true,
        }));

        let otherMappings: CoreDnpExportActivityMapping[] = props.updatedMappedActivities
            .filter(x => x.flowSyncActivityId !== flowSyncActivity.id);

        props.onUpdate([...otherMappings, ...updatedMappings])
    };

    const selected = (activityId: string) => {
        return props.updatedMappedActivities &&
            props.updatedMappedActivities
                .filter(x => x.flowSyncActivityId === activityId)
                .map(x => x.dnpActivityId);
    };

    return (<>
        {props.activitiesBySchool.code === StatusCode.COMPLETE && props.activitiesBySchool.data.values.map(a => {
            const selectedDnpActivity = selected(a.id);

            let dnpActivityOptions: SearchableOptionItem<string>[] = [];
            if (props.dnpActivities.code === StatusCode.COMPLETE) {
                dnpActivityOptions = props.dnpActivities.data
                    .filter(da => {
                        //always include previously selected
                        if (selectedDnpActivity.some(x => x === da.id)) {
                            return true;
                        }

                        //apply schooltype filter
                        if (props.filter.schoolType && da.schoolType !== props.filter.schoolType) {
                            return false;
                        }

                        //apply schoolYear filter
                        if (props.filter.schoolYear && props.filter.schoolYear?.length > 0 && !props.filter.schoolYear.includes(da.schoolYear)) {
                            return false;
                        }

                        return true;
                    })
                    .map(da => ({ value: da.id, label: da.displayName }))
            }

            return (
                <div key={a.id} className={(selectedDnpActivity.length ? 'highlight-unmapped ' : '') + "--row sm-right"}>
                    <span data-label="Aktivitet"><Link to={`/activity/${a.id}/information`} target="_blank">{a.displayName}</Link></span>
                    <span data-label="Skola"><Link to={`/schoolunit/${a.schoolUnit.id}/information`} target="_blank">{a.schoolUnit.title}</Link></span>
                    <span data-label="Årskurs">{GetSchoolYear(a.schoolYear)}</span>
                    <span data-label="Startdatum">{Helper.formatDateOnly(a.startDate)}</span>
                    <span data-label="Slutdatum">{Helper.formatDateOnly(a.endDate)}</span>
                    <span data-label="Kurs/Ämne">{a.subjectTitle ? a.subjectTitle : a.courseTitle}</span>
                    <span>
                        <MultiSelectInput
                            items={dnpActivityOptions}
                            onChange={(name, value) => updateActivity(value, a)}
                            name='schoolType'
                            selected={selectedDnpActivity}
                            defaultText='Välj provaktivitet'
                            disabled={props.dnpActivities.code !== StatusCode.COMPLETE || props.disabled}
                        />
                    </span>
                </div>
            );
        })}
    </>);
}

export default UnmappedDnpActivities;
