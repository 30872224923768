import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom'
import * as GroupType from '../../../util/GroupTypeUtil';
import * as Helper from '../../../util/Helper';
import StatusCode from '../../../util/StatusCode';
import { GetGroup, GroupState } from '../../../store/GroupAction';
import TableVertical from '../../misc/TableVertical';
import { CoreStudentGroup } from '../../../models/CoreGroup';
import { RequestState } from '../../../models/RequestState';

interface State {
    groups: GroupState
}

const GroupViewInfo = () => {
    const { groupId } = useParams();
    const dispatch = useDispatch();
    const group = useSelector<State, RequestState<CoreStudentGroup>>(state => state.groups.group);

    useEffect(() => {
        if (groupId !== undefined) {
            GetGroup(groupId)(dispatch);
        }
    }, [dispatch, groupId]);

    const renderGroupInfo = () => {
        let groupInfo = [];
        if (group.code === StatusCode.COMPLETE) {
            const c = group.data;
            groupInfo.push(
                { header: 'Namn', content: c.title },
                { header: 'Aktiv', content: Helper.active(c.active) },
                { header: 'Skola', content: c.schoolUnit && <Link to={`/schoolunit/${c.schoolUnit.id}/information`}> { c.schoolUnit.title }</Link> },
                { header: 'Grupptyp', content: c.studentGroupType ? GroupType.groupTypeFromId(c.studentGroupType.id) : 'Okänd' },
                { header: 'Startdatum', content: Helper.formatDateOnly(c.startDate) },
                { header: 'Slutdatum', content: Helper.formatDateOnly(c.endDate) },
                { header: 'Skapad', content: Helper.formatDate(c.created) },
                { header: 'Ändrad', content: Helper.formatDate(c.modified) },
            )
        }
        return (
            <TableVertical
                header='Information'
                info={groupInfo}
                error={group.code === StatusCode.ERROR}
                searching={group.code === StatusCode.PENDING}
            />
        )
    }

    return (<>
        {renderGroupInfo()}
    </>);
}

export default GroupViewInfo;
