import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useSearchParams, Link } from 'react-router-dom';
import { ActivityState, ListActivities } from '../../store/ActivityAction';
import { getModules, ModuleState } from '../../store/ModuleAction';
import debounce from 'lodash/debounce';
import * as Helper from '../../util/Helper';
import StatusCode from '../../util/StatusCode';
import TableHorizontal from '../misc/TableHorizontal';
import FilterBottomComponent from '../misc/FilterBottomComponent';
import Search from '../misc/Search';
import ActiveToggle from '../misc/ActiveToggle';
import SelectInput from '../misc/SelectInput';
import RequestParser from '../../util/RequestParser';
import { RequestState } from '../../models/RequestState';
import { CoreActivityListing } from '../../models/CoreActivity';
import { PageResult } from '../../models/CoreModels';
import { ModuleTypeInfo } from '../../util/UtilityTypes';
import { InfoObject } from '../misc/TableHorizontalPagedToggle';

interface ActivityListState {
    activities: ActivityState
    module: ModuleState
}

const ActivityList = () => {
    const dispatch = useDispatch();

    const [search, setSearch] = useState<string>('');
    const [searchParams, setSearchParams] = useSearchParams();

    const activities = useSelector<ActivityListState, RequestState<PageResult<CoreActivityListing>>>(state => state.activities.activities);
    const modules = useSelector<ActivityListState, ModuleTypeInfo[]>(state => Helper.distinctSchoolAdminModuleTypes(state.module.modules));

    const filter = RequestParser.parseFilterModuleSearchPageRequest(searchParams);

    useEffect(() => {
        if (filter.search) {
            setSearch(filter.search);
        }
        ListActivities(filter)(dispatch);
        getModules()(dispatch);
    }, [dispatch]);

    useEffect(() => {
        ListActivities(filter, true)(dispatch);
        window.scrollTo(0, 0);
    }, [searchParams]);

    const updateFilter = (name: string, value?: number | string | boolean | null) => {
        if (name !== 'page') {
            searchParams.set('page', '1');
        }

        if (value !== undefined && value !== '' && value !== null) {
            if (typeof (value) === 'string') {
                searchParams.set(name, value);
            } else {
                searchParams.set(name, value.toString());
            }
        } else {
            searchParams.delete(name);
        }

        setSearchParams(searchParams);
    }

    const debouncedSearch = useCallback(
        debounce((search: string) => {
            updateFilter('search', search);
        }, 300), [searchParams]);


    const onSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        const search = e.target.value;
        setSearch(search);
        debouncedSearch(search);
    }

    const clearFilter = () => {
        setSearch('');
        setSearchParams([]);
    }

    const mapActivities = (): InfoObject[] => {
        if (activities.code !== StatusCode.COMPLETE) {
            return [];
        }

        return activities.data.values.map(a => ({
            id: a.id,
            data: [
                { value: <Link to={`/activity/${a.id}/information`}>{a.displayName}</Link> },
                { value: a.schoolUnit && <Link to={`/schoolunit/${a.schoolUnit.id}/information` }>{a.schoolUnit.title}</Link>},
                { value: a.groupAssignments?.reduce((acc, ga) => acc + ga.studentCount, 0) ?? 0 },
                { value: a.teacherCount },
                { value: Helper.active(a.active) },
                { value: Helper.formatDate(a.modified) },
            ]
        }))
    }

    const renderFilter = () => (
        <Row>
            <Col sm="6">
                <Search value={search} onChange={onSearch} placeholder="Sök aktivitet" />
            </Col>
            <Col sm="4">
                <SelectInput
                    items={modules.map(m => ({ value: m.moduleTypeName, text: `Filtrera på ${m.moduleTypeDisplayName}` }))}
                    onChange={(name, value) => updateFilter(name, value)}
                    name='moduleTypeName'
                    selected={filter.moduleTypeName}
                    defaultText='Filtrera på modultyp'
                />
            </Col>
            <Col sm="2">
                <ActiveToggle
                    onlyActive={filter.onlyActive ?? true}
                    update={(active) => updateFilter('onlyActive', active)}
                    disabled={activities.code === StatusCode.PENDING}
                />
            </Col>
        </Row>
    );

    const renderBody = () => {
        return (
            <TableHorizontal
                header={[{ value: 'Aktivitet' }, { value: 'Skola' }, { value: 'Antal elever' }, { value: 'Antal lärare' }, { value: 'Aktiv' }, { value: 'Ändrad' }]}
                info={mapActivities()}
                error={activities.code === StatusCode.ERROR}
                searching={activities.code === StatusCode.PENDING}
                displayName="Aktiviteter"
                noResMsg="Inga aktiviteter hittades"
            />
        )
    }

    return (
        <div className="container">
            <div className="header--fixed">
                <h1>Aktiviteter</h1>
                {renderFilter()}
            </div>
            <div className="body--scroll padding-40">
                {renderBody()}
            </div>

            <FilterBottomComponent
                requestState={activities}
                clearFilter={clearFilter}
                updateFilter={(value) => updateFilter('page', value)}
            />

        </div>
    )
}

export default ActivityList;
