import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import MsalApi from './MsalApi';
import GoogleApi from './GoogleApi';
import store from '../store/store';
import { AuthActionType } from '../store/AuthAction';

const ATTACHMENT_FILENAME_REGEX = /filename=([^;]*);/;

// NOTE: Disgusting hack to support IE11 blob download.
declare global {
    interface Navigator {
        msSaveOrOpenBlob: (blob: Blob, filename: string) => void
    }
}

interface BlobEventTarget extends EventTarget {
    response?: Blob
}

const getToken = async () => {
    const type = localStorage.getItem('AuthAuthority');
    switch (type) {
        case 'google':
            try {
                const token = await GoogleApi.token();
                return token;
            } catch (err) {
                console.warn("User must sign in");
                store.dispatch({ type: AuthActionType.RESET_AUTH_STATUS });
                return;
            }
        case 'microsoft':
            try {
                const tokenResponse = await MsalApi.idTokenSilent();
                return tokenResponse.idToken.rawIdToken;
            }
            catch (err) {
                console.warn("User must sign in");
                store.dispatch({ type: AuthActionType.RESET_AUTH_STATUS });
                return;
            }
        default:
            console.warn("No auth authority has been chosen");
            store.dispatch({ type: AuthActionType.RESET_AUTH_STATUS });
            return;
    }
};

class InstanceClient {

    static get<T>(url: string, params: any = undefined): Promise<AxiosResponse<T, any>> {
        return InstanceClient.send<T, any>({
            method: 'GET',
            url: url,
            params: params
        });
    }

    static post<T, D>(url: string, body: D | undefined = undefined): Promise<AxiosResponse<T, D>> {
        return InstanceClient.send<T, D>({
            method: 'POST',
            url: url,
            data: body
        });
    }

    static put<T, D>(url: string, body: D | undefined = undefined): Promise<AxiosResponse<T, D>> {
        return InstanceClient.send<T, D>({
            method: 'PUT',
            url: url,
            data: body
        });
    }

    static patch<T, D>(url: string, params: any = undefined, body: D | undefined = undefined): Promise<AxiosResponse<T, D>> {
        return InstanceClient.send<T, D>({
            method: 'PATCH',
            url: url,
            data: body,
            params: params
        });
    }

    static delete<T, D>(url: string, params: any = undefined, body: D | undefined = undefined): Promise<AxiosResponse<T, D>> {
        return InstanceClient.send<T, D>({
            method: 'DELETE',
            url: url,
            data: body,
            params: params
        });
    }

    static download = async (url: string, defaultName: string = 'file') => {
        var token = await getToken();

        return new Promise((resolve, reject) => {
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";

            const xhr = new XMLHttpRequest();
            xhr.open("GET", url, true);
            xhr.responseType = 'blob';
            xhr.setRequestHeader('Authorization', `Bearer ${token}`);
            xhr.onload = function (e) {
                if (!e.currentTarget) {
                    return reject("Current target of event does not exist");
                }

                const blobTarget = e.currentTarget as BlobEventTarget;
                if (!blobTarget.response) {
                    return reject("Current event target does not contain a blob response.");
                }

                if (xhr.status !== 200) {
                    return reject('Server response was ' + xhr.status);
                }
                const disposition = xhr.getResponseHeader('Content-Disposition');
                if (!disposition || disposition.indexOf('attachment') === -1) {
                    return reject('Response did not contain the Content-Disposition header.');
                }

                let filename = "";
                const matches = ATTACHMENT_FILENAME_REGEX.exec(disposition);
                if (matches !== null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, '');
                } else {
                    filename = defaultName;
                }

                if (window.navigator.msSaveOrOpenBlob) {
                    // IE11
                    window.navigator.msSaveOrOpenBlob(blobTarget.response, filename);
                } else {
                    // Google chome, Firefox, ....
                    var url = (window.URL || window.webkitURL).createObjectURL(blobTarget.response);
                    a.href = url;
                    a.download = filename;
                    a.click();
                    window.URL.revokeObjectURL(url);
                }

                resolve(filename);
            };

            xhr.send();
        });
    };

    static async send<T, D>(request: AxiosRequestConfig<D>): Promise<AxiosResponse<T, D>> {
        var token = await getToken();

        if (token) {
            if (!request.headers) {
                request.headers = {};
            }

            request.headers.Authorization = `Bearer ${token}`;
        }

        return await axios<T, AxiosResponse<T, D>, D>(request);
    }
}

export default InstanceClient;