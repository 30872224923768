import React, { ReactNode } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import StatusCode from '../../util/StatusCode';
import SaveStatus from '../misc/SaveStatus';

interface ModalComponentProps {
    header: ReactNode
    cancel: string
    size?: string
    isOpen?: boolean
    confirm?: string
    disabled?: boolean
    toggleModal?: React.MouseEventHandler<any> // NOTE: Try to remove 'any' type from handers
    update?: React.MouseEventHandler<HTMLButtonElement>
    saveStatus?: StatusCode,
    successMsg?: string, 
}

type ModalComponentPropsWithChildren = React.PropsWithChildren<ModalComponentProps>

const ModalComponent: React.FC<ModalComponentPropsWithChildren> = (props) => {
    return (
        <Modal size={props.size} isOpen={props.isOpen} toggle={props.toggleModal} scrollable>
            <ModalHeader toggle={props.toggleModal}>
                {props.header}
            </ModalHeader>
            <ModalBody>
                {props.children}
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.toggleModal}>{props.cancel}</Button>
                {props.update && <Button disabled={props.disabled} onClick={props.update}>{props.confirm}</Button>}
                {props.saveStatus &&
                    <SaveStatus
                        saveStatus={props.saveStatus}
                        successMsg={props.successMsg}
                    />}
            </ModalFooter>
        </Modal>
    )
}

export default ModalComponent;
