import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { ListSchoolUnits, SchoolUnitState } from '../../store/SchoolAction';
import { getModules, ModuleState } from '../../store/ModuleAction';
import debounce from 'lodash/debounce';
import * as Helper from '../../util/Helper';
import ActiveToggle from '../misc/ActiveToggle';
import StatusCode from '../../util/StatusCode';
import TableHorizontal from '../misc/TableHorizontal';
import FilterBottomComponent from '../misc/FilterBottomComponent';
import Search from '../misc/Search';
import SelectInput from '../misc/SelectInput';
import RequestParser from '../../util/RequestParser';
import { RequestState } from '../../models/RequestState';
import { CoreSchoolUnit } from '../../models/CoreSchoolUnit';
import { PageResult } from '../../models/CoreModels';
import { ModuleTypeInfo } from '../../util/UtilityTypes';
import { InfoObject } from '../misc/TableHorizontalPagedToggle';

interface SchoolUnitListState {
    schools: SchoolUnitState
    module: ModuleState
}

const SchoolUnitList = () => {
    const dispatch = useDispatch();

    const [search, setSearch] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    const schools = useSelector<SchoolUnitListState, RequestState<PageResult<CoreSchoolUnit>>>(state => state.schools.schools);
    const modules = useSelector<SchoolUnitListState, ModuleTypeInfo[]>(state => Helper.distinctSchoolAdminModuleTypes(state.module.modules));

    var filter = RequestParser.parseFilterModuleSearchPageRequest(searchParams);

    useEffect(() => {
        if (filter.search) {
            setSearch(filter.search);
        }
        ListSchoolUnits(filter)(dispatch);
        getModules()(dispatch);
    }, [dispatch]);

    useEffect(() => {
        ListSchoolUnits(filter, true)(dispatch);
        window.scrollTo(0, 0);
    }, [dispatch, searchParams]);

    const debouncedSearch = useCallback(
        debounce(search => {
            updateFilter('search', search);
        }, 300), [searchParams]);

    const onSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        let search = e.target.value;
        setSearch(search);
        debouncedSearch(search);
    };

    const updateFilter = (name: string, value?: number | string | boolean | null) => {
        if (name !== 'page') {
            searchParams.set('page', '1');
        }

        if (value !== undefined && value !== '' && value !== null) {
            if (typeof (value) === 'string') {
                searchParams.set(name, value);
            } else {
                searchParams.set(name, value.toString());
            }
        } else {
            searchParams.delete(name);
        }

        setSearchParams(searchParams);
    }

    const clearFilter = () => {
        setSearch('');
        setSearchParams([]);
    };

    const mapSchoolUnits = (): InfoObject[] => {
        if (schools.code !== StatusCode.COMPLETE) {
            return [];
        }

        return schools.data.values.map(s => ({
            id: s.id,
            data: [
                { value: <Link to={`/schoolunit/${s.id}/information`}>{s.title}</Link> },
                { value: Helper.schoolTypes(s.schoolTypes) },
                { value: Helper.active(s.active) },
                { value: Helper.formatDate(s.created) },
                { value: Helper.formatDate(s.modified) },
            ]
        }));
    }

    const renderFilter = () => (
        <Row>
            <Col sm="6">
                <Search value={search} onChange={onSearch} placeholder="Sök skolor" />
            </Col>
            <Col sm="4">
                <SelectInput
                    items={modules.map(m => ({ value: m.moduleTypeName, text: `Filtrera på ${m.moduleTypeDisplayName}` }))}
                    onChange={(name, value) => updateFilter(name, value)}
                    name='moduleTypeName'
                    selected={filter.moduleTypeName}
                    defaultText='Filtrera på modultyp'
                />
            </Col>
            <Col sm="2">
                <ActiveToggle
                    onlyActive={filter?.onlyActive ?? true}
                    update={(active) => updateFilter('onlyActive', active)}
                    disabled={schools.code === StatusCode.PENDING}
                />
            </Col>
        </Row>
    );

    const renderSchoolUnits = () => {
        const schoolsInfo = mapSchoolUnits();
        return (
            <TableHorizontal
                header={[{ value: 'Namn' }, { value: 'Skoltyp' }, { value: 'Aktiv' }, { value: 'Skapad' }, { value: 'Ändrad' }]}
                info={schoolsInfo}
                error={schools.code === StatusCode.ERROR}
                searching={schools.code === StatusCode.PENDING}
                displayName="Skolor"
                noResMsg="Inga skolor hittades"
            />
        )
    }

    return (
        <div className="container">
            <div className="header--fixed">
                <h1>Skolor</h1>
                {renderFilter()}
            </div>

            <div className="body--scroll padding-40">
                {renderSchoolUnits()}
            </div>

            <FilterBottomComponent
                clearFilter={clearFilter}
                updateFilter={(value) => updateFilter('page', value)}
                requestState={schools}
            />
        </div>
    )
}

export default SchoolUnitList;
