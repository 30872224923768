import React from 'react';
import { FormGroup, Label, Input, Card, CardHeader, CardBody, Collapse, Button, FormFeedback } from 'reactstrap';
import ConfigurationComponent from '../ConfigurationComponent';
import SchoolSoftFilterInput from './components/SchoolSoftFilterInput';

class SchoolSoftSchedule extends ConfigurationComponent {
    state = { open: true }

    validate(configuration, active) {
        const validation = [];

        if (active) {
            if (!configuration.webApp || configuration.webApp === "") {
                validation.push('webApp');
            }
            if (configuration.selectedEmail === 0) {
                validation.push('selectedEmail');
            }
            if (configuration.eventNaming === 0) {
                validation.push('eventNaming');
            }
        }
        return validation;
    }

    render() {
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);
        const validation = this.validate(configuration, this.props.active);
        
        return (
            <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    {this.props.moduleDisplayName}
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>
                        <div style={{ margin: '1rem 0' }}>
                            <FormGroup>
                                <Label for="baseAddress">Webapp</Label>
                                <Input type="text" name="webApp" id="webApp" placeholder="Webapp" value={configuration.webApp || ''} disabled={this.props.disableInput} onChange={this.updateProperty} invalid={validation.indexOf('webApp') !== -1} autoComplete="off" />
                                <FormFeedback>Webapp måste anges för att kunna aktivera importmodulen</FormFeedback>
                            </FormGroup>
                            <FormGroup tag="fieldset">
                                <Label>Läs epostaddress ifrån:</Label>
                                <FormGroup check>
                                    <Input type="radio" id="type1" name="selectedEmail" value={1} checked={configuration.selectedEmail === 1} disabled={this.props.disableInput} onChange={this.updateProperty} invalid={validation.indexOf('selectedEmail') !== -1} />
                                    <Label for="type1" check>Epost</Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Input type="radio" id="type2" name="selectedEmail" value={2} checked={configuration.selectedEmail === 2} disabled={this.props.disableInput} onChange={this.updateProperty} invalid={validation.indexOf('selectedEmail') !== -1} />
                                    <Label for="type2" check>Microsoft Teams konto</Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Input type="radio" id="type3" name="selectedEmail" value={3} checked={configuration.selectedEmail === 3} disabled={this.props.disableInput} onChange={this.updateProperty} invalid={validation.indexOf('selectedEmail') !== -1} />
                                    <Label for="type3" check>Google Classroom konto</Label>
                                </FormGroup>
                                <FormFeedback>Utläsning av epostaddress måste konfigureras för att kunna aktivera importmodulen</FormFeedback>
                            </FormGroup>
                            <FormGroup tag="fieldset">
                                <Label>Namnsätt schemaposition enligt:</Label>
                                <FormGroup check>
                                    <Input type="radio" id="entype1" name="eventNaming" value={1} checked={configuration.eventNaming === 1} disabled={this.props.disableInput} onChange={this.updateProperty} invalid={validation.indexOf('eventNaming') !== -1} />
                                    <Label for="entype1" check>Lektionens kurs/ämne</Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Input type="radio" id="entype2" name="eventNaming" value={2} checked={configuration.eventNaming === 2} disabled={this.props.disableInput} onChange={this.updateProperty} invalid={validation.indexOf('eventNaming') !== -1} />
                                    <Label for="entype2" check>Gruppernas namn</Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Input type="radio" id="entype3" name="eventNaming" value={3} checked={configuration.eventNaming === 3} disabled={this.props.disableInput} onChange={this.updateProperty} invalid={validation.indexOf('eventNaming') !== -1} />
                                    <Label for="entype3" check>Gruppernas namn (Lektionens kurs/ämne)</Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Input type="radio" id="entype4" name="eventNaming" value={4} checked={configuration.eventNaming === 4} disabled={this.props.disableInput} onChange={this.updateProperty} invalid={validation.indexOf('eventNaming') !== -1} />
                                    <Label for="entype4" check>Lektionens kurs/ämne (Gruppernas namn)</Label>
                                </FormGroup>
                                <FormFeedback>Namnsättning av schemaposition måste konfigureras för att kunna aktivera importmodulen</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <h5>Filter</h5>
                                <p>Filtrera bort lektioner baserat på ämne.</p>
                                <SchoolSoftFilterInput name="eventFilters" filters={configuration.eventFilters} onChange={this.updateProperty} disabled={this.props.disableInput} />
                            </FormGroup>
                        </div>
                    </CardBody>
                </Collapse>
            </Card>)
    }
}

export default SchoolSoftSchedule;
