import React from 'react';
import { OnChangeCallback } from '../../../../../../util/UtilityTypes'
import BoolInput from '../../../../../misc/BoolInput';
import IntInput from '../../../../../misc/IntInput';
import EnumInput from '../../../../../misc/EnumInput';
import TextInput from '../../../../../misc/TextInput';
import * as GoogleUser from '../../../../../../util/GoogleUserExportTypes';
import { update } from '../../../../../../util/Helper';

interface GoogleUserPasswordPolicyProps {
    passwordPolicy: GoogleUser.PasswordPolicy
    configurationType: string
    disabled?: boolean
    validation: string[]
    onChange: OnChangeCallback<GoogleUser.PasswordPolicy>
}

const GoogleUserPasswordPolicy: React.FC<GoogleUserPasswordPolicyProps> = (props) => {
    const renderTypeContent = () => {
        switch (props.passwordPolicy.type) {
            case GoogleUser.PasswordPolicyType.STATIC: {
                return (<>
                    <hr />
                    <h5>Inställningar för statiskt lösenord</h5>
                    <TextInput
                        title="Statiskt lösenord"
                        name={`${props.configurationType}-passwordPolicy.staticValue`}
                        value={props.passwordPolicy.staticValue}
                        updateValue={(n, v) => update(n, props.passwordPolicy, { staticValue: v }, props.onChange)}
                        disabled={props.disabled}
                        invalidFeedback="Lösenordet behöver innehålla minst 8 tecken inkl ev. personnummer"
                        invalid={props.validation.indexOf(`${props.configurationType}.passwordPolicy.staticValue`) !== -1}
                    />
                    <BoolInput
                        title='Använd månad och dag från personnummer'
                        name={`${props.configurationType}-passwordPolicy.useMonthAndDayFromSocialnumber`}
                        value={props.passwordPolicy.useMonthAndDayFromSocialnumber}
                        updateValue={(n, v) => update(n, props.passwordPolicy, { useMonthAndDayFromSocialnumber: v }, props.onChange)}
                        disabled={props.disabled}
                    />
                </>);
            };
            case GoogleUser.PasswordPolicyType.RANDOM: {
                return (<>
                    <hr />
                    <h5>Inställningar för slumpmässigt lösenord</h5>
                    <IntInput
                        title="Minsta längd"
                        name={`${props.configurationType}-passwordPolicy.minLength`}
                        value={props.passwordPolicy.minLength}
                        disabled={props.disabled}
                        updateValue={(n, v) => update(n, props.passwordPolicy, { minLength: v }, props.onChange)}
                        invalidFeedback="Antal tecken måste vara minst 8 tecken"
                        invalid={props.validation.indexOf(`${props.configurationType}.passwordPolicy.minLength`) !== -1}
                    />
                    <IntInput
                        title="Max längd"
                        name={`${props.configurationType}-passwordPolicy.maxLength`}
                        value={props.passwordPolicy.maxLength}
                        disabled={props.disabled}
                        updateValue={(n, v) => update(n, props.passwordPolicy, { maxLength: v }, props.onChange)}
                        invalidFeedback="Antal tecken måste vara mer än minsta längd och max 100 tecken"
                        invalid={props.validation.indexOf(`${props.configurationType}.passwordPolicy.maxLength`) !== -1}
                    />
                    <EnumInput
                        title='Password character set'
                        name={`${props.configurationType}-passwordPolicy.passwordCharacterSets`}
                        value={props.passwordPolicy.passwordCharacterSets}
                        options={GoogleUser.passwordCharacterSets}
                        onChange={(n, v) => update(n, props.passwordPolicy, { passwordCharacterSets: v }, props.onChange)}
                        disabled={props.disabled}
                        multi={true}
                        invalidFeedback="Ange minst två val"
                        invalid={props.validation.indexOf(`${props.configurationType}.passwordPolicy.passwordCharacterSets`) !== -1}
                    />
                </>);
            };
            default: return null;
        }
    }

    return (<>
        <BoolInput
            title='Byt lösenord vid första inloggning'
            name={`${props.configurationType}-passwordPolicy.forcePasswordChangeOnNextLogin`}
            value={props.passwordPolicy.forcePasswordChangeOnNextLogin}
            updateValue={(n, v) => update(n, props.passwordPolicy, { forcePasswordChangeOnNextLogin: v }, props.onChange)}
            disabled={props.disabled}
        />
        <EnumInput
            title='Lösenordstyp'
            name={`${props.configurationType}-passwordPolicy.type`}
            value={props.passwordPolicy.type}
            options={GoogleUser.passwordPolicyTypeOptions}
            onChange={(n, v) => update(n, props.passwordPolicy, { type: v }, props.onChange)}
            disabled={props.disabled}
        />
        {renderTypeContent()}
    </>);
}

export default GoogleUserPasswordPolicy;
