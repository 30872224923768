import React, { useEffect, useState } from 'react';
import Loading from '../../misc/Loading';
import { useDispatch, useSelector } from 'react-redux';
import { validateAppToken, clearAppToken, AppTokenState, setAppToken } from '../../../store/AppTokenAction';
import AppTokenGoogleServiceAccount from './appToken/AppTokenGoogleServiceAccount';
import AppTokenMicrosoftApplication from './appToken/AppTokenMicrosoftApplication';
import AppTokenMicrosoftDelegated from './appToken/AppTokenMicrosoftDelegated';
import { CardBody, CardTitle, Col, Button } from 'reactstrap';
import AppTokenModal from './AppTokenModal';
import { AppTokenType, CoreAppToken, CoreAppTokenValidation } from '../../../models/CoreAppToken';
import { RequestState } from '../../../models/RequestState';
import StatusCode from '../../../util/StatusCode';

const errorCodes = {
    1: 'AppTokenId missing',
    2: 'Tenant missing',
    3: 'Code missing',

    400: 'Bad Request',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    500: 'Internal Server Error'
}

interface AppTokenRootState {
    token: AppTokenState
}

interface AppTokenConfigurationProps {
    active: boolean,
    appToken: CoreAppToken,
    additionalScopes?: string[],
    disableInput: boolean,
    invalid: (invalid: boolean) => void
}

const AppTokenConfiguration = (props: AppTokenConfigurationProps) => {

    const [clearAppTokenModalOpen, setClearAppTokenModalOpen] = useState(false);

    const appTokenValidationRequestState = useSelector<AppTokenRootState, RequestState<CoreAppTokenValidation>>(x => x.token.appTokenValidation);
    const appTokenRequestState = useSelector<AppTokenRootState, RequestState<CoreAppToken>>(x => x.token.appToken);

    const dispatch = useDispatch();

    useEffect(() => {
        setAppToken(props.appToken)(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (appTokenRequestState.code === StatusCode.COMPLETE) {
            revalidateAppToken(props.additionalScopes);
        }
    }, [appTokenRequestState, props.additionalScopes])

    useEffect(() => {
        const validation = validate();
        props.invalid(validation.length > 0);
    }, [appTokenValidationRequestState, props.active])

    const revalidateAppToken = (additionalScopes?: string[]) => {
        validateAppToken(props.appToken.id, additionalScopes)(dispatch);
    }

    const validate = () => {
        const validation = [];

        if (appTokenValidationRequestState.code === StatusCode.COMPLETE && appTokenValidationRequestState.data.valid === false && props.active) {
            validation.push('inactiveAppToken');
        }

        return validation;
    };

    const removeAppToken = async () => {
        await clearAppToken(props.appToken.id)(dispatch)
        setClearAppTokenModalOpen(false);
    }

    const renderClearAppToken = () => {
        return (
            <AppTokenModal
                isOpen={clearAppTokenModalOpen}
                toggleModal={() => setClearAppTokenModalOpen(!clearAppTokenModalOpen)}
                header="Vill du verkligen återställa åtkomstkonfigurationen?"
                body="Om flera moduler använder samma åtkomstkonfiguration så slutar dessa att fungera tills dess att åtkomstkonfigurationen konfigureras på nytt."
                confirm={removeAppToken}
                disabled={props.disableInput}
            />
        )
    }

    const information = () => {

        if (appTokenValidationRequestState.code === StatusCode.ERROR) {
            return (
                <div>
                    <p>Misslyckades</p>
                    <p>Servern svarade med '{appTokenValidationRequestState.error.code}'</p>
                    <Button className="token_btn" onClick={() => revalidateAppToken()}>Testa igen</Button>
                </div>
            )
        }

        if (appTokenRequestState.code === StatusCode.ERROR) {
            return (
                <div>
                    <p>Misslyckades</p>
                    <p>Servern svarade med '{appTokenRequestState.error.code}'</p>
                    <Button className="token_btn" onClick={() => revalidateAppToken()}>Testa igen</Button>
                </div>
            )
        }

        if (appTokenRequestState.code === StatusCode.NONE || appTokenRequestState.code === StatusCode.PENDING) {
            return (<Loading />);
        }

        if (appTokenValidationRequestState.code === StatusCode.NONE || appTokenValidationRequestState.code === StatusCode.PENDING) {
            return (<Loading />);
        }

        const appToken = appTokenRequestState.data;
        let type = appToken.appTokenType;
        if (type === AppTokenType.GOOGLE_SERVICE_ACCOUNT) {
            return (
                <AppTokenGoogleServiceAccount
                    appToken={appToken}
                    disableInput={props.disableInput}
                />
            )
        } else if (type === AppTokenType.MICROSOFT_APPLICATION) {
            return (
                <AppTokenMicrosoftApplication
                    appToken={appToken}
                    disableInput={props.disableInput}
                />
            )
        } else if (type === AppTokenType.MICROSOFT_DELEGATED) {
            return (
                <AppTokenMicrosoftDelegated
                    appToken={appToken}
                    additionalScopes={props.additionalScopes}
                    disableInput={props.disableInput}
                />
            )
        } else {
            return <div>Typen finns inte</div>
        }
    }

    const validation = validate();
    return (
        <Col sm="6" className="apptoken-configuration">
            <CardBody>
                <span style={{ display: 'flex' }}><CardTitle tag="h5">Åtkomstkonfiguration</CardTitle>
                    {!props.disableInput && <p className="p-xs p-link ml-2" onClick={() => setClearAppTokenModalOpen(true)}>Återställ</p>}</span>
                {validation.indexOf('inactiveAppToken') !== -1 &&
                    <div className='push--bottom d-block invalid-feedback'>Åtkomstkonfigurationen måste slutföras för att kunna aktivera modulen</div>
                }
                {information()}
                {clearAppTokenModalOpen && renderClearAppToken()}
            </CardBody>
        </Col>
    )

}

export default AppTokenConfiguration;
