import React from 'react';
import EnumInput from '../../../../misc/EnumInput';
import { TeamsFixArchivedSettings, GroupSettings, SharePointSiteSettings, ArchivedSettingBehaviour } from '../../../../../models/TeamsFixTypes';

interface TeamsFixArchivedSettingsProps {
    teamsFixSettings: TeamsFixArchivedSettings
    disabled: boolean
    onChange: (x: TeamsFixArchivedSettings) => void
    name: string
}

const TeamsFixArchivedSettingsComponent: React.FC<TeamsFixArchivedSettingsProps> = (props) => {
    const settings = props.teamsFixSettings;

    const notifyChange = (change: Partial<TeamsFixArchivedSettings>) => props.onChange({
        ...settings,
        ...change
    });

    return (<>
        <EnumInput
            title="När ska inställningarna sättas"
            name={props.name + ".settingsBehaviour"}
            value={settings.settingBehaviour}
            options={ArchivedSettingBehaviour}
            onChange={(n, v) => notifyChange({ settingBehaviour: v })}
            disabled={props.disabled}
            multi={false}
        />

        <EnumInput
            title="SharePoint-inställningar"
            name={props.name + ".sharePointSiteSettings"}
            value={settings.sharePointSiteSettings}
            options={SharePointSiteSettings}
            onChange={(n, v) => notifyChange({ sharePointSiteSettings: v })}
            disabled={props.disabled}
            multi={true}
        />

        <EnumInput
            title="Gruppinställningar"
            name={props.name + ".groupSettings"}
            value={settings.groupSettings}
            options={GroupSettings}
            onChange={(n, v) => notifyChange({ groupSettings: v })}
            disabled={props.disabled}
            multi={true}
        />
    </>);
}

export default TeamsFixArchivedSettingsComponent;
