import { Dispatch } from 'react';
import { Action, Reducer } from 'redux';
import { CoreGroupActivityListing, CoreGroupListing, CoreGroupUserListing, CoreStudentGroup } from '../models/CoreGroup';
import { PageResult } from '../models/CoreModels';
import { CoreTracking } from '../models/CoreTracking';
import { GroupSearchPageRequest, SearchPageRequest } from '../models/Requests';
import { CacheRequestState, ExtendedRequestState, RequestState } from '../models/RequestState';
import InstanceClient from '../services/InstanceClient';
import StatusCode from '../util/StatusCode';
import store from './store';

enum GroupActionType {
    GET_GROUPS = 'GET_GROUPS',
    SET_GROUPS_FILTER = 'SET_GROUPS_FILTER',
    GET_GROUPS_UPDATE = 'SET_GROUPS_UPDATE',
    GET_GROUP = 'GET_GROUP',
    GET_GROUP_STUDENTS = 'GET_GROUP_STUDENTS',
    GET_GROUP_ASSIGNMENTROLES = 'GET_GROUP_ASSIGNMENTROLES',
    GET_GROUP_ACTIVITIES = 'GET_GROUP_ACTIVITIES',
    GET_GROUP_TRACKINGS = 'GET_GROUP_TRACKINGS',
}

export type GroupUserRequestState = ExtendedRequestState<PageResult<CoreGroupUserListing>, { filter: Partial<SearchPageRequest> }>
export type GroupActivityRequestState = ExtendedRequestState<PageResult<CoreGroupActivityListing>, { filter: Partial<SearchPageRequest> }>

export interface GroupState {
    groups: CacheRequestState<PageResult<CoreGroupListing>>
    group: RequestState<CoreStudentGroup>
    groupTrackings: RequestState<CoreTracking[]>
    students: GroupUserRequestState
    assignmentRoles: GroupUserRequestState
    activities: GroupActivityRequestState
    filter: Partial<GroupSearchPageRequest>
}

interface Group_ListGroupsBase extends Action<GroupActionType> {
    type: GroupActionType.GET_GROUPS,
    groups: RequestState<PageResult<CoreGroupListing>>
}

interface Group_ListGroupsFilter extends Action<GroupActionType> {
    type: GroupActionType.SET_GROUPS_FILTER,
    filter: Partial<GroupSearchPageRequest>
}

interface Group_ListGroupsUpdate extends Action<GroupActionType> {
    type: GroupActionType.GET_GROUPS_UPDATE
}

type Group_ListGroups = Group_ListGroupsBase | Group_ListGroupsFilter | Group_ListGroupsUpdate;

interface Group_GetGroup extends Action<GroupActionType> {
    type: GroupActionType.GET_GROUP
    group: RequestState<CoreStudentGroup>
}

interface Group_GetTrackings extends Action<GroupActionType> {
    type: GroupActionType.GET_GROUP_TRACKINGS
    groupTrackings: RequestState<CoreTracking[]>
}

interface Group_GetStudents extends Action<GroupActionType> {
    type: GroupActionType.GET_GROUP_STUDENTS
    students: GroupUserRequestState
}

interface Group_GetAssignmentRoles extends Action<GroupActionType> {
    type: GroupActionType.GET_GROUP_ASSIGNMENTROLES
    assignmentRoles: GroupUserRequestState
}

interface Group_GetActivities extends Action<GroupActionType> {
    type: GroupActionType.GET_GROUP_ACTIVITIES
    activities: GroupActivityRequestState
}

type GroupAction = Group_ListGroups
    | Group_GetGroup
    | Group_GetTrackings
    | Group_GetStudents
    | Group_GetAssignmentRoles
    | Group_GetActivities;

export const GetGroups = (filter: Partial<GroupSearchPageRequest> = {}, update: boolean = false) => (dispatch: Dispatch<Group_ListGroups>) => {
    dispatch({ type: GroupActionType.SET_GROUPS_FILTER, filter });
    if (update) {
        dispatch({ type: GroupActionType.GET_GROUPS_UPDATE });
    }

    const url = '/api/core/group';
    InstanceClient.get<PageResult<CoreGroupListing>>(url, filter)
        .then(res => {
            dispatch({
                type: GroupActionType.GET_GROUPS,
                groups: {
                    code: StatusCode.COMPLETE,
                    data: res.data,
                }
            })
        })
        .catch(error =>
            dispatch({
                type: GroupActionType.GET_GROUPS,
                groups: { code: StatusCode.ERROR, error: error }
            })
        )
};

export const GetGroup = (id: string) => (dispatch: Dispatch<Group_GetGroup>) => {
    const storeKey = store.getState().groups.group?.key;
    if (id === storeKey) {
        return;
    }

    dispatch({
        type: GroupActionType.GET_GROUP,
        group: { code: StatusCode.PENDING },
    });
    const url = `/api/core/group/${id}`;
    InstanceClient.get<CoreStudentGroup>(url)
        .then(res => {
            dispatch({
                type: GroupActionType.GET_GROUP,
                group: { code: StatusCode.COMPLETE, data: res.data, key: id },
            })
        })
        .catch(error =>
            dispatch({
                type: GroupActionType.GET_GROUP,
                group: { code: StatusCode.ERROR, error }
            })
        )
};

export const GetGroupTrackings = (id: string) => (dispatch: Dispatch<Group_GetTrackings>) => {
    const storeKey = store.getState().groups.groupTrackings?.key;
    if (id === storeKey) {
        return;
    }
    dispatch({
        type: GroupActionType.GET_GROUP_TRACKINGS,
        groupTrackings: { code: StatusCode.PENDING }
    })
    InstanceClient.get<CoreTracking[]>(`/api/core/group/${id}/trackings`)
        .then(res =>
            dispatch({
                type: GroupActionType.GET_GROUP_TRACKINGS,
                groupTrackings: { code: StatusCode.COMPLETE, data: res.data, key: id }
            })
        )
        .catch(error =>
            dispatch({
                type: GroupActionType.GET_GROUP_TRACKINGS,
                groupTrackings: { code: StatusCode.ERROR, error }
            })
        );
};

export const GetGroupStudents = (id: string, filter: Partial<SearchPageRequest> = {}) => (dispatch: Dispatch<Group_GetStudents>) => {
    const storeKey = store.getState().groups.students?.key;
    const key = id + '-' + filter.onlyActive + '-' + filter.page;
    if (key === storeKey) {
        return;
    }

    dispatch({
        type: GroupActionType.GET_GROUP_STUDENTS,
        students: { code: StatusCode.PENDING, filter },
    });
    const url = `/api/core/group/${id}/students`;
    InstanceClient.get<PageResult<CoreGroupUserListing>>(url, filter)
        .then(res => {
            dispatch({
                type: GroupActionType.GET_GROUP_STUDENTS,
                students: {
                    code: StatusCode.COMPLETE,
                    data: res.data,
                    filter,
                    key,
                },
            })
        })
        .catch(error =>
            dispatch({
                type: GroupActionType.GET_GROUP_STUDENTS,
                students: { code: StatusCode.ERROR, error, filter }
            })
        )
};

export const GetGroupAssignmentRoles = (id: string, filter: Partial<SearchPageRequest> = {}) => (dispatch: Dispatch<Group_GetAssignmentRoles>) => {
    const storeKey = store.getState().groups.assignmentRoles?.key;
    const key = id + '-' + filter.onlyActive + '-' + filter.page;

    if (key === storeKey) {
        return;
    }

    dispatch({
        type: GroupActionType.GET_GROUP_ASSIGNMENTROLES,
        assignmentRoles: { code: StatusCode.PENDING, filter },
    });
    const url = `/api/core/group/${id}/assignmentRoles`;
    InstanceClient.get<PageResult<CoreGroupUserListing>>(url, filter)
        .then(res => {
            dispatch({
                type: GroupActionType.GET_GROUP_ASSIGNMENTROLES,
                assignmentRoles: {
                    code: StatusCode.COMPLETE,
                    data: res.data,
                    filter,
                    key,
                },
            })
        })
        .catch(error =>
            dispatch({
                type: GroupActionType.GET_GROUP_ASSIGNMENTROLES,
                assignmentRoles: { code: StatusCode.ERROR, error, filter }
            })
        )
};

export const GetGroupActivities = (id: string, filter: Partial<SearchPageRequest> = {}) => (dispatch: Dispatch<Group_GetActivities>) => {
    const storeKey = store.getState().groups.activities?.key;
    const key = id + '-' + filter.onlyActive + '-' + filter.page;
    if (key === storeKey) {
        return;
    }

    dispatch({
        type: GroupActionType.GET_GROUP_ACTIVITIES,
        activities: { code: StatusCode.PENDING, filter },
    });
    const url = `/api/core/group/${id}/activities`;
    InstanceClient.get<PageResult<CoreGroupActivityListing>>(url, filter)
        .then(res => {
            dispatch({
                type: GroupActionType.GET_GROUP_ACTIVITIES,
                activities: {
                    code: StatusCode.COMPLETE,
                    data: res.data,
                    filter,
                    key,
                },
            })
        })
        .catch(error =>
            dispatch({
                type: GroupActionType.GET_GROUP_ACTIVITIES,
                activities: { code: StatusCode.ERROR, error, filter }
            })
        )
};

const initialState: GroupState = {
    groups: { code: StatusCode.PENDING },
    group: { code: StatusCode.NONE },
    groupTrackings: { code: StatusCode.NONE },
    students: { code: StatusCode.PENDING, filter: {} },
    assignmentRoles: { code: StatusCode.PENDING, filter: {} },
    activities: { code: StatusCode.PENDING, filter: {} },
    filter: {},
}

export const reducer: Reducer<GroupState, GroupAction> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case GroupActionType.GET_GROUPS:
            return {
                ...state,
                groups: action.groups,
            };
        case GroupActionType.GET_GROUPS_UPDATE:
            return {
                ...state,
                groups: { ...state.groups, code: StatusCode.PENDING }
            };
        case GroupActionType.GET_GROUP:
            return {
                ...state,
                group: action.group,
            };
        case GroupActionType.GET_GROUP_STUDENTS:
            return {
                ...state,
                students: action.students,
            };
        case GroupActionType.GET_GROUP_ASSIGNMENTROLES:
            return {
                ...state,
                assignmentRoles: action.assignmentRoles,
            };
        case GroupActionType.GET_GROUP_ACTIVITIES:
            return {
                ...state,
                activities: action.activities,
            };
        case GroupActionType.SET_GROUPS_FILTER:
            return {
                ...state,
                filter: action.filter,
            };
        case GroupActionType.GET_GROUP_TRACKINGS:
            return {
                ...state,
                groupTrackings: action.groupTrackings,
            };
        default:
            return state;
    }
}