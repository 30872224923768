import React, {FC} from 'react';

const NotFound: FC<{}> = () => (
    <div className="home_bg padding-40">
        <div className="error-page">
            <h2 className="error-page__title">Ledsen, sidan du letar efter hittas inte.</h2>
            <p>Det kanske är fel url eller så finns inte sidan du letar efter längre.</p>
        </div>
    </div>
);

export default NotFound;
