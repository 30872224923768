import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Label, Input, Form } from 'reactstrap';
import StatusCode from '../../../util/StatusCode';
import SaveStatus from '../../misc/SaveStatus';


const CreateNewModuleModal = (props) => {
    return <Modal isOpen={props.isOpen} toggle={props.toggleModal}>
        <ModalHeader toggle={props.toggleModal}>Skapa ny modul</ModalHeader>
        <Form onSubmit={props.saveChanges}>
            <ModalBody>
                <Label>Typ av modul</Label>
                <h6 className="bold">{props.module.title}</h6>
                <FormGroup>
                    <Label for="title">Titel</Label>
                    <Input type="text" name="title" id="title" placeholder="Ange titel" value={props.title} onChange={props.updateTitle} disabled={props.disabled} autoComplete="off" required />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={props.toggleModal}>Stäng</Button>
                <Button color="primary" type="submit" onClick={props.createModule} disabled={props.title === '' || props.saveStatus !== StatusCode.NONE}>Skapa</Button>{' '}
                <SaveStatus
                    saveStatus={props.saveStatus}
                    successMsg='Modulen skapades'
                />
            </ModalFooter>
        </Form>
    </Modal>;
}

export default CreateNewModuleModal;
