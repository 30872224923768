import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetUser, UserState } from '../../../store/UserAction';
import { useParams, Link, Route, Routes, Navigate } from 'react-router-dom'
import * as Tab from '../../../util/TabValue';
import RenderTabs from '../../misc/Tabs';
import UserViewInfo from './UserViewInfo';
import UserViewObjectLogs from './UserViewObjectLogs';
import UserViewTrackings from './UserViewTrackings';
import UserViewUserRelations from './UserViewUserRelations';
import UserViewActivities from './UserViewActivities';
import UserViewGroups from './UserViewGroups';
import UserViewSchoolUnits from './UserViewSchoolUnits';
import NotFound from '../components/NotFound';
import { CoreUser } from '../../../models/CoreUser';
import { RequestState } from '../../../models/RequestState';
import StatusCode from '../../../util/StatusCode';
import Loading from '../../misc/Loading';

interface UserViewState {
    users: UserState
}

const UserView = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const user = useSelector<UserViewState, RequestState<CoreUser>>(state => state.users.user);

    useEffect(() => {
        if (userId) {
            GetUser(userId)(dispatch);
        }
    }, [dispatch, userId]);

    const tabs = [
        Tab.Tabs.Information,
        Tab.Tabs.ObjectLogs,
        Tab.Tabs.UserSchoolUnits,
        Tab.Tabs.Activities,
        Tab.Tabs.Groups,
        Tab.Tabs.UserRelations,
        Tab.Tabs.Trackings,
    ];

    if (userId === undefined) {
        return;
    }

    switch (user.code) {
        case StatusCode.COMPLETE:
            return (
                <div className="padding-40 container single">
                    <h1><Link to="/user">Person</Link>{' / ' + user.data.displayName}</h1>

                    <RenderTabs
                        tabs={tabs}
                        id={userId}
                        url="user" />

                    <Routes>
                        <Route path={Tab.Tabs.Information.url} element={<UserViewInfo />} />
                        <Route path={Tab.Tabs.ObjectLogs.url} element={<UserViewObjectLogs />} />
                        <Route path={Tab.Tabs.UserSchoolUnits.url} element={<UserViewSchoolUnits />} />
                        <Route path={Tab.Tabs.Activities.url} element={<UserViewActivities />} />
                        <Route path={Tab.Tabs.Groups.url} element={<UserViewGroups />} />
                        <Route path={Tab.Tabs.UserRelations.url} element={<UserViewUserRelations />} />
                        <Route path={Tab.Tabs.Trackings.url} element={<UserViewTrackings />} />
                        <Route path="*" element={<Navigate replace={true} to={`/user/${userId}/${Tab.Tabs.Information.url}`} />} />
                    </Routes>
                </div>
            );
        case StatusCode.ERROR:
            if (user.error.response?.status === 404) {
                return (
                    <NotFound
                        header="Personer"
                        name="Personen"
                        link="/user"
                    />
                );
            } else {
                return <p>{user.error.response?.statusText}</p>
            }
        default:
            return (
                <Loading />
            );
    }
}

export default UserView;
