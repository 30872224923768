import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom'
import { GetGroupStudents, GetGroupAssignmentRoles, GroupState, GroupUserRequestState } from '../../../store/GroupAction';
import * as Helper from '../../../util/Helper';
import StatusCode from '../../../util/StatusCode';
import ActiveToggle from '../../misc/ActiveToggle';
import TableHorizontalPagedToggle, { InfoObject } from '../../misc/TableHorizontalPagedToggle';
import * as AssignmentRole from '../../../util/AssignmentRoleTypeUtil';

interface GroupViewMembersState {
    groups: GroupState
}


const GroupViewMembers = () => {
    const { groupId } = useParams();
    const dispatch = useDispatch();
    const [show, setShow] = useState({ students: true, assignmentRoles: true });

    const students = useSelector<GroupViewMembersState, GroupUserRequestState>(state => state.groups.students);
    const assignmentRoles = useSelector<GroupViewMembersState, GroupUserRequestState>(state => state.groups.assignmentRoles);

    useEffect(() => {
        if (groupId) {
            GetGroupStudents(groupId, { ...students?.filter })(dispatch);
            GetGroupAssignmentRoles(groupId, { ...assignmentRoles?.filter })(dispatch);
        }
    }, [dispatch, groupId]);

    const updateStudentsPage = (value: number) => {
        if (groupId) {
            GetGroupStudents(groupId, { ...students.filter, page: value })(dispatch);
        }
    }

    const updateAssignmentRolesPage = (value: number) => {
        if (groupId) {
            GetGroupAssignmentRoles(groupId, { ...assignmentRoles.filter, page: value })(dispatch);
        }
    }

    const updateOnlyActive = (value: boolean) => {
        if (groupId) {
            GetGroupStudents(groupId, { ...students.filter, onlyActive: value, page: 1 })(dispatch);
            GetGroupAssignmentRoles(groupId, { ...assignmentRoles.filter, onlyActive: value, page: 1 })(dispatch);
        }
    }

    const mapStudents = (): InfoObject[] => {
        if (students.code !== StatusCode.COMPLETE) {
            return [];
        }

        return students.data.values.map(x => ({
            id: x.id,
            data: [
                { value: <Link to={`/user/${x.id}/information`}>{x.title}</Link> },
                { value: x.emailAddresses ? x.emailAddresses.map(e => e).join(', ') : '' },
                { value: Helper.active(x.active) },
            ]
        }))
    }

    const mapAssignmentRoles = (): InfoObject[] => {
        if (assignmentRoles.code !== StatusCode.COMPLETE) {
            return [];
        }

        return assignmentRoles.data.values.map(x => ({
            id: x.id,
            data: [
                { value: <Link to={`/user/${x.id}/information`}>{x.title}</Link> },
                { value: AssignmentRole.assignmentRoleTypeFromId(x.assignmentRoleType) },
                { value: x.emailAddresses ? x.emailAddresses.map(e => e).join(', ') : '' },
                { value: Helper.active(x.active) },
            ]
        }))
    }

    const renderStudents = () => {
        const studentInfo = mapStudents();
        const count = Helper.getTotalCountText(students);
        return (
            <TableHorizontalPagedToggle
                header={[{ value: `Elever ${count}` }, { value: 'E-postadress' }, { value: 'Aktiv' }]}
                mobileHeader={['Namn', 'E-postadress', 'Aktiv']}
                info={studentInfo}
                show={show.students}
                toggle={() => setShow({ ...show, students: !show.students })}
                displayName={`Elever ${count}`}
                onChangePage={updateStudentsPage}
                noResultMsg="Den här gruppen saknar elever"
                requestState={students}
            />
        )
    }

    const renderAssignmentRoles = () => {
        const assignmentRoleInfo = mapAssignmentRoles();
        const count = Helper.getTotalCountText(assignmentRoles);
        return (
            <TableHorizontalPagedToggle
                header={[{ value: `Personal ${count}` }, { value: 'Roll' }, { value: 'E-postadress' }, { value: 'Aktiv' }]}
                mobileHeader={['Namn', 'Roll', 'E-postadress', 'Aktiv']}
                info={assignmentRoleInfo}
                show={show.assignmentRoles}
                toggle={() => setShow({ ...show, assignmentRoles: !show.assignmentRoles })}
                displayName={`Personal ${count}`}
                onChangePage={updateAssignmentRolesPage}
                noResultMsg="Den här gruppen saknar personal"
                requestState={assignmentRoles}
            />
        )
    }

    return (<>
        <div className="header_member_table large">Medlemmar
            <span className="right">
                <ActiveToggle
                    id="group-members"
                    onlyActive={students?.filter?.onlyActive ?? true}
                    update={updateOnlyActive}
                    disabled={students.code === StatusCode.PENDING || assignmentRoles.code === StatusCode.PENDING}
                />
            </span></div>
        {renderAssignmentRoles()}
        {renderStudents()}
    </>);
}

export default GroupViewMembers;
