
const PersonSex = new Map<number, string>([
    [0, "Odefinierat"],
    [1, "Man"],
    [2, "Kvinna"],
    [3, "Okänd"],
]);

export const GetPersonSex = (id: number): string => PersonSex.get(id) ?? '-';

const PersonStatus = new Map<number, string>([
    [1, "Aktiv"],
    [2, "Utvandrad"],
    [3, "Avliden"],
]);

export const GetPersonStatus = (id: number): string => PersonStatus.get(id) ?? '-';

const PersonEPPN = new Map<number, string>([
    [0, "Odefinierat"],
    [1, "Elev"],
    [2, "Personal"],
]); 

export const GetPersonEPPN = (id: number): string => PersonEPPN.get(id) ?? '-'; 

const PersonEmail = new Map<number, string>([
    [0, "Odefinierat"],
    [1, "Personlig"],
    [2, "Elev"],
    [3, "Personal"],
    [4, "Annat arbete"],
]); 

export const GetPersonEmail = (id: number): string => PersonEmail.get(id) ?? '-'; 

const PersonAddress = new Map<number, string>([
    [0, "Odefinierat"],
    [1, "Folkbokföringadress"],
    [2, "Särskild postadress"],
    [3, "Tillfällig adress"],
    [4, "Postadress"],
]); 

export const GetPersonAddress = (id: number): string => PersonAddress.get(id) ?? '-'; 

const PersonPhoneNumber = new Map<number, string>([
    [0, "Odefinierat"],
    [1, "Hem"],
    [2, "Arbete"],
]);

export const GetPersonPhoneNumber = (id: number): string => PersonPhoneNumber.get(id) ?? '-'; 
