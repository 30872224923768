interface AuthorizationInfo {
    tenant?: string,
    adminConsent: boolean,
    code?: string
}

interface AuthorizationCallback {
    messageType: string,
    authorizationInfo: AuthorizationInfo
}

const parseAuthInfoFromQuery = () => {
    if (!window.location.search.length) return { adminConsent: false };

    var searches = window.location.search.substring(1).split("&");
    var obj : { [key: string] : string } = {};
    searches.forEach(search => {
        var split = search.split("=");
        if (split.length < 2) return;
        const key = decodeURIComponent(split[0]);
        const value = decodeURIComponent(split[1])
        obj[key] = value;
    });

    return {
        adminConsent: obj['admin_consent'] ?? false,
        tenant: obj['tenant'],
        code: obj['code']
    };
};

export const handleAuthWindow = () => {
    if (window.location.pathname === '/authorizationCallback' && window.opener) {
        var authorizationInfo = parseAuthInfoFromQuery();
        window.opener.postMessage({
            messageType: 'authorizationCallback',
            authorizationInfo: authorizationInfo
        } as AuthorizationCallback);
        window.close();
    }
};

export const authWindow = (url: string) => {
    return new Promise<AuthorizationInfo>((resolve, reject) => {
        var handler = (e: MessageEvent<AuthorizationCallback>) => {
            if (e && e.data && e.data.messageType && e.data.messageType === 'authorizationCallback') {
                console.log(e.data)
                window.removeEventListener('message', handler);
                resolve(e.data.authorizationInfo);
            }
        };

        window.addEventListener('message', handler);
        window.open(url);
    });
};