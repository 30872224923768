import React from 'react';
import { FormGroup, Card, CardHeader, CardBody, Collapse, Button } from 'reactstrap';
import ConfigurationComponent from '../ConfigurationComponent';
import EnumInput from '../../../misc/EnumInput';
import NullableIntInputCheckboxText from '../../../misc/NullableIntInputCheckboxText';
import NullableInput from '../../../misc/NullableInput';

const groupType = [
    { value: 1, text: 'Microsoft 365' },
    { value: 2, text: 'Säkerhetsgrupp' },
];

const memberShipType = [
    { value: 1, text: 'Strikt: De som lagts till manuellt tas bort' },
    { value: 2, text: 'Passivt: De som läggs till manuellt får ligga kvar' },
];

const groupSelection = [
    { value: 4, text: 'Skolgrupp' },
    { value: 2, text: 'Skolgrupp Personal' },
    { value: 1, text: 'Skolgrupp Elever' },
    { value: 8, text: 'Mentorsgrupp'},
    { value: 16, text: 'Avdelning' },
    { value: 32, text: 'Avdelning Personal'},
    { value: 64, text: 'Avdelning Barn'},
    { value: 128, text: 'Klass'},
    { value: 256, text: 'Klass Personal'},
    { value: 512, text: 'Klass Elever'},
    { value: 1024, text: 'Undervisningsgrupp'},
    { value: 2048, text: 'Undervisningsgrupp Personal'},
    { value: 4096, text: 'Undervisningsgrupp Elever'},
];

class AzureADGroupExport extends ConfigurationComponent {
    state = {
        open: true,
    }

    validate(configuration, active) {
        const validation = [];

        if (Number.isNaN(configuration.inactivateAfter)) {
            validation.push('inactivateAfter');
        }
        if (Number.isNaN(configuration.removeAfter)) {
            validation.push('removeAfter');
        }
        if (configuration.prefix === '' || (configuration.prefix && configuration.prefix.length > 64)) {
            validation.push('prefix');
        }
        if (configuration.suffix === '' || (configuration.suffix && configuration.suffix.length > 64)) {
            validation.push('suffix');
        }

        if (active) {
            if (configuration.memberMembershipType !== 1 && configuration.memberMembershipType !== 2) {
                validation.push('memberMembershipType')
            }
            if (configuration.ownerMembershipType !== 1 && configuration.ownerMembershipType !== 2) {
                validation.push('ownerMembershipType')
            }
            if (configuration.groupType !== 1 && configuration.groupType !== 2 && configuration.groupType !== 3) {
                validation.push('groupType')
            }
            if (configuration.groupSelection <= 0) {
                validation.push('groupSelection')
            }
        }
        return validation;
    }

    warnings(update) {
        const warningMessages = [];
        if (update.groupSelection) {
            var propsValue = this.props.configuration.groupSelection;

            // Create warning if any group selection is removed but do not create warning if added.
            // XOR to find differance then use AND to check if diff was caused by removing from old value.
            if (((propsValue ^ update.groupSelection) & propsValue) !== 0) {
                warningMessages.push('Om du väljer bort grupper kommer dessa att inaktiveras.')
            }
        }
        return warningMessages;
    }

    updateAzureAD = async (prop, value) => {
        if (prop === 'inactivateAfter' && value === null) {
            await this.updatePropertyValue('removeAfter', null)
        }
        this.updatePropertyValue(prop, value)
    }

    render() {
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);
        const validation = this.validate(configuration, this.props.active);

        return (
            <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    {this.props.moduleDisplayName}
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>
                        <div style={{ margin: '1rem 0' }}>
                            <EnumInput
                                title='Typ av grupp'
                                name='groupType'
                                value={configuration.groupType}
                                options={groupType}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                                invalid={validation.indexOf('groupType') !== -1}
                                invalidFeedback='Typ av grupp måste anges för att kunna aktivera modulen'
                            />
                            <EnumInput
                                title='Medlemskap'
                                name='memberMembershipType'
                                value={configuration.memberMembershipType}
                                options={memberShipType}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                                invalid={validation.indexOf('memberMembershipType') !== -1}
                                invalidFeedback='Medlemsskap måste anges för att kunna aktivera modulen'
                            />
                            <EnumInput
                                title='Ägareskap'
                                value={configuration.ownerMembershipType}
                                name='ownerMembershipType'
                                options={memberShipType}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                                invalid={validation.indexOf('ownerMembershipType') !== -1}
                                invalidFeedback='Ägareskap måste anges för att kunna aktivera modulen'
                            />
                            <EnumInput
                                multi={true}
                                title='Skapa följande grupper'
                                name="groupSelection"
                                value={configuration.groupSelection}
                                options={groupSelection}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                                invalid={validation.indexOf('groupSelection') !== -1}
                                invalidFeedback='Minst en grupptyp måste anges för att kunna aktivera modulen'
                            />

                            <FormGroup>
                                <h5>Prefix/Suffix</h5>
                                <NullableInput
                                    disabled={this.props.disableInput}
                                    title="Prefix (sätts före gruppnamnet)"
                                    checkboxTitle="Aktivera prefix"
                                    value={configuration.prefix}
                                    onChange={this.updatePropertyValue}
                                    name="prefix"
                                    invalidFeedback="Prefix måste anges och vara mindre än 64 tecken"
                                    invalid={validation.indexOf('prefix') !== -1}
                                />
                                <NullableInput
                                    disabled={this.props.disableInput}
                                    title="Suffix (sätts efter gruppnamnet)"
                                    checkboxTitle="Aktivera suffix"
                                    value={configuration.suffix}
                                    onChange={this.updatePropertyValue}
                                    name="suffix"
                                    invalidFeedback="Suffix måste anges och vara mindre än 64 tecken"
                                    invalid={validation.indexOf('suffix') !== -1}
                                />
                            </FormGroup>

                            <FormGroup>
                                <h5>Livscykelhantering</h5>
                                <NullableIntInputCheckboxText
                                    name="inactivateAfter"
                                    value={configuration.inactivateAfter}
                                    disabled={this.props.disableInput}
                                    title="Inaktivera efter dagar"
                                    invalidFeedback="Ange dagar till inaktivering"
                                    invalid={validation.indexOf('inactivateAfter') !== -1}
                                    defaultValue={0}
                                    onChange={(k, v) => this.updateAzureAD(k, v)}
                                    checkboxTitle="Ingen inaktivering"
                                />

                                <NullableIntInputCheckboxText
                                    name="removeAfter"
                                    value={configuration.removeAfter}
                                    disabled={this.props.disableInput || configuration.inactivateAfter === null}
                                    title="Dagar till radering efter inaktivering"
                                    invalidFeedback="Ange dagar till radering efter inaktivering"
                                    invalid={validation.indexOf('removeAfter') !== -1}
                                    defaultValue={180}
                                    onChange={(k, v) => this.updateAzureAD(k, v)}
                                    checkboxTitle="Ingen radering"
                                    checked={configuration.inactivateAfter === null}
                                />
                            </FormGroup>
                            
                        </div>
                    </CardBody>
                </Collapse>
            </Card>)
    }
};

export default AzureADGroupExport;
