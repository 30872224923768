import { Action, Dispatch, Reducer } from 'redux';
import { PageResult } from '../../models/CoreModels';
import { ScheduleSync_CoreEventRef, ScheduleSync_CoreTrackings, ScheduleSync_CoreUser, ScheduleSync_UsersFilter } from '../../models/CoreScheduleSyncModels';
import { PageRequestState, RequestState } from '../../models/RequestState';
import InstanceClient from '../../services/InstanceClient';
import StatusCode from '../../util/StatusCode';
import store from '../store';

enum ScheduleSync_UserActionType {
    SCHEDULESYNC_USERS = 'SCHEDULESYNC_USERS',
    SCHEDULESYNC_USERS_FILTER = 'SCHEDULESYNC_USERS_FILTER',
    SCHEDULESYNC_USERS_UPDATE = 'SCHEDULESYNC_USERS_UPDATE',
    SCHEDULESYNC_USER = 'SCHEDULESYNC_USER',
    SCHEDULESYNC_USER_TRACKINGS = 'SCHEDULESYNC_USER_TRACKINGS',
    SCHEDULESYNC_USER_SCHEDULE = 'SCHEDULESYNC_USER_SCHEDULE',
}

export interface ScheduleSync_UserState {
    users: PageRequestState<ScheduleSync_CoreUser>
    filter: ScheduleSync_UsersFilter
    user: RequestState<ScheduleSync_CoreUser>
    userTrackings: RequestState<ScheduleSync_CoreTrackings>
    events: RequestState<ScheduleSync_CoreEventRef[]>
}

interface ScheduleSync_ListUsersAction extends Action<ScheduleSync_UserActionType> {
    type: ScheduleSync_UserActionType.SCHEDULESYNC_USERS
    users: PageRequestState<ScheduleSync_CoreUser>
}

interface ScheduleSync_UsersFilterAction extends Action<ScheduleSync_UserActionType> {
    type: ScheduleSync_UserActionType.SCHEDULESYNC_USERS_FILTER
    filter: ScheduleSync_UsersFilter
}

interface ScheduleSync_UsersUpdateAction extends Action<ScheduleSync_UserActionType> {
    type: ScheduleSync_UserActionType.SCHEDULESYNC_USERS_UPDATE
}

interface ScheduleSync_GetUserAction extends Action<ScheduleSync_UserActionType> {
    type: ScheduleSync_UserActionType.SCHEDULESYNC_USER
    user: RequestState<ScheduleSync_CoreUser>
}

interface ScheduleSync_UserTrackingsAction extends Action<ScheduleSync_UserActionType> {
    type: ScheduleSync_UserActionType.SCHEDULESYNC_USER_TRACKINGS
    userTrackings: RequestState<ScheduleSync_CoreTrackings>
}

interface ScheduleSync_UserScheduleAction extends Action<ScheduleSync_UserActionType> {
    type: ScheduleSync_UserActionType.SCHEDULESYNC_USER_SCHEDULE
    events: RequestState<ScheduleSync_CoreEventRef[]>
}

type ScheduleSync_UserAction =
    | ScheduleSync_ListUsersAction
    | ScheduleSync_UsersFilterAction
    | ScheduleSync_UsersUpdateAction
    | ScheduleSync_GetUserAction
    | ScheduleSync_UserTrackingsAction
    | ScheduleSync_UserScheduleAction

export const ScheduleSyncUsers = (filter = {}, update: boolean = false) => (dispatch: Dispatch<ScheduleSync_ListUsersAction | ScheduleSync_UsersFilterAction | ScheduleSync_UsersUpdateAction>) => {
    dispatch({ type: ScheduleSync_UserActionType.SCHEDULESYNC_USERS_FILTER, filter: filter });
    if (update) {
        dispatch({ type: ScheduleSync_UserActionType.SCHEDULESYNC_USERS_UPDATE });
    }

    const url = '/api/schedulesync/v1/core/users'
    return InstanceClient.get<PageResult<ScheduleSync_CoreUser>>(url, filter)
        .then(res => {
            dispatch({
                type: ScheduleSync_UserActionType.SCHEDULESYNC_USERS,
                users: {
                    code: StatusCode.COMPLETE,
                    data: res.data.values,
                    totalCount: res.data.totalCount,
                    pagination: {
                        pageSize: res.data.pageSize,
                        totalPages: res.data.totalPages,
                        currentPage: res.data.currentPage
                    }
                }
            })
        })
        .catch(err =>
            dispatch({
                type: ScheduleSync_UserActionType.SCHEDULESYNC_USERS,
                users: { code: StatusCode.ERROR, error: err }
            })
        );
};

export const User = (id: string) => (dispatch: Dispatch<ScheduleSync_GetUserAction>) => {
    const storeKey = store.getState().schedulesync_users.user?.key;
    const key = id;
    if (key === storeKey) {
        return;
    }

    dispatch({
        type: ScheduleSync_UserActionType.SCHEDULESYNC_USER,
        user: { code: StatusCode.PENDING },
    });

    const url = `/api/schedulesync/v1/core/user/${id}`;
    return InstanceClient.get<ScheduleSync_CoreUser>(url)
        .then(res => {
            dispatch({
                type: ScheduleSync_UserActionType.SCHEDULESYNC_USER,
                user: {
                    code: StatusCode.COMPLETE,
                    data: res.data,
                    key: key,
                }
            })
        })
        .catch(err =>
            dispatch({
                type: ScheduleSync_UserActionType.SCHEDULESYNC_USER,
                user: { code: StatusCode.ERROR, error: err.response.status }
            })
        );
};

export const UserSchedule = (id: string, onlyActive: boolean, weekDate: Date) => (dispatch: Dispatch<ScheduleSync_UserScheduleAction>) => {
    const storeKey = store.getState().schedulesync_users.events?.key;
    const key = id + '-' + onlyActive + '-' + weekDate;
    if (key === storeKey) {
        return;
    }

    dispatch({
        type: ScheduleSync_UserActionType.SCHEDULESYNC_USER_SCHEDULE,
        events: {
            code: StatusCode.PENDING, filter: { onlyActive: onlyActive, weekDate: weekDate }
        },
    });
    const url = '/api/schedulesync/v1/core/user/' + id + '/schedule?onlyActive=' + onlyActive + '&weekDate=' + weekDate.toJSON();
    return InstanceClient.get<ScheduleSync_CoreEventRef[]>(url)
        .then(res => {
            dispatch({
                type: ScheduleSync_UserActionType.SCHEDULESYNC_USER_SCHEDULE,
                events: { code: StatusCode.COMPLETE, data: res.data, key: key, filter: { onlyActive: onlyActive, weekDate: weekDate } }
            })
        })
        .catch(err =>
            dispatch({
                type: ScheduleSync_UserActionType.SCHEDULESYNC_USER_SCHEDULE,
                events: { code: StatusCode.ERROR, error: err }
            })
        );
};

export const UserTrackings = (id: string) => (dispatch: Dispatch<ScheduleSync_UserTrackingsAction>) => {
    const storeKey = store.getState().schedulesync_users.userTrackings?.key;
    const key = id;
    if (key === storeKey) {
        return;
    }

    dispatch({
        type: ScheduleSync_UserActionType.SCHEDULESYNC_USER_TRACKINGS,
        userTrackings: { code: StatusCode.PENDING },
    });
    return InstanceClient.get<ScheduleSync_CoreTrackings>(`/api/schedulesync/v1/core/user/${id}/trackings`)
        .then(res => {
            dispatch({
                type: ScheduleSync_UserActionType.SCHEDULESYNC_USER_TRACKINGS,
                userTrackings: { code: StatusCode.COMPLETE, data: res.data, key: key }
            })
        })
        .catch(err =>
            dispatch({
                type: ScheduleSync_UserActionType.SCHEDULESYNC_USER_TRACKINGS,
                userTrackings: { code: StatusCode.ERROR, error: err }
            })
        );
};

const initialState: ScheduleSync_UserState = {
    users: { code: StatusCode.PENDING },
    events: { code: StatusCode.NONE },
    userTrackings: { code: StatusCode.NONE },
    filter: {},
    user: { code: StatusCode.NONE },
}

export const reducer: Reducer<ScheduleSync_UserState, ScheduleSync_UserAction> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case ScheduleSync_UserActionType.SCHEDULESYNC_USERS:
            return {
                ...state,
                users: action.users,
            }
        case ScheduleSync_UserActionType.SCHEDULESYNC_USERS_FILTER:
            return {
                ...state,
                filter: action.filter,
            };
        case ScheduleSync_UserActionType.SCHEDULESYNC_USERS_UPDATE:
            return {
                ...state,
                users: { ...state.users, code: StatusCode.PENDING },
            };
        case ScheduleSync_UserActionType.SCHEDULESYNC_USER:
            return {
                ...state,
                user: action.user,
            }
        case ScheduleSync_UserActionType.SCHEDULESYNC_USER_TRACKINGS:
            return {
                ...state,
                userTrackings: action.userTrackings,
            };
        case ScheduleSync_UserActionType.SCHEDULESYNC_USER_SCHEDULE:
            return {
                ...state,
                events: action.events,
            }
        default:
            return state;
    }
};
