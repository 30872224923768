import React from 'react';
import { Card, CardHeader, CardBody, Collapse, Button } from 'reactstrap';
import ConfigurationComponent from '../ConfigurationComponent';
import EnumInput from '../../../misc/EnumInput';
import BoolInput from '../../../misc/BoolInput';

const resourceModes = [
    { value: 2, text: "Strikt: De som lagts till manuellt tas bort" },
    { value: 3, text: "Passivt: De som läggs till manuellt får ligga kvar"},
    { value: 1, text: "Inte alls: Hantera inte resurser" }
];

const eventVisibilities = [
    { value: 0, text: "Default: Information om kalenderhändelsen syns för andra enligt användarens inställning av synlighet på kalendern" },
    { value: 1, text: "Publikt: Information om kalenderhändelsen syns alltid för alla" },
    { value: 2, text: "Privat: Information om kalenderhändelsen syns enbart av användaren själv" }
];

class ScheduleSyncGoogle extends ConfigurationComponent {
    state = { open: true }

    render() {
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);

        return (
            <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    {this.props.moduleDisplayName}
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>
                        <div style={{ margin: '1rem 0' }}>
                            <EnumInput
                                title='Hantera resurser'
                                name='resourceMode'
                                value={configuration.resourceMode}
                                options={resourceModes}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                            />
                            <BoolInput
                                title='Inkludera möteslänk'
                                name='includeMeetLink'
                                value={configuration.includeMeetLink}
                                updateValue={(prop, value) => this.updatePropertyValue(prop, value)}
                                disabled={this.props.disableInput}
                            />
                            <EnumInput
                                title='Synlighet för kalenderhändelser'
                                name='eventVisibility'
                                value={configuration.eventVisibility}
                                options={eventVisibilities}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                            />
                        </div>
                    </CardBody>
                </Collapse>
            </Card>)
    }
}

export default ScheduleSyncGoogle;
