import RenderTabs from '../../misc/Tabs';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Logs from './Logs';
import InstancePhaseTable from '../components/instancePhase/InstancePhaseTable';
import { useParams, Link, Route, Routes, Navigate } from 'react-router-dom'
import * as Tab from '../../../util/TabValue';
import { getModuleInstance, ModuleState } from '../../../store/ModuleAction';
import * as Helper from '../../../util/Helper';
import { CoreModuleInstance, Result } from '../../../models/CoreModels';

interface InstanceLogsState {
    module: ModuleState
}

const InstanceLogs = () => {
    const { moduleId, moduleInstanceId } = useParams();
    const dispatch = useDispatch();
    const moduleInstance = useSelector<InstanceLogsState, Result<CoreModuleInstance>>(state => state.module.moduleInstance);

    useEffect(() => {
        if (moduleId && moduleInstanceId) {
            getModuleInstance(moduleId, moduleInstanceId, true)(dispatch);
        }
    }, [dispatch, moduleId, moduleInstanceId]);

    const tabs = [
        Tab.Tabs.InstanceLogs,
        Tab.Tabs.InstancePhaseStatistics,
    ];

    const displayName = moduleInstance.data?.title ? ' / ' + moduleInstance.data.title : '';
    const timeStamp = moduleInstance.data ? `/ ${Helper.formatDate(moduleInstance.data.startedAt)}` : '';

    if (!moduleId || !moduleInstanceId) {
        return;
    }

    return (
        <div className="container">
            <div className="pr-40 pl-40 pt-40">
                <h2><Link to={`/module/${moduleId}/moduleInstanceRun`}>Logg</Link> {displayName} {timeStamp}</h2>
                <RenderTabs
                    tabs={tabs}
                    id={moduleId}
                    url="module"
                    id2={moduleInstanceId}
                    url2="instance"
                />
                <div className="tab-border" />
            </div>

            <Routes>
                <Route path={Tab.Tabs.InstanceLogs.url} element={<Logs />} />
                <Route path={Tab.Tabs.InstancePhaseStatistics.url} element={<InstancePhaseTable completed={Boolean(moduleInstance.data?.completedAt)} />} />
                <Route path="*" element={<Navigate replace={true} to={`/module/${moduleId}/instance/${moduleInstanceId}/${Tab.Tabs.InstanceLogs.url}`} />} />
            </Routes>
        </div>
    );
};

export default InstanceLogs;
