import InstanceClient from '../../services/InstanceClient';
import StatusCode from '../../util/StatusCode';

const DASHBOARD = 'ONEROSTER_DASHBOARD';


export const getOneRosterInfo = () => dispatch => {
    const url = '/api/core/oneroster/dashboard'
    InstanceClient.get(url)
        .then(res => {
            dispatch({
                type: DASHBOARD,
                oneroster: { code: StatusCode.COMPLETE, data: res.data }
            })
        })
        .catch(error =>
            dispatch({
                type: DASHBOARD,
                oneroster: { code: StatusCode.ERROR, error }
            })
        )
}


const initialState = {
    oneroster: { code: StatusCode.PENDING }
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case DASHBOARD:
            return {
                ...state,
                oneroster: action.oneroster
            }
        default:
            return state;
    }
}

