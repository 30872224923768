import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RequestState } from '../../models/RequestState';
import { GetModuleInstanceFileName, DownloadFileForInstanceModule, ClearDownloadFileForInstanceModule, BlobContainerState, BlobContainerStateDownloadFileStatuses } from '../../store/BlobContainerAction';
import FilesModal from './FilesModal';

interface ModuleInstanceFilesModalState {
    blob: BlobContainerState
}

interface ModuleInstanceFilesModalProps {
    moduleInstanceId: string
    isOpen?: boolean
    toggleModal?: MouseEventHandler
}

const ModuleInstanceFilesModal: React.FC<ModuleInstanceFilesModalProps> = (props) => {
    const dispatch = useDispatch();
    const fileNames = useSelector<ModuleInstanceFilesModalState, RequestState<string[]>>(state => state.blob.fileNames);
    const downloadFileStatuses = useSelector<ModuleInstanceFilesModalState, BlobContainerStateDownloadFileStatuses>(state => state.blob.downloadFileStatuses);

    useEffect(() => {
        GetModuleInstanceFileName(props.moduleInstanceId)(dispatch);
        return () => {
            ClearDownloadFileForInstanceModule()(dispatch);
        }
    }, [dispatch, props.moduleInstanceId]);

    const downloadFile = (name: string) => {
        DownloadFileForInstanceModule(props.moduleInstanceId, name)(dispatch);
    }

    return <FilesModal
        downloadFileStatuses={downloadFileStatuses}
        fileNames={fileNames}
        downloadFile={downloadFile}
        isOpen={props.isOpen}
        toggleModal={props.toggleModal}/>
}

export default ModuleInstanceFilesModal;
