import React from 'react';
import './Unauthorized.css';
import { signOut } from '../../store/AuthAction';
import { useDispatch } from 'react-redux';

const Unauthorized: React.FC = () => {
    const dispatch = useDispatch()

    return (
        <div className="unauthorized">
            <div className="login__logo-container">
                <img className="login__logo" src="/FS_ikon.svg" alt="flowsync logo" /> <h1 className='login__logo-text'>flowsync</h1>
            </div>

            <p>Du saknar behörighet för att se den här instansen av Flowsync</p>
            <button onClick={() => signOut()(dispatch)}>Logga in med annat konto</button>
        </div>
    )
}

export default Unauthorized;
