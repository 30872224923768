export enum CoreGoogleUserTrackingConflictType {
    Unknown = 0,
    TrackingKey = 1,
    SourceObjectType = 2,
    Identifier = 3,
    EmailAddress = 4,
}

export interface CoreGoogleUserTrackingConflict {
    type: CoreGoogleUserTrackingConflictType
    trackingId: string
    flowSyncId: string
    displayName: string
    active: boolean
    sourceName: string
    sourceEnvironment: string
    sourceObjectType: string
    sourceIdentifier: string
}

export interface CoreGoogleUserTrackingUpdate {
    primaryEmail: string
}

export interface CoreGoogleUserTrackingMapping {
    googleId: string
    primaryEmail?: string
}