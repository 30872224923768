import React from 'react';
import Select, { CSSObjectWithLabel, SingleValue } from 'react-select';
import { OnChangeCallback, SearchableOptionItem } from '../../util/UtilityTypes'


const comparer = <TValue extends string | number>(a: SearchableOptionItem<TValue>, b: SearchableOptionItem<TValue>): number => {
    return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
}

interface SearchableSelectInputProps<TValue extends string | number> {
    onChange: OnChangeCallback<TValue | undefined | null>
    items: SearchableOptionItem<TValue>[]
    defaultText: string
    disabled?: boolean
    name: string
    selected?: TValue
}

const SearchableSelectInput = <TValue extends string | number>(props: SearchableSelectInputProps<TValue>) => {
    const handleChange = (option: SingleValue<SearchableOptionItem<TValue>>) => {
        if (!option) {
            props.onChange(props.name, null);
        } else {
            props.onChange(props.name, option.value);
        }
    };

    let selected = props.selected !== undefined ? (props.selected && props.items && props.items.find(x => x.value === props.selected)) : null;
    let items = props.items && props.items.sort(comparer);

    return (
        <Select
            value={selected}
            onChange={(option) => handleChange(option)}
            options={items}
            placeholder={props.defaultText}
            noOptionsMessage={() => "Resultat saknas"}
            isDisabled={!props.items.length || props.disabled}
            isClearable
            styles={{
                control: (baseStyles, state): CSSObjectWithLabel =>
                ({
                    ...baseStyles,
                    boxShadow: state.isFocused ? '0 0 0 0.25rem rgba(13, 110, 253, 0.25)' : undefined,
                    backgroundColor: state.isDisabled ? '#e9ecef' : '#fff',
                    borderColor: '#ced4da',
                    textAlign: 'left',
                }),
                option: (base, state): CSSObjectWithLabel =>
                ({
                    ...base,
                    padding: '0 0.75rem',
                    backgroundColor: state.isFocused ? '#1967d2' : undefined,
                    color: state.isFocused ? '#fff' : undefined,
                    lineHeight: '1.5',
                }),
                placeholder: (base) => ({
                    ...base,
                    color: '#212529'
                }),
                dropdownIndicator: (base) => ({
                    ...base,
                    color: '#212529',
                }),
            }}
        />
    );
}

export default SearchableSelectInput;
