import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom'
import { ActivityState, ActivityStudentGroupRequestState, GetActivityGroups } from '../../../store/ActivityAction';
import * as Helper from '../../../util/Helper';
import StatusCode from '../../../util/StatusCode';
import ActiveToggle from '../../misc/ActiveToggle';
import TableHorizontalPagedToggle, { InfoObject } from '../../misc/TableHorizontalPagedToggle';

interface State {
    activities: ActivityState
}

const ActivityViewGroups = () => {
    const { activityId } = useParams();
    const dispatch = useDispatch();
    const [show, setShow] = useState(true);

    const groups = useSelector<State, ActivityStudentGroupRequestState>(state => state.activities.activityGroups);

    useEffect(() => {
        if (activityId !== undefined) {
            GetActivityGroups(activityId, { ...groups?.filter })(dispatch);
        }
    }, [dispatch, activityId]);

    const updateFilter = (value: string | boolean | number, name: string) => {
        const filter = { ...groups.filter };
        if (name === "onlyActive") {
            filter['page'] = 1;
        }
        if (activityId !== undefined) {
            GetActivityGroups(activityId, { ...filter, [name]: value })(dispatch);
        }
    }

    const mapGroups = (): InfoObject[] => {
        if (groups.code !== StatusCode.COMPLETE) {
            return [];
        }

        return groups.data.values.map(g => ({
            id: g.id,
            data: [
                { value: <Link to={`/group/${g.id}/information`}>{g.title}</Link> },
                { value: Helper.active(g.active) },
                { value: Helper.formatDateOnly(g.startDate) },
                { value: Helper.formatDateOnly(g.endDate) },
            ]
        }));
    }

    const renderGroups = () => {
        const groupInfo = mapGroups();
        return (
            <TableHorizontalPagedToggle
                header={[{ value: "Namn" }, { value: "Aktiv" }, { value: "Startdatum" }, { value: "Slutdatum" }]}
                info={groupInfo}
                show={show}
                toggle={() => setShow(!show)}
                onChangePage={(p) => updateFilter(p, 'page')}
                noResultMsg="Den här aktiviteten saknar grupper"
                requestState={groups}
            />
        )
    }

    const count = Helper.getTotalCountText(groups);
    return (<>
        <div className="header_member_table large">Grupper {count}
            <span className="right">
                <ActiveToggle
                    id="groups"
                    onlyActive={groups?.filter?.onlyActive ?? true}
                    update={(active) => updateFilter(active, 'onlyActive')}
                    disabled={groups.code === StatusCode.PENDING}
                />
            </span></div>
        {renderGroups()}
    </>)
}

export default ActivityViewGroups;
