const assignmentRoleTypes = new Map<number, string>([
    [1, "Rektor"],
    [2, "Mentor"],
    [3, "Förskollärare"],
    [4, "Barnskötare"],
    [5, "Fritidspedagog"],
    [6, "Specialpedagog"],
    [7, "Schemaläggare"],
    [8, "Elevhälsopersonal"],
    [9, "Lärarassistent"],
    [10, "Administrativ personal"],
    [0, "Odefinierad"],
]);

export const assignmentRoleTypeFromId = (id: number): string => assignmentRoleTypes.get(id) ?? 'Saknas';