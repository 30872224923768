import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppTokenState, updateAppToken, validateAppToken } from '../../../../store/AppTokenAction';
import Loading from '../../../misc/Loading';
import { Input, Label, Button, Form } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CoreAppToken, CoreAppTokenValidation } from '../../../../models/CoreAppToken';
import { RequestState } from '../../../../models/RequestState';
import StatusCode from '../../../../util/StatusCode';
import Copy from '../../../misc/Copy';

interface AppTokenGoogleServiceAccountProps {
    appToken: CoreAppToken,
    disableInput: boolean
}

interface AppTokenRootState {
    token: AppTokenState
}

const AppTokenGoogleServiceAccount = (props: AppTokenGoogleServiceAccountProps) => {
    const [userName, setUserName] = useState('');
    const [updatingApptoken, setUpdatingAppToken] = useState(false);

    const appTokenValidationRequestState = useSelector<AppTokenRootState, RequestState<CoreAppTokenValidation>>(x => x.token.appTokenValidation);

    const dispatch = useDispatch();

    const generateGoogleServiceAccount: React.FormEventHandler<HTMLFormElement> = async (e) => {
        e.preventDefault();

        let appTokenBody = {
            appRegistration: props.appToken.appRegistration,
            userName: userName
        }

        setUpdatingAppToken(true)
        await updateAppToken(props.appToken.id, appTokenBody)(dispatch);
        setUpdatingAppToken(false);

        validateUpdate();
    }

    const validateUpdate = async () => {
        await validateAppToken(props.appToken.id)(dispatch);
    }

    const unauthorizedAccess = (appTokenValidation: CoreAppTokenValidation) => {
        return (
            <div>
                <p><FontAwesomeIcon icon="triangle-exclamation" className="apptoken-configuration__status-icon--warning" />Kontot behöver konfigureras</p>
                <p>Du behöver godkänna applikationen i din Google-miljö</p>
                <p>Följ instruktionerna nedan</p>
                <ol>
                    <li>Gå till <a href={appTokenValidation.flowSyncUXUri} target="_blank" rel="noopener noreferrer">{appTokenValidation.flowSyncUXUri}</a>. Hantera domänomfattande delegering </li>
                    <li>Klicka på "Lägg till ny"</li>
                    <li>Fyll i Klient id: {appTokenValidation.clientId}</li>
                    <li>Fyll i följande domänomfattningar: {appTokenValidation.scopes && <Copy value={appTokenValidation.scopes?.join(',')} />}</li>
                    <ul>
                        {appTokenValidation.scopes?.join(', ')}
                    </ul>
                    <li>Klicka på "Auktorisera"</li>
                </ol>

                <p>Efter att applikationen godkänts i din Google-miljö, behöver du validera inloggningen.</p>
                <div className="apptoken-configuration__button-container">
                    <Button color="primary" disabled={props.disableInput} onClick={validateUpdate}>Validera inloggning</Button>
                </div>
            </div>
        )
    }

    if (appTokenValidationRequestState.code === StatusCode.NONE || appTokenValidationRequestState.code === StatusCode.PENDING) {
        return (<Loading />);
    }

    if (appTokenValidationRequestState.code === StatusCode.ERROR) {
        return (
            <div>
                <p>Misslyckades</p>
                <p>Servern svarade med '{appTokenValidationRequestState.error.code}'</p>
                <Form onSubmit={generateGoogleServiceAccount}>
                    <Label for="userName">Servicekonto: </Label>
                    <Input name="userName" disabled={props.disableInput} onChange={(e) => setUserName(e.target.value.trim())} value={userName} />
                    <div className="apptoken-configuration__button-container">
                        <Button color="primary" type="submit">Konfigurera</Button>
                        {updatingApptoken && <Loading className="push--left" />}
                    </div>
                </Form>
            </div>
        )
    }

    const appTokenValidation = appTokenValidationRequestState.data;

    if (!appTokenValidation.valid && appTokenValidation.reason === 'UnauthorizedAccess') {
        return unauthorizedAccess(appTokenValidation);
    }

    if (!appTokenValidation.valid) {
        return (
            <div>
                <p><FontAwesomeIcon icon="triangle-exclamation" className="apptoken-configuration__status-icon--warning" />Kontot behöver konfigureras</p>
                {props.appToken.userName && <p>Användarnamnet '{props.appToken.userName}' är inte giltigt</p>}
                <Form onSubmit={generateGoogleServiceAccount}>
                    <Label>Servicekonto: </Label>
                    <Input name="userName" disabled={props.disableInput} onChange={(e) => setUserName(e.target.value.trim())} value={userName} />
                    <div className="apptoken-configuration__button-container">
                        <Button className="apptoken-configuration__button" color="primary" disabled={props.disableInput} type="submit">Konfigurera</Button>
                        {updatingApptoken && <Loading className="push--left" />}
                    </div>
                </Form>
            </div>
        )
    }

    return (<div>
        <div className='apptoken-configuration__status'>
            <FontAwesomeIcon icon="check" className="apptoken-configuration__status-icon--ok" />Kontot är konfigurerat
        </div>
        <div className='apptoken-configuration__status'>
            <FontAwesomeIcon icon="user" className="apptoken-configuration__status-icon" />Servicekonto: {props.appToken.userName}
        </div>
    </div>);
}

export default AppTokenGoogleServiceAccount;
