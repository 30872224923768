import { Action, Dispatch, Reducer } from 'redux';
import { CoreSchoolAdministrationDashboardInfo } from '../models/CoreDashboardModels';
import { CacheRequestState, RequestState } from '../models/RequestState';
import InstanceClient from '../services/InstanceClient';
import StatusCode from '../util/StatusCode';

enum AdministrationDashboardActionType {
    SCHOOLADMIN_DASHBOARD = 'SCHOOLADMIN_DASHBOARD',
    SCHOOLADMIN_DASHBOARD_UPDATE = 'SCHOOLADMIN_DASHBOARD_UPDATE',
}

interface AdministrationDashboard_SchoolAdminDashboard extends Action<AdministrationDashboardActionType> {
    type: AdministrationDashboardActionType.SCHOOLADMIN_DASHBOARD
    dashboard: RequestState<CoreSchoolAdministrationDashboardInfo>
}

interface AdministrationDashboard_SchoolAdminDashboardUpdate extends Action<AdministrationDashboardActionType> {
    type: AdministrationDashboardActionType.SCHOOLADMIN_DASHBOARD_UPDATE
}

type AdministrationDashboardAction = AdministrationDashboard_SchoolAdminDashboard | AdministrationDashboard_SchoolAdminDashboardUpdate;

export interface AdministrationDashboardState {
    dashboard: CacheRequestState<CoreSchoolAdministrationDashboardInfo>
}

export const GetAdministrationInfo = (filter = {}, update = false) => (dispatch: Dispatch<AdministrationDashboardAction>) => {
    const url = '/api/core/schooladministration/dashboard'
    if (update) {
        dispatch({ type: AdministrationDashboardActionType.SCHOOLADMIN_DASHBOARD_UPDATE });
    }
    InstanceClient.get<CoreSchoolAdministrationDashboardInfo>(url, filter)
        .then(res => {
            dispatch({
                type: AdministrationDashboardActionType.SCHOOLADMIN_DASHBOARD,
                dashboard: { code: StatusCode.COMPLETE, data: res.data }
            })
        })
        .catch(error =>
            dispatch({
                type: AdministrationDashboardActionType.SCHOOLADMIN_DASHBOARD,
                dashboard: { code: StatusCode.ERROR, error }
            })
        )
}

const initialState: AdministrationDashboardState = {
    dashboard: { code: StatusCode.PENDING }
}

export const reducer: Reducer<AdministrationDashboardState, AdministrationDashboardAction> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case AdministrationDashboardActionType.SCHOOLADMIN_DASHBOARD:
            return {
                ...state,
                dashboard: action.dashboard
            };
        case AdministrationDashboardActionType.SCHOOLADMIN_DASHBOARD_UPDATE:
            return {
                ...state,
                dashboard: { ...state.dashboard, code: StatusCode.PENDING }
            };
        default:
            return state;
    }
}
