import React, {FC} from 'react';
import Layout from '../Layout'
import { Link } from 'react-router-dom';

const Forbidden: FC<{}> = () => {
    return (
        <Layout>
            <div className="home_bg padding-40">
                <div className="error-page">
                    <h2 className="error-page__title">Behörighet saknas</h2>
                    <p>Du saknar behörighet att se den här sidan av FlowSync. Klicka <Link to="/">här</Link> om du vill komma tillbaka till startsidan.</p>
                </div>
            </div>
        </Layout>
    )
}

export default Forbidden;
