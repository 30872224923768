import React from 'react'
import Loading from '../../../misc/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const minuteDivider = 60 * 1000;
const hourDivider = 60 * 60 * 1000;
const dayDivider = 24 * 60 * 60 * 1000;

export const getIcon = (status: number): JSX.Element | null => {
    switch (status) {
        case 1: return <Loading className="phase-spinner" />;
        case 2: return <FontAwesomeIcon icon="check-circle" className="green" />;
        case 3: return <FontAwesomeIcon icon="times-circle" className="red" />;
        case 4: return <FontAwesomeIcon icon="stop-circle" className="red" />;
        case 5: return <FontAwesomeIcon icon="xmark" className="red" />;
        default: return null;
    }
}

export const getStatus = (status: number): string | undefined => {
    switch (status) {
        case 1: return "Fasen körs";
        case 2: return "Fasen är färdig";
        case 3: return "Fasen avslutades med fel";
        case 4: return "Fasen avslutades med karantän";
        case 5: return "Fasen är avbruten";
        default: return undefined;
    }
}

function msToTime(duration: number): string {
    const seconds = Math.floor(duration / 1000) % 60;
    const minutes = Math.floor(duration / minuteDivider) % 60;
    const hours = Math.floor(duration / hourDivider);

    const timeString = [hours, minutes, seconds].map(n => n.toString().padStart(2, '0')).join(':');
    const millisString = (duration % 1000).toString().padStart(3, '0');

    return `${timeString}.${millisString}`;
}

export const getDuration = (startedAt: string, completedAt?: string): string => {
    if (!completedAt) {
        return 'Fasen ej avslutad';
    }
    const timestamp = Date.parse(completedAt) - Date.parse(startedAt)
    return msToTime(timestamp)
}
