import { EnumOptionItem } from "../util/UtilityTypes";

export enum TeamsFixMessagingSettings {
    NONE = 0,
    ALLOW_USER_EDIT_MESSAGES = 1,
    ALLOW_USER_DELETE_MESSAGES = 2,
    ALLOW_OWNER_DELETE_MESSAGES = 4,
}

export enum TeamsFixMemberSettings {
    NONE = 0,
    ALLOW_CREATE_UPDATE_CHANNELS = 1,
    ALLOW_DELETE_CHANNELS = 2,
    ALLOW_ADD_REMOVE_APPS = 4,
    ALLOW_CREATE_UPDATE_REMOVE_TABS = 8,
    ALLOW_CREATE_UPDATE_REMOVE_CONNECTORS = 16,
}

export enum TeamsFixSharePointSiteSettings {
    NONE = 0,
    DOCUMENT_MEMBER_READONLY = 1,
    STYLE_LIBRARY_MEMBER_READONLY = 2,
    SITE_PAGES_MEMBER_READONLY = 4,
    FORM_SERVER_TEMPLATES_MEMBER_READONLY = 8,
}

export enum TeamsFixGroupSettings {
    NONE = 0,
    HIDDEN_FROM_EXCHANGE_CLIENTS_ENABLED = 1,
    HIDDEN_FROM_ADDRESS_LISTS_ENABLED = 2,
}

export enum TeamsFixSettingBehaviour {
    UNDEFINED = 0,
    SET_ONCE = 1,
    FORCE = 2
}

export interface TeamsFix {
    enabled: boolean
    timeZoneId: number | null
    standardSettings: TeamsFixStandardSettings,
    archivedSettings: TeamsFixArchivedSettings
}

export interface TeamsFixStandardSettings {
    settingBehaviour: TeamsFixSettingBehaviour
    messagingSettings: TeamsFixMessagingSettings
    memberSettings: TeamsFixMemberSettings
    sharePointSiteSettings: TeamsFixSharePointSiteSettings
    groupSettings: TeamsFixGroupSettings
}

export interface TeamsFixArchivedSettings {
    settingBehaviour: TeamsFixSettingBehaviour
    sharePointSiteSettings: TeamsFixSharePointSiteSettings
    groupSettings: TeamsFixGroupSettings
}

export const MessagingSettings: EnumOptionItem<TeamsFixMessagingSettings>[] = [
    { value: TeamsFixMessagingSettings.ALLOW_USER_EDIT_MESSAGES, text: "Tillåt att användare redigerar meddelanden" },
    { value: TeamsFixMessagingSettings.ALLOW_USER_DELETE_MESSAGES, text: "Tillåt att användare raderar sina egna meddelanden" },
    { value: TeamsFixMessagingSettings.ALLOW_OWNER_DELETE_MESSAGES, text: "Tillåt att ägare raderar meddelanden" },
]

export const MemberSettings: EnumOptionItem<TeamsFixMemberSettings>[] = [
    { value: TeamsFixMemberSettings.ALLOW_CREATE_UPDATE_CHANNELS, text: "Tillåt att medlemmar kan skapa eller redigera kanaler" },
    { value: TeamsFixMemberSettings.ALLOW_DELETE_CHANNELS, text: "Tillåt att medlemmar kan radera kanaler" },
    { value: TeamsFixMemberSettings.ALLOW_ADD_REMOVE_APPS, text: "Tillåt att medlemmar kan lägga till och ta bort appar" },
    { value: TeamsFixMemberSettings.ALLOW_CREATE_UPDATE_REMOVE_TABS, text: "Tillåt att medlemmar kan hantera flikar" },
    { value: TeamsFixMemberSettings.ALLOW_CREATE_UPDATE_REMOVE_CONNECTORS, text: "Tillåt att medlemmar kan hantera connectorer" },
]

export const SharePointSiteSettings: EnumOptionItem<TeamsFixSharePointSiteSettings>[] = [
    { value: TeamsFixSharePointSiteSettings.DOCUMENT_MEMBER_READONLY, text: "Läsbehörighet för elever på Filer" },
    { value: TeamsFixSharePointSiteSettings.STYLE_LIBRARY_MEMBER_READONLY, text: "Läsbehörighet för elever på Formatbiblioteket" },
    { value: TeamsFixSharePointSiteSettings.SITE_PAGES_MEMBER_READONLY, text: "Läsbehörighet för elever på Webbplatssidor" },
    { value: TeamsFixSharePointSiteSettings.FORM_SERVER_TEMPLATES_MEMBER_READONLY, text: "Läsbehörighet för elever på Formulärmallar" },
]

export const GroupSettings: EnumOptionItem<TeamsFixGroupSettings>[] = [
    { value: TeamsFixGroupSettings.HIDDEN_FROM_EXCHANGE_CLIENTS_ENABLED, text: "Dölj teamet/gruppen i Exchange-klienten" },
    { value: TeamsFixGroupSettings.HIDDEN_FROM_ADDRESS_LISTS_ENABLED, text: "Dölj teamet/gruppen i addressboken" },
]

export const StandardSettingBehaviour: EnumOptionItem<TeamsFixSettingBehaviour>[] = [
    { value: TeamsFixSettingBehaviour.SET_ONCE, text: "Ställ endast in inställningarna när teamet skapas, avarkiveras eller inställningarna ändras" },
    { value: TeamsFixSettingBehaviour.FORCE, text: "Tvinga inställningarna så fort synken upptäcker att de frångåtts" },
]

export const ArchivedSettingBehaviour: EnumOptionItem<TeamsFixSettingBehaviour>[] = [
    { value: TeamsFixSettingBehaviour.SET_ONCE, text: "Ställ endast in inställningarna när teamet arkiveras eller inställningarna ändras" },
    { value: TeamsFixSettingBehaviour.FORCE, text: "Tvinga inställningarna så fort synken upptäcker att de frångåtts" },
]