import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Result } from "../../../models/CoreModels";
import { CoreObjectLogs } from "../../../models/CoreObjectLogs";
import { GetObjectLogs } from "../../../store/ModuleAction";
import ObjectLogs from "../../misc/ObjectLogs";
import * as LogTypes from '../../../util/FlowSyncLoggerTypes';


interface ObjectLogsState {
    objectLogs: Result<CoreObjectLogs[]>
}

interface State {
    module: ObjectLogsState
}


const UserViewObjectLogs = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const objectLogs = useSelector<State, Result<CoreObjectLogs[]>>(state => state.module.objectLogs);

    useEffect(() => {
        GetObjectLogs({ objectId: userId, objectSource: LogTypes.Flowsync.SourceName, objectType: LogTypes.Flowsync.User })(dispatch);
    }, [dispatch, userId]);

    return (<>
        <ObjectLogs
            objectLogs={objectLogs}
            noResMsg="Det finns inga loggrader för denna person den senaste körningen"
        />
    </>);
}

export default UserViewObjectLogs;
