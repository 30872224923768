import React, { Component } from 'react';

export class Toggle extends Component {
    toggle = (e) => {
        this.props.update(!e.target.checked);
    }

    render() {
        let checked = this.props.checked !== null ? !this.props.checked : false;

        return (
            <div className="toggle">
                <p>{this.props.header}</p>
                <div className="toggle-switch">
                    <input
                        type="checkbox"
                        className="toggle-switch-checkbox"
                        onChange={this.toggle}
                        disabled={this.props.searching}
                        name={this.props.name}
                        id={this.props.name}
                        checked={checked}
                    />
                    <label className="toggle-switch-label" htmlFor={this.props.name}>
                        <span className="toggle-switch-inner"
                            data-yes="På"
                            data-no="Av"
                        />
                        <span className="toggle-switch-switch" />
                    </label>
                </div>
            </div>
        )
    }
}

export default Toggle;
