import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import * as Helper from '../../util/Helper';

interface CopyProps {
    value: string
    key?: React.Key
    disabled?: boolean
}

const Copy: React.FC<CopyProps> = (props) => {
    const [messageTimeout, setMessageTimeout] = useState<NodeJS.Timeout | undefined>(undefined);
    const className = props.disabled ? 'flowsync-copy-icon-disabled' : 'flowsync-copy-icon';

    const onClickHandler: React.MouseEventHandler = (e) => {
        e.stopPropagation();

        if (props.disabled) {
            return;
        }

        Helper.copyToClipboard(props.value);
        if (messageTimeout) {
            clearTimeout(messageTimeout);
        }

        setMessageTimeout(setTimeout(() => setMessageTimeout(undefined), 750));
    }

    const messageContainer = messageTimeout ? (<p className="flowsync-copy-message">Copied to clipboard!</p>) : null;
    return (<span key={props.key}>
        <FontAwesomeIcon className={className} icon="copy" onClick={onClickHandler} />
        {messageContainer}
    </span>);
}

export default Copy;