import React from 'react';
import { FormGroup, Label, Input, Card, CardHeader, CardBody, Collapse, Button, FormFeedback } from 'reactstrap';
import ConfigurationComponent from '../ConfigurationComponent';
import EnumInput from '../../../misc/EnumInput';
import NullableInput from '../../../misc/NullableInput';

const importType = [
    { value: 2, text: "Endast markerad" },
    { value: 3, text: "Hybrid" },
    { value: 1, text: "Endast SS12000" },
];

const activityNaming = [
    { value: 1, text: "Aktivitetens namn" },
    { value: 2, text: "Gruppens namn" },
    { value: 3, text: "Gruppens namn + Aktivitetens namn" },
];

const exportType = [
    { value: 1, text: "Classroom" },
    { value: 2, text: "Teams" }
];

const Address = 1;
const CivicNo = 2;
const PersonalEmail = 4;
const Custodian = 8;
const sensitiveFieldOptions = [
    { value: Address, text: "Adress" },
    { value: CivicNo, text: "Personnummer" },
    // { value: PersonalEmail, text: "Privat E-postadress" }, NOTE: Aktivera när SchoolSoft släpper ifrån sig e-postaddresser med typ.
    { value: Custodian, text: "Vårdnadshavare" }
]

const hasDeselectedType = (originalValue, updatedValue, type) => {
    return updatedValue !== undefined && (originalValue & type) === type && (updatedValue & type) === 0;
}

class SchoolSoft extends ConfigurationComponent {
    state = { open: true }

    validate(configuration, active) {
        const validation = [];

        if (active) {
            if (configuration.type === 0) {
                validation.push('type');
            }
            if (configuration.targetSystem === 0) {
                validation.push('targetSystem');
            }
            if (configuration.type === 1 && configuration.activityNaming === 0) {
                validation.push('activityNaming');
            }
            if (!configuration.webApp || configuration.webApp === "") {
                validation.push('webApp');
            }
        }
        if (configuration.municipalityCode === '') {
            validation.push("municipalityCode");
        }

        return validation;
    }

    warnings(update) {
        const warningMessages = [];

        if (hasDeselectedType(this.props.configuration.sensitiveFields, update.sensitiveFields, Custodian)) {
            warningMessages.push('Tänk på att om utläsning av vårdnadshavare inaktiveras så kommer dessa att tas bort från de system som vårdnadshavare skickas till');
        }

        return warningMessages;
    }

    render() {
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);
        const validation = this.validate(configuration, this.props.active);

        return (
            <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    {this.props.moduleDisplayName}
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>
                        <div style={{ margin: '1rem 0' }}>

                            <EnumInput
                                title='Typ av import'
                                value={configuration.type}
                                options={importType}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                                name='type'
                                invalid={validation.indexOf('type') !== -1}
                                invalidFeedback='Typ av import måste anges för att kunna aktivera modulen'
                            />

                            {configuration.type === 1 && <EnumInput
                                title='Namnsättning av aktiviteter (Endast SS12000)'
                                value={configuration.activityNaming}
                                options={activityNaming}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput || configuration.type !== 1}
                                name='activityNaming'
                                invalid={validation.indexOf('activityNaming') !== -1}
                                invalidFeedback='Namnsättning av aktiviteter måste anges för att kunna aktivera modulen'
                            />}

                            <EnumInput
                                title='Importtyp för'
                                name='targetSystem'
                                value={configuration.targetSystem}
                                options={exportType}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                                invalid={validation.indexOf('targetSystem') !== -1}
                                invalidFeedback='Val av importtyp måste anges för att kunna aktivera modulen'
                            />

                            <EnumInput
                                multi={true}
                                title='Databehandling'
                                name="sensitiveFields"
                                value={configuration.sensitiveFields}
                                options={sensitiveFieldOptions}
                                disabled={this.props.disableInput}
                                onChange={this.updatePropertyValue}
                                invalid={validation.indexOf('sensitiveFields') !== -1}
                                invalidFeedback='Ange minst en grupptyp för att aktivera den här modulen'
                            />

                            {configuration.type === 3 && <NullableInput
                                disabled={this.props.disableInput || configuration.type === 2}
                                title="Filtrering av kommunkod"
                                checkboxTitle="Aktivera kommunkod"
                                value={configuration.municipalityCode}
                                onChange={this.updatePropertyValue}
                                name="municipalityCode"
                                invalid={validation.indexOf('municipalityCode') !== -1}
                                invalidFeedback="Ange kommunkod när checkboxen är ikryssad"
                            />}

                            <FormGroup>
                                <Label tag="h5" for="baseAddress">Webapp</Label>
                                <Input type="text" name="webApp" id="webApp" placeholder="Webapp" value={configuration.webApp || ''} disabled={this.props.disableInput} onChange={this.updateProperty} invalid={validation.indexOf('webApp') !== -1} autoComplete="off" />
                                <FormFeedback>Webapp måste anges för att kunna aktivera importmodulen</FormFeedback>
                            </FormGroup>
                        </div>
                    </CardBody>
                </Collapse>
            </Card>
        )
    }
}

export default SchoolSoft;
