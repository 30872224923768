import React from 'react';
import { FormGroup, Label, Input, Card, CardHeader, CardBody, Collapse, Button, FormFeedback } from 'reactstrap';
import ConfigurationComponent from '../ConfigurationComponent';
import EnumInput from '../../../misc/EnumInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Address = 1;
const CivicNo = 2;
const PersonalEmail = 4;
const Custodian = 8;
const sensitiveFieldOptions = [
    { value: Address, text: "Aktivera utläsning av adress" },
    { value: CivicNo, text: "Aktivera utläsning av personnummer" },
    { value: PersonalEmail, text: "Aktivera utläsning av privat e-postadress" },
    { value: Custodian, text: "Aktivera utläsning av vårdnadshavare" },
];

const schoolTypes = [
    { value: 1, text: 'Förskola' },
    { value: 2, text: 'Förskoleklass' },
    { value: 4, text: 'Fritidshem' },
    { value: 8, text: 'Grundskola' },
    { value: 16, text: 'Anpassad Grundskola' },
    { value: 128, text: 'Gymnasieskola' },
    { value: 256, text: 'Anpassad Gymnasieskola' },
    { value: 512, text: 'Vuxenutbildning' },
    { value: 1024, text: 'Anpassad Vuxenutbildning' },
    { value: 2048, text: 'Yrkeshögskola' },
    { value: 32768, text: 'SFI' },
    { value: 65536, text: 'Pedagogisk omsorg' },
];

const EducationGroup = 1;
const ActivityBasedGroup = 2;
const groupType = [
    { value: EducationGroup, text: 'Grupper' },
    { value: ActivityBasedGroup, text: 'Kurs och ämne' }
];

const MentorBasedMentorGroup = 1;
const ClassBasedMentorGroup = 2;
const mentorGroupType = [
    { value: MentorBasedMentorGroup, text: 'Mentorgrupp' },
    { value: ClassBasedMentorGroup, text: 'Klassbaserad mentorgrupp' }
];


const hasDeselectedType = (originalValue, updatedValue, type) => {
    return updatedValue !== undefined && (originalValue & type) === type && (updatedValue & type) === 0;
}

class Edlevo extends ConfigurationComponent {
    state = { open: true }

    validate(configuration, active) {
        const validation = [];

        if (active) {
            if (!configuration.baseAddress || configuration.baseAddress === "" || configuration.baseAddress.charAt(configuration.baseAddress.length - 1) !== '/') {
                validation.push('baseAddress');
            }
            if (!configuration.licenseKey || configuration.licenseKey === "") {
                validation.push('licenseKey');
            }
            if (!configuration.certificateThumbprint || configuration.certificateThumbprint === "") {
                validation.push('certificate');
            }
            if (configuration.schoolTypes <= 0) {
                validation.push('schoolTypes')
            }
        }

        if (configuration.onlyIncludeSchoolsWithMunicipalityCode && !configuration.municipalityCode) {
            validation.push('municipalityCode');
        }
        if (configuration.startDateOffset === null) {
            validation.push('startDateOffset');
        }
        return validation;
    }

    warnings(update) {
        const warningMessages = [];

        if (hasDeselectedType(this.props.configuration.groupType, update.groupType, EducationGroup)
            || hasDeselectedType(this.props.configuration.groupType, update.groupType, ActivityBasedGroup)) {
            warningMessages.push('Om du tar bort typer från aktivitetsutläsningen kommer alla aktiviteter utlästa från den typen att inaktiveras')
        }

        if (hasDeselectedType(this.props.configuration.mentorGroupType, update.mentorGroupType, MentorBasedMentorGroup)
            || hasDeselectedType(this.props.configuration.mentorGroupType, update.mentorGroupType, ClassBasedMentorGroup)) {
            warningMessages.push('Om du tar bort typer från mentorgrupputläsningen kommer alla mentorgrupper utlästa från den typen att inaktiveras')
        }

        if (hasDeselectedType(this.props.configuration.sensitiveFields, update.sensitiveFields, Custodian)) {
            warningMessages.push('Tänk på att om utläsning av vårdnadshavare inaktiveras så kommer dessa att tas bort från de system som vårdnadshavare skickas till');
        }

        return warningMessages;
    }

    updateState = (newUpdates, prop, value) => {
        if (this.props.configuration[prop] === value) {
            delete newUpdates[prop];
        } else {
            newUpdates[prop] = value;
        }

        if (prop === 'onlyIncludeGovernedByMunicipal' && value === false) {
            this.updateState(newUpdates, 'onlyIncludeSchoolsWithMunicipalityCode', false);
        } else if (prop === 'onlyIncludeSchoolsWithMunicipalityCode' && value === false) {
            this.updateState(newUpdates, 'municipalityCode', null);
        }
    }

    render() {
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);
        const validation = this.validate(configuration, this.props.active);

        return (
            <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    <span>
                        {this.props.moduleDisplayName}
                        <a href="https://flowsync.se/help/edlevo/activities" target="_blank"><FontAwesomeIcon className="documentation-icon" title="Visa hjälpavsnitt" icon="question-circle" /></a>
                    </span>
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>
                        <div style={{ margin: '1rem 0' }}>
                            <h5>Åtkomst</h5>
                            <FormGroup>
                                <Label for="baseAddress">Basadress</Label>
                                <Input type="text" name="baseAddress" id="baseAddress" placeholder="Basadress" value={configuration.baseAddress || ''} disabled={this.props.disableInput} onChange={this.updateProperty} invalid={validation.indexOf('baseAddress') !== -1} autoComplete="off" />
                                <FormFeedback>Basadress måste anges för att kunna aktivera importmodulen. Adressen måste avslutas med '/'</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="licenseKey">Licensnyckel</Label>
                                <Input type="text" name="licenseKey" id="licenseKey" placeholder="Licensnyckel" value={configuration.licenseKey || ''} disabled={this.props.disableInput} onChange={this.updateProperty} invalid={validation.indexOf('licenseKey') !== -1} autoComplete="off" />
                                <FormFeedback>Licensnyckel måste anges för att kunna aktivera importmodulen</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="certificateThumbprint">Certifikat</Label>
                                <Input type="text" name="certificateThumbprint" id="certificateThumbprint" placeholder="Certifikat" value={configuration.certificateThumbprint || ''} disabled={this.props.disableInput} invalid={validation.indexOf('certificate') !== -1} onChange={this.updateProperty} autoComplete="off" />
                                <FormFeedback>Certifikat måste anges för att kunna aktivera importmodulen</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <h5>Generellt</h5>
                                <Label for="startDateOffset">Läs ut information detta antal dagar i förväg</Label>
                                <Input type="number" name="startDateOffset" id="startDateOffset" placeholder="Antal dagar" value={configuration.startDateOffset !== undefined || configuration.startDateOffset !== null ? configuration.startDateOffset : ''} disabled={this.props.disableInput} onChange={this.updateProperty} required invalid={validation.indexOf('startDateOffset') !== -1} />
                                <FormFeedback>Ett värde måste anges.</FormFeedback>
                            </FormGroup>

                            <EnumInput
                                title='Databehandling'
                                name='sensitiveFields'
                                value={configuration.sensitiveFields}
                                options={sensitiveFieldOptions}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                                multi={true}
                            />

                            <FormGroup>
                                <h5>Personer</h5>
                                <FormGroup check name="includePersonsWithProtectedAddressInformation">
                                    <Label check style={{ display: 'block', marginBottom: '8px' }}>
                                        <Input
                                            type="checkbox"
                                            name="includePersonsWithProtectedAddressInformation"
                                            id="includePersonsWithProtectedAddressInformation"
                                            disabled={this.props.disableInput}
                                            checked={configuration.includePersonsWithProtectedAddressInformation}
                                            onChange={this.updateProperty} /> Inkludera personer med skyddad adress
                                    </Label>
                                </FormGroup>
                            </FormGroup>

                            <h5>Aktiviteter</h5>
                            <EnumInput
                                title='Läs ut aktiviteter baserat på'
                                name='groupType'
                                titleSmall={true}
                                value={configuration.groupType}
                                options={groupType}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                                multi={true}
                            />

                            <h5>Mentorgrupper</h5>
                            <EnumInput
                                title='Läs ut mentorgrupper baserat på'
                                name='mentorGroupType'
                                titleSmall={true}
                                value={configuration.mentorGroupType}
                                options={mentorGroupType}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                                multi={true}
                            />

                            <FormGroup>
                                <Label for="notesFilter">
                                    Läs bara ut grupper med nedanstående anteckning/information
                                    <br />
                                    <i>(Lämna tom för att läsa ut alla grupper)</i>
                                </Label>
                                <Input type="text" name="notesFilter" id="notesFilter" placeholder="Anteckning" value={configuration.notesFilter || ''} disabled={this.props.disableInput} onChange={this.updateProperty} autoComplete="off" />
                            </FormGroup>

                            <FormGroup>
                                <h5>Skolor</h5>
                                <FormGroup check name="onlyIncludeGovernedByMunicipal">
                                    <Label check style={{ display: 'block', marginBottom: '8px' }}>
                                        <Input type="checkbox" name="onlyIncludeGovernedByMunicipal" id="onlyIncludeGovernedByMunicipal" disabled={this.props.disableInput} checked={configuration.onlyIncludeGovernedByMunicipal} onChange={this.updateProperty} /> Ta enbart med kommunala skolor
                                    </Label>
                                </FormGroup>

                                <FormGroup check className="govern_schools">
                                    <Label check style={{ minWidth: '200px', marginBottom: '8px', marginTop: '3px' }}>
                                        <Input disabled={!configuration.onlyIncludeGovernedByMunicipal || this.props.disableInput} type="checkbox" name="onlyIncludeSchoolsWithMunicipalityCode" id="onlyIncludeSchoolsWithMunicipalityCode" checked={configuration.onlyIncludeSchoolsWithMunicipalityCode} onChange={this.updateProperty} /> Med kommunkod
                                    </Label>
                                    <span>
                                        <Input disabled={!configuration.onlyIncludeGovernedByMunicipal || !configuration.onlyIncludeSchoolsWithMunicipalityCode || this.props.disableInput} invalid={validation.indexOf('municipalityCode') !== -1} type="text" placeholder="Ange kommunkod" name="municipalityCode" id="municipalityCode" value={configuration.municipalityCode || ''} onChange={this.updateProperty} />
                                        <FormFeedback>Kommunkod måste anges om "Med kommunkod" är ikryssad</FormFeedback>
                                    </span>
                                </FormGroup>
                            </FormGroup>

                            <EnumInput
                                multi={true}
                                title='Läs ut följande skolformer'
                                titleSmall={true}
                                onChange={this.updatePropertyValue}
                                name="schoolTypes"
                                value={configuration.schoolTypes}
                                options={schoolTypes}
                                disabled={this.props.disableInput}
                                invalid={validation.indexOf('schoolTypes') !== -1}
                                invalidFeedback='Ange minst en skolform för att aktivera den här modulen'
                            />
                        </div>
                    </CardBody>
                </Collapse>
            </Card>)
    }
}

export default Edlevo;
