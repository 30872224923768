import React from 'react';
import { FormGroup } from 'reactstrap';
import { OnChangeCallback } from '../../../../../../util/UtilityTypes'
import CheckboxListItem from '../../../../../misc/CheckboxListItem';
import { CoreHaldorEducationGroupSetting } from '../../../../../../models/CoreHaldorModels';

interface HaldorEducationGroupSettingProps {
    title: string
    name: string
    value: CoreHaldorEducationGroupSetting
    onChange: OnChangeCallback<CoreHaldorEducationGroupSetting>
    disabled?: boolean
}

const HaldorEducationGroupSetting: React.FC<HaldorEducationGroupSettingProps> = (props) => {
    
    const onChange: OnChangeCallback<boolean> = (prop, value) => {    
        props.onChange(props.name, {
            classOneRoster: prop === 'classOneRoster' ? value : props.value.classOneRoster,
            departmentOneRoster: prop === 'departmentOneRoster' ? value : props.value.departmentOneRoster,
            activityOneRoster: prop === 'activityOneRoster' ? value : props.value.activityOneRoster
        })
    }

    return (
        <FormGroup tag="fieldset">

            <h5>{props.title}</h5>

            <CheckboxListItem text={'Baserat på klass från OneRoster'}
                id={'classOneRoster'}
                name={'classOneRoster'}
                value={props.value.classOneRoster}
                onChange={(e) => onChange(e.target.name, e.target.checked)}
                disabled={props.disabled}
            />
            
            <CheckboxListItem text={'Baserat på avdelning från OneRoster'}
                id={'departmentOneRoster'}
                name={'departmentOneRoster'}
                value={props.value.departmentOneRoster}
                onChange={(e) => onChange(e.target.name, e.target.checked)}
                disabled={props.disabled}
            />

            <CheckboxListItem text={'Baserat på aktivitet från OneRoster'}
                id={'activityOneRoster'}
                name={'activityOneRoster'}
                value={props.value.activityOneRoster}
                onChange={(e) => onChange(e.target.name, e.target.checked)}
                disabled={props.disabled}
            />

        </FormGroup>
    )
}

export default HaldorEducationGroupSetting;