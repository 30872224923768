import { CoreWebSocketContext } from '../models/CoreNotificationsModels';
import InstanceClient from './InstanceClient';

type WebSocketCallback = (message: NotificationServiceMessage) => void;
type WebSocketUnsubscribeCallback = () => void;

interface WebSocketCallbackRegistry {
    [key: string]: Set<WebSocketCallback>
}

export interface NotificationServiceMessage {
    messageId: string
    type: string
    data: any // Evil, should find fix. This requires a cast on usage :(
}

let socketPromise: Promise<WebSocket> | undefined = undefined;
const subscriptions: WebSocketCallbackRegistry = {}

const getSocket = () => {
    if (!socketPromise) {
        socketPromise = new Promise(async (resolve, reject) => {
            try {
                const response = await InstanceClient.get<CoreWebSocketContext>('/api/notifications/token');
                const socket = new WebSocket(`wss://${window.location.host}/api/notifications/socket/${response.data.clientId}`);

                socket.onmessage = async (e) => {
                    const text = await e.data.text();
                    const message = JSON.parse(text) as NotificationServiceMessage;

                    const callbacks = subscriptions[message.type];
                    if (callbacks) {
                        callbacks.forEach((cb) => cb(message));
                    }
                }

                socket.onclose = () => {
                    socketPromise = undefined;
                }

                socket.onerror = (e) => {
                    console.error(e);
                    socketPromise = undefined;
                }

                socket.onopen = () => {
                    resolve(socket);
                }
            } catch (e) {
                reject(e);
            }
        });
    }
    
    return socketPromise;
}

export const subscribe = (types: string[], callback: WebSocketCallback): WebSocketUnsubscribeCallback => {
    types.forEach(type => {
        let callbacks = subscriptions[type];
        if (callbacks === undefined) {
            callbacks = new Set<WebSocketCallback>();
            subscriptions[type] = callbacks;
        }

        callbacks.add(callback);
    });

    getSocket()
        .then((socket) => {
            const text = JSON.stringify({
                type: 'subscribe',
                data: types,
            });

            socket.send(new Blob([text], { type: 'application/json' }));
        })
        .catch(error => {
            console.error(error);
            unsubscribe(types, callback);
        });

    return () => unsubscribe(types, callback);
}

const unsubscribe = (types: string[], callback: WebSocketCallback) => {
    getSocket()
        .then((socket) => {
            const text = JSON.stringify({
                type: 'unsubscribe',
                data: types,
            });

            socket.send(new Blob([text], { type: 'application/json' }));
        })
        .catch((error) => {
            console.error(error);
        });

    types.forEach(type => {
        const callbacks = subscriptions[type];
        if (callbacks === undefined) {
            return;
        }

        callbacks.delete(callback);
    })
}
