import InstanceClient from '../../services/InstanceClient';
import StatusCode from '../../util/StatusCode';
import store from '../store';

const ONEROSTER_SCHOOLUNITS = 'ONEROSTER_SCHOOLUNITS';
const ONEROSTER_SCHOOLUNITS_UPDATE = 'ONEROSTER_SCHOOLUNITS_UPDATE';
const ONEROSTER_SCHOOLS_FILTER = 'ONEROSTER_SCHOOLS_FILTER';
const ONEROSTER_SCHOOLUNIT = 'ONEROSTER_SCHOOLUNIT';


export const getOneRosterSchoolUnits = (filter = {}, update = false) => dispatch => {
    dispatch({
        type: ONEROSTER_SCHOOLS_FILTER,
        filter: filter,
    });
    if (update) {
        dispatch({ type: ONEROSTER_SCHOOLUNITS_UPDATE });
    }

    const url = '/api/core/oneroster/org';
    InstanceClient.get(url, filter)
        .then(res => {
            dispatch({
                type: ONEROSTER_SCHOOLUNITS,
                schoolUnits: {
                    code: StatusCode.COMPLETE,
                    data: res.data.values,
                    totalCount: res.data.totalCount,
                    pagination: {
                        pageSize: res.data.pageSize,
                        currentPage: res.data.currentPage,
                        totalPages: res.data.totalPages,
                    }
                },
            })
        })
        .catch(error =>
            dispatch({
                type: ONEROSTER_SCHOOLUNITS,
                schoolUnits: { code: StatusCode.ERROR, error: error }
            })
        )
}

export const getOneRosterSchoolUnit = (id) => dispatch => {
    const storeKey = store.getState().oneroster_schoolunit.schoolUnit.key;
    const key = id;
    if (key === storeKey) {
        return;
    }

    const url = '/api/core/oneroster/org/' + id;
    InstanceClient.get(url)
        .then(res => {
            dispatch({
                type: ONEROSTER_SCHOOLUNIT,
                schoolUnit: { code: StatusCode.COMPLETE, data: res.data, key: key },
            })
        })
        .catch(error =>
            dispatch({
                type: ONEROSTER_SCHOOLUNIT,
                schoolUnit: { code: StatusCode.ERROR, error: error.response.status }
            })
        )
}


const initialState = {
    schoolUnits: { code: StatusCode.PENDING },
    schoolUnit: { code: StatusCode.PENDING },
    filter: {},
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case ONEROSTER_SCHOOLUNITS:
            return {
                ...state,
                schoolUnits: action.schoolUnits,
            }
        case ONEROSTER_SCHOOLUNITS_UPDATE:
            return {
                ...state,
                schoolUnits: { ...state.schoolUnits, code: StatusCode.PENDING }
            }
        case ONEROSTER_SCHOOLS_FILTER:
            return {
                ...state,
                filter: action.filter
            }
        case ONEROSTER_SCHOOLUNIT:
            return {
                ...state,
                schoolUnit: action.schoolUnit
            }
        default:
            return state;
    }
}

