import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, Route, Routes, Navigate } from 'react-router-dom'
import { getOneRosterSchoolUnit } from '../../store/OneRoster/OneRoster_SchoolUnitAction';
import StatusCode from '../../util/StatusCode';
import * as Helper from '../../util/Helper';
import TableVertical from '../misc/TableVertical';
import * as Tab from '../../util/TabValue';
import RenderTabs from '../misc/Tabs';


export default () => {
    const { schoolId } = useParams();
    const dispatch = useDispatch();
    const school = useSelector(state => state.oneroster_schoolunit.schoolUnit);

    useEffect(() => {
        getOneRosterSchoolUnit(schoolId)(dispatch);
    }, [dispatch]);

    const tabs = [
        Tab.Tabs.Information,
    ];

    const displayName = school.data?.name ? ' / ' + school.data.name : '';

    if (school?.error === 400 || school?.error === 404) {
        return <NotFound />;
    }

    return (
        <div className="padding-40 container single">
            <h1><Link to="/oneroster/schoolunit">Skola</Link> {displayName}</h1>

            <RenderTabs
                tabs={tabs}
                id={schoolId}
                url="oneroster/schoolunit" />

            <Routes>
                <Route path={Tab.Tabs.Information.url} element={<Info />} />
                <Route path="*" element={<Navigate replace={true} to={`/oneroster/schoolunit/${schoolId}/${Tab.Tabs.Information.url}`} />} />
            </Routes>
        </div>
    );
}

export const NotFound = () => {
    return (
        <div className="padding-40 container single">
            <h1><Link to="/oneroster/schoolunit">Skola</Link></h1>
            <p>Skolan hittades inte. Gör en ny <Link to='/schoolunit'>sökning</Link></p>
        </div>
    );
}

export const Info = () => {
    const { schoolId } = useParams();
    const dispatch = useDispatch();
    const school = useSelector(state => state.oneroster_schoolunit.schoolUnit);

    useEffect(() => {
        getOneRosterSchoolUnit(schoolId)(dispatch);
    }, [dispatch]);

    const renderSchoolInfo = () => {
        let schoolInfo = [];
        if (school.code === StatusCode.COMPLETE) {
            const s = school.data;
            schoolInfo.push(
                { header: 'Namn', content: s.name, },
                { header: 'SIS ID', content: s.id },
                { header: 'Aktiv', content: Helper.active(s.active) },
                { header: 'Skapad', content: Helper.formatDate(s.created), classname: 'nowrap' },
                { header: 'Ändrad', content: Helper.formatDate(s.modified), classname: 'nowrap' },
                { header: 'Antal Avsnitt', content: <Link to={`/oneroster/class?search=${s.name}`} > {s.classCount}</Link> },
                { header: 'Antal Lärare', content: <Link to={`/oneroster/user?search=${s.name}&role=teacher`}>{s.teacherCount}</Link> },
                { header: 'Antal Elever', content: <Link to={`/oneroster/user?search=${s.name}&role=student`}>{s.studentCount}</Link> },
            )
        }
        return (
            <TableVertical
                header='Information'
                info={schoolInfo}
                error={school.code === StatusCode.ERROR}
                searching={school.code === StatusCode.PENDING}
            />
        )
    }

    return (<>
        {renderSchoolInfo()}
    </>)
}
