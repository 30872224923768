import React, { useState } from 'react';
import { OnChangeCallback } from '../../../../../../util/UtilityTypes'
import { Card, CardHeader, CardBody, Collapse, Button, FormGroup, Form, Table, Input } from 'reactstrap';

interface FormField<TValue> {
    value: TValue
}

interface FormData {
    newReportReceiver: FormField<string>
}

interface UserSyncReportRecipientsProps {
    reportRecipients: string[]
    onChange: OnChangeCallback<string[]>
    disableInput?: boolean
}

const UserSyncReportRecipients: React.FC<UserSyncReportRecipientsProps> = (props) => {
    const [open, setOpen] = useState(true);

    const remove = (index: number) => {
        const clone = [...props.reportRecipients];
        clone.splice(index, 1);
        props.onChange('reportRecipients', clone);
    }

    const add = (e: React.SyntheticEvent) => {
        e.preventDefault();
        const target = e.target as typeof e.target & FormData;
        const emailAddress = target.newReportReceiver.value;
        target.newReportReceiver.value = '';

        if (!props.reportRecipients.some(x => x === emailAddress)) {
            props.onChange('reportRecipients', [...props.reportRecipients, emailAddress]);
        }
    }

    return (
        <Card>
            <CardHeader tag="h4">
                <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => setOpen(!open)}>{open ? '-' : '+'}</Button>
                Rapport
            </CardHeader>
            <Collapse isOpen={open}>
                <CardBody className="multiple-cards">
                    <FormGroup>
                        <Form onSubmit={add}>
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th>E-postaddress</th>
                                        <th />
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.reportRecipients.map((rr, index) => (
                                        <tr key={index}>
                                            <td>{rr}</td>
                                            <td className='text-right'>
                                                <Button color="danger" onClick={() => remove(index)} disabled={props.disableInput}>Ta bort</Button>
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td>
                                            <Input type="email" name="newReportReceiver" disabled={props.disableInput} required placeholder="E-postaddress" />
                                        </td>
                                        <td className='text-right'>
                                            <Button color="primary" type="submit" disabled={props.disableInput}>Lägg till</Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Form>
                    </FormGroup>
                </CardBody>
            </Collapse>
        </Card>);
}

export default UserSyncReportRecipients;