import {
    PageRequest,
    SearchPageRequest,
    FilterModuleSearchPageRequest,
    GroupSearchPageRequest,
    ScheduleSyncEventSearchPageRequest,
    OneRosterClassSearchPageRequest,
    OneRosterUserSearchPageRequest,
    UserSearchPageRequest,
    InstanceLogSearchPageRequest,
    DnpActivitiesSearchPageRequest,
} from '../models/Requests'

const parsePageRequest = (searchParams: URLSearchParams): Partial<PageRequest> => {
    const request: Partial<PageRequest> = {};

    const page = getNumber(searchParams, 'page');
    if (page !== null) {
        request.page = page;
    }

    const pageSize = getNumber(searchParams, 'pageSize');
    if (pageSize !== null) {
        request.pageSize = pageSize;
    }

    return request;
}

const parseSearchPageRequest = (searchParams: URLSearchParams): Partial<SearchPageRequest> => {
    const request: Partial<SearchPageRequest> = parsePageRequest(searchParams);

    const search = searchParams.get('search');
    if (search !== null) {
        request.search = search;
    }

    const onlyActive = getBoolean(searchParams, 'onlyActive');
    if (onlyActive !== null) {
        request.onlyActive = onlyActive;
    }

    return request;
}

const parseFilterModuleSearchPageRequest = (searchParams: URLSearchParams): Partial<FilterModuleSearchPageRequest> => {
    const request: Partial<FilterModuleSearchPageRequest> = parseSearchPageRequest(searchParams);

    const moduleTypeName = searchParams.get('moduleTypeName')
    if (moduleTypeName !== null) {
        request.moduleTypeName = moduleTypeName;
    }

    return request;
}

const parseGroupSearchPageRequest = (searchParams: URLSearchParams): Partial<GroupSearchPageRequest> => {
    const request: Partial<GroupSearchPageRequest> = parseFilterModuleSearchPageRequest(searchParams);

    const groupType = getNumber(searchParams, 'groupType');
    if (groupType !== null) {
        request.groupType = groupType;
    }

    return request;
}

const parseUserSearchPageRequest = (searchParams: URLSearchParams): Partial<UserSearchPageRequest> => {
    const request: Partial<UserSearchPageRequest> = parseFilterModuleSearchPageRequest(searchParams);

    const roleId = getNumber(searchParams, 'roleId');
    if (roleId !== null) {
        request.roleId = roleId;
    }

    const roleType = getNumber(searchParams, 'roleType');
    if (roleType !== null) {
        request.roleType = roleType;
    }

    return request;
}

const parseScheduleSyncEventSearchPageRequest = (searchParams: URLSearchParams): Partial<ScheduleSyncEventSearchPageRequest> => {
    const request: Partial<ScheduleSyncEventSearchPageRequest> = parseSearchPageRequest(searchParams);

    const onlyUpcomingDates = getBoolean(searchParams, 'onlyUpcomingDates');
    if (onlyUpcomingDates !== null) {
        request.onlyUpcomingDates = onlyUpcomingDates;
    }
    
    return request;
}

const parseOneRosterClassSearchPageRequest = (searchParams: URLSearchParams): Partial<OneRosterClassSearchPageRequest> => {
    const request: Partial<OneRosterClassSearchPageRequest> = parseSearchPageRequest(searchParams);

    const status = searchParams.get('status');
    if (status !== null) {
        request.status = status; 
    }

    return request;
}

const parseOneRosterUserSearchPageRequest = (searchParams: URLSearchParams): Partial<OneRosterUserSearchPageRequest> => {
    const request: Partial<OneRosterUserSearchPageRequest> = parseSearchPageRequest(searchParams);

    const role = searchParams.get('role');
    if (role !== null) {
        request.role = role;
    }

    return request;
}

const parseInstancelogSearchPageRequest = (searchParams: URLSearchParams): Partial<InstanceLogSearchPageRequest> => {
    const request: Partial<InstanceLogSearchPageRequest> = parsePageRequest(searchParams);

    const search = searchParams.get('search');
    if (search !== null) {
        request.search = search;
    }

    const severity = getNumber(searchParams, 'severity');
    if (severity !== null) {
        request.severity = severity;
    }

    const eventId = getNumber(searchParams, 'eventId');
    if (eventId !== null) {
        request.eventId = eventId;
    }

    return request;
}

const parseDnpActivitiesSearchPageRequest = (searchParams: URLSearchParams): Partial<DnpActivitiesSearchPageRequest> => {
    const request: Partial<DnpActivitiesSearchPageRequest> = parsePageRequest(searchParams);

    const filterType = searchParams.get('filterType');
    if (filterType !== null) {
        request.filterType = filterType;
    }

    const search = searchParams.get('search');
    if (search !== null) {
        request.search = search;
    }

    const syllabusId = searchParams.get('syllabusId');
    if (syllabusId !== null) {
        request.syllabusId = syllabusId;
    }

    const schoolType = searchParams.get('schoolType');
    if (schoolType !== null) {
        request.schoolType = schoolType;
    }

    const schoolYear = getNumbers(searchParams, 'schoolYear');
    if (schoolYear.length > 0) {
        schoolYear.forEach(x => {
            request.schoolYear = schoolYear;
        })
    } 

    const schoolUnitIds = getStrings(searchParams, 'schoolUnitIds');
    if (schoolUnitIds.length > 0) {
        schoolUnitIds.forEach(x => {
            request.schoolUnitIds = schoolUnitIds;
        })
    }

    return request;
}

const getStrings = (searchParams: URLSearchParams, name: string): string[] => {
    const stringValues = searchParams.getAll(name);
    return stringValues.map(x => x);
} 

const getNumbers = (searchParams: URLSearchParams, name: string): number[] => {
    const stringValues = searchParams.getAll(name);
    return stringValues.map(x => parseInt(x, 10));
}

const getNumber = (searchParams: URLSearchParams, name: string): number | null => {
    const stringValue = searchParams.get(name);
    return stringValue ? parseInt(stringValue, 10) : null;
}

const getBoolean = (searchParams: URLSearchParams, name: string): boolean | null => {
    const stringValue = searchParams.get(name);
    return stringValue ? stringValue.toLowerCase() === 'true' : null;
}

export default {
    parsePageRequest,
    parseSearchPageRequest,
    parseFilterModuleSearchPageRequest,
    parseGroupSearchPageRequest,
    parseUserSearchPageRequest,
    parseScheduleSyncEventSearchPageRequest,
    parseOneRosterClassSearchPageRequest,
    parseOneRosterUserSearchPageRequest,
    parseInstancelogSearchPageRequest,
    parseDnpActivitiesSearchPageRequest,
}