import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CacheRequestState } from '../../models/RequestState';
import { PageResult } from '../../models/CoreModels';
import StatusCode from '../../util/StatusCode';

export type OnChangePageCallback = (value: number) => void;

interface PagingInfo {
    pageSize: number
    currentPage: number
    totalPages: number
}

interface PaginationProps<T> {
    onChangePage: OnChangePageCallback
    requestState?: CacheRequestState<PageResult<T>>
    className?: string
}

interface PaginationInterval {
    start: number
    end: number
}

const getPagingInfo = <T,>(requestState?: CacheRequestState<PageResult<T>>): PagingInfo | undefined => {
    if (requestState === undefined || requestState.code === StatusCode.NONE || requestState.code === StatusCode.ERROR) {
        return undefined;
    }

    return {
        currentPage: requestState.data?.currentPage ?? 0,
        pageSize: requestState.data?.pageSize ?? 0,
        totalPages: requestState.data?.totalPages ?? 0,
    }
}

const Pagination = <T,>(props: PaginationProps<T>) => {

    const pagingInfo = getPagingInfo(props.requestState)

    // Don't display pager if there is only 1 page
    if (pagingInfo === undefined || pagingInfo.totalPages <= 1) {
        return null;
    }

    const setPage = (page: number): void => {
        const newPage = getNewPageNumber(page);

        if (isCurrentPage(newPage)) {
            return;
        }

        props.onChangePage(newPage);
    }

    const getNewPageNumber = (page: number): number => {
        if (page > pagingInfo.totalPages) {
            return pagingInfo.totalPages;
        } else if (page < 1) {
            return 1;
        } else {
            return page;
        }
    }

    const getPageInterval = (showPages: number): PaginationInterval => {
        const interval = { start: 1, end: pagingInfo.totalPages };

        const highHalf = Math.ceil(showPages / 2)
        const lowHalf = Math.floor(showPages / 2)

        // If there are more pages then [showPages] we need to calculate which to display.
        if (pagingInfo.totalPages > showPages) {
            if (pagingInfo.currentPage < lowHalf + 1) {
                interval.end = showPages;
            } else if (pagingInfo.currentPage + lowHalf > pagingInfo.totalPages) {
                interval.start = pagingInfo.totalPages - (showPages - 1);
            } else {
                interval.start = pagingInfo.currentPage - lowHalf;
                interval.end = pagingInfo.currentPage + (highHalf - 1);
            }
        }

        return interval;
    }

    const generatePages = (interval: PaginationInterval, screenSize: string): JSX.Element[] => {
        return Array.apply(0, Array(interval.end + 1 - interval.start)).map((_, index) => interval.start + index).map((page, index) => {
            const classNames = combineClassNamesIfCurrentPage(screenSize, 'active', page);
            return <li key={index} className={classNames} onClick={() => setPage(page)}>{page}</li>
        });
    }

    const combineClassNamesIfCurrentPage = (first: string | null, second: string, page: number): string => {
        const isCurrent = isCurrentPage(page);
        if (isCurrent && first) {
            return `${first} ${second}`;
        } else if (isCurrent) {
            return second;
        } else if (first) {
            return first;
        } else {
            return '';
        }
    }

    const isCurrentPage = (page: number): boolean => {
        return pagingInfo.currentPage === page;
    }

    const showEndPage = (totalPages: number, endPage: number): string | undefined => {
        if (totalPages === endPage) {
            return 'hide';
        }
    }

    const showStartPage = (startPage: number): string | undefined => {
        if (startPage === 1) {
            return 'hide';
        }
    }

    const renderPages = (interval: PaginationInterval, screenSize: string, totalPages: number): React.ReactNode => {
        return (<>
            <li className={showStartPage(interval.start) + ` ${screenSize}`} title="Första sidan" onClick={() => setPage(1)}>1</li>
            <span className={`${showStartPage(interval.start)} ${screenSize} pagination-dots`}>...</span>
            {generatePages(interval, screenSize)}
            <span className={`${showEndPage(totalPages, interval.end)} ${screenSize} pagination-dots`}>...</span>
            <li className={showEndPage(totalPages, interval.end) + ` ${screenSize}`} title="Sista sidan" onClick={() => setPage(totalPages)}>{totalPages}</li>
        </>)
    }

    const desktopInterval = getPageInterval(9);
    const mobileInterval = getPageInterval(3);

    return (
        <div className={props.className + " pagination-box"}>

            <ul className="pagination">
                <li className={combineClassNamesIfCurrentPage(null, 'disabled', 1)} onClick={() => setPage(pagingInfo.currentPage - 1)}>
                    <FontAwesomeIcon icon="chevron-left" />
                </li>

                {renderPages(mobileInterval, 'hideOnLg', pagingInfo.totalPages)}
                {renderPages(desktopInterval, 'showOnLg', pagingInfo.totalPages)}

                <li title="Nästa sida" className={combineClassNamesIfCurrentPage(null, 'disabled', pagingInfo.totalPages)} onClick={() => setPage(pagingInfo.currentPage + 1)}>
                    <FontAwesomeIcon icon="chevron-right" />
                </li>
            </ul>

        </div>
    );
};

export default Pagination;
