import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';


interface CheckboxListItemProps {
    text: string
    id: string
    name: string
    value: boolean
    onChange: React.ChangeEventHandler<HTMLInputElement>
    disabled?: boolean
}

const CheckboxListItem = (props: CheckboxListItemProps) => {
    return <FormGroup check>
        <Label check className={props.disabled ? 'disabled' : undefined}>
            <Input type="checkbox" id={props.id} name={props.name} checked={props.value || false} onChange={props.onChange} disabled={props.disabled} />
            {props.text}
        </Label>
    </FormGroup>;
};

export default CheckboxListItem;