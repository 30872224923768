import React from 'react'
import { OnChangeCallback } from '../../util/UtilityTypes'
import { OptionItem } from '../../util/UtilityTypes'
import { Input, FormFeedback } from 'reactstrap';

type SelectInputType = 'number' | 'text'

interface SelectInputProps<TValue extends string | number> {
    name: string
    type?: SelectInputType
    defaultText: string
    defaultValue?: TValue
    selected?: TValue
    disabled?: boolean
    onChange: OnChangeCallback<TValue | undefined>
    items: OptionItem<TValue>[]
    invalid?: boolean 
    invalidFeedback?: string
    disableSort?: boolean
    className?: string
}

// NOTE: This is equvivalent with 'React.FC<SelectFilterProps<TValue>>' but neccesarry to support generics
const SelectInput = <TValue extends string | number>(props: SelectInputProps<TValue>) => {
    const type = props.type ?? 'text';
    const defaultValue = props.defaultValue ?? (type === 'number' ? 0 : '') as TValue;
    
    const onChangeHandler: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        if (event.target.value === defaultValue) {
            props.onChange(props.name, undefined);
        } else if (typeof selected === 'number') {
            props.onChange(props.name, parseInt(event.target.value) as TValue);
        } else {
            props.onChange(props.name, event.target.value as TValue);
        }
    }

    let selected = defaultValue;
    if (props.selected !== null && props.selected !== undefined) {
        selected = props.selected;
    }

    let items = props.items; 
    if (!props.disableSort && items) {
        items = items.sort(comparer);
    }

    return (<div className={props.className ?? ''}>
        <Input type="select" value={selected} disabled={props.disabled} onChange={onChangeHandler} invalid={props.invalid} className="select-input">
            <option value={defaultValue}>{props.defaultText}</option>
            {items && items
                .filter((item) => item.value !== defaultValue)
                .map((item, i) => (<option key={i} value={item.value}>{item.text}</option>))}
        </Input>
        {props.invalid && <FormFeedback style={{ display: 'block' }}>{props.invalidFeedback}</FormFeedback>}
    </div>);
}

const comparer = <TValue extends string | number>(a: OptionItem<TValue>, b: OptionItem<TValue>): number => {
    if (a.text < b.text) {
        return -1;
    }

    if (a.text > b.text) {
        return 1;
    }

    return 0;
}

export default SelectInput;