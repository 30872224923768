import React from 'react';
import StatusCode from '../../util/StatusCode';
import * as InstanceLogsUtil from '../../util/InstanceLogsUtil';
import * as Helper from '../../util/Helper';
import TableHorizontal from './TableHorizontal';
import { Result } from '../../models/CoreModels';
import { CoreObjectLogs } from '../../models/CoreObjectLogs';
import { Link } from 'react-router-dom';


interface ObjectLogsProps {
    objectLogs: Result<CoreObjectLogs[]>
    noResMsg: string
}


const ObjectLogs: React.FC<ObjectLogsProps> = (props: ObjectLogsProps) => {
    const formattedObjectLogs = props.objectLogs.data?.map((o, i) => ({
        id: `${i}`,
        data: [
            { value: <Link to={`/module/${o.module.id}/status`}>{o.module.title}</Link> },
            { value: Helper.formatDate(o.timestamp) },
            { value: InstanceLogsUtil.severityById(o.severity) },
            { value: o.message },
            { value: o.eventId },
        ]
    })) ?? [];

    return (<div>
        <h5 className="header-menu">Logginformation</h5>
        <TableHorizontal
            header={[{ value: 'Modul' }, { value: 'Tidpunkt' }, { value: 'Allvarlighetsgrad' }, { value: 'Meddelande' }, { value: 'Event-id' }]}
            info={formattedObjectLogs ?? []}
            error={props.objectLogs.code === StatusCode.ERROR}
            searching={props.objectLogs.code === StatusCode.PENDING}
            noResMsg={props.noResMsg}
        />
    </div>);
}

export default ObjectLogs;
