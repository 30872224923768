import React from 'react';
import { FormGroup } from 'reactstrap';
import { OnChangeCallback } from '../../../../../../util/UtilityTypes'
import CheckboxListItem from '../../../../../misc/CheckboxListItem';
import HaldorUnifiedSetting from './HaldorUnifiedSetting';
import { CoreHaldorMentorGroupSetting } from '../../../../../../models/CoreHaldorModels';
import { CoreHaldorUnifiedSetting } from '../../../../../../models/CoreHaldorModels';

interface HaldorMentorGroupSettingProps {
    title: string
    name: string
    value: CoreHaldorMentorGroupSetting
    onChange: OnChangeCallback<CoreHaldorMentorGroupSetting>
    disabled?: boolean
}

const HaldorMentorGroupSetting: React.FC<HaldorMentorGroupSettingProps> = (props) => {

    const updatePropertyValue: OnChangeCallback<boolean | CoreHaldorUnifiedSetting> = (prop, value) => {
        props.onChange(props.name, {
            classUnifiedGroup: prop === 'classUnifiedGroup' ? value as CoreHaldorUnifiedSetting : props.value.classUnifiedGroup,
            departmentUnifiedGroup: prop === 'departmentUnifiedGroup' ? value as CoreHaldorUnifiedSetting : props.value.departmentUnifiedGroup,
            mentorUnifiedGroup: prop === 'mentorUnifiedGroup' ? value as CoreHaldorUnifiedSetting : props.value.mentorUnifiedGroup,
            classOneRoster: prop === 'classOneRoster' ? value as boolean : props.value.classOneRoster,
            departmentOneRoster: prop === 'departmentOneRoster' ? value as boolean : props.value.departmentOneRoster,
        })
    }

    return (
        <FormGroup tag="fieldset">

            <h5>{props.title}</h5>

            <HaldorUnifiedSetting
                name='classUnifiedGroup'
                basedOnText='klass'
                value={props.value.classUnifiedGroup}
                onChange={updatePropertyValue}
                disabled={props.disabled}
                disableEnableSetting={false}
            />

            <CheckboxListItem text={'Baserat på klass från OneRoster'}
                id={'classOneRoster'}
                name={'classOneRoster'}
                value={props.value.classOneRoster}
                onChange={(e) => updatePropertyValue(e.target.name, e.target.checked)}
                disabled={props.disabled}
            />

            <HaldorUnifiedSetting
                name='departmentUnifiedGroup'
                basedOnText='avdelning'
                value={props.value.departmentUnifiedGroup}
                onChange={updatePropertyValue}
                disabled={props.disabled}
                disableEnableSetting={false}
            />

            <CheckboxListItem text={'Baserat på avdelning från OneRoster'}
                id={'departmentOneRoster'}
                name={'departmentOneRoster'}
                value={props.value.departmentOneRoster}
                onChange={(e) => updatePropertyValue(e.target.name, e.target.checked)}
                disabled={props.disabled}
            />

            <HaldorUnifiedSetting
                name='mentorUnifiedGroup'
                basedOnText='mentorgrupp'
                value={props.value.mentorUnifiedGroup}
                onChange={updatePropertyValue}
                disabled={props.disabled}
                disableEnableSetting={false}
            />

        </FormGroup>
    )
}

export default HaldorMentorGroupSetting;