import React from 'react';

type UpdateCallback = (checked: boolean) => void;

interface ActivityToggleProps {
    id?: string
    onlyActive: boolean
    disabled: boolean
    update: UpdateCallback
}

const ActiveToogle: React.FC<ActivityToggleProps> = (props) => {

    const toggle: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        props.update(!e.target.checked);
    }

    let onlyActive = props.onlyActive !== null ? !props.onlyActive : false;

    return (
        <div className="toggle">
            <p>Visa inaktiva</p>
            <div className="toggle-switch">
                <input
                    type="checkbox"
                    className="toggle-switch-checkbox"
                    name={`active-${props.id}`}
                    id={`active-${props.id}`}
                    checked={onlyActive}
                    onChange={toggle}
                    disabled={props.disabled}
                />
                <label className="toggle-switch-label" htmlFor={`active-${props.id}`}>
                    <span className="toggle-switch-inner"
                        data-yes="På"
                        data-no="Av"
                    />
                    <span className="toggle-switch-switch" />
                </label>
            </div>
        </div>
    )
}

export default ActiveToogle;
