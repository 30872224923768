import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { GetStudentActivities, GetTeacherActivities, UserActivitiesRequestState, UserState } from "../../../store/UserAction";
import * as Helper from '../../../util/Helper';
import StatusCode from "../../../util/StatusCode";
import ActiveToggle from "../../misc/ActiveToggle";
import TableHorizontalPagedToggle, { InfoObject } from "../../misc/TableHorizontalPagedToggle";

interface UserViewActivitiesState {
    users: UserState
}

const UserViewActivities = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const [show, setShow] = useState({ studentActivitites: true, teacherActivities: true })
    const studentActivities = useSelector<UserViewActivitiesState, UserActivitiesRequestState>(state => state.users.studentActivities);
    const teacherActivities = useSelector<UserViewActivitiesState, UserActivitiesRequestState>(state => state.users.teacherActivities);

    useEffect(() => {
        if (userId) {
            GetTeacherActivities(userId, { ...teacherActivities.filter })(dispatch);
            GetStudentActivities(userId, { ...studentActivities.filter })(dispatch);
        }
    }, [dispatch, userId]);

    const updateTeacherActivitiesPage = (value: number) => {
        if (userId) {
            GetTeacherActivities(userId, { ...teacherActivities.filter, page: value })(dispatch);
        }
    }

    const updateStudentActivitiesPage = (value: number) => {
        if (userId) {
            GetStudentActivities(userId, { ...studentActivities.filter, page: value })(dispatch);
        }
    }

    const updateOnlyActive = (value: boolean) => {
        if (userId) {
            GetStudentActivities(userId, { ...studentActivities.filter, onlyActive: value, page: 1 })(dispatch);
            GetTeacherActivities(userId, { ...teacherActivities.filter, onlyActive: value, page: 1 })(dispatch);
        }
    }

    const mapTeacherActivities = (activities: UserActivitiesRequestState): InfoObject[] => {
        if (activities.code !== StatusCode.COMPLETE) {
            return [];
        }

        return activities.data.values.map(x => ({
            id: x.id,
            data: [
                { value: <Link to={`/activity/${x.id}/information`}>{x.displayName}</Link> },
                { value: <Link to={`/schoolunit/${x.schoolUnitId}/information`}>{x.schoolUnitDisplayName}</Link> },
                { value: Helper.active(x.active) },
                { value: Helper.formatDateOnly(x.startDate) },
                { value: Helper.formatDateOnly(x.endDate) },
            ]
        }));
    }

    const mapStudentActivities = (activities: UserActivitiesRequestState): InfoObject[] => {
        if (activities.code !== StatusCode.COMPLETE) {
            return [];
        }

        return activities.data.values.map(x => ({
            id: x.id,
            data: [
                { value: <Link to={`/activity/${x.id}/information`} >{x.displayName}</Link> },
                { value: <Link to={`/schoolunit/${x.schoolUnitId}/information`}>{x.schoolUnitDisplayName}</Link> },
                { value: Helper.active(x.active) },
                { value: Helper.formatDateOnly(x.startDate) },
                { value: Helper.formatDateOnly(x.endDate) },
                x.studentGroupTitle ? { value: <Link to={`/group/${x.studentGroupId}/information`}>{x.studentGroupTitle}</Link> } : { value: 'Direktkopplad' }
            ]
        }));
    }

    const renderStudentActivities = () => {
        const studentInfo = mapStudentActivities(studentActivities);
        const count = Helper.getTotalCountText(studentActivities);
        return (
            <TableHorizontalPagedToggle
                header={[{ value: `Elevkoppling ${count}` }, { value: 'Skola' }, { value: 'Aktiv' }, { value: 'Startdatum' }, { value: 'Slutdatum' }, { value: 'Grupp' }]}
                mobileHeader={['Aktivitet', 'Skola', 'Aktiv', 'Startdatum', 'Slutdatum', 'Grupp']}
                info={studentInfo}
                show={show.studentActivitites}
                toggle={() => setShow({ ...show, studentActivitites: !show.studentActivitites })}
                displayName={`Elevkopplingar ${count}`}
                noResultMsg="Den här personen saknar elevaktiviteter"
                onChangePage={updateStudentActivitiesPage}
                requestState={studentActivities}
            />
        )
    }

    const renderTeacherActivities = () => {
        const teacherInfo = mapTeacherActivities(teacherActivities);
        const count = Helper.getTotalCountText(teacherActivities);
        return (
            <TableHorizontalPagedToggle
                header={[{ value: `Lärarkoppling ${count}` }, { value: 'Skola' }, { value: 'Aktiv' }, { value: 'Startdatum' }, { value: 'Slutdatum' }]}
                mobileHeader={['Aktivitet', 'Skola', 'Aktiv', 'Startdatum', 'Slutdatum']}
                info={teacherInfo}
                show={show.teacherActivities}
                toggle={() => setShow({ ...show, teacherActivities: !show.teacherActivities })}
                displayName={`Lärarkopplingar ${count}`}
                noResultMsg="Den här personen saknar läraraktiviteter"
                onChangePage={updateTeacherActivitiesPage}
                requestState={teacherActivities}
            />
        )
    }

    return (<>
        <div className="header_member_table large">Aktiviteter
            <span className="right">
                <ActiveToggle
                    onlyActive={studentActivities?.filter?.onlyActive ?? true}
                    update={updateOnlyActive}
                    disabled={studentActivities.code === StatusCode.PENDING || teacherActivities.code === StatusCode.PENDING}
                />
            </span>
        </div>
        {renderTeacherActivities()}
        {renderStudentActivities()}
    </>)

}

export default UserViewActivities;
