import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom'
import TableHorizontalPagedToggle, { InfoObject } from '../../misc/TableHorizontalPagedToggle';
import ActiveToggle from '../../misc/ActiveToggle';
import * as Helper from '../../../util/Helper';
import StatusCode from '../../../util/StatusCode';
import { GetActivityTeachers, GetActivityStudents, ActivityState, ActivityUsersRequestState } from '../../../store/ActivityAction';

interface ActivityViewMembersState {
    activities: ActivityState
}

const ActivityViewMembers = () => {
    const { activityId } = useParams();
    const dispatch = useDispatch();

    const teachers = useSelector<ActivityViewMembersState, ActivityUsersRequestState>(state => state.activities.activityTeachers);
    const students = useSelector<ActivityViewMembersState, ActivityUsersRequestState>(state => state.activities.activityStudents);

    const [show, setShow] = useState({ student: true, teacher: true });

    useEffect(() => {
        if (activityId) {
            GetActivityStudents(activityId, { ...students.filter })(dispatch);
            GetActivityTeachers(activityId, { ...teachers.filter })(dispatch);
        }
    }, [dispatch, activityId])

    const updateTeachersPage = (value: number) => {
        if (activityId) {
            GetActivityTeachers(activityId, { ...teachers.filter, page: value })(dispatch);
        }
    }

    const updateStudentsPage = (value: number) => {
        if (activityId) {
            GetActivityStudents(activityId, { ...students.filter, page: value })(dispatch);
        }
    }

    const updateOnlyActive = (value: boolean) => {
        if (activityId) {
            GetActivityStudents(activityId, { ...students.filter, onlyActive: value, page: 1 })(dispatch);
            GetActivityTeachers(activityId, { ...teachers.filter, onlyActive: value, page: 1 })(dispatch);
        }
    }

    const mapTeachers = (users: ActivityUsersRequestState): InfoObject[] => {
        if (users.code !== StatusCode.COMPLETE) {
            return [];
        }

        return users.data.values.map(x => ({
            id: x.id,
            data: [
                { value: <Link to={`/user/${x.id}/information`}>{x.title}</Link> },
                { value: x.emailAddresses?.map(e => e).join(', ') },
                { value: Helper.active(x.active) },
            ]
        }));
    }

    const mapStudents = (users: ActivityUsersRequestState) => {
        if (users.code !== StatusCode.COMPLETE) {
            return [];
        }

        return users.data.values.map(x => ({
            id: x.id,
            data: [
                { value: <Link to={`/user/${x.id}/information`}>{x.title}</Link> },
                { value: x.emailAddresses?.map(e => e).join(', ') },
                { value: <span className="joined-list">{x.studentGroups?.map(sg => (<Link to={`/group/${sg.id}/information`} key={sg.id}>{sg.title}</Link>))}</span> },
                { value: Helper.active(x.active) },
            ]
        }));
    }

    const renderTeachers = () => {
        const count = Helper.getTotalCountText(teachers);
        return (
            <TableHorizontalPagedToggle
                header={[{ value: `Lärare ${count}` }, { value: 'E-postadress' }, { value: 'Aktiv' }]}
                mobileHeader={['Namn', 'E-postadress', 'Aktiv']}
                info={mapTeachers(teachers)}
                show={show.teacher}
                toggle={() => setShow({ ...show, teacher: !show.teacher })}
                displayName={`Lärare ${count}`}
                noResultMsg="Den här aktiviteten saknar lärare"
                onChangePage={updateTeachersPage}
                requestState={teachers}
            />
        )
    }

    const renderStudents = () => {
        const count = Helper.getTotalCountText(students);
        return (
            <TableHorizontalPagedToggle
                header={[{ value: `Elever ${count}` }, { value: 'E-postadress' }, { value: "Elevgrupp" }, { value: 'Aktiv' }]}
                mobileHeader={['Namn', 'E-postadress', 'Elevgrupp', 'Aktiv']}
                info={mapStudents(students)}
                show={show.student}
                toggle={() => setShow({ ...show, student: !show.student })}
                displayName={`Elever ${count}`}
                noResultMsg="Den här aktiviteten saknar elever"
                onChangePage={updateStudentsPage}
                requestState={students}
            />
        )
    }

    return (<>
        <div className="header_member_table large">Medlemmar
            <span className="right">
                <ActiveToggle
                    id="members"
                    onlyActive={students?.filter?.onlyActive ?? true}
                    update={updateOnlyActive}
                    disabled={teachers.code === StatusCode.PENDING || students.code === StatusCode.PENDING}
                />
            </span></div>
        {renderTeachers()}
        {renderStudents()}
    </>);
}

export default ActivityViewMembers;
