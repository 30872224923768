import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListAuditLog, ListAuditLogEventTypes } from '../../store/AuditLogAction';
import debounce from 'lodash/debounce';
import { Row, Col, Button } from 'reactstrap';
import * as Helper from '../../util/Helper';
import * as AuditLogEvent from '../../util/AuditLogEvent';
import SelectInput from '../misc/SelectInput';
import { Link, useSearchParams } from 'react-router-dom';
import StatusCode from '../../util/StatusCode';
import ModalComponent from '../misc/ModalComponent';
import TableHorizontal from '../misc/TableHorizontal';
import store from '../../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Search from '../misc/Search';
import FilterBottomComponent from '../misc/FilterBottomComponent';


const AuditLog = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [additionalInformation, setAdditionalInformation] = useState(undefined);

    const logs = useSelector(state => state.auditlog.logs);
    const eventTypes = useSelector(state => state.auditlog.eventTypes);
    const isAdmin = useSelector(state => state.me.isAdmin);
    const me = useSelector(state => state.me.me);

    const filter = Object.fromEntries(searchParams.entries());

    useEffect(() => {
        if (filter.search) {
            setSearch(filter.search);
        }
        ListAuditLogEventTypes()(dispatch);
    }, [dispatch]);

    useEffect(() => {
        ListAuditLog(filter, true)(dispatch);
        window.scrollTo(0, 0);
    }, [searchParams]);

    useEffect(() => {
        const interval = setInterval(() => {
            const storeFilter = store.getState();
            ListAuditLog(storeFilter.auditlog.filter, false)(dispatch);
        }, 60000)
        return () => clearInterval(interval);
    }, []);

    const updateFilter = (name, value) => {
        if (name !== 'page') {
            searchParams.set('page', 1);
        }
        if (value) {
            searchParams.set(name, value);
        }
        else {
            searchParams.delete(name);
        }
        setSearchParams(searchParams);
    }

    const debouncedSearch = useCallback(
        debounce(search => {
            updateFilter('search', search);
        }, 300)
        , []);

    const onSearch = (e) => {
        e.preventDefault();
        let searchValue = e.target.value;
        setSearch(searchValue);
        debouncedSearch(searchValue);
    }

    const clearFilter = () => {
        setSearch('');
        setSearchParams([]);
    }

    const sourceName = (log, eventType) => {
        const masterUrl = me.masterUrl;
        const customerId = me.customerId;

        switch (log.type) {
            case 1: {
                if (eventType.eventId === 10003) {
                    return <Link to={{ pathname: `/module/${log.id}/status` }} target="_blank">{log.name}</Link>
                }
                return <Link to={{ pathname: `/module/${log.id}/configuration` }} target="_blank">{log.name}</Link>
            }
            case 3: {
                return <a href={`${masterUrl}/license/${customerId}`} target="_blank">{log.name}</a>
            }
            case 4: {
                return <a href={`${masterUrl}/permissiongroups`} target="_blank">{log.name}</a>
            }
            case 5: {
                return <a href={`${masterUrl}/permissions/${customerId}`} target="_blank">{log.name}</a>
            }
            case 7: {
                return <Link to={{ pathname: `/user/${log.id}` }} target="_blank">{log.name}</Link>
            }
            default: return log.name
        }
    }

    const renderAuditLogs = () => {
        const listAuditLogs = logs.data?.map(log => ({
            id: log.id,
            data: [
                { value: AuditLogEvent.translateEvent(log.event, 'sv-SE') },
                { value: sourceName(log.source, log.event) },
                { value: AuditLogEvent.sourceTypeFromId(log.source.type) },
                { value: log.user.displayName + ', ' + log.user.email },
                { value: Helper.formatDate(log.timestamp) },
                { value: log.additionalInformation && <Button className="icon-btn right" onClick={() => (setOpen(true), setAdditionalInformation(log.additionalInformation))} title="Visa avancerad information"><FontAwesomeIcon icon="ellipsis-vertical" /></Button> }
            ]
        })) ?? [];

        return (
            <div>
                <TableHorizontal
                    header={[{ value: 'Beskrivning' }, { value: 'Titel' }, { value: 'Typ' }, { value: 'Användare' }, { value: 'Tidpunkt' }, { value: 'Avancerad information' }]}
                    info={listAuditLogs}
                    error={logs.code === StatusCode.ERROR}
                    searching={logs.code === StatusCode.PENDING}
                    displayName="Granskningslogg"
                    noResMsg="Inga granskningslogg hittades"
                />
            </div>
        )
    }

    const renderFilter = () => {
        return (
            <Row>
                <Col sm="6">
                    <Search value={search} onChange={onSearch} placeholder="Sök granskningslogg" />
                </Col>
                <Col sm="6">
                    <SelectInput
                        items={eventTypes.code === StatusCode.COMPLETE && eventTypes.data.map(e => ({ value: e.eventId, text: `Filtrera på ${AuditLogEvent.translateEvent(e, 'sv-SE')}` }))}
                        onChange={(name, value) => updateFilter(name, value)}
                        name='eventId'
                        selected={filter.eventId}
                        defaultText='Visa alla beskrivningar'
                        disabled={eventTypes.code !== StatusCode.COMPLETE}
                    />
                </Col>
            </Row>
        )
    }

    const renderBody = () => {
        if (isAdmin === false) {
            return "Du saknar behörighet för att se denna sida"
        } else {
            return (<>

                {renderAuditLogs()}

                {open && <ModalComponent
                    isOpen={open}
                    toggleModal={() => (setOpen(false), setAdditionalInformation(undefined))}
                    header="Avancerad information"
                    size="xl"
                    cancel="Stäng"
                >
                    <div>
                        <pre>{Helper.formatText(additionalInformation)}</pre>
                    </div>
                </ModalComponent>}
            </>)
        }
    }

    const requestState = Helper.convertToCacheRequestState(logs);

    return (
        <div className="container auditlog">
            <div className="header--fixed">
                <h1>Granskningslogg</h1>
                {renderFilter()}
            </div>
            <div className="body--scroll padding-40">
                {renderBody()}
            </div>

            <FilterBottomComponent
                clearFilter={() => clearFilter()}
                updateFilter={(page) => updateFilter("page", page)}
                requestState={requestState}
            />

        </div>
    )
}

export default AuditLog;
