import React, { useState } from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { OnChangeCallback } from '../../util/UtilityTypes'

interface NullableInputProps {
    title: string
    name: string
    value?: string | null
    checkboxTitle?: string
    titleSmall?: boolean
    disabled?: boolean
    invalid?: boolean
    invalidFeedback?: string
    onChange: OnChangeCallback<string | null>
}

const NullableInput: React.FC<NullableInputProps> = (props) => {
    const [checked, setChecked] = useState(props.value !== null && props.value !== undefined)
    const [previous, setPrevious] = useState(props.value ?? '')

    const toggle = (shouldBeChecked: boolean) => {
        setChecked(shouldBeChecked);
        const value = shouldBeChecked ? previous : null;
        
        props.onChange(props.name, value)
    }

    const onChange = (value: string) => {
        setPrevious(value);
        props.onChange(props.name, value);
    }

    const onClickDisabled = () => {
        if (!props.disabled && !checked) {
            toggle(true)
        }
    }

    return (
        <FormGroup>
            {props.titleSmall ? <Label style={{ display: 'block' }}>{props.title}</Label> : <h5>{props.title}</h5>}
            <FormGroup check>
                <Input type="checkbox" style={{ marginTop: '10px' }} title={props.checkboxTitle}
                    disabled={props.disabled}
                    checked={checked}
                    onChange={(e) => toggle(e.target.checked)}
                />
                <FormGroup onClick={onClickDisabled} style={{ marginLeft: '10px' }}>
                    <Input className="md-w-50 lg-w-25" type="text" name={props.name} id={props.name}
                        value={props.value ?? ''}
                        disabled={props.disabled || !checked}
                        onChange={(e) => onChange(e.target.value)}
                        invalid={props.invalid}
                    />
                    <FormFeedback>{props.invalidFeedback}</FormFeedback>
                </FormGroup>
            </FormGroup>
        </FormGroup>
    );
}

export default NullableInput;
