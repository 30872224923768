import React from 'react';
import { MyModuleTypes, OneRosterDashboard, ScheduleSyncDashboard, SchoolAdminDashboard } from '../../../models/CoreDashboardModels';
import { Result } from '../../../models/CoreModels';
import StatusCode from '../../../util/StatusCode';
import Loading from '../../misc/Loading';
import AuthorizedLink from '../../auth/AuthorizedLink';
import { FlowSyncAccessPolicy } from '../../../models/FlowSyncAccessPolicy';


const schoolAdmin = [
    { name: "Skolor", cell: 'schoolUnits', link: "/schoolunit" },
    { name: "Aktiviteter", cell: 'activities', link: "/activity" },
    { name: "Grupper", cell: 'groups', link: "/group" },
    { name: "Personer", cell: 'users', link: "/user" },
];

const oneRoster = [
    { name: "Skolor", cell: 'schoolUnits', link: "/oneroster/schoolunit" },
    { name: "Avsnitt", cell: 'classes', link: "/oneroster/class?status=active" },
    { name: "Användare", cell: 'users', link: "/oneroster/user" },
];

const scheduleSync = [
    { name: "Användare", cell: 'users', link: "/schedulesync/user/" },
    { name: "Schemapositioner", cell: 'events', link: "/schedulesync/event/" },
];

type StringMap = { [key: string]: number }

type Data = {
    name: string
    cell: string | number
    link: string
};

interface HomeGeneralModulesProps {
    myModuleTypes: Result<MyModuleTypes>
    schoolAdminInfo: Result<SchoolAdminDashboard>
    oneRosterInfo: Result<OneRosterDashboard>
    scheduleSyncInfo: Result<ScheduleSyncDashboard>
}


const HomeGeneralModules = (props: HomeGeneralModulesProps) => {

    const renderGeneralModuleTypes = () => {
        if (props.myModuleTypes.code === StatusCode.PENDING) {
            return <Loading />;
        };
        if (props.myModuleTypes.code === StatusCode.ERROR) {
            return "Kunde inte hämta generell FlowSync information";
        };
        if (props.myModuleTypes.code === StatusCode.COMPLETE) {
            let modules = [];
            for (const [key, value] of Object.entries(props.myModuleTypes.data)) {
                if (value) {
                    modules.push(key)
                }
            };
            return modules.map(x => {
                return <span key={x}>{ModuleType(x)}</span>
            });
        };
    };

    const ModuleType = (value: string) => {
        switch (value) {
            case "schoolAdmin": return renderFlowSyncInfo(props.schoolAdminInfo, "Skoladministration", schoolAdmin);
            case "oneRoster": return renderFlowSyncInfo(props.oneRosterInfo, "OneRoster", oneRoster);
            case "scheduleSync": return renderFlowSyncInfo(props.scheduleSyncInfo, "Schemapublicering", scheduleSync);
            default: return;
        }
    };

    const renderFlowSyncInfo = (info: Result<StringMap>, header: string, data: Data[]) => {
        return (
            <div className="dashboard_module">
                <div className="dashboard_top">
                    <h5>{header}</h5>
                </div>
                <div className="dashboard_content">
                    {(() => {
                        switch (info.code) {
                            case StatusCode.PENDING: return <Loading />;
                            case StatusCode.ERROR: return <p>Kunde inte hämta information</p>;
                            case StatusCode.COMPLETE: {
                                return (
                                    data.map((x, i) => (
                                        <p key={i}>
                                            <AuthorizedLink accessPolicy={FlowSyncAccessPolicy.READ} to={x.link}>
                                                {`${x.name}: ${info.data[x.cell].toLocaleString()}`}
                                            </AuthorizedLink>
                                        </p>
                                    ))
                                )
                            }
                        }
                    })()}
                </div>
            </div>);
    }

    return (<>
        {renderGeneralModuleTypes()}
    </>);
}

export default HomeGeneralModules;
