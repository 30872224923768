import { ErrorMessageDictionary } from "../util/ErrorMessageDictionaries";
import { ErrorRequestState } from "./RequestState";

interface ErrorObject {
    error: string
    description: string
    extensions?: { [key: string]: any }
}

export const getErrorObject = (requestState: ErrorRequestState, dictionary?: ErrorMessageDictionary): ErrorObject => {
    var data = requestState.error.response?.data;
    if (!data) {
        return {
            error: 'EmptyResponse',
            description: dictionary?.__default ?? 'Unknown error',
            extensions: {
                message: requestState.error.message,
                status: requestState.error.response?.status,
            },
        };
    }

    let errorCode: string | undefined = undefined;
    let description: string | undefined = undefined;
    let extensions: any | undefined = undefined;

    Object.entries(data).forEach(entry => {
        if (typeof (entry[0]) !== 'string') {
            return;
        }

        switch (entry[0]) {
            case 'error': {
                if (typeof (entry[1]) === 'string') {
                    errorCode = entry[1]
                }
                break;
            }
            case 'description': {
                if (typeof (entry[1]) === 'string') {
                    description = entry[1]
                }
                break;
            }
            case 'extensions': {
                if (typeof (entry[1]) === 'object') {
                    extensions = entry[1]
                }
                break;
            }
            default:
                break;
        }
    });

    if (!errorCode || !description) {
        return {
            error: 'InvalidFormat',
            description: dictionary?.__default ?? 'Unknown error',
            extensions: data,
        };
    }

    if (!dictionary) {
        return { error: errorCode, description, extensions };
    }

    return {
        error: errorCode,
        description: dictionary[errorCode] ?? dictionary.__default,
        extensions,
    };
}

export default ErrorObject;