import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table } from 'reactstrap';
import { getOneRosterInfo } from '../../store/OneRoster/OneRoster_DashboardAction';
import StatusCode from '../../util/StatusCode';
import Loading from '../misc/Loading';
import { Link } from 'react-router-dom'
import * as Helper from '../../util/Helper';

const info = {
    schoolUnits: { name: 'Skolor', link: '/oneroster/schoolunit/' },
    classes: { name: 'Avsnitt', link: '/oneroster/class/' },
    teachers: { name: 'Lärare', link: '/oneroster/user?role=teacher' },
    students: { name: 'Elever', link: '/oneroster/user?role=student' }
}

class OneRoster_Dashboard extends Component {

    componentDidMount() {
        this.props.getOneRosterInfo();
    }

    renderBody() {
        const { oneroster } = this.props;
        if (oneroster.code === StatusCode.PENDING) {
            return <Loading />;
        }
        if (oneroster.code === StatusCode.ERROR) {
            return <p>Något gick fel</p>
        }

        return (
            <div>

                <h3>Antal aktiva</h3>
                <div className="list-group list-group-horizontal-lg">
                    {Object.entries(info).map(entry => {
                        const [key, value] = entry;
                        return (
                            <Link to={value.link} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center flex-fill" key={entry}>
                                {value.name}
                                <span className="badge bg-primary rounded-pill">{oneroster.data[key]}</span>
                            </Link>
                        )
                    })}
                </div>

                <h3 className="mt-3">Avsnittsöversikt</h3>
                <div className="mobile-header-table">Läsår</div>
                <Table className="overwiew-table">
                    <thead>
                        <tr>
                            <th>Start</th>
                            <th>Slut</th>
                            <th>Aktiva, ej provisionerade</th>
                            <th>Aktiva, provisionerade</th>
                            <th>Inaktiva</th>
                            <th>Arkiverade</th>
                        </tr>
                    </thead>
                    <tbody>
                        {oneroster.data.classStats.map(x => (
                            <tr key={x.start}>
                                <td data-label="Start">{Helper.formatDateOnly(x.start)}</td>
                                <td data-label="Slut">{Helper.formatDateOnly(x.end)}</td>
                                <td data-label="Aktiva, ej provisionerade">{x.active}</td>
                                <td data-label="Aktiva, provisionerade">{x.activeProvisioned}</td>
                                <td data-label="Inaktiva">{x.inactive}</td>
                                <td data-label="Arkiverade">{x.archived}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }

    render() {
        return (
            <div className="container dashboard_oneroster padding-40">
                <h1>OneRoster</h1>
                {this.renderBody()}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    oneroster: state.oneroster_dashboard.oneroster,
})

export default connect(mapStateToProps, { getOneRosterInfo })(OneRoster_Dashboard);
