import React, { ReactNode } from 'react';
import { Table } from 'reactstrap';
import Loading from './Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface TableVerticalInfoObject {
    header: string
    content: ReactNode
    className?: string 
}

interface TableVerticalProps {
    searching: boolean
    error: boolean
    info: TableVerticalInfoObject[]
    header: string
    displayToggle?: boolean
    show?: boolean
    toggle?: () => void 
}

const TableVertical: React.FC<TableVerticalProps> = (props: TableVerticalProps) => {
    const renderBody = () => {
        return (<>
            <Table className="info_table">
                <tbody>
                    {props.info && props.info.map((row, i) =>
                        <tr key={i}>
                            <th className={row.className}>{row.header}</th>
                            <td data-label={row.header}>{row.content ?? '-'}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {props.searching && <Loading />}
            {props.error && <p>Kunde inte hämta</p>}
            {!props.error && !props.searching && !props.info.length && <p>Resultat saknas</p>}
        </>)
    }

    return (
        <div>
            <div className="header_member_table">{props.header}
                {props.displayToggle ?
                    <div onClick={props.toggle} className="right">
                        {props.show ? <FontAwesomeIcon title="Dölj information" icon="chevron-down" /> : <FontAwesomeIcon title="Visa information" icon="chevron-right" />}
                    </div> : null}
            </div>

            {props.displayToggle ?
                props.show && renderBody()
                :
                renderBody()}

        </div>
    )
}

export default TableVertical;
