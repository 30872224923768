import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { PageResult } from '../../models/CoreModels';
import { CacheRequestState } from '../../models/RequestState';
import Pagination, { OnChangePageCallback } from '../misc/Pagination';
import * as Helper from '../../util/Helper'

interface FilterBottomComponentProps<T> {
    updateFilter: OnChangePageCallback
    requestState?: CacheRequestState<PageResult<T>>
    clearFilter?: React.MouseEventHandler<HTMLButtonElement>
}

const FilterBottomComponent = <T,>(props: FilterBottomComponentProps<T>) => {
    const totalCount = Helper.getTotalCount(props.requestState);

    return (
        <div className="filter-bottom">
            <Row>
                <Col sm="3" className="vertical-center">
                    {totalCount !== undefined && <p className="small-italic">Totalt {totalCount} resultat</p>}
                </Col>
                <Col sm="6">
                    <Pagination
                        requestState={props.requestState}
                        onChangePage={(p) => props.updateFilter(p)} />
                </Col>
                <Col sm="3">
                    <Button onClick={props.clearFilter}>Nollställ filter</Button>
                </Col>
            </Row>
        </div>
    )
}

export default FilterBottomComponent;
