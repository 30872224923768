import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableHorizontal from '../misc/TableHorizontal';
import { AdministrationDashboardState, GetAdministrationInfo } from '../../store/AdministrationDashboardAction';
import StatusCode from '../../util/StatusCode';
import Loading from '../misc/Loading';
import { Link } from 'react-router-dom'
import * as Helper from '../../util/Helper';
import SelectInput from '../misc/SelectInput';
import { getModules, ModuleState } from '../../store/ModuleAction';
import { Button, Row, Col } from 'reactstrap';
import { ModuleTypeInfo } from '../../util/UtilityTypes';
import { CacheRequestState } from '../../models/RequestState';
import { CoreSchoolAdministrationDashboardInfo, SchoolYearClassStat } from '../../models/CoreDashboardModels';
import { InfoObject } from '../misc/TableHorizontalPagedToggle';

interface AdministrationDashboardViewState {
    admin_dashboard: AdministrationDashboardState
    module: ModuleState
}

type CountSelector = (data: CoreSchoolAdministrationDashboardInfo) => number;

interface CountInfo {
    key: string
    name: string,
    link: string,
    selector: CountSelector
}

const info: CountInfo[] = [
    { key: 'schoolUnitCount', name: 'Skolor', link: '/schoolunit', selector: (data) => data.schoolUnitCount },
    { key: 'groupCount', name: 'Grupper', link: '/group', selector: (data) => data.groupCount },
    { key: 'activityCount', name: 'Aktiviteter', link: '/activity', selector: (data) => data.activityCount },
    { key: 'userCount', name: 'Personer', link: '/user', selector: (data) => data.userCount }
];

const Administration_Dashboard = () => {
    const dispatch = useDispatch();

    const [selectedModule, setSelectedModule] = useState<ModuleTypeInfo | undefined>();
    const dashboard = useSelector<AdministrationDashboardViewState, CacheRequestState<CoreSchoolAdministrationDashboardInfo>>(state => state.admin_dashboard.dashboard);
    const modules = useSelector<AdministrationDashboardViewState, ModuleTypeInfo[]> (state => Helper.distinctSchoolAdminModuleTypes(state.module.modules));

    useEffect(() => {
        GetAdministrationInfo()(dispatch);
        getModules()(dispatch);
    }, [dispatch]);

    useEffect(() => {
        GetAdministrationInfo(selectedModule, true)(dispatch);
    }, [dispatch, selectedModule]);

    const updateFilter = (value?: string) => {
        if (value !== undefined) {
            var moduleType = Helper.getModuleTypeObj(modules, value);
            setSelectedModule(moduleType);
        } else {
            setSelectedModule(undefined);
        }
    }

    const getCount = (reqState: CacheRequestState<CoreSchoolAdministrationDashboardInfo>, selector: CountSelector): number => {
        if ((dashboard.code === StatusCode.COMPLETE || dashboard.code === StatusCode.PENDING) && dashboard.data) {
            return selector(dashboard.data);
        }

        return 0;
    }

    const renderBody = () => {
        if (dashboard.code === StatusCode.PENDING && !dashboard.data) {
            return <Loading />;
        }
        if (dashboard.code === StatusCode.ERROR) {
            return <p>Något gick fel</p>
        }
        return (
            <>
                <Row>
                    <Col sm="6">
                        <SelectInput
                            items={modules.map(m => ({ value: m.moduleTypeName, text: `Filtrera på ${m.moduleTypeDisplayName}` }))}
                            onChange={(name, value) => updateFilter(value)}
                            name='moduleTypeName'
                            selected={selectedModule?.moduleTypeName}
                            defaultText='Filtrera på modultyp'
                        />
                    </Col>
                    <Col sm="6">
                        <Button className="btn-primary" onClick={() => updateFilter()}>Rensa filter</Button>
                    </Col>
                </Row>

                {renderActive()}
                <div className="double-col">
                    {renderSchoolUnits()}
                    {renderGroups()}
                    {renderActivities()}
                    {renderUsers()}
                </div>
            </>
        )
    }

    const renderActive = () => (<>
        <h3 className="mt-3">Antal aktiva</h3>
        <div className="list-group list-group-horizontal-lg">
            {info.map(entry => {
                return (
                    <Link to={entry.link} className="list-group-item list-group-item-action d-flex justify-content-between align-items-center flex-fill" key={entry.key}>
                        {entry.name}
                        <span className="badge bg-primary rounded-pill">{getCount(dashboard, entry.selector)}</span>
                    </Link>
                )
            })}
        </div>
    </>)

    const renderSchoolUnits = () => {
        return (<div>
            <h3 className="mt-3">Skolöversikt</h3>
            {renderTable(data => data.schoolUnits)}
        </div>);
    };

    const renderGroups = () => {
        return (<div>
            <h3 className="mt-3">Gruppöversikt</h3>
            {renderTable(data => data.groups)}
        </div>);
    };

    const renderActivities = () => {
        return (<div>
            <h3 className="mt-3">Aktivitetsöversikt</h3>
            {renderTable(data => data.activities)}
        </div>);
    };

    const renderUsers = () => {
        return (<div>
            <h3 className="mt-3">Personöversikt</h3>
            {renderTable(data => data.users)}
        </div>);
    };

    const renderTable = (selector: (data: CoreSchoolAdministrationDashboardInfo) => SchoolYearClassStat[]) => {
        let info: InfoObject[] = [];
        if ((dashboard.code === StatusCode.COMPLETE || dashboard.code === StatusCode.PENDING) && dashboard.data) {
            info = selector(dashboard.data).map((x, i) => ({
                id: i.toString(),
                data: [
                    { value: Helper.formatDateOnly(x.start) },
                    { value: Helper.formatDateOnly(x.end) },
                    { value: x.active },
                    { value: x.inactive },
                ]
            }));
        }

        return (
            <TableHorizontal
                header={[{ value: 'Startdatum' }, { value: 'Slutdatum' }, { value: 'Aktiv' }, { value: 'Inaktiv' }]}
                info={info}
                error={dashboard.code === StatusCode.ERROR}
                searching={dashboard.code === StatusCode.PENDING}
            />
        )
    }

    return (
        <div className="container dashboard_oneroster padding-40">
            <h1>Skoladministration</h1>
            {renderBody()}
        </div>
    )
}

export default Administration_Dashboard;
