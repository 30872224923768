import React from 'react';
import { Link } from 'react-router-dom'

interface NotFoundProps {
    header: string
    link: string
    name: string
}

const NotFound: React.FC<NotFoundProps> = (props: NotFoundProps) => {
    return (
        <div className="padding-40 container single">
            <h1><Link to={props.link}>{props.header}</Link></h1>
            <p>{props.name} hittades inte. Gör en ny <Link to={props.link} >sökning</Link></p>
        </div>
    );
}

export default NotFound;
