import React from 'react';
import { connect } from 'react-redux';
import { Form, FormGroup, Label, Input, Card, CardBody, CardHeader, Collapse, Button, FormFeedback, Row, Col } from 'reactstrap';
import ConfigurationComponent from '../ConfigurationComponent';
import { getSystemNames } from '../../../../store/ModuleAction';
import BoolInput from '../../../misc/BoolInput';
import EnumInput from '../../../misc/EnumInput';
import NullableIntInputCheckboxText from '../../../misc/NullableIntInputCheckboxText';
import StatusCode from '../../../../util/StatusCode';
import UserSyncReportRecipients from './components/Shared/UserSyncReportRecipients';


const entraIdUserExportUserType = [
    { value: 1, text: 'Elever' },
    { value: 2, text: 'Personal' },
];

const entraIdUserExportCompanyNameSource = [
    { value: 0, text: 'Tomt värde' },
    { value: 1, text: 'Primär skola' },
    { value: 2, text: 'Konstant värde' },
];

const entraIdUserExportDepartmentNameSource = [
    { value: 0, text: 'Tomt värde' },
    { value: 1, text: 'Primär skola' },
    { value: 2, text: 'Konstant värde' },
];

const entraIdUserExportEmployeeIdSource = [
    { value: 0, text: 'Tomt värde' },
    { value: 1, text: 'Användarens id i FlowSync' },
    { value: 2, text: 'Personnummer' },
    { value: 3, text: 'Användarens id i elevregister' },
];

const entraIdUserExportEmployeeTypeSource = [
    { value: 0, text: 'Tomt värde' },
    { value: 1, text: 'Roll (Elev eller Personal)' },
];

const entraIdUserExportJobTitleSource = [
    { value: 0, text: 'Tomt värde' },
    { value: 1, text: 'Personalkategori (elever får kategorin Elev)' },
];


class EntraIdUserExport extends ConfigurationComponent {
    state = {
        open: true,
    };

    componentDidMount() {
        this.props.getSystemNames();
    }

    validate(configuration, active) {
        const validation = [];

        if (configuration.synchronizedUserType !== 1
            &&
            configuration.synchronizedUserType !== 2)
        {
            validation.push('synchronizedUserType')
        }
        if (configuration.companyNameSource === 2
            &&
            !configuration.companyNameConstant)
        {
            validation.push('companyNameConstant');
        }
        if (configuration.departmentSource === 2
            &&
            !configuration.departmentConstant)
        {
            validation.push('departmentConstant');
        }
        if (configuration.employeeIdSource === 3
            &&
            !configuration.employeeIdSourceName)
        {
            validation.push('employeeIdSourceName');
        }
        if (configuration.archiveGracePeriod < 0)
        {
            validation.push('archiveGracePeriod')
        }
        if (configuration.archiveGracePeriod === 0
            &&
            configuration.deleteGracePeriod !== null
            &&
            configuration.deleteGracePeriod < 14)
        {
            validation.push('deleteGracePeriod')
        }
        if (configuration.deleteGracePeriod < 0)
        {
            validation.push('deleteGracePeriod')
        }
        
        return validation;
    }

    warnings(update) {
        const warningMessages = [];

        return warningMessages;
    }

    render() {
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);
        const validation = this.validate(configuration, this.props.active);
        const disabledCompanyNameConstant = this.props.disableInput || configuration.companyNameSource !== 2;
        const disabledDepartmentConstant = this.props.disableInput || configuration.departmentSource !== 2;
        const disabledEmployeeIdSourceName = this.props.disableInput || configuration.employeeIdSource !== 3;


        return (
            <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    {this.props.moduleDisplayName}
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>
                        <div style={{ margin: '1rem 0' }}>
                                <EnumInput
                                    title='Typ av användare'
                                    name='synchronizedUserType'
                                    value={configuration.synchronizedUserType}
                                    options={entraIdUserExportUserType}
                                    onChange={(k, v) => this.updatePropertyValue(k, v)}
                                    disabled={this.props.disableInput}
                                    invalid={validation.indexOf('synchronizedUserType') !== -1}
                                    invalidFeedback='Välj vilken typ av användare den här modulen hanterar'
                                />

                                <FormGroup>
                                    <h5>Företagsnamn</h5>
                                    <EnumInput
                                        title='Hämta fältet companyName från:'
                                        titleSmall={true}
                                        name='companyNameSource'
                                        value={configuration.companyNameSource}
                                        options={entraIdUserExportCompanyNameSource}
                                        onChange={(k, v) => this.updatePropertyValue(k, v)}
                                        disabled={this.props.disableInput}
                                        invalid={validation.indexOf('companyNameSource') !== -1}
                                        invalidFeedback='Välj vad som ska synkroniseras till fältet companyName i EntraID'
                                    />
                                    <Input
                                        type="text"
                                        className="ms-3 md-w-50 lg-w-25"
                                        name="companyNameConstant"
                                        id="companyNameConstant"
                                        value={configuration.companyNameConstant || ''}
                                        disabled={disabledCompanyNameConstant}
                                        onChange={this.updateProperty}
                                        invalid={validation.indexOf('companyNameConstant') !== -1}
                                        autoComplete="off"
                                        />
                                    <FormFeedback className="ms-3">Konstant värde för företagsnamn måste anges för att kunna aktivera modulen</FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <h5>Avdelning</h5>
                                    <EnumInput
                                        title='Hämta fältet department från:'
                                        titleSmall={true}
                                        name='departmentSource'
                                        value={configuration.departmentSource}
                                        options={entraIdUserExportDepartmentNameSource}
                                        onChange={(k, v) => this.updatePropertyValue(k, v)}
                                        disabled={this.props.disableInput}
                                        invalid={validation.indexOf('departmentSource') !== -1}
                                        invalidFeedback='Välj vad som ska synkroniseras till fältet department i EntraID'
                                    />
                                    <Input
                                        type="text"
                                        className="ms-3 md-w-50 lg-w-25"
                                        name="departmentConstant"
                                        id="departmentConstant"
                                        value={configuration.departmentConstant || ''}
                                        disabled={disabledDepartmentConstant}
                                        onChange={this.updateProperty}
                                        invalid={validation.indexOf('departmentConstant') !== -1}
                                        autoComplete="off"
                                    />
                                    <FormFeedback className="ms-3">Konstant värde för avdelning måste anges för att kunna aktivera modulen</FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <h5>AnställningsId</h5>
                                    <EnumInput
                                        title='Hämta fältet employeeId från:'
                                        titleSmall={true}
                                        name='employeeIdSource'
                                        value={configuration.employeeIdSource}
                                        options={entraIdUserExportEmployeeIdSource}
                                        onChange={(k, v) => this.updatePropertyValue(k, v)}
                                        disabled={this.props.disableInput}
                                        invalid={validation.indexOf('employeeIdSource') !== -1}
                                        invalidFeedback='Välj vad som ska synkroniseras till fältet employeeId i EntraID'
                                    />
                                    <Input
                                        type="select"
                                        className="ms-3 md-w-50 lg-w-25"
                                        name="employeeIdSourceName"
                                        id="employeeIdSourceName"
                                        onChange={this.updateProperty}
                                        disabled={disabledEmployeeIdSourceName}
                                        value={configuration.employeeIdSourceName}
                                        invalid={validation.indexOf('employeeIdSourceName') !== -1} >
                                            <option value='' disabled>Ej vald</option>
                                            {this.props.systemNames.code === StatusCode.COMPLETE && this.props.systemNames.data.map((type, i) => <option key={i} value={type}>{type}</option>)}
                                    </Input>
                                    <FormFeedback className="ms-3">Elevregister måste väljas för att kunna aktivera modulen</FormFeedback>
                                </FormGroup>

                                <FormGroup>
                                    <h5>Anställingstyp</h5>
                                    <EnumInput
                                        title='Hämta fältet employeeType från:'
                                        titleSmall={true}
                                        name='employeeTypeSource'
                                        value={configuration.employeeTypeSource}
                                        options={entraIdUserExportEmployeeTypeSource}
                                        onChange={(k, v) => this.updatePropertyValue(k, v)}
                                        disabled={this.props.disableInput}
                                        invalid={validation.indexOf('employeeTypeSource') !== -1}
                                        invalidFeedback='Välj vad som ska synkroniseras till fältet employeeType i EntraID'
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <h5>Yrkesroll</h5>
                                    <EnumInput
                                        title='Hämta fältet jobTitle från:'
                                        titleSmall={true}
                                        name='jobTitleSource'
                                        value={configuration.jobTitleSource}
                                        options={entraIdUserExportJobTitleSource}
                                        onChange={(k, v) => this.updatePropertyValue(k, v)}
                                        disabled={this.props.disableInput}
                                        invalid={validation.indexOf('jobTitleSource') !== -1}
                                        invalidFeedback='Välj vad som ska synkroniseras till fältet jobTitle i EntraID'
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <BoolInput
                                        title="Exportera Hemadress"
                                        value={configuration.exportAddress}
                                        id="exportAddress"
                                        name="exportAddress"
                                        updateValue={this.updatePropertyValue}
                                        disabled={this.props.disableInput}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <h5>Livscykelhantering</h5>
                                    <NullableIntInputCheckboxText
                                        name="archiveGracePeriod"
                                        value={configuration.archiveGracePeriod}
                                        disabled={this.props.disableInput}
                                        title="Inaktivera användare (dagar efter inaktivering i FlowSync):"
                                        invalidFeedback="Ange dagar till inaktivering i EntraID"
                                        invalid={validation.indexOf('archiveGracePeriod') !== -1}
                                        defaultValue={0}
                                        onChange={(k, v) => this.updatePropertyValue(k, v)}
                                        checkboxTitle="Ingen inaktivering av användare"
                                    />
                                    <NullableIntInputCheckboxText
                                        name="deleteGracePeriod"
                                        value={configuration.deleteGracePeriod}
                                        disabled={this.props.disableInput}
                                        title="Ta bort (dagar efter inaktivering i EntraID):"
                                        invalidFeedback="Ange dagar till radering efter inaktivering av anvbändare"
                                        invalid={validation.indexOf('deleteGracePeriod') !== -1}
                                        defaultValue={365}
                                        onChange={(k, v) => this.updatePropertyValue(k, v)}
                                        checkboxTitle="Ingen radering"
                                    />
                                </FormGroup>

                                <UserSyncReportRecipients
                                    reportRecipients={configuration.reportRecipients}
                                    disableInput={this.props.disableInput}
                                    onChange={(this.updatePropertyValue)}
                                />
                        </div>
                    </CardBody>
                </Collapse>
            </Card>
        )
    }
};

const mapStateToProps = state => ({
    systemNames: state.module.systemNames
})

export default connect(mapStateToProps, { getSystemNames })(EntraIdUserExport);
