import React from 'react';
import { Route, Routes } from 'react-router-dom';

import {} from './components/styles/CssFiles';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight, faChevronLeft, faChevronDown, faBars, faLongArrowAltRight, faCogs, faCog, faSchool, faUser, faPlus, faPuzzlePiece, faNetworkWired, faChartLine, faFileImport, faFileExport, faCheck, faTriangleExclamation, faCopy, faSyncAlt, faBan, faCheckCircle, faToggleOff, faToggleOn, faTimesCircle, faExclamationCircle, faStopCircle, faCalendarAlt, faPowerOff, faUsers, faList, faTimes, faSignInAlt, faFile, faFileDownload, faFileAlt, faQuestionCircle, faPen, faTrash, faInfoCircle, faEllipsisVertical, faChartSimple, faClipboard, faShoePrints, faUsersRectangle, faArrowUpRightFromSquare, faEllipsis, faFolder, faCrown, faCarSide, faListCheck, faChartBar, faXmark, faArrowRotateLeft, faDiagramProject, faExclamation, faScroll, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons'
import { faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { faCircleCheck as farCircleCheck, faCircle as farCircle } from '@fortawesome/free-regular-svg-icons';

import Layout from './components/Layout';
import Home from './components/general/Home/Home';
import ModuleTriggerOverview from './components/ModuleTriggerOverview';
import Administration_Dashboard from './components/administration/Administration_Dashboard';
import SchoolUnitList from './components/administration/SchoolUnitList';
import SchoolUnitView from './components/administration/SchoolUnitView/SchoolUnitView';
import ActivityList from './components/administration/ActivityList';
import ActivityView from './components/administration/ActivityView/ActivityView';
import UserList from './components/administration/UserList';
import UserView from './components/administration/UserView/UserView';
import DutyView from './components/administration/DutyView/DutyView';
import GroupList from './components/administration/GroupList';
import GroupView from './components/administration/GroupView/GroupView';
import Private from './components/auth/Private';
import ModuleList from './components/configuration/ModuleList';
import AuditLog from './components/configuration/AuditLog';
import NotFound from './components/NotFound';
import Users_ScheduleSync from './components/schedulesync/Users_ScheduleSync';
import Events_ScheduleSync from './components/schedulesync/Events_ScheduleSync';
import Event_ScheduleSync from './components/schedulesync/Event_ScheduleSync';
import User_ScheduleSync from './components/schedulesync/User_ScheduleSync';
import InstanceLogs from './components/configuration/InstanceLog/InstanceLogs';

import OneRoster_Dashboard from './components/oneroster/OneRoster_Dashboard';
import OneRoster_SchoolUnits from './components/oneroster/OneRoster_SchoolUnits';
import OneRoster_Classes from './components/oneroster/OneRoster_Classes';
import OneRoster_Class from './components/oneroster/OneRoster_Class';
import OneRoster_Users from './components/oneroster/OneRoster_Users';
import OneRoster_User from './components/oneroster/OneRoster_User';
import OneRoster_SchoolUnit from './components/oneroster/OneRoster_SchoolUnit';
import NavSection from './util/NavSection';
import Configuration from './components/configuration/Configuration';
import DnpActivities from './components/configuration/components/modules/components/DnpExport/DnpActivities';
import { FlowSyncAccessPolicy } from './models/FlowSyncAccessPolicy';

library.add(faChevronRight, faChevronLeft, faChevronDown, faBars, faLongArrowAltRight, faCogs, faCog, faSchool, faUser, faPlus, faPuzzlePiece, faNetworkWired, faChartLine, faFileImport, faFileExport, faCheck, faGoogle, faMicrosoft, faTriangleExclamation, faCopy, faSyncAlt, faBan, faCheckCircle, faToggleOff, faToggleOn, faTimesCircle, faExclamationCircle, faStopCircle, faCalendarAlt, faPowerOff, faUsers, faList, faTimes, faSignInAlt, faFile, faFileDownload, faFileAlt, faQuestionCircle, faPen, faTrash, faInfoCircle, faEllipsisVertical, faChartSimple, faClipboard, faShoePrints, faUsersRectangle, faArrowUpRightFromSquare, faEllipsis, faFolder, faCrown, faCarSide, faListCheck, faChartBar, faXmark, faArrowRotateLeft, faDiagramProject, faExclamation, faScroll, faUpRightAndDownLeftFromCenter, farCircleCheck, farCircle);

const PrivateLayout = (props) => {
    return <Private accessPolicy={props.accessPolicy}><Layout navSection={props.navSection}>{props.children}</Layout></Private>
}

export default function App() {
    return (
        <Routes>
            <Route exact path='/' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ_OVERVIEW}><Home /></PrivateLayout>} />
            <Route exact path='/overview' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ_OVERVIEW}><ModuleTriggerOverview /></PrivateLayout>} />
            <Route exact path='/dashboard' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.SCHOOLADMINISTRATION}><Administration_Dashboard /></PrivateLayout>} />
            <Route exact path='/schoolunit' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.SCHOOLADMINISTRATION}><SchoolUnitList /></PrivateLayout>} />
            <Route path='/schoolunit/:schoolId/*' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.SCHOOLADMINISTRATION}><SchoolUnitView /></PrivateLayout>} />
            <Route path='/activity' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.SCHOOLADMINISTRATION}><ActivityList /></PrivateLayout>} />
            <Route path='/activity/:activityId/*' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.SCHOOLADMINISTRATION}><ActivityView /></PrivateLayout>} />
            <Route exact path='/user' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.SCHOOLADMINISTRATION}><UserList /></PrivateLayout>} />
            <Route path='/user/:userId/*' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.SCHOOLADMINISTRATION}><UserView /></PrivateLayout>} />
            <Route path='/duty/:dutyId/*' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.SCHOOLADMINISTRATION}><DutyView /></PrivateLayout>} />
            <Route exact path='/group' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.SCHOOLADMINISTRATION}><GroupList /></PrivateLayout>} />
            <Route exact path='/group/:groupId/*' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.SCHOOLADMINISTRATION}><GroupView /></PrivateLayout>} />

            <Route exact path='/schedulesync/user' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.SCHEDULESYNC}><Users_ScheduleSync /></PrivateLayout>} />
            <Route path='/schedulesync/user/:userId/*' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.SCHEDULESYNC}><User_ScheduleSync /></PrivateLayout>} />
            <Route exact path='/schedulesync/event' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.SCHEDULESYNC}><Events_ScheduleSync /></PrivateLayout>} />
            <Route path='/schedulesync/event/:eventId/*' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.SCHEDULESYNC}><Event_ScheduleSync /></PrivateLayout>} />

            <Route exact path='/module' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.CONFIGURATION}><ModuleList /></PrivateLayout>} />
            <Route path='/module/:moduleId/*' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.CONFIGURATION}><Configuration /></PrivateLayout>} />

            <Route path='/mapping/:dnpSchoolUnitId/:dnpExportId' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.CONFIGURATION}><DnpActivities /></PrivateLayout>} />

            <Route path='/module/:moduleId/instance/:moduleInstanceId/*' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.CONFIGURATION}><InstanceLogs /></PrivateLayout>} />
            <Route exact path='/auditlog' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.ADMIN} navSection={NavSection.CONFIGURATION}><AuditLog /></PrivateLayout>} />

            <Route exact path='/oneroster/dashboard' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.ONEROSTER}><OneRoster_Dashboard /></PrivateLayout>} />
            <Route exact path='/oneroster/schoolunit' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.ONEROSTER}><OneRoster_SchoolUnits /></PrivateLayout>} />
            <Route path='/oneroster/schoolunit/:schoolId/*' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.ONEROSTER}><OneRoster_SchoolUnit /></PrivateLayout>} />
            <Route exact path='/oneroster/class' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.ONEROSTER}><OneRoster_Classes /></PrivateLayout>} />
            <Route path='/oneroster/class/:eventId/*' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.ONEROSTER}><OneRoster_Class /></PrivateLayout>} />
            <Route exact path='/oneroster/user' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.ONEROSTER}><OneRoster_Users /></PrivateLayout>} />
            <Route path='/oneroster/user/:userId/*' element={<PrivateLayout accessPolicy={FlowSyncAccessPolicy.READ} navSection={NavSection.ONEROSTER}><OneRoster_User /></PrivateLayout>} />

            <Route path="*" element={<PrivateLayout><NotFound /></PrivateLayout>} />
        </Routes>
    );
}
