import React from 'react';
import { LanguageCode } from '../../util/LanguageCode';
import { ErrorRequestState } from '../../models/RequestState';
import errorMessageDictionaries from '../../util/ErrorMessageDictionaries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getErrorObject } from '../../models/ErrorObject';

interface CoreApiErrorProps {
    languageCode: LanguageCode,
    requestState: ErrorRequestState,
}

const CoreApiError: React.FC<CoreApiErrorProps> = (props) => {
    var message = getErrorMessage(props.languageCode, props.requestState);
    return <><FontAwesomeIcon title="ERROR" color="red" icon={['fas', 'triangle-exclamation']} /> {message}</>
}

const getErrorMessage = (languageCode: LanguageCode, requestState: ErrorRequestState) => {
    if (requestState.error.response?.status === 403) {
        switch (languageCode) {
            case 'sv_SE':
                return 'Du saknar behörighet';
            default:
                return 'Missing permission';
        }
    }

    const dictionary = errorMessageDictionaries[languageCode];
    return getErrorObject(requestState, dictionary).description
}

export default CoreApiError;