import React, { useState, useEffect } from "react";
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../misc/Loading';
import Turbo from '../misc/Turbo';
import { getModuleStatus, requestStart } from '../../store/ModuleAction';
import { subscribe } from '../../store/NotificationAction';
import * as Helper from '../../util/Helper';
import StatusCode from '../../util/StatusCode';
import { useParams, Link } from 'react-router-dom';
import InstancePhase from "./components/instancePhase/InstancePhase";


const ModuleStatus = () => {
    const { moduleId } = useParams();
    const dispatch = useDispatch();
    const [lastMessageId, setMessageId] = useState(undefined);

    const startRequest = useSelector(state => state.module.startRequest);
    const isAdmin = useSelector(state => state.me.isAdmin);
    const notification = useSelector(state => state.notification);
    const module = useSelector(state => state.module.moduleStatus);

    useEffect(() => {
        getModuleStatus(moduleId)(dispatch);
        return subscribe(['moduleInstanceStartRequest', 'moduleInstanceStart', 'moduleInstanceComplete'])(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (module.code === StatusCode.COMPLETE && lastMessageId !== notification.messageId) {
            setMessageId(notification.messageId);
            getModuleStatus(moduleId, false)(dispatch);
        }
    })

    const onClickRequestStart = async () => {
        await requestStart(moduleId)(dispatch);
    }

    const renderRequestStartStatus = () => {
        switch (startRequest.code) {
            case StatusCode.PENDING: return <Loading style={{ display: 'inline' }} />;
            case StatusCode.ERROR: return 'Något gick fel';
            case StatusCode.COMPLETE: return 'Lyckades';
            default: return null;
        }
    }

    const renderStatusMessage = () => {
        var isRunning = module.data.startedAt && !module.data.completedAt;

        if (!module.data.runnable) {
            return <div>Modulen kan inte köras</div>
        } else if (module.data.isInQuarantine) {
            return <span className="margin-right1"><FontAwesomeIcon icon="stop-circle" className="icon red" /> Modulen är satt i karantän</span>;
        } else if (isRunning) {
            return <span className="margin-right1"><FontAwesomeIcon icon="sync-alt" className="icon green" /> Körs</span>;
        } else if (module.data.isStartRequested) {
            return (<div>
                <div>Startar</div>
                {module.data.runningTrailingModule && <div className="push--vertical flex"><FontAwesomeIcon icon="info-circle" className="icon_active blue push--right" title="Varning" /> <span>Den här modulen ingår i en körningskedja. Modulen kommer att starta när <a href={`/module/${module.data.runningTrailingModule.id}/status`}>{module.data.runningTrailingModule.title}</a> och dess efterföljande moduler har körts klart.</span></div>}
            </div>)
        } else if (module.data.completedAt) {
            return `Kördes senast ${Helper.formatDate(module.data.completedAt)}`;
        }
        return "Det finns ingen körningsinformation för den här modulen";
    }

    const renderBody = () => {
        switch (module.code) {
            case StatusCode.PENDING: return <Loading />;
            case StatusCode.ERROR: return "Kunde inte hämta status för modulen";
            case StatusCode.COMPLETE:

                const quarantined = module?.data?.isInQuarantine;
                const active = module?.data?.isActive;
                const canRequestStart = startRequest.code !== StatusCode.PENDING && active && !quarantined && isAdmin;
                
                return <>
                    <div>
                        <h5>Status</h5>
                        <div style={{ margin: '1rem 0' }}>{renderStatusMessage()}</div>
                        {module.data.runnable && <Button color="primary" className="start-request" disabled={!canRequestStart} onClick={() => onClickRequestStart()}>Starta</Button>}
                        {renderRequestStartStatus()}
                    </div>
                    <Turbo />
                    <InstancePhase
                        moduleInstanceId={module.data.id}
                        moduleId={moduleId}
                        completed={Boolean(module.data?.completedAt)}
                    />
                </>;
            default: return (<p>Något gick fel. Tillbaka till <Link to="/module">modulsidan</Link></p>)
        }
    }

    return (
        <div className="mt-4">
            {renderBody()}
        </div>
    );
}

export default ModuleStatus;
