import InstanceClient from "../services/InstanceClient";
import StatusCode from "../util/StatusCode";

const GET_SCHOOLADMIN_INFO = 'GET_FLOWSYNC_INFO';
const GET_SCHEDULESYNC_INFO = 'GET_SCHEDULESYNC_INFO';
const GET_ONEROSTER_INFO = 'GET_ONEROSTER_INFO';


export const GetSchoolAdminInfo = () => dispatch => {
    const url = '/api/core/dashboard/schooladmin'
    return InstanceClient.get(url)
        .then(res => 
            dispatch({
                type: GET_SCHOOLADMIN_INFO,
                schoolAdmin: { code: StatusCode.COMPLETE, data: res.data }
            }))
        .catch(err => 
            dispatch({
                type: GET_SCHOOLADMIN_INFO,
                schoolAdmin: { code: StatusCode.ERROR, error: err }
            }));
};

export const GetScheduleSyncInfo = () => dispatch => {
    const url = '/api/core/dashboard/schedulesync'
    return InstanceClient.get(url)
        .then(res =>
            dispatch({
                type: GET_SCHEDULESYNC_INFO,
                scheduleSync: { code: StatusCode.COMPLETE, data: res.data }
            }))
        .catch(err =>
            dispatch({
                type: GET_SCHEDULESYNC_INFO,
                scheduleSync: { code: StatusCode.ERROR, error: err }
            }));
};

export const GetOneRosterInfo = () => dispatch => {
    const url = '/api/core/dashboard/oneroster'
    return InstanceClient.get(url)
        .then(res =>
            dispatch({
                type: GET_ONEROSTER_INFO,
                oneRoster: { code: StatusCode.COMPLETE, data: res.data }
            }))
        .catch(err =>
            dispatch({
                type: GET_ONEROSTER_INFO,
                oneRoster: { code: StatusCode.ERROR, error: err }
            }));
};

const initialState = {
    schoolAdmin: { code: StatusCode.PENDING },
    scheduleSync: { code: StatusCode.PENDING },
    oneRoster: { code: StatusCode.PENDING },
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case GET_SCHOOLADMIN_INFO:
            return {
                ...state,
                schoolAdmin: action.schoolAdmin,
            };
        case GET_SCHEDULESYNC_INFO:
            return {
                ...state,
                scheduleSync: action.scheduleSync,
            };
        case GET_ONEROSTER_INFO:
            return {
                ...state,
                oneRoster: action.oneRoster,
            };
        default:
            return state;
    }
};