import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import TrackingsModule from '../components/TrackingsModule';
import { GetSchoolUnitTrackings, SchoolUnitState } from '../../../store/SchoolAction';
import { RequestState } from '../../../models/RequestState';
import { CoreTracking } from '../../../models/CoreTracking';

interface State {
    schools: SchoolUnitState
}

const SchoolUnitViewTrackings = () => {
    const { schoolId } = useParams();
    const dispatch = useDispatch();
    const trackings = useSelector<State, RequestState<CoreTracking[]>>(state => state.schools.schoolTrackings);

    useEffect(() => {
        if (schoolId !== undefined) {
            GetSchoolUnitTrackings(schoolId)(dispatch);
        }
    }, [dispatch, schoolId]);

    return (<>
        <TrackingsModule
            trackings={trackings}
            noResMsg="Den här skolan saknar källor"
        />
    </>)
}

export default SchoolUnitViewTrackings;
