import { Action, Dispatch, Reducer } from 'redux';
import { CoreDisplayableRef, PageResult } from '../../models/CoreModels';
import { ScheduleSync_CoreEvent, ScheduleSync_CoreEventListing, ScheduleSync_CoreExportRef, ScheduleSync_ListEventsFilter } from '../../models/CoreScheduleSyncModels';
import { PageRequestState, RequestState } from '../../models/RequestState';
import InstanceClient from '../../services/InstanceClient';
import StatusCode from '../../util/StatusCode';
import store from '../store';

enum ScheduleSync_EventActionType {
    LIST_EVENTS = 'LIST_EVENTS',
    LIST_EVENTS_FILTER = 'LIST_EVENTS_FILTER',
    LIST_EVENTS_UPDATE = 'LIST_EVENTS_UPDATE',
    GET_EVENT = 'GET_EVENT',
    GET_EVENT_ORGANIZERS = 'GET_EVENT_ORGANIZERS',
    GET_EVENT_PARTICIPANTS = 'GET_EVENT_PARTICIPANTS',
    GET_EVENT_TRACKINGS = 'GET_EVENT_TRACKINGS',
}

export interface ScheduleSync_EventState {
    events: PageRequestState<ScheduleSync_CoreEventListing>,
    event: RequestState<ScheduleSync_CoreEvent>,
    eventOrganizers: RequestState<PageResult<CoreDisplayableRef>>,
    eventParticipants: RequestState<PageResult<CoreDisplayableRef>>,
    eventTrackings: RequestState<ScheduleSync_CoreExportRef[]>,
    filter: {},
}

interface ScheduleSync_ListEventsAction extends Action<ScheduleSync_EventActionType> {
    type: ScheduleSync_EventActionType.LIST_EVENTS
    events: PageRequestState<ScheduleSync_CoreEventListing>
}

interface ScheduleSync_ListEventsFilterAction extends Action<ScheduleSync_EventActionType> {
    type: ScheduleSync_EventActionType.LIST_EVENTS_FILTER
    filter: ScheduleSync_ListEventsFilter
}

interface ScheduleSync_ListEventsUpdateAction extends Action<ScheduleSync_EventActionType> {
    type: ScheduleSync_EventActionType.LIST_EVENTS_UPDATE
}

interface ScheduleSync_GetEventAction extends Action<ScheduleSync_EventActionType> {
    type: ScheduleSync_EventActionType.GET_EVENT
    event: RequestState<ScheduleSync_CoreEvent>
}

interface ScheduleSync_GetEventOrganizersAction extends Action<ScheduleSync_EventActionType> {
    type: ScheduleSync_EventActionType.GET_EVENT_ORGANIZERS
    eventOrganizers: RequestState<PageResult<CoreDisplayableRef>>
}

interface ScheduleSync_GetEventParticipantsAction extends Action<ScheduleSync_EventActionType> {
    type: ScheduleSync_EventActionType.GET_EVENT_PARTICIPANTS
    eventParticipants: RequestState<PageResult<CoreDisplayableRef>>
}

interface ScheduleSync_GetEventTrackingsAction extends Action<ScheduleSync_EventActionType> {
    type: ScheduleSync_EventActionType.GET_EVENT_TRACKINGS
    eventTrackings: RequestState<ScheduleSync_CoreExportRef[]>
}

type ScheduleSync_EventAction =
    | ScheduleSync_ListEventsAction
    | ScheduleSync_ListEventsFilterAction
    | ScheduleSync_ListEventsUpdateAction
    | ScheduleSync_GetEventAction
    | ScheduleSync_GetEventOrganizersAction
    | ScheduleSync_GetEventParticipantsAction
    | ScheduleSync_GetEventTrackingsAction


export const Events = (filter: ScheduleSync_ListEventsFilter = {}, update: boolean = false) => (dispatch: Dispatch<ScheduleSync_ListEventsAction | ScheduleSync_ListEventsFilterAction | ScheduleSync_ListEventsUpdateAction>) => {
    dispatch({ type: ScheduleSync_EventActionType.LIST_EVENTS_FILTER, filter: filter, })

    if (update) {
        dispatch({ type: ScheduleSync_EventActionType.LIST_EVENTS_UPDATE })
    }
    const url = '/api/schedulesync/v1/core/event'
    return InstanceClient.get<PageResult<ScheduleSync_CoreEventListing>>(url, filter)
        .then(res => {
            dispatch({
                type: ScheduleSync_EventActionType.LIST_EVENTS,
                events: {
                    code: StatusCode.COMPLETE,
                    data: res.data.values,
                    totalCount: res.data.totalCount,
                    pagination: {
                        totalPages: res.data.totalPages,
                        pageSizes: res.data.pageSize,
                        currentPage: res.data.currentPage
                    }
                },
            })
        })
        .catch(err =>
            dispatch({
                type: ScheduleSync_EventActionType.LIST_EVENTS,
                events: { code: StatusCode.ERROR, error: err }
            })
        );
};

export const GetEvent = (id: string) => (dispatch: Dispatch<ScheduleSync_GetEventAction>) => {
    const storeKey = store.getState().schedulesync_events.event?.key;
    const key = id;
    if (key === storeKey) {
        return;
    }

    dispatch({
        type: ScheduleSync_EventActionType.GET_EVENT,
        event: { code: StatusCode.PENDING }
    })

    return InstanceClient.get<ScheduleSync_CoreEvent>(`/api/schedulesync/v1/core/event/${id}`)
        .then(res => {
            dispatch({
                type: ScheduleSync_EventActionType.GET_EVENT,
                event: { code: StatusCode.COMPLETE, data: res.data, key: key }
            })
        })
        .catch(err =>
            dispatch({
                type: ScheduleSync_EventActionType.GET_EVENT,
                event: { code: StatusCode.ERROR, error: err }
            })
        );
};

export const GetEventOrganizers = (id: string, filter: ScheduleSync_ListEventsFilter = {}) => (dispatch: Dispatch<ScheduleSync_GetEventOrganizersAction>) => {
    const storeKey = store.getState().schedulesync_events.eventOrganizers?.key;
    const key = id + '-' + filter.onlyActive + '-' + filter.page;
    if (key === storeKey) {
        return;
    }

    dispatch({
        type: ScheduleSync_EventActionType.GET_EVENT_ORGANIZERS,
        eventOrganizers: { code: StatusCode.PENDING, filter: filter }
    })

    return InstanceClient.get<PageResult<CoreDisplayableRef>>(`/api/schedulesync/v1/core/event/${id}/organizers`, filter)
        .then(res => {
            dispatch({
                type: ScheduleSync_EventActionType.GET_EVENT_ORGANIZERS,
                eventOrganizers: { code: StatusCode.COMPLETE, data: res.data, key: key, filter: filter }
            })
        })
        .catch(err =>
            dispatch({
                type: ScheduleSync_EventActionType.GET_EVENT_ORGANIZERS,
                eventOrganizers: { code: StatusCode.ERROR, error: err.response.data.status }
            })
        );
}


export const GetEventParticipants = (id: string, filter: ScheduleSync_ListEventsFilter = {}) => (dispatch: Dispatch<ScheduleSync_GetEventParticipantsAction>) => {
    const storeKey = store.getState().schedulesync_events.eventParticipants?.key;
    const key = id + '-' + filter.onlyActive + '-' + filter.page;
    if (key === storeKey) {
        return;
    }

    dispatch({
        type: ScheduleSync_EventActionType.GET_EVENT_PARTICIPANTS,
        eventParticipants: { code: StatusCode.PENDING, filter: filter }
    })

    return InstanceClient.get<PageResult<CoreDisplayableRef>>(`/api/schedulesync/v1/core/event/${id}/participants`, filter)
        .then(res => {
            dispatch({
                type: ScheduleSync_EventActionType.GET_EVENT_PARTICIPANTS,
                eventParticipants: { code: StatusCode.COMPLETE, data: res.data, key: key, filter: filter }
            })
        })
        .catch(err =>
            dispatch({
                type: ScheduleSync_EventActionType.GET_EVENT_PARTICIPANTS,
                eventParticipants: { code: StatusCode.ERROR, error: err.response.data.status }
            })
        );
}

export const GetEventTrackings = (id: string, onlyActive?: boolean) => (dispatch: Dispatch<ScheduleSync_GetEventTrackingsAction>) => {
    const storeKey = store.getState().schedulesync_events.eventTrackings?.key;
    const key = id + '-' + onlyActive;
    if (key === storeKey) {
        return;
    }

    dispatch({
        type: ScheduleSync_EventActionType.GET_EVENT_TRACKINGS,
        eventTrackings: { code: StatusCode.PENDING, onlyActive: onlyActive  }
    })

    return InstanceClient.get<ScheduleSync_CoreExportRef[]>(`/api/schedulesync/v1/core/event/${id}/trackings`, { onlyActive })
        .then(res => {
            dispatch({
                type: ScheduleSync_EventActionType.GET_EVENT_TRACKINGS,
                eventTrackings: { code: StatusCode.COMPLETE, data: res.data, key: key, onlyActive: onlyActive }
            })
        })
        .catch(err => 
            dispatch({
                type: ScheduleSync_EventActionType.GET_EVENT_TRACKINGS,
                eventTrackings: { code: StatusCode.ERROR, error: err }
            })
        );
}

const initialState: ScheduleSync_EventState = {
    events: { code: StatusCode.PENDING },
    event: { code: StatusCode.PENDING },
    eventOrganizers: { code: StatusCode.PENDING },
    eventParticipants: { code: StatusCode.PENDING },
    eventTrackings: { code: StatusCode.PENDING },
    filter: {},
}

export const reducer: Reducer<ScheduleSync_EventState, ScheduleSync_EventAction> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case ScheduleSync_EventActionType.LIST_EVENTS:
            return {
                ...state,
                events: action.events,
            };
        case ScheduleSync_EventActionType.LIST_EVENTS_FILTER:
            return {
                ...state,
                filter: action.filter
            };
        case ScheduleSync_EventActionType.LIST_EVENTS_UPDATE:
            return {
                ...state,
                events: { ...state.events, code: StatusCode.PENDING }
            };
        case ScheduleSync_EventActionType.GET_EVENT:
            return {
                ...state,
                event: action.event,
            };
        case ScheduleSync_EventActionType.GET_EVENT_ORGANIZERS:
            return {
                ...state,
                eventOrganizers: action.eventOrganizers,
            };
        case ScheduleSync_EventActionType.GET_EVENT_PARTICIPANTS:
            return {
                ...state,
                eventParticipants: action.eventParticipants,
            };
        case ScheduleSync_EventActionType.GET_EVENT_TRACKINGS:
            return {
                ...state,
                eventTrackings: action.eventTrackings,
            };
        default:
            return state;
    }
};
