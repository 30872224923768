import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subscribe } from '../../../store/NotificationAction';
import { Row } from 'reactstrap';
import { getModules, getMyModuleTypes, ModuleState } from '../../../store/ModuleAction';
import { GetSchoolAdminInfo, GetOneRosterInfo, GetScheduleSyncInfo } from '../../../store/DashboardAction';
import Loading from '../../misc/Loading';
import StatusCode from '../../../util/StatusCode';
import { Result, CoreModule } from '../../../models/CoreModels';
import { SchoolAdminDashboard, OneRosterDashboard, ScheduleSyncDashboard, MyModuleTypes } from '../../../models/CoreDashboardModels';
import HomeModules from './HomeModules';
import HomeGeneralModules from './HomeGeneralModules';


interface DashboardState {
    schoolAdmin: Result<SchoolAdminDashboard>
    oneRoster: Result<OneRosterDashboard>
    scheduleSync: Result<ScheduleSyncDashboard>
};

interface Notification {
    messageId?: string
}

interface State {
    module: ModuleState
    dashboard: DashboardState
    notification: Notification
};


const Home = () => {
    const dispatch = useDispatch();

    const [lastMessageId, setMessageId] = useState<string | undefined>(undefined);

    const modules = useSelector<State, Result<CoreModule[]>>(state => state.module.modules);
    const myModuleTypes = useSelector<State, Result<MyModuleTypes>>(state => state.module.myModuleTypes);
    const schoolAdminInfo = useSelector<State, Result<SchoolAdminDashboard>>(state => state.dashboard.schoolAdmin);
    const oneRosterInfo = useSelector<State, Result<OneRosterDashboard>>(state => state.dashboard.oneRoster);
    const scheduleSyncInfo = useSelector<State, Result<ScheduleSyncDashboard>>(state => state.dashboard.scheduleSync);
    const notification = useSelector<State, Notification>(state => state.notification);

    useEffect(() => {
        getModules()(dispatch);
        if (myModuleTypes.code === StatusCode.NONE) {
            getMyModuleTypes(true)(dispatch);
        }
        if (myModuleTypes.code === StatusCode.COMPLETE) {
            if (myModuleTypes.data.schoolAdmin) {
                GetSchoolAdminInfo()(dispatch);
            }
            if (myModuleTypes.data.oneRoster) {
                GetOneRosterInfo()(dispatch);
            }
            if (myModuleTypes.data.scheduleSync) {
                GetScheduleSyncInfo()(dispatch);
            }
        }
        return subscribe(['moduleInstanceStartRequest', 'moduleInstanceStart', 'moduleInstanceComplete'])(dispatch);
    }, [dispatch, myModuleTypes.code]);

    useEffect(() => {
        if (modules.code === StatusCode.COMPLETE && lastMessageId !== notification.messageId) {
            setMessageId(notification.messageId);
            getModules()(dispatch);
            GetSchoolAdminInfo()(dispatch);
            GetOneRosterInfo()(dispatch);
            GetScheduleSyncInfo()(dispatch);
        }
    }, [modules.code, notification.messageId, lastMessageId, dispatch]);

    const renderBody = () => {
        if (modules.code === StatusCode.PENDING && myModuleTypes.code === StatusCode.PENDING) {
            return <Loading />;
        } else {
            return (<Row>
                <div className="column w30">
                    <HomeModules
                        modules={modules}
                        type={1}
                    />
                </div>
                <div className="column w40">
                    <HomeGeneralModules
                        myModuleTypes={myModuleTypes}
                        schoolAdminInfo={schoolAdminInfo}
                        oneRosterInfo={oneRosterInfo}
                        scheduleSyncInfo={scheduleSyncInfo}
                    />
                </div>
                <div className="column w30">
                    <HomeModules
                        modules={modules}
                        type={2}
                    />
                </div>
            </Row>);
        }
    };

    return (
        <div className="home_bg padding-40">
            {renderBody()}
        </div>
    );
}

export default Home;
