
export const setQuery = (filter) => {
    if (filter === undefined) {
        return '';
    }

    let userFilter = [];
    Object.entries(filter)
        .forEach(([key, value]) => {
            if (value !== '' && value !== undefined && value !== null) {
                userFilter.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
            }
        })

    if (!userFilter.length) {
        return '';
    }

    return `?${userFilter.join('&')}`;
}

export const getQuery = (searchQuery) => {
    if (!searchQuery.length) {
        return {};
    }

    var searches = searchQuery.substring(1).split('&');
    var filter = {};
    searches.forEach(search => {
        var split = search.split("=");
        if (split.length < 2) return;
        if (split[1] !== '') {
            filter[split[0]] = formatValue(decodeURIComponent(split[1]))
        }
    });
    return filter;
}

export const formatValue = (value) => {
    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    if (isNumeric(value)) {
        return parseInt(value, 10);
    }
    if (value === 'true')
        return true;
    if (value === 'false') {
        return false;
    }

    return value;
}
