import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import { CoreTracking } from "../../../../models/CoreTracking";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalComponent from '../../../misc/ModalComponent';
import { ClearUpdateGoogleUserTracking, TrackingOperationState, UpdateGoogleUserTracking } from '../../../../store/TrackingOperationAction';
import { RequestState } from '../../../../models/RequestState';
import { CoreGoogleUserTrackingUpdate, CoreGoogleUserTrackingConflict } from '../../../../models/CoreTrackingOperation';
import StatusCode from '../../../../util/StatusCode';
import Loading from '../../../misc/Loading';
import { Link } from 'react-router-dom';
import { ExportGoogleUserTrackingMetadata } from "../../../../models/TrackingMetadata";
import { getErrorObject } from "../../../../models/ErrorObject";
import errorMessageDictionaries from "../../../../util/ErrorMessageDictionaries";
import GoogleUserTrackingOperationsValidationResult from "./GoogleUserTrackingOperationsValidationResult";
import CoreApiError from "../../../misc/CoreApiError";

interface EditGoogleUserTrackingWizardState {
    trackingOperation: TrackingOperationState
}

interface EditGoogleUserTrackingWizardProps {
    tracking: CoreTracking
    close: (success: boolean) => void
}

const modalTitle = 'Redigera Google användar koppling';

const EditGoogleUserTrackingWizard: React.FC<EditGoogleUserTrackingWizardProps> = (props) => {
    const dispatch = useDispatch();
    const metadata = JSON.parse(props.tracking.metadata) as ExportGoogleUserTrackingMetadata;
    const [wizardStep, setWizardStep] = useState<'input' | 'verify' | 'done'>('input');
    const [update, setUpdate] = useState<CoreGoogleUserTrackingUpdate>({ primaryEmail: metadata.PrimaryEmail });
    const updateGoogleUserTracking = useSelector<EditGoogleUserTrackingWizardState, RequestState<CoreGoogleUserTrackingConflict[]>>(state => state.trackingOperation.updateGoogleUserTracking)
    const initial: CoreGoogleUserTrackingUpdate = { primaryEmail: metadata.PrimaryEmail };

    const updatePrimaryEmail: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setUpdate({
            ...update,
            primaryEmail: e.target.value,
        });
    }

    const submitInput: React.MouseEventHandler<HTMLButtonElement> = (_) => {
        if (update.primaryEmail) {
            UpdateGoogleUserTracking(update, props.tracking.trackingId, true)(dispatch);
            setWizardStep('verify');
        }
    }

    const confirmInput: React.MouseEventHandler<HTMLButtonElement> = (_) => {
        if (update.primaryEmail) {
            UpdateGoogleUserTracking(update, props.tracking.trackingId, false)(dispatch);
            setWizardStep('done');
        }
    }

    const close = (success: boolean) => {
        ClearUpdateGoogleUserTracking()(dispatch);
        setWizardStep('input');
        setUpdate({ primaryEmail: '' });
        props.close(success);
    }

    switch (wizardStep) {
        case 'input': {
            if (isCurrentlyLifecycleManaged(props.tracking)) {
                return <ModalComponent
                    isOpen={true}
                    toggleModal={() => close(false)}
                    header={modalTitle}
                    cancel="Avbryt"><FontAwesomeIcon title="ERROR" color="red" icon={['fas', 'triangle-exclamation']} /> Det är inte tillåtet att ändra en tracking som håller på att livscykelhanteras.</ModalComponent>;
            }

            return <ModalComponent
                isOpen={true}
                toggleModal={() => close(false)}
                header={modalTitle}
                update={submitInput}
                disabled={update.primaryEmail === ''}
                confirm="Validera"
                cancel="Avbryt">
                <Form>
                    <FormGroup key="googlePrimaryEmail">
                        <Label for="googlePrimaryEmail">Google primär e-post</Label>
                        <Input
                            type="text"
                            name="googlePrimaryEmail"
                            id="googlePrimaryEmail"
                            placeholder="abc@flowsync.se"
                            autoComplete="off"
                            invalid={update.primaryEmail === ''}
                            value={update.primaryEmail}
                            onChange={updatePrimaryEmail} />
                        <FormFeedback>Primär e-post måste ha ett värde</FormFeedback>
                    </FormGroup>
                </Form>
            </ModalComponent>;
        }
        case 'verify': {
            if (updateGoogleUserTracking.code === StatusCode.COMPLETE) {
                if (update.primaryEmail === initial.primaryEmail) {
                    return <ModalComponent
                        isOpen={true}
                        toggleModal={() => close(false)}
                        header={modalTitle}
                        cancel="Avbryt">Du har inte ändrat något...</ModalComponent>
                } else if (updateGoogleUserTracking.data.length === 0) {
                    return <ModalComponent
                        isOpen={true}
                        toggleModal={() => close(false)}
                        header={modalTitle}
                        update={confirmInput}
                        confirm="Bekräfta"
                        cancel="Avbryt">
                        <p><FontAwesomeIcon title="OK" color="green" icon={['fas', 'check-circle']} /> Inga fel upptäcktes, vänligen bekräfta ändring:</p>
                        {(update.primaryEmail !== initial.primaryEmail) && <>
                            <div className="mt-3">
                                <b>Ändra primär e-post:</b>
                                <div className="gridList" style={{ gridTemplateColumns: '1fr 7fr' }}>
                                    <div>Från:</div>
                                    <div>{initial.primaryEmail}</div>
                                    <div>Till:</div>
                                    <div>{update.primaryEmail}</div>
                                </div>
                            </div>
                            <p className="mt-3"><FontAwesomeIcon title="WARNING" color="#fc1" icon={['fas', 'triangle-exclamation']} /> Verifiera att e-postadressen '{update.primaryEmail}' inte är upptagen i Google eller att den gäller för konto med id: {props.tracking.sourceSystemId}.</p>
                        </>}
                    </ModalComponent>
                }

                return <ModalComponent
                    isOpen={true}
                    toggleModal={() => close(false)}
                    header={modalTitle}
                    cancel="Avbryt">
                    <p><FontAwesomeIcon title="ERROR" color="red" icon={['fas', 'triangle-exclamation']} /> Konflikter upptäcktes:</p>
                    <GoogleUserTrackingOperationsValidationResult primaryEmail={update.primaryEmail} conflicts={updateGoogleUserTracking.data} />
                </ModalComponent>;
            } else if (updateGoogleUserTracking.code === StatusCode.ERROR) {
                return <ModalComponent
                    isOpen={true}
                    toggleModal={() => close(false)}
                    header={modalTitle}
                    cancel="Avbryt"><CoreApiError languageCode={'sv_SE'} requestState={updateGoogleUserTracking} /></ModalComponent>
            } else {
                return <ModalComponent
                    isOpen={true}
                    toggleModal={() => close(false)}
                    header={modalTitle}
                    cancel="Avbryt">
                    <Loading />
                </ModalComponent>;
            }
        }
        case 'done': {
            if (updateGoogleUserTracking.code === StatusCode.COMPLETE) {
                return <ModalComponent
                    isOpen={true}
                    toggleModal={() => close(true)}
                    header={modalTitle}
                    cancel="Stäng">Ändringen genomförd.</ModalComponent>
            } else if (updateGoogleUserTracking.code === StatusCode.ERROR) {
                return <ModalComponent
                    isOpen={true}
                    toggleModal={() => close(false)}
                    header={modalTitle}
                    cancel="Avbryt"><CoreApiError languageCode={'sv_SE'} requestState={updateGoogleUserTracking} /></ModalComponent>
            } else {
                return <ModalComponent
                    isOpen={true}
                    toggleModal={() => close(false)}
                    header={modalTitle}
                    cancel="Avbryt">
                    <Loading />
                </ModalComponent>;
            }
        }
    }
}

const isCurrentlyLifecycleManaged = (trackig: CoreTracking) => trackig.active && !trackig.deleted && (trackig.inactivated || trackig.archived);

export default EditGoogleUserTrackingWizard;
