import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { ListStorageContainerBlobs, BlobContainerState, DownloadBlobFile, ClearBlobDownloadStatuses, BlobContainerStateDownloadFileStatuses, ClearBlobContent, BlobContentState, GetBlobFile } from '../../store/BlobContainerAction';
import { RequestState } from '../../models/RequestState';
import { CoreStorageContainerBlob } from '../../models/Requests';
import FilesModal, { BlobContentManagement } from './FilesModal';
import { clear } from 'console';

interface StorageContainerExplorerProps {
    blobContainerUrl?: string | null
    blobSourceKey?: string | null
    expectedFiles?: string[] | null
    disabled?: boolean
}

interface StorageContainerExplorerState {
    blob: BlobContainerState
}

const StorageContainerExplorer: React.FC<StorageContainerExplorerProps> = (props) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();
    const blob = useSelector<StorageContainerExplorerState, BlobContentState>(state => state.blob.blob);
    const blobs = useSelector<StorageContainerExplorerState, RequestState<string[]>>(state => state.blob.blobs);
    const blobDownloadStatuses = useSelector<StorageContainerExplorerState, BlobContainerStateDownloadFileStatuses>(state => state.blob.blobDownloadStatuses);
    const disabled = props.disabled || !props.blobContainerUrl || !props.blobSourceKey;

    // NOTE: Returns cleanup method only
    useEffect(() => () => {
        ClearBlobDownloadStatuses()(dispatch);
        ClearBlobContent()(dispatch);
    }, [dispatch])

    const onButtonClickHandler = () => {
        setIsModalOpen(true);
        if (props.blobContainerUrl && props.blobSourceKey) {
            ListStorageContainerBlobs({
                blobContainerUrl: props.blobContainerUrl,
                blobSourceKey: props.blobSourceKey,
            })(dispatch);
        }
    }

    const toggle = () => setIsModalOpen(!isModalOpen);

    const downloadFile = (blobName: string) => {
        if (props.blobContainerUrl && props.blobSourceKey) {
            const blob: CoreStorageContainerBlob = {
                blobContainerUrl: props.blobContainerUrl,
                blobSourceKey: props.blobSourceKey,
                blobName,
            }

            DownloadBlobFile(blob)(dispatch);
        }
    }

    const getFileContent = (blobName: string) => {
        if (props.blobContainerUrl && props.blobSourceKey) {
            const blob: CoreStorageContainerBlob = {
                blobContainerUrl: props.blobContainerUrl,
                blobSourceKey: props.blobSourceKey,
                blobName,
            }

            GetBlobFile(blob)(dispatch);
        }
    }

    const clearFileContent = () => {
        ClearBlobContent()(dispatch);
    }

    return (<>
        <div className="flex children-mr-3 align-items-center mt-4">
            <Button disabled={disabled} onClick={onButtonClickHandler}>Visa filer</Button>
        </div>
        {isModalOpen && <FilesModal
            title="Storage Container"
            downloadFileStatuses={blobDownloadStatuses}
            fileNames={blobs}
            downloadFile={downloadFile}
            fileContent={({
                get: getFileContent,
                clear: clearFileContent,
                state: blob,
            })}
            isOpen={isModalOpen}
            toggleModal={toggle}
            expectedFiles={props.expectedFiles} />
        }
    </>)
};

export default StorageContainerExplorer;