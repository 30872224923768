import React from 'react';
import EnumInput from '../../../../../misc/EnumInput';
import CheckboxListItem from '../../../../../misc/CheckboxListItem';
import NullableSelect from '../../../../../misc/NullableSelect';
import * as GoogleUser from '../../../../../../util/GoogleUserExportTypes';
import { OptionItem, OnChangeCallback } from '../../../../../../util/UtilityTypes';
import { update } from '../../../../../../util/Helper';
import { FormGroup } from 'reactstrap';

interface GeneralSettings {
    displayNamePolicy: GoogleUser.DisplayNamePolicy
    includeHomePhone: boolean
    includeMobilePhone: boolean
    includeWorkPhone: boolean
    includeHomeAddress: boolean
    includeUserOrganisations: GoogleUser.UserOrganisationType
}

interface GoogleUserDisplayNamePolicyProps {
    generalSettings: GeneralSettings
    suffixOptions: OptionItem<number>[]
    configurationType: string
    validation: string[]
    disabled?: boolean
    onChange: OnChangeCallback<GeneralSettings | undefined>
}

const GoogleUserGeneralSettings: React.FC<GoogleUserDisplayNamePolicyProps> = (props) => {

    const updateConfig = (updates: Partial<GeneralSettings>) => {
        console.log(updates)
        update('', props.generalSettings, updates, props.onChange)
    }

    return (<>
        <EnumInput
            title='Visningsnamn'
            options={GoogleUser.displayNameFormats}
            disabled={props.disabled}
            value={props.generalSettings.displayNamePolicy.format}
            name={`${props.configurationType}-displayNamePolicy.format`}
            onChange={(n, v) => updateConfig({ displayNamePolicy: { ...props.generalSettings.displayNamePolicy, format: v } })}
        />

        <NullableSelect
            options={props.suffixOptions}
            title="Lägg till suffix (sätts efter visningsnamnet)"
            defaultValue={{ value: 0, text: "Lägg till suffix" }}
            disabled={props.disabled}
            checkboxTitle="Aktivera suffix"
            value={props.generalSettings.displayNamePolicy.suffix}
            name={`${props.configurationType}-displayNamePolicy.suffix`}
            onChange={(n, v) => updateConfig({ displayNamePolicy: { ...props.generalSettings.displayNamePolicy, suffix: v } })}
            invalidFeedback="Suffix saknas"
            invalid={props.validation.indexOf(`${props.configurationType}.displayNamePolicy.suffix`) !== -1}
        />

        <FormGroup>
            <h5>Telefonnummer</h5>
            <CheckboxListItem
                text="Arbetstelefon"
                value={props.generalSettings.includeWorkPhone}
                id="includeWorkPhone"
                name="includeWorkPhone"
                onChange={(v) => updateConfig({ includeWorkPhone: v.target.checked })}
                disabled={props.disabled}
            />
            <CheckboxListItem
                text="Hemtelefon"
                value={props.generalSettings.includeHomePhone}
                id="includeHomePhone"
                name="includeHomePhone"
                onChange={(v) => updateConfig({ includeHomePhone: v.target.checked })}
                disabled={props.disabled}
            />
            <CheckboxListItem
                text="Mobiltelefon"
                value={props.generalSettings.includeMobilePhone}
                id="includeMobilePhone"
                name="includeMobilePhone"
                onChange={(v) => updateConfig({ includeMobilePhone: v.target.checked })}
                disabled={props.disabled}
            />
        </FormGroup>

        <FormGroup>
            <h5>Adresser</h5>
            <CheckboxListItem
                text="Hemadress"
                value={props.generalSettings.includeHomeAddress}
                id="includeHomeAddress"
                name="includeHomeAddress"
                onChange={(v) => updateConfig({ includeHomeAddress: v.target.checked })}
                disabled={props.disabled}
            />
        </FormGroup>

        <FormGroup>
            <EnumInput
                title='Organisationskoppling'
                options={GoogleUser.userOrganisation}
                disabled={props.disabled}
                value={props.generalSettings.includeUserOrganisations}
                name={`${props.configurationType}-includeUserOrganisations`}
                onChange={(n, v) => updateConfig({ includeUserOrganisations: v })}
            />
        </FormGroup>
    </>);
}

export default GoogleUserGeneralSettings;
