import React, { MouseEventHandler, useState } from 'react';
import ModalComponent from '../misc/ModalComponent';
import StatusCode from '../../util/StatusCode';
import Loading from '../misc/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RequestState } from '../../models/RequestState';
import * as Helper from '../../util/Helper';
import { BlobContentState, BlobContainerStateDownloadFileStatuses } from '../../store/BlobContainerAction';

interface FileItem {
    isFolder: false
    name: string
    fullName: string
}

interface FolderItem {
    isFolder: true
    name: string
    fullName: string
    files: PathItem[]
}

type PathItem = FileItem | FolderItem

export interface BlobContentManagement {
    get: (fullName: string) => void
    clear: () => void
    state: BlobContentState
}

interface FilesModalProps {
    title?: string
    downloadFileStatuses: BlobContainerStateDownloadFileStatuses
    fileNames: RequestState<string[]>
    downloadFile: (fullName: string) => void
    fileContent?: BlobContentManagement
    isOpen?: boolean
    toggleModal?: MouseEventHandler
    expectedFiles?: string[] | null
}

const FilesModal: React.FC<FilesModalProps> = (props) => {
    const { downloadFileStatuses, fileNames } = props;
    const [openFolder, setOpenFolder] = useState<{ [key: string]: boolean }>({});
    const [viewContent, setViewContent] = useState<boolean>(false);

    const renderFileStatus = (fullName: string) => {
        if (downloadFileStatuses[fullName]) {
            switch (downloadFileStatuses[fullName].code) {
                case StatusCode.PENDING: return <Loading className="small" style={{ display: 'inline' }} />;
                case StatusCode.COMPLETE: return <FontAwesomeIcon icon="check" color="green" className="icon" />
                case StatusCode.ERROR: return <FontAwesomeIcon icon="exclamation-triangle" color="red" className="icon" />
                default: return;
            }
        }
    }

    const renderListItem = (listItem: PathItem) => {
        if (listItem.isFolder) {
            return (<>
                <div onClick={() => setOpenFolder({ ...openFolder, [listItem.fullName]: !openFolder[listItem.fullName] })} className="pointer mb-2"><FontAwesomeIcon icon="folder" color="orange" className="icon me-2" />{listItem.name}</div>
                {openFolder[listItem.fullName] && <div className="ms-4">{listItem.files.map((x, i) => <span key={i}>{renderListItem(x)}</span>)}</div>}
            </>);
        } else {
            return <div className="mb-2">
                <FontAwesomeIcon icon="file" color="gray" className="icon me-2" />
                <span className="me-2">{listItem.name}</span>
                {props.fileContent && <FontAwesomeIcon title="Visa filinnehåll" icon="file-alt" color="steelblue" className="icon me-2 c-pointer" onClick={() => openViewContent(listItem.fullName)} />}
                <FontAwesomeIcon title="Ladda ner fil" icon="file-download" color="steelblue" className="icon me-2 c-pointer" onClick={() => props.downloadFile(listItem.fullName)} />
                {renderFileStatus(listItem.fullName)}
            </div>;
        }
    }

    const joinPath = (name: string, prevPath?: string): string =>
        prevPath === undefined ? name : (prevPath + "/" + name);

    const placeFile = (files: PathItem[], fileName: string, prevPath?: string): PathItem[] => {
        const split = fileName.split('/');

        if (split.length === 1) {
            files.push({ isFolder: false, name: fileName, fullName: joinPath(fileName, prevPath) });
        }
        else {
            const currentName = split.shift();

            if (currentName === undefined) {
                return files;
            }

            let folder = files.find(x => x.name === currentName);
            if (folder === undefined) {
                folder = { isFolder: true, name: currentName, files: [], fullName: joinPath(currentName, prevPath) };
                files.push(folder);
            }

            if (folder.isFolder) {
                const fullPath = split.join('/')
                placeFile(folder.files, fullPath, joinPath(currentName, prevPath))
            }
        }

        return files;
    }

    const compareFiles = (a: PathItem, b: PathItem) => {
        if (a.isFolder && b.isFolder) {
            return Helper.compareStrings(a.name, b.name);
        } else if (a.isFolder) {
            return -1;
        } else if (b.isFolder) {
            return 1;
        } else {
            return Helper.compareStrings(a.name, b.name);
        }
    }

    const sortSubFolders = (files: PathItem[]) => {
        return files?.map(x => {
            if (x.isFolder) {
                x.files = sortSubFolders(x.files);
            }

            return x;
        }).sort(compareFiles);
    }

    const openViewContent = (fullName: string) => {
        if (props.fileContent) {
            props.fileContent.get(fullName);
            setViewContent(true);
        }
    }

    const closeViewContent = () => {
        if (props.fileContent) {
            props.fileContent.clear();
        }

        setViewContent(false);
    }

    const renderFilesWhenComplete = (files: string[]) => {
        if (files.length === 0) {
            return "Det finns inga filer att hämta. Filerna tas automatiskt bort efter ett visst antal dagar.";
        }

        const groupedItems = files.reduce<PathItem[]>((acc, value) => placeFile(acc, value), [])
        const orderedList = sortSubFolders(groupedItems);

        return <>
            <p>Filer som finns för nedladdning</p>
            {orderedList.map((x, i) => (
                <span key={i}>
                    {renderListItem(x)}
                </span>
            ))}
        </>;
    }

    const renderFiles = () => {
        switch (fileNames.code) {
            case (StatusCode.PENDING):
                return <Loading />;
            case (StatusCode.ERROR):
                return "Kunde inte hämta filer";
            case (StatusCode.COMPLETE):
                return renderFilesWhenComplete(fileNames.data);
            default:
                return "Kunde inte hämta filer";
        }
    };

    const renderMissingFilesWarning = () => {
        if (fileNames.code === StatusCode.COMPLETE && props.expectedFiles) {
            var missingFiles = props.expectedFiles.filter(x => !fileNames.data.some(y => x === y));
            if (missingFiles.length > 0) {
                return (<div className="red">
                    <p>{'Filer som förväntas finnas i storage containern saknas:'}</p>
                    <ul>
                        {missingFiles.map(x => (<li key={x}>{x}</li>))}
                    </ul>
                </div>);
            }
        }
    }

    const getFileContentModalContent = () => {
        if (!props.fileContent || !viewContent) {
            return null;
        }

        switch (props.fileContent.state.content.code) {
            case StatusCode.NONE:
                return null;
            case StatusCode.PENDING:
                return <Loading />;
            case StatusCode.ERROR:
                return <span className="red">"Kunde inte hämta fil innehåll."</span>;
            case StatusCode.COMPLETE:
                return <pre>{props.fileContent.state.content.data}</pre>
        }
    }

    const renderFileContent = () => {
        const modalContent = getFileContentModalContent();

        if (props.fileContent && modalContent) {
            return (<ModalComponent
                size="xl"
                isOpen={viewContent}
                toggleModal={closeViewContent}
                header={props.fileContent.state.name}
                cancel="Stäng">
                {modalContent}
            </ModalComponent>)
        }

        return null;
    }

    return (
        <>
            <ModalComponent
                isOpen={props.isOpen}
                toggleModal={props.toggleModal}
                header={props.title ?? "Hämta filer"}
                cancel="Stäng"
            >
                <>
                    {renderMissingFilesWarning()}
                    {renderFiles()}
                </>

            </ModalComponent>
            {renderFileContent()}
        </>
    )
}

export default FilesModal;
