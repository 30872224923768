import React, { ChangeEventHandler } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';

interface SearchProps {
    className?: string
    value: string | number
    onChange: ChangeEventHandler<HTMLInputElement>
    placeholder: string
}

const Search = (props: SearchProps) => {
    return <Form autoComplete="off" onSubmit={e => e.preventDefault()}>
        <FormGroup className={props.className + " search-form"}>
            <Input type="text" name="search" value={props.value || ''} onChange={props.onChange} placeholder={props.placeholder} />
        </FormGroup>
    </Form>
}

export default Search;
