import React, { useState, useEffect, useCallback, ReactNode } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getModules, ModuleState } from '../../store/ModuleAction';
import { GetUsers, UserState } from '../../store/UserAction';
import { getRoles, FilterState } from '../../store/FilterAction';
import { Row, Col } from 'reactstrap';
import debounce from 'lodash/debounce';
import * as Helper from '../../util/Helper';
import ActiveToggle from '../misc/ActiveToggle';
import SelectInput from '../misc/SelectInput';
import StatusCode from '../../util/StatusCode';
import Search from '../misc/Search';
import FilterBottomComponent from '../misc/FilterBottomComponent';
import TableHorizontal from '../misc/TableHorizontal';
import RequestParser from '../../util/RequestParser';
import { InfoObject } from '../misc/TableHorizontalPagedToggle';
import { RequestState } from '../../models/RequestState';
import { PageResult, RoleFilter } from '../../models/CoreModels';
import { ModuleTypeInfo } from '../../util/UtilityTypes';
import { CoreUserListing } from '../../models/CoreUser';

const presentSchools = (user: CoreUserListing): ReactNode => {
    const schools: { [key: string]: string } = {};

    if (user.enrollments) {
        for (let i = 0; i < user.enrollments.length; i++) {
            const schoolUnit = user.enrollments[i].schoolUnit;
            if (schoolUnit) {
                schools[schoolUnit.id] = schoolUnit.title ?? '-';
            }
        }
    }

    if (user.duties) {
        for (let i = 0; i < user.duties.length; i++) {
            const schoolUnit = user.duties[i].schoolUnit;
            if (schoolUnit) {
                schools[schoolUnit.id] = schoolUnit.title ?? '-';
            }
        }
    }

    if (user.userRelations) {
        for (let i = 0; i < user.userRelations.length; i++) {
            const schoolUnit = user.userRelations[i].schoolUnit;
            if (schoolUnit) {
                schools[schoolUnit.id] = schoolUnit.title ?? '-';
            }
        }
    }

    const entries = Object.entries(schools);
    if (entries.length === 0) {
        return 'Saknas';
    }

    return (<>
        {entries.reduce<JSX.Element[]>((acc, x, i) => {
            const link = (<Link to={`/schoolunit/${x[0]}/information`} key={x[0]}>{x[1]}</Link>);
            if (i === 0) {
                return [...acc, link];
            } else {
                return [...acc, (<span key={`delimiter-${x[0]}`}>, </span>), link];
            }
        }, [])}
    </>);
};

interface UserListState {
    users: UserState
    module: ModuleState
    filter: FilterState
}

const UserList = () => {
    const dispatch = useDispatch();

    const [search, setSearch] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    const users = useSelector<UserListState, RequestState<PageResult<CoreUserListing>>>(state => state.users.users);
    const roles = useSelector<UserListState, RoleFilter[]>(state => state.filter.roles);
    const modules = useSelector<UserListState, ModuleTypeInfo[]>(state => Helper.distinctSchoolAdminModuleTypes(state.module.modules));

    var filter = RequestParser.parseUserSearchPageRequest(searchParams);

    useEffect(() => {
        if (filter.search) {
            setSearch(filter.search);
        }
        GetUsers(filter)(dispatch);
        getRoles()(dispatch);
        getModules()(dispatch);
    }, [dispatch]);

    useEffect(() => {
        GetUsers(filter, true)(dispatch);
        window.scrollTo(0, 0);
    }, [searchParams]);

    const updateFilter = (name: string, value?: number | string | boolean | null) => {
        if (name !== 'page') {
            searchParams.set('page', '1');
        }

        if (value !== undefined && value !== '' && value !== null) {
            if (typeof (value) === 'string') {
                searchParams.set(name, value);
            } else {
                searchParams.set(name, value.toString());
            }
        } else {
            searchParams.delete(name);
        }

        setSearchParams(searchParams);
    }

    const updateFilterRole = (value?: string | null) => {
        if (value !== undefined && value !== null) {
            const parts = value.split('-');
            const roleType = parseInt(parts[0]);
            const roleId = parseInt(parts[1]);

            searchParams.set('roleType', roleType.toString());
            searchParams.set('roleId', roleId.toString());
        } else {
            searchParams.delete('roleType');
            searchParams.delete('roleId');
        }

        searchParams.set('page', '1');

        setSearchParams(searchParams);
    }

    const debouncedSearch = useCallback(
        debounce(search => {
            updateFilter('search', search);
        }, 300), [searchParams]);

    const onSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        let search = e.target.value;
        setSearch(search);
        debouncedSearch(search);
    }

    const clearFilter = () => {
        setSearch('');
        setSearchParams([]);
    }

    const mapUsers = (): InfoObject[] => {
        if (users.code !== StatusCode.COMPLETE) {
            return [];
        }

        return users.data.values.map(u => {
            return ({
                id: u.id,
                data: [
                    { value: < Link to={`/user/${u.id}/information`}>{u.displayName}</Link> },
                    { value: presentSchools(u) },
                    { value: Helper.presentRole(u) },
                    { value: u.activityCount },
                    { value: Helper.active(u.active) },
                    { value: Helper.formatDate(u.modified) },
                ]
            })
        });
    }

    const renderFilter = () => (<>
        <Row>
            <Col sm="6">
                <Search value={search} onChange={onSearch} placeholder="Sök personer" />
            </Col>
            <Col sm="4">
                <SelectInput
                    items={modules.map(m => ({ value: m.moduleTypeName, text: `Filtrera på ${m.moduleTypeDisplayName}` }))}
                    onChange={(name, value) => updateFilter(name, value)}
                    name='moduleTypeName'
                    selected={filter.moduleTypeName}
                    defaultText='Filtrera på modultyp'
                />
            </Col>
            <Col sm="2">
                <ActiveToggle
                    onlyActive={filter.onlyActive ?? true}
                    update={(active) => updateFilter('onlyActive', active)}
                    disabled={users.code === StatusCode.PENDING}
                />
            </Col>
        </Row>
        <Row>
            <Col sm="6">
                <SelectInput
                    items={roles.map(role => ({ value: `${role.type}-${role.id}`, text: `Filtrera på ${role.displayName}` }))}
                    onChange={(_, value) => updateFilterRole(value)}
                    name='role'
                    selected={`${filter["roleType"]}-${filter["roleId"]}`}
                    defaultText='Visa alla roller'
                />
            </Col>
        </Row>
    </>);

    const renderUsers = () => {
        const usersInfo = mapUsers();
        return (
            <TableHorizontal
                header={[{ value: 'Namn' }, { value: 'Skola' }, { value: 'Roll' }, { value: 'Antal aktiviteter' }, { value: 'Aktiv' }, { value: 'Ändrad' }]}
                info={usersInfo}
                error={users.code === StatusCode.ERROR}
                searching={users.code === StatusCode.PENDING}
                displayName="Personer"
                noResMsg="Inga personer hittades"
            />
        )
    }

    return (
        <div className="container">
            <div className="header--fixed large-filter">
                <h1>Personer</h1>
                {renderFilter()}
            </div>
            <div className="body--scroll padding-40">
                {renderUsers()}
            </div>

            <FilterBottomComponent
                clearFilter={clearFilter}
                updateFilter={(value) => updateFilter('page', value)}
                requestState={users}
            />

        </div>
    )
}

export default UserList;
