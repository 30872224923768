import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StatusCode from "../../../util/StatusCode";
import TableHorizontal from "../../misc/TableHorizontal";
import PrimarySchoolModal from "./PrimarySchoolModal";
import * as Helper from '../../../util/Helper';
import * as PrimarySchoolTypes from '../../../util/PrimarySchoolUtil';
import { CoreDisplayableRef, CoreEnumeration, Result, NoContentResult } from "../../../models/CoreModels";
import { DeleteUserPrimarySchool, GetUserSchoolUnits, ResetPrimarySchoolSaveStatus, SetUserPrimarySchool } from "../../../store/UserAction";
import { CoreUserDuty, CoreUserEnrollment, CoreUserSchoolUnitRelations } from "../../../models/CoreUser";
import { CorePrimarySchoolStatus } from "../../../models/CoreUser";
import * as UserRelationUtil from '../../../util/UserRelationUtil';
import { MeState } from "../../../store/MeAction";
import { FlowSyncAccessPolicy, isAuthorized } from "../../../models/FlowSyncAccessPolicy";

type PrimarySchoolOption = {
    primarySchoolStatus: CorePrimarySchoolStatus
    schoolUnit: CoreDisplayableRef
}

interface DisplaySchool {
    active: boolean
    schoolUnit: CoreDisplayableRef
    dutyRole?: CoreEnumeration
    startDate?: string
    endDate?: string
    primarySchoolStatus?: CorePrimarySchoolStatus
    relationType?: number
}

interface UserState {
    userSchoolUnits: Result<CoreUserSchoolUnitRelations>
    primarySchool: NoContentResult
}

interface UserViewSchoolUnitsState {
    users: UserState
    me: MeState
}

const userRoleForSchool = (user: DisplaySchool): string => {
    if (user.dutyRole) {
        return user.dutyRole.name;
    }
    if (user.relationType) {
        return UserRelationUtil.relationTypeById(user.relationType, false);
    }
    return 'Elev';
};

const convertUserSchoolUnits = (userSchoolUnits: Result<CoreUserSchoolUnitRelations>) => {
    const displaySchools: DisplaySchool[] = [];

    Helper.ensureArray(userSchoolUnits.data?.duties).forEach(duty => {
        if (duty.schoolUnit) {
            displaySchools.push({
                active: duty.active,
                schoolUnit: duty.schoolUnit,
                startDate: duty.startDate,
                endDate: duty.endDate,
                dutyRole: duty.dutyRole,
                primarySchoolStatus: duty.primarySchoolStatus,
            })
        }
    });

    Helper.ensureArray(userSchoolUnits.data?.enrollments).forEach(enrollment => {
        if (enrollment.schoolUnit) {
            displaySchools.push({
                active: enrollment.active,
                schoolUnit: enrollment.schoolUnit,
                startDate: enrollment.startDate,
                endDate: enrollment.endDate,
                primarySchoolStatus: enrollment.primarySchoolStatus,
            });
        }
    });

    Helper.ensureArray(userSchoolUnits.data?.userRelations).forEach(userRelation => {
        if (userRelation.schoolUnit) {
            displaySchools.push({
                active: userRelation.active,
                schoolUnit: userRelation.schoolUnit,
                relationType: userRelation.relationType
            });
        }
    });

    return displaySchools;
}

const getPrimarySchoolOptions = (userSchoolUnits: Result<CoreUserSchoolUnitRelations>): PrimarySchoolOption[] => {
    const uniqueOptions: Map<string, PrimarySchoolOption> = new Map<string, PrimarySchoolOption>();

    const memberships: (CoreUserDuty | CoreUserEnrollment)[] = Helper.ensureArray(userSchoolUnits.data?.duties)
    const enrollments = Helper.ensureArray(userSchoolUnits.data?.enrollments);

    memberships.concat(enrollments).forEach(membership => {
        if (!membership.primarySchoolStatus || membership.primarySchoolStatus.status === PrimarySchoolTypes.Types.DEFAULT) {
            return;
        }

        if (!membership.schoolUnit || uniqueOptions.has(membership.schoolUnit.id)) {
            return;
        }

        uniqueOptions.set(membership.schoolUnit.id, {
            primarySchoolStatus: membership.primarySchoolStatus,
            schoolUnit: membership.schoolUnit,
        });
    });

    return Array.from(uniqueOptions.values());
}

const UserViewSchoolUnits = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const userSchoolUnits = useSelector<UserViewSchoolUnitsState, Result<CoreUserSchoolUnitRelations>>(state => state.users.userSchoolUnits);
    const authorizedAccessPolicies = useSelector<UserViewSchoolUnitsState, FlowSyncAccessPolicy[]>(state => state.me.me?.authorizedAccessPolicies ?? []);
    const primarySchoolSaveStatus = useSelector<UserViewSchoolUnitsState, NoContentResult>(state => state.users.primarySchool);

    const [open, setOpen] = useState(false);
    const [primarySchool, setPrimarySchool] = useState<PrimarySchoolOption | undefined>(undefined);
    const [selectableSchoolUnits, setSelectableSchoolUnits] = useState<PrimarySchoolOption[] | []>([]);

    useEffect(() => {
        if (userId) {
            GetUserSchoolUnits(userId)(dispatch);
        }
    }, [dispatch, userId]);

    useEffect(() => {
        if (primarySchoolSaveStatus.code === StatusCode.COMPLETE && userId) {
            setTimeout(() => {
                GetUserSchoolUnits(userId, true)(dispatch);
                setOpen(false);
                ResetPrimarySchoolSaveStatus()(dispatch);
            }, 1500);
        }
    })

    useEffect(() => {
        if (userSchoolUnits.code === StatusCode.COMPLETE) {
            const selectableSchoolUnitsList = getPrimarySchoolOptions(userSchoolUnits)
                .sort((a, b) => a.schoolUnit.title! > b.schoolUnit.title! ? 1 : -1);

            setSelectableSchoolUnits(selectableSchoolUnitsList);

            const selectedPrimary = selectableSchoolUnitsList.find(x => x.primarySchoolStatus.overriddenPrimary) ||
                selectableSchoolUnitsList.find(x => x.primarySchoolStatus.status === PrimarySchoolTypes.Types.DEFAULTPRIMARY);
            if (selectedPrimary) {
                setPrimarySchool(selectedPrimary);
            }
        }
    }, [userSchoolUnits]);

    const updatePrimarySchool = async (updatedSchool: string) => {
        if (userId) {
            SetUserPrimarySchool(userId, updatedSchool)(dispatch);
        }
    }

    const deleteOverridenPrimarySchool = async () => {
        if (userId) {
            DeleteUserPrimarySchool(userId)(dispatch);
        }
    }

    const closeModal = (): void => {
        setOpen(false);
        ResetPrimarySchoolSaveStatus()(dispatch);
    }

    const renderModal = () => (
        <PrimarySchoolModal
            isOpen={open}
            toggleModal={() => closeModal()}
            saveStatus={primarySchoolSaveStatus.code}
            deleteOverridenPrimarySchool={() => deleteOverridenPrimarySchool()}
            items={selectableSchoolUnits}
            primarySchool={primarySchool}
            getIcons={primarySchoolStatus => getIcons(primarySchoolStatus)}
            update={(updatedSchool) => updatePrimarySchool(updatedSchool)}
            disabled={!isAuthorized(authorizedAccessPolicies, FlowSyncAccessPolicy.MAP)}
        />
    );

    const getIcons = (primarySchoolStatus: CorePrimarySchoolStatus) => {
        let icon = [];
        if (primarySchoolStatus?.overriddenPrimary) {
            icon.push(<FontAwesomeIcon key='green' className="me-2" title="Vald primär skola" color="orange" icon="crown" />);
        }
        if (primarySchoolStatus?.status === PrimarySchoolTypes.Types.DEFAULTPRIMARY) {
            icon.push(<FontAwesomeIcon key='grey' className="me-2" title="Beräknad primär skola" color="grey" icon="check" />);
        }
        return icon;
    }

    const iconWithPen = (schoolUnitId: string, first: boolean, primarySchoolStatus?: CorePrimarySchoolStatus) => {
        if (primarySchoolStatus) {
            const icons = getIcons(primarySchoolStatus);
            const allowedEdit = isAuthorized(authorizedAccessPolicies, FlowSyncAccessPolicy.MAP);
            const displayPen = icons.length >= 1 && selectableSchoolUnits.length > 1 && primarySchool?.schoolUnit.id === schoolUnitId && first && allowedEdit;
            return (<>
                {icons}
                {displayPen && <FontAwesomeIcon className="c-pointer" title="Redigera primär skola" icon="pen" onClick={() => setOpen(true)} />}
            </>);
        }
        return '-';
    }

    const renderUserSchoolUnits = () => {
        let combineRoles: DisplaySchool[] = [];
        if (userSchoolUnits.code === StatusCode.COMPLETE) {
            combineRoles = convertUserSchoolUnits(userSchoolUnits)
        }

        const handled: string[] = [];
        const isHandled = (schoolUnitId: string) => handled.some(s => s === schoolUnitId)

        const userSchools = combineRoles
            .sort((a, b) => a.schoolUnit.title! > b.schoolUnit.title! ? 1 : -1)
            .map((s, i) => {
                var first = !isHandled(s.schoolUnit.id);
                if (s.primarySchoolStatus) {
                    handled.push(s.schoolUnit.id);
                }
                return ({
                    id: i.toString(),
                    data: [
                        { value: <Link to={`/schoolunit/${s.schoolUnit.id}`}>{s.schoolUnit.title} </Link> },
                        { value: userRoleForSchool(s) },
                        { value: s.active !== undefined ? Helper.active(s.active) : '-' },
                        { value: Helper.formatDateOnly(s.startDate) },
                        { value: Helper.formatDateOnly(s.endDate) },
                        { value: iconWithPen(s.schoolUnit.id, first, s.primarySchoolStatus) },
                    ]
                });
            }) ?? [];
        return (
            <TableHorizontal
                header={[{ value: 'Skola' }, { value: 'Roll' }, { value: 'Aktiv' }, { value: 'Startdatum' }, { value: 'Slutdatum' }, { value: <>Primär <a href="https://flowsync.se/help/schooladmin/primarySchool" target="_blank" rel="noreferrer" > <FontAwesomeIcon className="documentation-icon small" title="Visa hjälpavsnitt" icon="question-circle" /></a> </>, dataLabel: "Primär" }]}
                info={userSchools}
                displayName="Skolkopplingar"
                searching={userSchoolUnits.code === StatusCode.PENDING}
                error={userSchoolUnits.code === StatusCode.ERROR}
                noResMsg="Den här personen saknar skolkopplingar"
            />
        )
    }

    return (<>
        {renderUserSchoolUnits()}
        {open && renderModal()}
    </>)
}

export default UserViewSchoolUnits;
