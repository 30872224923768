import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import StatusCode from '../../util/StatusCode';
import * as Helper from '../../util/Helper';
import TableHorizontal from '../misc/TableHorizontal';
import debounce from 'lodash/debounce';
import ActiveToggle from '../misc/ActiveToggle';
import SelectInput from '../misc/SelectInput';
import * as GroupType from '../../util/GroupTypeUtil';
import FilterBottomComponent from '../misc/FilterBottomComponent';
import Search from '../misc/Search';
import { getModules, ModuleState } from '../../store/ModuleAction';
import RequestParser from '../../util/RequestParser';
import { GetGroups, GroupState } from '../../store/GroupAction';
import { ModuleTypeInfo } from '../../util/UtilityTypes';
import { RequestState } from '../../models/RequestState';
import { CoreGroupListing } from '../../models/CoreGroup';
import { PageResult } from '../../models/CoreModels';
import { InfoObject } from '../misc/TableHorizontalPagedToggle';

interface GroupListState {
    groups: GroupState
    module: ModuleState
}

const GroupList = () => {
    const dispatch = useDispatch();

    const [search, setSearch] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    const groups = useSelector<GroupListState, RequestState<PageResult<CoreGroupListing>>>(state => state.groups.groups);
    const modules = useSelector<GroupListState, ModuleTypeInfo[]>(state => Helper.distinctSchoolAdminModuleTypes(state.module.modules));

    var filter = RequestParser.parseGroupSearchPageRequest(searchParams);

    useEffect(() => {
        if (filter.search) {
            setSearch(filter.search);
        }
        GetGroups(filter)(dispatch);
        getModules()(dispatch);
    }, [dispatch]);

    useEffect(() => {
        GetGroups(filter, true)(dispatch);
        window.scrollTo(0, 0);
    }, [searchParams]);

    const updateFilter = (name: string, value?: number | string | boolean | null) => {
        if (name !== 'page') {
            searchParams.set('page', '1');
        }

        if (value !== undefined && value !== '' && value !== null) {
            if (typeof (value) === 'string') {
                searchParams.set(name, value);
            } else {
                searchParams.set(name, value.toString());
            }
        } else {
            searchParams.delete(name);
        }

        setSearchParams(searchParams);
    }

    const debouncedSearch = useCallback(
        debounce(search => {
            updateFilter('search', search);
        }, 300), [searchParams]);

    const onSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        e.preventDefault();
        let search = e.target.value;
        setSearch(search);
        debouncedSearch(search);
    };

    const clearFilter = () => {
        setSearch('');
        setSearchParams([]);
    };

    const mapGroups = (): InfoObject[] => {
        if (groups.code != StatusCode.COMPLETE) {
            return [];
        }

        return groups.data.values.map(g => ({
            id: g.id,
            data: [
                { value: <Link to={`/group/${g.id}/information`}>{g.title}</Link> },
                { value: g.schoolUnit && <Link to={`/schoolunit/${g.schoolUnit.id}/information`}>{g.schoolUnit.title}</Link> },
                { value: g.studentGroupType ? GroupType.groupTypeFromId(g.studentGroupType.id) : 'Okänd' },
                { value: g.studentCount },
                { value: g.teacherCount },
                { value: Helper.active(g.active) },
                { value: Helper.formatDate(g.modified) },
            ]
        }))
    }

    const renderFilter = () => (<>
        <Row>
            <Col sm="6">
                <Search value={search} onChange={onSearch} placeholder="Sök grupp" />
            </Col>
            <Col sm="4">
                <SelectInput
                    items={modules.map(m => ({ value: m.moduleTypeName, text: `Filtrera på ${m.moduleTypeDisplayName}` }))}
                    onChange={(name, value) => updateFilter(name, value)}
                    name='moduleTypeName'
                    selected={filter.moduleTypeName}
                    defaultText='Filtrera på modultyp'
                />
            </Col>
            <Col sm="2">
                <ActiveToggle
                    onlyActive={filter?.onlyActive ?? true}
                    update={(active) => updateFilter('onlyActive', active)}
                    disabled={groups.code === StatusCode.PENDING}
                />
            </Col>
        </Row>
        <Row>
            <Col sm="6">
                <SelectInput
                    items={GroupType.GroupTypes.map(groupType => ({ value: groupType.id, text: `Filtrera på ${groupType.name}` }))}
                    onChange={(name, value) => updateFilter(name, value)}
                    name='groupType'
                    selected={filter.groupType}
                    defaultText='Visa alla grupptyper'
                />
            </Col>
        </Row>
    </>);

    const renderGroups = () => {
        const groupInfo = mapGroups();
        return (
            <TableHorizontal
                header={[{ value: 'Titel' }, { value: 'Skola' }, { value: 'Grupptyp' }, { value: 'Antal elever' }, { value: 'Antal personal' }, { value: 'Aktiv' }, { value: 'Ändrad' }]}
                info={groupInfo}
                error={groups.code === StatusCode.ERROR}
                searching={groups.code === StatusCode.PENDING}
                displayName="Grupper"
                noResMsg="Inga grupper hittades"
            />
        )
    }

    return (
        <div className="container list_groups">

            <div className="header--fixed large-filter">
                <h1>Grupper</h1>
                {renderFilter()}
            </div>

            <div className="body--scroll padding-40">
                {renderGroups()}
            </div>

            <FilterBottomComponent
                clearFilter={clearFilter}
                updateFilter={(value) => updateFilter('page', value)}
                requestState={groups}
            />

        </div>
    )
}

export default GroupList;
