import React, { useState } from 'react';
import { FormGroup, Label, Col, Row } from 'reactstrap';
import { OnChangeCallback, OptionItem } from '../../../../../util/UtilityTypes'
import * as AsmTypes from '../../../../../util/AppleSchoolManagerTypes';
import SelectInput from '../../../../misc/SelectInput';


interface NameObject {
    mappingType: string
    mappingValue: string
};

interface ValueObject {
    mappingType: number
    mappingValue: string | undefined
};

interface AsmMappingProps {
    title: string
    name: NameObject
    value: ValueObject
    defaultText: string
    disabled?: boolean
    invalid?: boolean
    invalidFeedback?: string
    onChange: OnChangeCallback<string | undefined | number>
};

const AsmMapping: React.FC<AsmMappingProps> = (props) => {

    const [previous, setPrevious] = useState(props.value.mappingValue ?? undefined)

    const onChangeMappingTypes = async (name: string, value: number | undefined) => {
        if (value !== undefined) {
            await props.onChange(name, value);
            if (value === 0) {
                props.onChange(props.name.mappingValue, undefined);
            }
            if (value !== 0 && previous) {
                props.onChange(props.name.mappingValue, previous);
            }
        }
    }

    const onChangeMappingValue = (name: string, value: string | undefined) => {
        setPrevious(value);
        props.onChange(name, value);
    }

    const getText = (item: OptionItem<AsmTypes.AppleSchoolManagerUserMappingType>): string => {
        if (item.value === AsmTypes.AppleSchoolManagerUserMappingType.DEFAULT) {
            return props.defaultText;
        }

        return item.text;
    }

    return (
        <FormGroup>
            <Label style={{ display: 'block' }}>{props.title}:</Label>
            <FormGroup>
                <Row>
                    <Col sm="3">
                        <SelectInput
                            items={AsmTypes.userMappingTypes.map(opt => ({ value: opt.value, text: getText(opt) }))}
                            onChange={(name, value) => onChangeMappingTypes(name, value)}
                            name={props.name.mappingType}
                            selected={props.value.mappingType}
                            defaultText={props.defaultText}
                            defaultValue={AsmTypes.userMappingTypes[0].value}
                            disabled={props.disabled}
                        />
                    </Col>

                    <Col>
                        <SelectInput
                            items={AsmTypes.ExtensionAttributes.map(opt => ({ value: opt, text: opt }))}
                            onChange={(name, value) => onChangeMappingValue(name, value)}
                            name={props.name.mappingValue}
                            selected={props.value.mappingValue}
                            defaultText={`Välj ett extension attribut för ${props.title}`}
                            disableSort={true}
                            disabled={props.disabled || props.value.mappingType !== AsmTypes.AppleSchoolManagerUserMappingType.ONPREMISESEXTENSIONATTRIBUTE}
                            invalid={props.invalid}
                            invalidFeedback={props.invalidFeedback}
                        />

                    </Col>
                </Row>
            </FormGroup>
        </FormGroup>
    );
}

export default AsmMapping;
