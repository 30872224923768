const schoolTypeMasks = new Map<string, number>([
    ["Förskola", 0x00001],
    ["Förskoleklass", 0x00002],
    ["Fritidshem", 0x00004],
    ["Grundskola", 0x00008],
    ["Anpassad Grundskola", 0x00010],
    ["Specialskolan", 0x00020],
    ["Sameskola", 0x00040],
    ["Gymnasieskola", 0x00080],
    ["Anpassad Gymnasieskola", 0x00100],
    ["Vuxenutbildning", 0x00200],
    ["Anpassad Vuxenutbildning", 0x00400],
    ["Yrkeshögskola", 0x00800],
    ["Folkhögskola", 0x01000],
    ["Högskola", 0x02000],
    ["Universitet", 0x04000],
    ["Svenska för Invandrare", 0x08000],
    ["Pedagogisk omsorg", 0x10000],
    ["Annan utbildningsform", 0x20000],
]);

export const getTypes = (schoolTypeInt: number): string => schoolTypeInt === 0 ? 'Saknar utbildningsform' : Array.from(schoolTypeMasks)
    .filter(x => (schoolTypeInt & x[1]) === x[1])
    .map(x => x[0])
    .join(', ');

