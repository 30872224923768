import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, Route, Routes, Navigate } from 'react-router-dom'
import { Button } from 'reactstrap';
import { GetEvent, GetEventOrganizers, GetEventParticipants, GetEventTrackings } from '../../store/ScheduleSync/ScheduleSync_Event';
import * as Helper from '../../util/Helper';
import ActiveToggle from '../misc/ActiveToggle';
import ModalComponent from '../misc/ModalComponent';
import RenderTabs from '../misc/Tabs';
import * as Tab from '../../util/TabValue';
import StatusCode from '../../util/StatusCode';
import TableVertical from '../misc/TableVertical';
import TableHorizontalPagedToggle from '../misc/TableHorizontalPagedToggle';
import TableHorizontal from '../misc/TableHorizontal';


export default () => {
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const event = useSelector(state => state.schedulesync_events.event);

    useEffect(() => {
        GetEvent(eventId)(dispatch);
    }, [dispatch]);

    const tabs = [
        Tab.Tabs.Information,
        Tab.Tabs.Members,
        Tab.Tabs.Trackings,
    ];

    const displayName = event.data?.title ? ' / ' + event.data.title : '';

    if (event?.error === 400 || event?.error === 404) {
        return <NotFound />;
    }

    return (
        <div className="padding-40 container single">
            <h1><Link to="/schedulesync/event">Schemaposition</Link> {displayName}</h1>

            <RenderTabs
                tabs={tabs}
                id={eventId}
                url="schedulesync/event" />

            <Routes>
                <Route path={Tab.Tabs.Information.url} element={<Info />} />
                <Route path={Tab.Tabs.Members.url} element={<Members />} />
                <Route path={Tab.Tabs.Trackings.url} element={<Trackings />} />
                <Route path="*" element={<Navigate replace={true} to={`/schedulesync/event/${eventId}/${Tab.Tabs.Information.url}`} />} />
            </Routes>
        </div>
    );
}

export const NotFound = () => {
    return (
        <div className="padding-40 container single">
            <h1><Link to="/schedulesync/event">Schemaposition</Link></h1>
            <p>Schemapositionen hittades inte. Gör en ny <Link to='/schedulesync/event'>sökning</Link></p>
        </div>
    );
}

export const Info = () => {
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const event = useSelector(state => state.schedulesync_events.event);

    useEffect(() => {
        GetEvent(eventId)(dispatch);
    }, [dispatch]);

    const renderInfo = () => {
        let info = [];

        if (event.code === StatusCode.COMPLETE) {
            const e = event.data;
            info.push(
                { header: "Titel", content: e.title },
                { header: "Aktiv", content: Helper.active(e.active) },
                { header: "Starttid", content: Helper.formatDate(e.startTime) },
                { header: "Sluttid", content: Helper.formatDate(e.endTime) },
                { header: "Plats", content: e.location },
                { header: "Skola", content: e.schoolName },
                { header: "ExternalId", content: e.externalId },
                { header: "Skapad", content: Helper.formatDate(e.created) },
                { header: "Ändrad", content: Helper.formatDate(e.lastModified) },
            )
        }

        return (
            <TableVertical
                header='Information'
                info={info}
                error={event.code === StatusCode.ERROR}
                searching={event.code === StatusCode.PENDING}
            />
        )
    }

    return (<>
        {renderInfo()}
    </>)
}

export const Members = () => {
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const organizers = useSelector(state => state.schedulesync_events.eventOrganizers);
    const participants = useSelector(state => state.schedulesync_events.eventParticipants);

    const [show, setShow] = useState({ organizers: true, participants: true });

    useEffect(() => {
        GetEventOrganizers(eventId, { ...organizers?.filter })(dispatch);
        GetEventParticipants(eventId, { ...participants?.filter })(dispatch);
    }, [dispatch]);

    const updateFilter = (value, name, key) => {
        if (key === "participants") {
            GetEventParticipants(eventId, { ...participants.filter, [name]: value })(dispatch);
        }
        if (key === "organizers") {
            GetEventOrganizers(eventId, { ...organizers.filter, [name]: value })(dispatch);
        }
        if (name === "onlyActive") {
            GetEventParticipants(eventId, { ...participants.filter, [name]: value, page: 1 })(dispatch);
            GetEventOrganizers(eventId, { ...organizers.filter, [name]: value, page: 1 })(dispatch);
        }
    }

    const mapUsers = (users) => {
        return users.data?.values.map(x => ({
            id: x.id,
            data: [
                { value: <Link to={`/schedulesync/user/${x.id}/information`}>{x.title}</Link> },
                { value: Helper.active(x.active) },
            ]
        })) ?? [];
    }

    const renderOrganizers = () => {
        const count = Helper.getTotalCountText(organizers);
        return (
            <TableHorizontalPagedToggle
                header={[{ value: `Organisatör ${count}` }, { value: 'Aktiv' }]}
                mobileHeader={['Namn', 'Aktiv']}
                info={mapUsers(organizers)}
                show={show.organizers}
                toggle={() => setShow({ ...show, organizers: !show.organizers })}
                displayName={`Organisatörer ${count}`}
                noResultMsg="Schemapositionen saknar organisatör"
                onChangePage={(p) => updateFilter(p, 'page', 'organizers')}
                requestState={organizers}
            />
        )
    }

    const renderParticipants = () => {
        const count = Helper.getTotalCountText(participants);
        return (
            <TableHorizontalPagedToggle
                header={[{ value: `Deltagare ${count}` }, { value: 'Aktiv' }]}
                mobileHeader={['Namn', 'Aktiv']}
                info={mapUsers(participants)}
                show={show.participants}
                toggle={() => setShow({ ...show, participants: !show.participants })}
                displayName={`Deltagare ${count}`}
                noResultMsg="Schemapositionen saknar deltagare"
                onChangePage={(p) => updateFilter(p, 'page', 'participants')}
                requestState={participants}
            />
        )
    }

    return (<>
        <div className="header_member_table large">Medlemmar
            <span className="right">
                <ActiveToggle
                    onlyActive={organizers?.filter?.onlyActive ?? null}
                    update={(active) => updateFilter(active, 'onlyActive')}
                    disabled={organizers.code === StatusCode.PENDING || participants.code === StatusCode.PENDING}
                />
            </span></div>
        {renderOrganizers()}
        {renderParticipants()}
    </>);
}

export const Trackings = () => {
    const { eventId } = useParams();
    const dispatch = useDispatch();
    const [targetData, setTargetData] = useState(undefined);
    const [open, setOpen] = useState(false);

    const trackings = useSelector(state => state.schedulesync_events.eventTrackings);
    const [onlyActive, setOnlyActive] = useState(trackings?.onlyActive ?? true);

    useEffect(() => {
        GetEventTrackings(eventId, onlyActive)(dispatch);
    }, [dispatch, onlyActive]);

    const renderTrackings = () => {
        const trackingsList = trackings.data?.map(x => ({
            id: x.id,
            data: [
                { value: <Link to={`/module/${x.module.id}`}>{x.module.title}</Link> },
                { value: x.targetSystem },
                { value: x.targetId },
                { value: Helper.formatDate(x.exported) },
                { value: Helper.formatDate(x.exportedLastModified) },
                { value: Helper.active(x.active) },
                { value: x.targetData && <Button style={{ whiteSpace: 'nowrap' }} onClick={() => (setOpen(true), setTargetData(x.targetData))}>Visa information</Button> }
            ]
        })) ?? [];

        return (<>
            <h5 className="header-menu">Källor</h5>
            <TableHorizontal
                header={['Modul', 'Målsystem', 'Identifierare', 'Exporterad', 'Modifierad', 'Aktiv', 'Information']}
                info={trackingsList}
                error={trackings.code === StatusCode.ERROR}
                searching={trackings.code === StatusCode.PENDING}
                noResMsg="Den här schemapositionen saknar källor"
            />
        </>)
    }

    const count = trackings.data?.length >= 0 ? `(${trackings.data.length} st)` : '';
    return (<>
        <div className="header_member_table large">Källor {count}
            <span className="right">
                <ActiveToggle
                    onlyActive={trackings?.onlyActive ?? null}
                    update={(active) => setOnlyActive(active)}
                    disabled={trackings.code === StatusCode.PENDING}
                />
            </span></div>
        {renderTrackings()}

        {open &&
            <ModalComponent
                isOpen={open}
                toggleModal={() => (setOpen(false), setTargetData(undefined))}
                header="Information"
                body={<div><pre>{Helper.formatText(targetData)}</pre></div>}
                size="xl"
                cancel="Stäng"
            />}
    </>)
}
