import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams, Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { getOneRosterUsers, getOneRosterRoles } from '../../store/OneRoster/OneRoster_UserAction';
import StatusCode from '../../util/StatusCode';
import debounce from 'lodash/debounce';
import * as Helper from '../../util/Helper';
import ActiveToggle from '../misc/ActiveToggle';
import * as OneRosterRoleType from '../../util/OneRoster/OneRosterRoleTypeUtil';
import TableHorizontal from '../misc/TableHorizontal';
import SelectInput from '../misc/SelectInput';
import Search from '../misc/Search';
import FilterBottomComponent from '../misc/FilterBottomComponent';
import RequestParser from '../../util/RequestParser';


const OneRoster_Users = () => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    const users = useSelector(state => state.oneroster_user.users);
    const roles = useSelector(state => state.oneroster_user.roles);
    var filter = RequestParser.parseOneRosterUserSearchPageRequest(searchParams);

    useEffect(() => {
        if (filter.search) {
            setSearch(filter.search);
        }
        getOneRosterUsers(filter)(dispatch);
        getOneRosterRoles()(dispatch);
    }, [dispatch]);

    useEffect(() => {
        getOneRosterUsers(filter, true)(dispatch);
        window.scrollTo(0, 0);
    }, [searchParams]);

    const updateFilter = (name, value) => {
        if (value !== undefined && value !== '' && value !== null) {
            searchParams.set(name, value);
        } else {
            searchParams.delete(name);
        }

        if (name !== 'page') {
            searchParams.set('page', 1);
        }

        setSearchParams(searchParams);
    }

    const debouncedSearch = useCallback(
        debounce(search => {
            updateFilter('search', search);
        }, 300), [searchParams]);

    const onSearch = (e) => {
        e.preventDefault();
        let search = e.target.value;
        setSearch(search);
        debouncedSearch(search);
    }

    const clearFilter = () => {
        setSearch('');
        setSearchParams([]);
    }

    const renderBody = () => {
        const newList = users?.data?.map(u => ({
            id: u.id,
            data: [
                { value: <Link to={`/oneroster/user/${u.id}/information`}> {`${u.givenName} ${u.familyName}`}</Link> },
                { value: u.userName },
                { value: u.schoolCount },
                { value: u.classCount },
                { value: OneRosterRoleType.roleFromId(u.role) },
                { value: u.active ? 'Ja' : 'Nej' },
                { value: Helper.formatDate(u.created), className: 'nowrap' },
                { value: Helper.formatDate(u.modified), className: 'nowrap' },
            ]
        })) ?? [];

        return (
            <TableHorizontal
                header={[{ value: 'Namn' }, { value: 'Användarnamn' }, { value: 'Skolor' }, { value: 'Avsnitt' }, { value: 'Roll' }, { value: 'Aktiv' }, { value: 'Skapad' }, { value: 'Ändrad' }]}
                info={newList}
                error={users.code === StatusCode.ERROR}
                searching={users.code === StatusCode.PENDING}
                displayName="Användare"
                noResMsg="Inga användare hittades"
            />
        )
    }

    const renderFilter = () => (
        <Row>
            <Col sm="6">
                <Search value={search} onChange={onSearch} placeholder="Sök användare" />
            </Col>

            <Col lg="4">
                <SelectInput
                    items={roles?.data?.map(role => ({ value: role, text: `Filtrera på ${OneRosterRoleType.roleFromId(role)}` }))}
                    onChange={(name, value) => updateFilter(name, value)}
                    name='role'
                    selected={filter.role}
                    defaultText='Visa alla roller'
                    disabled={roles.code === StatusCode.PENDING}
                />
            </Col>

            <Col sm="2">
                <ActiveToggle
                    onlyActive={filter.onlyActive ?? null}
                    update={(active) => updateFilter('onlyActive', active)}
                    disabled={users.code === StatusCode.PENDING}
                />
            </Col>
        </Row>
    )

    const requestState = Helper.convertToCacheRequestState(users);

    return (
        <div className="container users_oneroster">
            <div className="header--fixed">
                <h1>Användare</h1>
                {renderFilter()}
            </div>
            <div className="body--scroll padding-40">
                {renderBody()}
            </div>

            <FilterBottomComponent
                requestState={requestState}
                clearFilter={clearFilter}
                updateFilter={(value) => updateFilter('page', value)}
            />

        </div>
    )
}

export default OneRoster_Users;
