const roleTypes = {
    'administrator': 'Skoladministratör',
    'aide': 'Lärarassistent',
    'guardian': 'Vårdnadshavare',
    'parent': 'Förälder',
    'proctor': 'Examensvakt',
    'relative': 'Släkting',
    'student': 'Elev',
    'teacher': 'Lärare',
}

export const roleFromId = (id) => {
    return roleTypes[id];
}

export const rolesArray = Object.values(roleTypes);

export const roleIdFromRole = (role) => {
    for (const [key, value] of Object.entries(roleTypes)) {
        if (value === role) {
            return key;
        }
    }
}
