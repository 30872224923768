import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom'
import { GetSchoolUnit, SchoolUnitState } from '../../../store/SchoolAction';
import * as SchoolTypeUtil from '../../../util/SchoolTypeUtil';
import * as Helper from '../../../util/Helper';
import StatusCode from '../../../util/StatusCode';
import TableVertical, { TableVerticalInfoObject } from '../../misc/TableVertical';
import { CoreSchoolUnit } from '../../../models/CoreSchoolUnit';
import { RequestState } from '../../../models/RequestState';

interface State {
    schools: SchoolUnitState
}

const SchoolUnitViewInfo = () => {
    const { schoolId } = useParams();

    const dispatch = useDispatch();
    const school = useSelector<State, RequestState<CoreSchoolUnit>>(state => state.schools.school);

    useEffect(() => {
        if (schoolId !== undefined) {
            GetSchoolUnit(schoolId)(dispatch);
        }
    }, [dispatch, schoolId]);

    const renderSchoolUnitInfo = () => {
        const schoolInfo: TableVerticalInfoObject[] = [];
        if (school.code === StatusCode.COMPLETE) {
            const s = school.data;
            schoolInfo.push(
                { header: 'Titel', content: s.title },
                { header: 'Kortnamn', content: s.shortName },
                { header: 'Id', content: s.id },
                { header: 'Skoltyp', content: s.schoolTypes ? SchoolTypeUtil.getTypes(s.schoolTypes?.id) : 'Okänt' },
                { header: 'Startdatum', content: Helper.formatDateOnly(s.startDate) },
                { header: 'Slutdatum', content: Helper.formatDateOnly(s.endDate) },
                { header: 'Kommunkod', content: s.municipalityCode },
                { header: 'Organisation', content: s.organisation?.title },
                { header: 'Skolenhetskod', content: s.schoolUnitCode },
                { header: 'Aktiv', content: Helper.active(s.active) },
                { header: 'Skapad', content: Helper.formatDate(s.created) },
                { header: 'Ändrad', content: Helper.formatDate(s.modified) },
                { header: 'Raderad', content: Helper.formatDate(s?.removed) },
            )
        }

        return (
            <TableVertical
                header='Information'
                info={schoolInfo}
                error={school.code === StatusCode.ERROR}
                searching={school.code === StatusCode.PENDING}
            />
        )
    }

    return (<>
        {renderSchoolUnitInfo()}
    </>)
}

export default SchoolUnitViewInfo;
