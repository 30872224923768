import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom'
import { GetGroupActivities, GroupActivityRequestState, GroupState } from '../../../store/GroupAction';
import * as Helper from '../../../util/Helper';
import StatusCode from '../../../util/StatusCode';
import ActiveToggle from '../../misc/ActiveToggle';
import { CoreGroupActivity } from '../../../models/CoreActivity';
import TableHorizontalPagedToggle, { InfoObject } from '../../misc/TableHorizontalPagedToggle';

interface GroupViewActivitiesState {
    groups: GroupState
}

const GroupViewActivities = () => {
    const { groupId } = useParams();
    const dispatch = useDispatch();
    const [show, setShow] = useState(true);
    const activities = useSelector<GroupViewActivitiesState, GroupActivityRequestState>(state => state.groups.activities);

    useEffect(() => {
        if (groupId !== undefined) {
            GetGroupActivities(groupId, { ...activities?.filter })(dispatch);
        }
    }, [dispatch, groupId]);

    const updateFilter = (value: boolean | number, name: string) => {
        const filter = { ...activities.filter };
        if (name === "onlyActive") {
            filter["page"] = 1;
        }
        if (groupId !== undefined) {
            GetGroupActivities(groupId, { ...filter, [name]: value })(dispatch);
        }
    }

    const mapActivities = (): InfoObject[] => {
        if (activities.code !== StatusCode.COMPLETE) {
            return [];
        }

        return activities.data.values.map(a => ({
            id: a.id,
            data: [
                { value: <Link to={`/activity/${a.id}/information`}>{a.title}</Link> },
                { value: Helper.active(a.active) },
                { value: Helper.formatDateOnly(a.startDate) },
                { value: Helper.formatDateOnly(a.endDate) },
            ]
        }));
    }

    const renderActivities = () => {
        const activitiesInfo = mapActivities();
        return (
            <TableHorizontalPagedToggle
                header={[{ value: 'Namn' }, { value: 'Aktiv' }, { value: 'Startdatum' }, { value: 'Slutdatum' }]}
                info={activitiesInfo}
                show={show}
                toggle={() => setShow(!show)}
                onChangePage={(p) => updateFilter(p, 'page')}
                noResultMsg="Den här gruppen saknar aktiviteter"
                requestState={activities}
            />
        )
    }

    const count = Helper.getTotalCountText(activities);
    return (<>
        <div className="header_member_table large">Aktiviteter {count}
            <span className="right">
                <ActiveToggle
                    id="group-activities"
                    onlyActive={activities?.filter?.onlyActive ?? true}
                    update={(active) => updateFilter(active, 'onlyActive')}
                    disabled={activities.code === StatusCode.PENDING}
                />
            </span></div>
        {renderActivities()}
    </>);
}

export default GroupViewActivities;
