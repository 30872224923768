import React, { useEffect, useState } from 'react';
import InstanceClient from '../services/InstanceClient';
import Dagre from '@dagrejs/dagre';
import ReactFlow, {
    ReactFlowProvider,
    useNodesState,
    useEdgesState,
    useReactFlow,
    Node,
    Edge,
    MarkerType,
    Position
} from 'reactflow';
import {
    CoreModuleTriggerOverview,
    CoreModuleTriggerOverviewTrigger,
    CoreModuleTriggerOverviewModule,
    CoreTriggerType,
    ModuleType,
} from '../models/CoreModels'
import { formatDate } from '../util/Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'reactflow/dist/style.css';

enum LayoutDirection {
    Horizontal = 'LR',
    Vertical = 'TB'
}

const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

const positionElements = (nodes: Node[], edges: Edge[], layout: LayoutDirection): [Node[], Edge[]] => {
    g.setGraph({ rankdir: layout });

    nodes.forEach((node) => g.setNode(node.id, { ...node.data, width: node.width, height: node.height }));
    edges.forEach((edge) => g.setEdge(edge.source, edge.target));

    Dagre.layout(g);

    return [
        nodes.map((node) => {
            var { x, y } = g.node(node.id);

            //assert that cron nodes (one per module) should always align with its triggered module.
            if (node.id.startsWith('cron-')) {
                const edge = edges.find(x => x.source === node.id);
                if (edge !== undefined) {
                    const triggeredNode = g.node(edge.target);
                    if (layout === LayoutDirection.Horizontal) {
                        y = triggeredNode.y
                    } else {
                        x = triggeredNode.x;
                    }
                }
            }
            
            // note on operations: Dagre nodes are anchored [center,center] while reactflow are anchored [top,left]
            return { ...node, position: { x: x - node.width! / 2, y: y - node.height! / 2 } }
        }),
        edges,
    ];
};

const getNodeType = (module: CoreModuleTriggerOverviewModule, triggers: CoreModuleTriggerOverviewTrigger[]): string => {
    const triggersOther = triggers.some(x => x.runAfterModuleId === module.id);

    if (triggersOther) {
        return "default";
    }
    else {
        return "output";
    }
};

const estimateLayout = (modules: CoreModuleTriggerOverviewModule[], triggers: CoreModuleTriggerOverviewTrigger[]): LayoutDirection => {
    var pathLengths = modules
        .filter(x => triggers.some(y => y.moduleId === x.id && y.triggerType === CoreTriggerType.CronSchedule))
        .map(x => {
            let count = 0;
            let ids = [x.id]
            while (ids.length !== 0) {
                ids = triggers
                    // eslint-disable-next-line no-loop-func
                    .filter(y => ids.includes(y.runAfterModuleId))
                    .map(y => y.moduleId);
                count++;
            }

            return count;
        });

    var maxPathLength = Math.max(...pathLengths);

    return (window.innerWidth / maxPathLength) > 350 ? LayoutDirection.Horizontal : LayoutDirection.Vertical;
}

const getNodes = (modules: CoreModuleTriggerOverviewModule[], triggers: CoreModuleTriggerOverviewTrigger[], layout: LayoutDirection): Node[] => {

    const triggerWarningMessage = (id: string, active: boolean) => {
        if (!active) {
            return "Modulen är inaktiv. Efterföljande moduler utan annan schemaläggning kommer inte att köras.";
        }

        const findTrigger = triggers.find(x => x.moduleId === id)
        if (findTrigger?.triggerType !== CoreTriggerType.CronSchedule && !modules.find(x => x.id === findTrigger?.runAfterModuleId)) {
            return "Modulen ska köra efter en modul som saknar schemaläggning.";
        }
        if (findTrigger?.triggerType === CoreTriggerType.CronSchedule && !findTrigger.cronSchedule) {
            return "Modulen saknar schemaläggning.";
        }
    }

    const moduleNodes: Node[] = modules.flatMap(x => {
        const warningMessage = triggerWarningMessage(x.id, x.active);

        return ({
            id: x.id,
            data: {
                label: (
                    <div title={warningMessage}>
                        {x.title}
                        {warningMessage !== undefined && <FontAwesomeIcon className="module-trigger-overview__inactive-icon" icon="exclamation-triangle" />}
                    </div>
                )
            },
            width: 150,
            height: 60,
            type: getNodeType(x, triggers),
            sourcePosition: layout === LayoutDirection.Horizontal ? Position.Right : Position.Bottom,
            targetPosition: layout === LayoutDirection.Horizontal ? Position.Left : Position.Top,
            position: { x: 0, y: 0 },
            className: !x.active ? "module-trigger-overview__node--inactive" : x.moduleType === ModuleType.ImportModule ? "module-trigger-overview__node--import" : "module-trigger-overview__node--export",
            draggable: false,
        })
    });

    const cronNodes = triggers
        .filter(x => x.triggerType === CoreTriggerType.CronSchedule)
        .reduce((acc, x) => { // because nextScheduledStart comes from module we only want to show one crontrigger per module together with its cron-values.
            const idx = acc.findIndex(y => y[0] === x.moduleId);
            if (idx !== -1) {
                acc[idx][1].push(x)
            } else {
                acc.push([x.moduleId, [x]])
            }

            return acc;
        }, [] as [string, CoreModuleTriggerOverviewTrigger[]][])
        .map(x => x[1])
        .map(x => ({
            id: "cron-" + x[0].id,
            width: 150,
            height: 60,
            data: {
                label: (
                    <div title={`Uträknat av cronuttryck: ${x.map(y => y.cronSchedule).join(", ")}`}>
                        {x[0].nextScheduledStart ?
                            <div>
                                <div><strong>Nästa start:</strong></div>
                                <div>{formatDate(x[0].nextScheduledStart, false)}</div>
                            </div> : 
                            <div>
                                <div><strong>Cronuttryck:</strong></div>
                                <div>{x.map(y => y.cronSchedule).join(", ")}</div>
                            </div>}
                    </div>
                )
            },
            className: 'module-trigger-overview__node--cron',
            type: "input",
            sourcePosition: layout === LayoutDirection.Horizontal ? Position.Right : Position.Bottom,
            position: { x: 0, y: 0 },
            draggable: false
        }));

    return [...moduleNodes, ...cronNodes]
}

const getEdges = (triggers: CoreModuleTriggerOverviewTrigger[]): Edge[] => {
    const edges: Edge[] = triggers
        .map(x => ({
            id: x.id,
            source: x.triggerType === CoreTriggerType.CronSchedule ? "cron-" + x.id : x.runAfterModuleId,
            target: x.moduleId,
            markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 20,
                height: 20,
                color: '#0e303b',
            },
            style: {
                stroke: '#0e303b',
                strokeWidth: 1,
            }
        }));

    return edges;
}

const ModuleTriggerOverviewFlow = () => {

    const reactFlow = useReactFlow();
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [triggerOverview, setTriggerOverview] = useState<CoreModuleTriggerOverview>({ modules: [], triggers: [] });

    useEffect(() => {
        (async () => {
            var res = await InstanceClient.get<CoreModuleTriggerOverview>("/api/core/module/triggeroverview");
            var triggers = res.data;
            setTriggerOverview(triggers);
        })();
    }, [setTriggerOverview])

    useEffect(() => {
        if (triggerOverview.modules.length === 0) {
            return;
        }

        const layout = estimateLayout(triggerOverview.modules, triggerOverview.triggers);
        const nodes = getNodes(triggerOverview.modules, triggerOverview.triggers, layout);
        const edges = getEdges(triggerOverview.triggers);

        const [positionedNodes, positionedEdges] = positionElements(nodes, edges, layout);

        setNodes(positionedNodes);
        setEdges(positionedEdges);

        window.requestAnimationFrame(() => {
            reactFlow.fitView();
        });
    }, [triggerOverview, reactFlow, setNodes, setEdges])

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            fitView
        />
    );
}

const ModuleTriggerOverview = () => {
    return (
        <div className="module-trigger-overview">
            <ReactFlowProvider>
                <ModuleTriggerOverviewFlow />
            </ReactFlowProvider>
        </div>
    );
};

export default ModuleTriggerOverview;