import React from 'react';
import { FormGroup, Label, Input, Card, CardHeader, CardBody, Collapse, Button, FormFeedback } from 'reactstrap';
import ConfigurationComponent from '../ConfigurationComponent';
import EnumInput from '../../../misc/EnumInput';
import ModalComponent from '../../../misc/ModalComponent';
import StatusCode from '../../../../util/StatusCode';
import InstanceClient from '../../../../services/InstanceClient';
import Loading from '../../../misc/Loading';
import * as AsmTypes from '../../../../util/AppleSchoolManagerTypes';
import AsmMapping from './components/AsmMapping';

class AppleSchoolManagerExport extends ConfigurationComponent {
    state = {
        open: true,
        resetFingerprintModal: {
            isOpen: false,
            reason: null,
            operationStatus: {
                code: StatusCode.NONE,
                error: undefined,
            }
        }
    }

    validate(configuration, active) {
        const validation = [];

        if (active) {
            if (!configuration.sftpHostname || configuration.sftpHostname === '') {
                validation.push('sftpHostname');
            }

            if (!configuration.sftpUsername || configuration.sftpUsername === '') {
                validation.push('sftpUsername');
            }

            if (!configuration.sftpPassword || configuration.sftpPassword === '') {
                validation.push('sftpPassword');
            }
        }

        if (configuration.personIdMappingType === AsmTypes.AppleSchoolManagerUserMappingType.ONPREMISESEXTENSIONATTRIBUTE  && !configuration.personIdMappingValue) {
            validation.push('personIdMappingValue');
        }

        if (configuration.personNumberMappingType === AsmTypes.AppleSchoolManagerUserMappingType.ONPREMISESEXTENSIONATTRIBUTE && !configuration.personNumberMappingValue) {
            validation.push('personNumberMappingValue');
        }

        if (configuration.sisUserNameMappingType === AsmTypes.AppleSchoolManagerUserMappingType.ONPREMISESEXTENSIONATTRIBUTE && !configuration.sisUserNameMappingValue) {
            validation.push('sisUserNameMappingValue');
        }

        if (configuration.emailAddressMappingType === AsmTypes.AppleSchoolManagerUserMappingType.ONPREMISESEXTENSIONATTRIBUTE && !configuration.emailAddressMappingValue) {
            validation.push('emailAddressMappingValue');
        }

        return validation;
    }

    setResetFingerprintModalState(state) {
        this.setState({
            resetFingerprintModal: {
                ...this.state.resetFingerprintModal,
                ...state,
            },
        })
    }

    updateResetFingerprintModalReason = (e) => this.setResetFingerprintModalState({ reason: e.target.value });

    closeResetFingerprintModal(id, confirmed) {
        if (confirmed) {
            this.setResetFingerprintModalState({
                operationStatus: {
                    code: StatusCode.PENDING
                }
            });

            const body = {
                reason: this.state.resetFingerprintModal.reason
            };

            InstanceClient.post(`/api/core/appleschoolmanagerconfiguration/${id}/clearsftpfingerprint`, body)
                .then(_ => this.setResetFingerprintModalState({
                    isOpen: false,
                    reason: null,
                    operationStatus: {
                        code: StatusCode.COMPLETE
                    }
                }))
                .catch(error => this.setResetFingerprintModalState({
                    operationStatus: {
                        code: StatusCode.ERROR,
                        error,
                    }
                }));
        } else {
            this.setResetFingerprintModalState({
                isOpen: false,
                reason: null,
                operationStatus: {
                    code: StatusCode.COMPLETE
                }
            })
        }
    }

    render() {
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);
        const validation = this.validate(configuration, this.props.active);
        const disabled = this.props.disableInput || this.state.resetFingerprintModal.isOpen;

        return <Card className="edit_module">
            <CardHeader tag="h2">
                <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                {this.props.moduleDisplayName}
            </CardHeader>
            <Collapse isOpen={this.state.open}>
                <CardBody>
                    <div style={{ margin: '1rem 0' }}>
                        <FormGroup>
                            <Label for="sftpHostname">SFTP Hostname</Label>
                            <Input
                                type="text"
                                name="sftpHostname"
                                id="sftpHostname"
                                value={configuration.sftpHostname || ''}
                                disabled={disabled}
                                onChange={this.updateProperty}
                                invalid={validation.indexOf('sftpHostname') !== -1}
                                autoComplete="off"
                            />
                            <FormFeedback>SFTP Hostname måste anges för att kunna aktivera importmodulen</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="sftpUsername">SFTP Username</Label>
                            <Input
                                type="text"
                                name="sftpUsername"
                                id="sftpUsername"
                                value={configuration.sftpUsername || ''}
                                disabled={disabled}
                                onChange={this.updateProperty}
                                invalid={validation.indexOf('sftpUsername') !== -1}
                                autoComplete="off"
                            />
                            <FormFeedback>SFTP Username måste anges för att kunna aktivera importmodulen</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label for="sftpPassword">SFTP Password</Label>
                            <Input
                                type="text"
                                name="sftpPassword"
                                id="sftpPassword"
                                value={configuration.sftpPassword || ''}
                                disabled={disabled}
                                onChange={this.updateProperty}
                                invalid={validation.indexOf('sftpPassword') !== -1}
                                autoComplete="off"
                            />
                            <FormFeedback>SFTP Password måste anges för att kunna aktivera importmodulen</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <div className="edit_module">
                                <Button
                                    className="flowsync-btn"
                                    disabled={disabled}
                                    onClick={(e) => this.setResetFingerprintModalState({ isOpen: true })}
                                >Återställ sparat fingeravtryck för SFTP-server</Button>
                            </div>
                        </FormGroup>
                        <ModalComponent
                            isOpen={this.state.resetFingerprintModal.isOpen}
                            toggleModal={() => this.closeResetFingerprintModal(configuration.id, false)}
                            header="Bekräfta återställ av sparat fingeravtryck för SFTP-server"
                            size="xl"
                            disabled={this.state.resetFingerprintModal.operationStatus.code == StatusCode.PENDING}
                            update={() => this.closeResetFingerprintModal(configuration.id, true)}
                            confirm="Genomför återställning"
                            cancel="Avbryt"
                        >
                            <p>Fingeravtrycket för SFTP-servern sparas för att FlowSync ska veta att den server den pratar med är densamma som det varit tidigare. Detta är en säkerhetsåtgärd för att t.ex. försvara sig mot en 'man in the middle'-attack eller liknande attack. Ibland kan fingeravtrycket ändras helt legitimt och då behöver vi uppdatera det fingeravtryck som vi har sparat. Var därför helt säker på att fingeravtrycket faktiskt har ändrats innan du fortsätter.</p>
                            <FormGroup>
                                <Label for="resetFingerprintReason">Anledning för återställning av sparat fingeravtryck för SFTP servern:</Label>
                                <Input
                                    type="text"
                                    id="resetFingerprintReason"
                                    disabled={this.state.resetFingerprintModal.operationStatus.code == StatusCode.PENDING}
                                    value={this.state.resetFingerprintModal.reason ?? ''}
                                    onChange={this.updateResetFingerprintModalReason} />
                                {this.state.resetFingerprintModal.operationStatus.code == StatusCode.PENDING && <Loading />}
                                {this.state.resetFingerprintModal.operationStatus.code == StatusCode.ERROR && <p style={{ color: 'red' }}>Kunde inte återställa server fingeravtryck</p>}
                            </FormGroup>
                        </ModalComponent>

                        <FormGroup>
                            <EnumInput
                                title="Skapa klasser i Apple School Manager baserat på:"
                                multi={true}
                                name="classSourceSelection"
                                value={configuration.classSourceSelection}
                                options={AsmTypes.classSourceSelectionOptions}
                                onChange={this.updatePropertyValue}
                                disabled={disabled}
                            />
                        </FormGroup>

                        <h5>Mappning för användarkolumner (staff.csv, students.csv)</h5>
                        <AsmMapping
                            title="person_id"
                            defaultText="FlowSync id (default)"
                            disabled={this.props.disableInput}
                            value={{ mappingType: configuration.personIdMappingType, mappingValue: configuration.personIdMappingValue }}
                            name={{ mappingType: "personIdMappingType", mappingValue: "personIdMappingValue" }}
                            onChange={this.updatePropertyValue}
                            invalidFeedback="Ange ett värde för extension attribut"
                            invalid={validation.indexOf('personIdMappingValue') !== -1}
                        />

                        <AsmMapping
                            title="person_number"
                            defaultText="Exportera ej (default)"
                            disabled={this.props.disableInput}
                            value={{ mappingType: configuration.personNumberMappingType, mappingValue: configuration.personNumberMappingValue }}
                            name={{ mappingType: "personNumberMappingType", mappingValue: "personNumberMappingValue" }}
                            onChange={this.updatePropertyValue}
                            invalidFeedback="Ange ett värde för extension attribut"
                            invalid={validation.indexOf('personNumberMappingValue') !== -1}
                        />

                        <AsmMapping
                            title="sis_username"
                            defaultText="E-postadress (default)"
                            disabled={this.props.disableInput}
                            value={{ mappingType: configuration.sisUserNameMappingType, mappingValue: configuration.sisUserNameMappingValue }}
                            name={{ mappingType: "sisUserNameMappingType", mappingValue: "sisUserNameMappingValue" }}
                            onChange={this.updatePropertyValue}
                            invalidFeedback="Ange ett värde för extension attribut"
                            invalid={validation.indexOf('sisUserNameMappingValue') !== -1}
                        />

                        <AsmMapping
                            title="email_address"
                            defaultText="E-postadress (default)"
                            disabled={this.props.disableInput}
                            value={{ mappingType: configuration.emailAddressMappingType, mappingValue: configuration.emailAddressMappingValue }}
                            name={{ mappingType: "emailAddressMappingType", mappingValue: "emailAddressMappingValue" }}
                            onChange={this.updatePropertyValue}
                            invalidFeedback="Ange ett värde för extension attribut"
                            invalid={validation.indexOf('emailAddressMappingValue') !== -1}
                        />

                    </div>
                </CardBody>
            </Collapse>
        </Card>;
    }
}

export default AppleSchoolManagerExport;
