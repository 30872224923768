import InstanceClient from '../services/InstanceClient';
import StatusCode from '../util/StatusCode';
import * as Notifications from '../services/Notifications';
import { Action, Dispatch, Reducer } from 'redux';
import { RequestState } from '../models/RequestState';
import { CoreModuleInstancePhase } from '../models/CoreModels';

enum ModuleInstancePhaseActionType {
    GET_INSTANCE_PHASE = 'GET_INSTANCE_PHASE',
    UPDATE_INSTANCE_PHASE = 'UPDATE_INSTANCE_PHASE',
    CLEAR_INSTANCE_PHASE = 'CLEAR_INSTANCE_PHASE',
}

interface ModuleInstancePhase_GetInstancePhase extends Action<ModuleInstancePhaseActionType> {
    type: ModuleInstancePhaseActionType.GET_INSTANCE_PHASE
    instancePhases: RequestState<CoreModuleInstancePhase[]>
}

interface ModuleInstancePhase_UpdateInstancePhase extends Action<ModuleInstancePhaseActionType> {
    type: ModuleInstancePhaseActionType.UPDATE_INSTANCE_PHASE
    updateInstancePhase: CoreModuleInstancePhase
}

interface ModuleInstancePhase_ClearInstancePhase extends Action<ModuleInstancePhaseActionType> {
    type: ModuleInstancePhaseActionType.CLEAR_INSTANCE_PHASE
}

type ModuleInstancePhaseAction = ModuleInstancePhase_GetInstancePhase
    | ModuleInstancePhase_UpdateInstancePhase
    | ModuleInstancePhase_ClearInstancePhase

export interface ModuleInstancePhaseState {
    instancePhases: RequestState<CoreModuleInstancePhase[]>
}

export const GetModuleInstancePhases = (moduleInstanceId: string) => (dispatch: Dispatch<ModuleInstancePhase_GetInstancePhase>) => {
    dispatch({ type: ModuleInstancePhaseActionType.GET_INSTANCE_PHASE, instancePhases: { code: StatusCode.PENDING } });
    
    InstanceClient.get<CoreModuleInstancePhase[]>(`/api/core/moduleInstancePhase/${moduleInstanceId}`)
        .then(res => dispatch({
            type: ModuleInstancePhaseActionType.GET_INSTANCE_PHASE,
            instancePhases: { code: StatusCode.COMPLETE, data: res.data },
        }))
        .catch(error => dispatch({
            type: ModuleInstancePhaseActionType.GET_INSTANCE_PHASE,
            instancePhases: { code: StatusCode.ERROR, error },
        }));
};

export const ModuleInstancePhasesSubscribe = (moduleInstanceId: string) => (dispatch: Dispatch<ModuleInstancePhase_UpdateInstancePhase>) => {
    Notifications.subscribe(['moduleInstancePhaseUpdate'], (data) => {
        if (data.data.moduleInstanceId === moduleInstanceId) {
            dispatch({ type: ModuleInstancePhaseActionType.UPDATE_INSTANCE_PHASE, updateInstancePhase: data.data })
        }
    });
};

export const ClearModuleInstancePhases = () => (dispatch: Dispatch<ModuleInstancePhase_ClearInstancePhase>) => {
    dispatch({ type: ModuleInstancePhaseActionType.CLEAR_INSTANCE_PHASE });
};

const initialState: ModuleInstancePhaseState = {
    instancePhases: { code: StatusCode.NONE },
};

export const reducer: Reducer<ModuleInstancePhaseState, ModuleInstancePhaseAction> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case ModuleInstancePhaseActionType.GET_INSTANCE_PHASE:
            return {
                ...state,
                instancePhases: action.instancePhases,
            };
        case ModuleInstancePhaseActionType.CLEAR_INSTANCE_PHASE:
            return {
                ...state,
                instancePhases: { code: StatusCode.NONE },
            };
        case ModuleInstancePhaseActionType.UPDATE_INSTANCE_PHASE:
            var updatedPhases = state.instancePhases.code === StatusCode.COMPLETE ? [...state.instancePhases.data] : [];
            var index = updatedPhases.findIndex(x => x.id === action.updateInstancePhase.id);

            if (index !== -1) {
                updatedPhases[index] = action.updateInstancePhase;
            } else {
                updatedPhases.push(action.updateInstancePhase);
            }

            return {
                ...state,
                instancePhases: {
                    ...state.instancePhases,
                    data: updatedPhases
                }
            };
        default:
            return state;
    }
}
