import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import * as Helper from '../../../../../../util/Helper';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatusCode from '../../../../../../util/StatusCode';
import { DnpActivity, CoreDnpActivityListing, CoreDnpExportActivityMapping } from '../../../../../../models/CoreDnpModels';
import { GetSchoolYear } from './DnpActivitiesUtil';
import { CacheRequestState, RequestState } from '../../../../../../models/RequestState';
import { PageResult } from '../../../../../../models/CoreModels';
import MultiSelectInput from '../../../../../misc/MultiSelectInput';
import { SearchableOptionItem } from '../../../../../../util/UtilityTypes';

interface DnpActivityMappingOptionValue {
    activityMappingId?: string
    valid: boolean
    dnpActivityId: string
    dnpActivityDisplayName?: string
    dnpActivitySchoolType?: string
}

interface MappedDnpActivitiesProps {
    dnpExportId: string
    dnpSchoolUnitId: string
    dnpActivities: RequestState<DnpActivity[]>
    updatedMappedActivities: CoreDnpExportActivityMapping[]
    onUpdate: (activities: CoreDnpExportActivityMapping[]) => void
    activitiesBySchool: CacheRequestState<PageResult<CoreDnpActivityListing>>
    mappedActivites: CoreDnpExportActivityMapping[]
    disabled: boolean
    filter: {
        schoolType?: string
        schoolYear?: number[]
    }
    translateSchoolType: (schoolType: string) => string
}

const MappedDnpActivities = (props: MappedDnpActivitiesProps) => {
    const [edit, setEdit] = useState<{ [key: string]: boolean }>({});

    const updateMappedActivities = (selectedDnpActivityMapping: DnpActivityMappingOptionValue[], flowSyncActivity: CoreDnpActivityListing) => {
        if (props.dnpActivities.code !== StatusCode.COMPLETE) {
            return; // can't update mappings if no dnp activities has been loaded
        }

        const dnpActivityLookup = props.dnpActivities.data.reduce<{ [key: string]: DnpActivity }>((lookup, da) => ({ ...lookup, [da.id]: da }), {});

        const updatedMappings: CoreDnpExportActivityMapping[] = selectedDnpActivityMapping.map(mapping => {
            const coreMapping: CoreDnpExportActivityMapping = {
                id: mapping.activityMappingId,
                dnpExportId: props.dnpExportId,
                dnpSchoolUnitId: props.dnpSchoolUnitId,
                flowSyncActivityId: flowSyncActivity.id,
                dnpActivityId: mapping.dnpActivityId,
                dnpActivityTitle: mapping.dnpActivityDisplayName,
                dnpActivitySchoolType: mapping.dnpActivitySchoolType,
                valid: mapping.valid,
            }

            const dnpActivity = dnpActivityLookup[mapping.dnpActivityId];
            if (dnpActivity) {
                coreMapping.dnpActivityTitle = dnpActivity.displayName;
                coreMapping.dnpActivitySchoolType = dnpActivity.schoolType;
            }

            return coreMapping;
        });

        const otherMappings: CoreDnpExportActivityMapping[] = props.updatedMappedActivities
            .filter(x => x.flowSyncActivityId !== flowSyncActivity.id);

        props.onUpdate([...otherMappings, ...updatedMappings])
    };

    const removeActivityMappings = (flowSyncActivity: CoreDnpActivityListing) => {
        updateMappedActivities([], flowSyncActivity);
    };

    const resetChanges = (flowSyncActivity: CoreDnpActivityListing) => {
        const dnpActivityIds: DnpActivityMappingOptionValue[] = flowSyncActivity.dnpActivityMappings
            .map(x => ({
                activityMappingId: x.id,
                valid: x.valid,
                dnpActivityId: x.dnpActivityId,
                dnpActivityDisplayName: x.dnpActivityTitle,
                dnpActivitySchoolType: x.dnpActivitySchoolType,
            }));
        updateMappedActivities(dnpActivityIds, flowSyncActivity);
    };

    const getActivityMappings = (activity: CoreDnpActivityListing) => {
        const updatedActivityMappings = props.updatedMappedActivities?.filter(x => x.flowSyncActivityId === activity.id);
        const isUpdated = !updatedActivityMappings ||
            updatedActivityMappings.length !== activity.dnpActivityMappings.length ||
            updatedActivityMappings.some(x => !activity.dnpActivityMappings.some(y => x.dnpActivityId === y.dnpActivityId));

        return {
            isUpdated: isUpdated,
            dnpActivities: isUpdated ? updatedActivityMappings : activity.dnpActivityMappings
        }
    }
    
    return (<>
        {props.activitiesBySchool.code === StatusCode.COMPLETE && props.activitiesBySchool.data.values.map(a => {
            const activityMappings = getActivityMappings(a);
            const updated = activityMappings.isUpdated;
            const removed = activityMappings.dnpActivities.length === 0 && !edit[a.id];

            const dnpActivityLookup: { [key: string]: DnpActivity } = {};
            if (props.dnpActivities.code === StatusCode.COMPLETE) {
                props.dnpActivities.data.forEach(x => dnpActivityLookup[x.id] = x);
            }

            const dnpActivityOptions: SearchableOptionItem<DnpActivityMappingOptionValue>[] = activityMappings.dnpActivities.map(x => {
                const dnpActivity = dnpActivityLookup[x.dnpActivityId];
                let label = x.dnpActivityTitle ?? 'Okänd';

                if (dnpActivity) {
                    label = dnpActivity.displayName;
                    delete dnpActivityLookup[x.dnpActivityId];
                }

                return {
                    label,
                    invalid: !x.valid,
                    value: {
                        activityMappingId: x.id,
                        valid: x.valid,
                        dnpActivityId: x.dnpActivityId,
                        dnpActivityDisplayName: x.dnpActivityTitle,
                        dnpActivitySchoolType: x.dnpActivitySchoolType,
                    }
                };
            });

            Object.values(dnpActivityLookup).forEach(x => {
                if (props.filter.schoolType && x.schoolType !== props.filter.schoolType) {
                    return;
                }

                if (props.filter.schoolYear && props.filter.schoolYear?.length > 0 && !props.filter.schoolYear.includes(x.schoolYear)) {
                    return;
                }

                dnpActivityOptions.push({
                    label: x.displayName,
                    value: {
                        valid: true,
                        dnpActivityId: x.id,
                        dnpActivitySchoolType: x.schoolType,
                        dnpActivityDisplayName: x.displayName,
                    }
                });
            });

            return (
                <div key={a.id} className={(edit[a.id] ? 'hightlight-edit ' : '') + (updated ? 'highlight-updated ' : '') + (removed ? 'highlight-removed ' : '') + "--row sm-right"}>
                    <span data-label="Aktivitet"><Link to={`/activity/${a.id}/information`} target="_blank">{a.displayName}</Link></span>
                    <span data-label="Skola"><Link to={`/schoolunit/${a.schoolUnit.id}/information`} target="_blank">{a.schoolUnit.title}</Link></span>
                    <span data-label="Årskurs">{GetSchoolYear(a.schoolYear)}</span>
                    <span data-label="Startdatum" className="nobreak">{Helper.formatDateOnly(a.startDate)}</span>
                    <span data-label="Slutdatum" className="nobreak">{Helper.formatDateOnly(a.endDate)}</span>
                    <span data-label="Kurs/Ämne">{a.subjectTitle ? a.subjectTitle : a.courseTitle}</span>

                    {edit[a.id] && !removed ?

                        <MultiSelectInput
                            items={dnpActivityOptions}
                            onChange={(name, value) => updateMappedActivities(value ?? [], a)}
                            name='schoolType'
                            selected={activityMappings.dnpActivities.map(x => ({
                                valid: x.valid,
                                activityMappingId: x.id,
                                dnpActivityId: x.dnpActivityId,
                                dnpActivityDisplayName: x.dnpActivityTitle,
                                dnpActivitySchoolType: x.dnpActivitySchoolType,
                            }))}
                            keySelector={(value) => value.dnpActivityId}
                            defaultText='Välj provaktivitet'
                            disabled={props.dnpActivities.code !== StatusCode.COMPLETE || props.disabled}
                        />
                        :
                        <span data-label="Provaktivitet" style={{ paddingLeft: '9px' }}>{activityMappings.dnpActivities.map(x => x.dnpActivityTitle).join(", ")}</span>
                    }

                    <span data-label="Status">{activityMappings.dnpActivities.every(x => x.valid) ? <FontAwesomeIcon title="Ok" color="green" icon="check" /> : <FontAwesomeIcon title="Felaktig" color="orangered" icon="triangle-exclamation" />}</span>

                    <span data-label="Redigera" className="dnp-schoolUnits_btn-box">
                        {!edit[a.id] && !removed && <Button disabled={props.disabled || props.dnpActivities.code === StatusCode.ERROR} color="white" onClick={() => setEdit({ [a.id]: true })}><FontAwesomeIcon title="Ändra kopplingen" color="#495057" icon="pen" /></Button>}
                        {edit[a.id] && <Button color="white" disabled={props.disabled} onClick={() => setEdit({ [a.id]: false })}><FontAwesomeIcon title="Stäng ändringsläge" color="#495057" icon="xmark" /></Button>}
                        {!removed && !updated && <Button color="white" disabled={props.disabled} onClick={() => removeActivityMappings(a)}><FontAwesomeIcon title="Ta bort koppling" color="#495057" icon="trash" /></Button>}
                        {(removed || updated) && <Button color="white" disabled={props.disabled} onClick={() => resetChanges(a)}><FontAwesomeIcon title="Ångra" color="#495057" icon="arrow-rotate-left" /></Button>}
                    </span>

                </div>
            )
        })}
    </>);
}

export default MappedDnpActivities;
