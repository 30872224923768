import { Button, Form, Input } from 'reactstrap';
import FontAwesomeButton from '../../../../../misc/FontAwesomeButton';
import * as GoogleUser from '../../../../../../util/GoogleUserExportTypes';
import { OnChangeCallback, OptionItem } from '../../../../../../util/UtilityTypes';
import React, { useState } from 'react';
import SelectInput from '../../../../../misc/SelectInput';
import { GridListItem, GridListHeader } from '../../../../../misc/GridListComponents';

interface GoogleUserCustomAttributesProps {
    customAttributes: GoogleUser.CustomAttribute[]
    validation: string[]
    configurationType: string
    disabled?: boolean
    onChange: OnChangeCallback<GoogleUser.CustomAttribute[]>
    sourceSystemOptions: OptionItem<string>[]
}

const defaultCustomAttribute: GoogleUser.CustomAttribute = {
    schemaName: '',
    attributeName: '',
    attributeType: GoogleUser.CustomAttributeType.UNDEFINED,
    sourceSystemName: null,
}

const customAttributeEquals = (a: GoogleUser.CustomAttribute, b: GoogleUser.CustomAttribute): boolean => {
    if (a.schemaName !== b.schemaName) {
        return false;
    }

    if (a.attributeName !== b.attributeName) {
        return false;
    }

    if (a.attributeType !== b.attributeType) {
        return false;
    }

    return a.sourceSystemName === b.sourceSystemName;
}

const convertTypeEnum = (value: GoogleUser.CustomAttributeType): string => {
    switch (value) {
        case GoogleUser.CustomAttributeType.UNDEFINED: return 'Okänt';
        case GoogleUser.CustomAttributeType.SCHOOL_UNIT_CODE: return 'Skolenhetskod';
        case GoogleUser.CustomAttributeType.SOURCE_SYSTEM_ID: return 'Externt id';
        case GoogleUser.CustomAttributeType.SOCIALNUMBER: return 'Personnummer';
    }
}

const GoogleUserCustomAttributes: React.FC<GoogleUserCustomAttributesProps> = (props) => {
    const [customAttributes, setCustomAttributes] = useState<GoogleUser.CustomAttribute[]>(props.customAttributes);
    const [currentIndex, setCurrentIndex] = useState<number | null>(null);
    const [currentCustomAttribute, setCurrentCustomAttribute] = useState<GoogleUser.CustomAttribute>({ ...defaultCustomAttribute })

    const calculateUpdatedCustomAttributes = (): GoogleUser.CustomAttribute[] | null => {
        if (Number.isFinite(currentIndex)) {
            return customAttributes.map((x, i) => i === currentIndex ? currentCustomAttribute : x);
        } else if (!customAttributes.some(x => customAttributeEquals(x, currentCustomAttribute))) {
            return [...customAttributes, currentCustomAttribute];
        } else {
            return null;
        }
    }

    const submitFilter = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault()
        const updatedCustomAttributes = calculateUpdatedCustomAttributes();

        setCurrent(null);

        if (updatedCustomAttributes) {
            notifyChange(updatedCustomAttributes);
        }
    }

    const setCurrent = (index: number | null): void => {
        setCurrentIndex(index);

        if (index || index === 0) {
            setCurrentCustomAttribute({ ...customAttributes[index] })
        } else {
            setCurrentCustomAttribute({ ...defaultCustomAttribute })
        }
    }

    const removeCustomAttribute = (index: number | null): void => {
        if (index === null) {
            return;
        }

        const remaining = customAttributes.filter((_, i) => i !== index);
        notifyChange(remaining);
    }

    const notifyChange = (updatedCustomAttributes: GoogleUser.CustomAttribute[]): void => {
        setCustomAttributes(updatedCustomAttributes);
        props.onChange(`${props.configurationType}-customAttributes`, updatedCustomAttributes);
    }

    const valid = (): boolean => {
        if (!currentCustomAttribute) {
            return false;
        }

        if (currentCustomAttribute.schemaName.length < 1) {
            return false;
        }

        if (currentCustomAttribute.attributeName.length < 1) {
            return false;
        }

        switch (currentCustomAttribute.attributeType) {
            case GoogleUser.CustomAttributeType.UNDEFINED:
                return false;
            case GoogleUser.CustomAttributeType.SOURCE_SYSTEM_ID:
                if (!currentCustomAttribute.sourceSystemName || !props.sourceSystemOptions.some(x => x.value === currentCustomAttribute.sourceSystemName)) {
                    return false;
                }
                break;
            default:
                break;
        }

        return true;
    }

    const renderRow = (index: number | null, customAttribute: GoogleUser.CustomAttribute): React.ReactNode | null => {
        const editing = Number.isFinite(currentIndex);

        if (index === currentIndex) {
            return (<React.Fragment key={index}>
                <GridListItem>
                    <Input type="text" disabled={props.disabled} placeholder="Schema namn" autoComplete="off" value={currentCustomAttribute.schemaName} onChange={(e) => setCurrentCustomAttribute({ ...currentCustomAttribute, schemaName: e.target.value })} />
                </GridListItem>
                <GridListItem>
                    <Input type="text" disabled={props.disabled} placeholder="Attribut namn" autoComplete="off" value={currentCustomAttribute.attributeName} onChange={(e) => setCurrentCustomAttribute({ ...currentCustomAttribute, attributeName: e.target.value })} />
                </GridListItem>
                <GridListItem>
                    <SelectInput
                        items={GoogleUser.customAttributeTypes}
                        selected={currentCustomAttribute.attributeType}
                        name={`${props.configurationType}-customAttributes-${index}-type`}
                        onChange={(n, v) => setCurrentCustomAttribute({ ...currentCustomAttribute, attributeType: v ?? GoogleUser.CustomAttributeType.UNDEFINED, sourceSystemName: null })}
                        defaultText="Okänt"
                        defaultValue={GoogleUser.CustomAttributeType.UNDEFINED}
                        disabled={props.disabled}
                    />
                </GridListItem>
                <GridListItem>
                    {currentCustomAttribute.attributeType === GoogleUser.CustomAttributeType.SOURCE_SYSTEM_ID && <SelectInput
                        items={props.sourceSystemOptions}
                        selected={currentCustomAttribute.sourceSystemName ?? undefined}
                        name={`${props.configurationType}-customAttributes-${index}-sourceSystemName`}
                        onChange={(n, v) => setCurrentCustomAttribute({ ...currentCustomAttribute, sourceSystemName: v ?? null})}
                        defaultText="Inget"
                        disabled={props.disabled}
                    />}
                </GridListItem>
                {editing ? <GridListItem span={{ startColumn: 5, columnCount: 2 }}>
                    <Button style={{ width: '100%' }} color="primary" type="submit" disabled={props.disabled || !valid()}>Spara</Button>
                    <Button style={{ width: '100%' }} color="danger" onClick={() => setCurrent(null)}>Avbryt</Button>
                </GridListItem> : <GridListItem span={{ startColumn: 5, columnCount: 2 }}>
                    <Button style={{ width: '100%' }} color="primary" type="submit" disabled={props.disabled || !valid()}>Lägg till</Button>
                </GridListItem>}
            </React.Fragment>)
        } else if (Number.isFinite(index)) {
            return (<React.Fragment key={index}>
                <GridListItem>{customAttribute.schemaName}</GridListItem>
                <GridListItem>{customAttribute.attributeName}</GridListItem>
                <GridListItem>{convertTypeEnum(customAttribute.attributeType)}</GridListItem>
                <GridListItem>{customAttribute.sourceSystemName}</GridListItem>
                <GridListItem className="gridListCenterContent">
                    <FontAwesomeButton icon="pen" onClick={() => setCurrent(index)} disabled={props.disabled || editing} title="Redigera filter" />
                </GridListItem>
                <GridListItem className="gridListCenterContent">
                    <FontAwesomeButton icon="trash" onClick={() => removeCustomAttribute(index)} disabled={props.disabled || editing} title="Radera filter" />
                </GridListItem>
            </React.Fragment>);
        } else {
            return null;
        }
    }

    return (<>
        <Form className="gridList" style={{ gridTemplateColumns: '4fr 4fr 4fr 4fr 1.5fr 1.5fr' }} onSubmit={(e) => submitFilter(e)}>
            <GridListHeader>Schema namn</GridListHeader>
            <GridListHeader>Attribut namn</GridListHeader>
            <GridListHeader>Värde typ</GridListHeader>
            <GridListHeader>Källa</GridListHeader>
            <GridListHeader span={{ startColumn: 5, columnCount: 2 }} />
            {customAttributes.map((ca, index) => renderRow(index, ca))}
            {renderRow(null, currentCustomAttribute)}
        </Form>
    </>);
}

export default GoogleUserCustomAttributes;