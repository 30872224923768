import { OptionItem } from './UtilityTypes'

export enum AppleSchoolManagerClassSource {
    NONE = 0x0000,
    CLASS = 0x0001,
    ACTIVITY = 0x0002,
}

export enum AppleSchoolManagerUserMappingType {
    DEFAULT = 0,
    ONPREMISESEXTENSIONATTRIBUTE = 1,
    GOOGLEID = 2,
    GOOGLEEMAIL = 3,
    FLOWSYNC_CIVICNO = 4,
}

export const classSourceSelectionOptions: OptionItem<number>[] = [
    { value: AppleSchoolManagerClassSource.CLASS, text: "Klass" },
    { value: AppleSchoolManagerClassSource.ACTIVITY, text: "Aktivitet" },
]

export const userMappingTypes: OptionItem<AppleSchoolManagerUserMappingType>[] = [
    { value: AppleSchoolManagerUserMappingType.DEFAULT, text: "Default" },
    { value: AppleSchoolManagerUserMappingType.ONPREMISESEXTENSIONATTRIBUTE, text: "AzureAD Extension attribut" },
    { value: AppleSchoolManagerUserMappingType.GOOGLEID, text: "Google Id från kontosynk" },
    { value: AppleSchoolManagerUserMappingType.GOOGLEEMAIL, text: "Google E-postadress från kontosynk" },
    { value: AppleSchoolManagerUserMappingType.FLOWSYNC_CIVICNO, text: "Personnummer" },
];

export const ExtensionAttributes: Array<string> = [
    "extensionAttribute1",
    "extensionAttribute2",
    "extensionAttribute3",
    "extensionAttribute4",
    "extensionAttribute5",
    "extensionAttribute6",
    "extensionAttribute7",
    "extensionAttribute8",
    "extensionAttribute9",
    "extensionAttribute10",
    "extensionAttribute11",
    "extensionAttribute12",
    "extensionAttribute13",
    "extensionAttribute14",
    "extensionAttribute15",
];
