import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { History, createBrowserHistory } from 'history';
import { routerReducer, routerMiddleware, RouterState } from "react-router-redux";
import * as SchoolActions from "./SchoolAction";
import * as ActivityActions from "./ActivityAction";
import * as UserAction from "./UserAction";
import * as DutyAction from "./DutyAction";
import * as GroupAction from "./GroupAction";
import * as AuthAction from "./AuthAction";
import * as AppTokenAction from "./AppTokenAction";
import * as DashboardAction from "./DashboardAction";
import * as FilterAction from "./FilterAction";
import * as MenuAction from "./MenuAction";
import * as AuditLogAction from "./AuditLogAction";
import * as MeAction from "./MeAction";
import * as ModuleAction from "./ModuleAction";
import * as ScheduleSync_Event from "./ScheduleSync/ScheduleSync_Event";
import * as ScheduleSync_User from "./ScheduleSync/ScheduleSync_User";
import * as NotificationAction from "./NotificationAction";
import * as OneRoster_Dashboard from "./OneRoster/OneRoster_DashboardAction";
import * as OneRoster_SchoolUnit from "./OneRoster/OneRoster_SchoolUnitAction";
import * as OneRoster_Class from "./OneRoster/OneRoster_ClassAction";
import * as OneRoster_User from "./OneRoster/OneRoster_UserAction";
import * as Consumers from "./ConsumerAction";
import * as BlobContainer from "./BlobContainerAction";
import * as AdministrationDashboard from "./AdministrationDashboardAction";
import * as DNP from "./DnpAction";
import * as InstancePhase from "./ModuleInstancePhaseAction";
import * as TrackingOperation from './TrackingOperationAction';

interface RootState {
    routing?: RouterState
    schools?: SchoolActions.SchoolUnitState
    activities?: ActivityActions.ActivityState
    users?: UserAction.UserState,
    groups?: GroupAction.GroupState
    duties?: DutyAction.DutyState
    auth?: AuthAction.AuthState
    token: AppTokenAction.AppTokenState
    dashboard?: DashboardAction.DashboardState
    filter?: FilterAction.FilterState,
    auditlog?: AuditLogAction.AuditLogState
    menu?: MenuAction.MenuState
    me?: MeAction.MeState
    module?: ModuleAction.ModuleState,
    schedulesync_events?: ScheduleSync_Event.ScheduleSync_EventState
    schedulesync_users?: ScheduleSync_User.ScheduleSync_UserState
    notification?: NotificationAction.NotificationState
    oneroster_dashboard?: OneRoster_Dashboard.OneRoster_DashboardState
    oneroster_schoolunit?: OneRoster_SchoolUnit.OneRoster_SchoolUnitState
    oneroster_class?: OneRoster_Class.OneRoster_ClassState
    oneroster_user?: OneRoster_User.OneRoster_UserState
    consumers?: Consumers.ConsumerState
    blob?: BlobContainer.BlobContainerState
    admin_dashboard?: AdministrationDashboard.AdministrationDashboardState
    dnp?: DNP.DnpState,
    instancePhase?: InstancePhase.ModuleInstancePhaseState,
    trackingOperation?: TrackingOperation.TrackingOperationState,
}

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION__?: Function;
        initialReduxState: RootState
    }
}

function configureStore(history: History, initialState: RootState) {
    const reducers = {
        schools: SchoolActions.reducer,
        activities: ActivityActions.reducer,
        users: UserAction.reducer,
        groups: GroupAction.reducer,
        duties: DutyAction.reducer,
        auth: AuthAction.reducer,
        token: AppTokenAction.reducer,
        dashboard: DashboardAction.reducer,
        filter: FilterAction.reducer,
        auditlog: AuditLogAction.reducer,
        menu: MenuAction.reducer,
        me: MeAction.reducer,
        module: ModuleAction.reducer,
        schedulesync_events: ScheduleSync_Event.reducer,
        schedulesync_users: ScheduleSync_User.reducer,
        notification: NotificationAction.reducer,
        oneroster_dashboard: OneRoster_Dashboard.reducer,
        oneroster_schoolunit: OneRoster_SchoolUnit.reducer,
        oneroster_class: OneRoster_Class.reducer,
        oneroster_user: OneRoster_User.reducer,
        consumers: Consumers.reducer,
        blob: BlobContainer.reducer,
        admin_dashboard: AdministrationDashboard.reducer,
        dnp: DNP.reducer,
        instancePhase: InstancePhase.reducer,
        trackingOperation: TrackingOperation.reducer,
    };

    const middleware = [thunk, routerMiddleware(history)];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === "development";
    if (isDevelopment && typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const rootReducer = combineReducers({
        ...reducers,
        routing: routerReducer,
    });

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}

export default configureStore(createBrowserHistory(), window.initialReduxState);
