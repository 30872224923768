import InstanceClient from '../services/InstanceClient';
import StatusCode from '../util/StatusCode';
import store from './store';
import { Action, Reducer, Dispatch } from 'redux';
import { CoreDnpActivityListing, CoreDnpActivityMappingView, CoreDnpExportActivityMapping, DnpActivity, DnpEnvironment, DnpOrganization, DnpOrganizationSchoolUnit, DnpSchoolType } from '../models/CoreDnpModels';
import { PageResult } from '../models/CoreModels';
import { CacheRequestState, EmptyRequestState, RequestState } from '../models/RequestState';

export interface SaveActivityMappingsState {
    updatedMappedActivities: CoreDnpExportActivityMapping[]
    allMappingsAreValid: boolean
}

enum DnpActionType {
    GET_DNP_ORGANIZATION = 'GET_DNP_ORGANIZATION',
    GET_DNP_ACTIVITIES = 'GET_DNP_ACTIVITIES',
    GET_ACTIVITIES_BY_SCHOOL = 'GET_ACTIVITIES_BY_SCHOOL',
    UPDATE_GET_ACTIVITIES_BY_SCHOOL = 'UPDATE_GET_ACTIVITIES_BY_SCHOOL',
    GET_MAPPED_ACTIVITIES = 'GET_MAPPED_ACTIVITIES',
    BEGIN_SAVE_ACTIVITY_MAPPINGS = 'BEGIN_SAVE_ACTIVITY_MAPPINGS',
    FINISH_SAVE_ACTIVITY_MAPPINGS = 'COMPLETE_SAVE_ACTIVITY_MAPPINGS',
    GET_DNP_SCHOOLTYPES = 'GET_DNP_SCHOOLTYPES',
    GET_DNP_SCHOOLUNIT = 'GET_DNP_SCHOOLUNIT',
}

export interface DnpState {
    dnpOrganization: RequestState<DnpOrganization>
    dnpSchoolUnit: RequestState<DnpOrganizationSchoolUnit>
    dnpActivities: RequestState<DnpActivity[]>
    dnpSchoolTypes: RequestState<DnpSchoolType[]>
    mappedActivites: RequestState<CoreDnpActivityMappingView>
    activitiesBySchool: CacheRequestState<PageResult<CoreDnpActivityListing>>
    saveActivityMappings: EmptyRequestState
    saveActivityMappingsState: RequestState<SaveActivityMappingsState>
}

interface Dnp_GetDnpOrganization extends Action<DnpActionType> {
    type: DnpActionType.GET_DNP_ORGANIZATION
    dnpOrganization: RequestState<DnpOrganization>
}
interface Dnp_GetDnpSchoolUnit extends Action<DnpActionType> {
    type: DnpActionType.GET_DNP_SCHOOLUNIT
    dnpSchoolUnit: RequestState<DnpOrganizationSchoolUnit>
}

interface Dnp_GetDnpActivities extends Action<DnpActionType> {
    type: DnpActionType.GET_DNP_ACTIVITIES
    dnpActivities: RequestState<DnpActivity[]>
}

interface Dnp_GetDnpSchoolTypes extends Action<DnpActionType> {
    type: DnpActionType.GET_DNP_SCHOOLTYPES
    dnpSchoolTypes: RequestState<DnpSchoolType[]>
}

interface Dnp_GetMappedDnpActivities extends Action<DnpActionType> {
    type: DnpActionType.GET_MAPPED_ACTIVITIES
    mappedActivites: RequestState<CoreDnpActivityMappingView>
}

interface Dnp_GetActivitiesBySchool extends Action<DnpActionType> {
    type: DnpActionType.GET_ACTIVITIES_BY_SCHOOL
    activitiesBySchool: CacheRequestState<PageResult<CoreDnpActivityListing>>
}

interface Dnp_UpdateActivitiesBySchool extends Action<DnpActionType> {
    type: DnpActionType.UPDATE_GET_ACTIVITIES_BY_SCHOOL
}

interface Dnp_BeginSaveActivityMappings extends Action<DnpActionType> {
    type: DnpActionType.BEGIN_SAVE_ACTIVITY_MAPPINGS
    saveActivityMappingsState: RequestState<SaveActivityMappingsState>
}

interface Dnp_FinishSaveActivityMappings extends Action<DnpActionType> {
    type: DnpActionType.FINISH_SAVE_ACTIVITY_MAPPINGS
    saveActivityMappings: RequestState<CoreDnpActivityMappingView>
}

type DnpAction =
    Dnp_GetDnpOrganization
    | Dnp_GetDnpSchoolUnit
    | Dnp_GetDnpActivities
    | Dnp_GetDnpSchoolTypes
    | Dnp_GetMappedDnpActivities
    | Dnp_GetActivitiesBySchool
    | Dnp_UpdateActivitiesBySchool
    | Dnp_BeginSaveActivityMappings
    | Dnp_FinishSaveActivityMappings


type Dnp_SaveActivityMappingDispatch = Dispatch<Dnp_BeginSaveActivityMappings> & Dispatch<Dnp_FinishSaveActivityMappings>;


export const GetDnpOrganization = (environment: DnpEnvironment, orgNum: string) => async (dispatch: Dispatch<Dnp_GetDnpOrganization>): Promise<void> => {
    const storeKey = store.getState().dnp.dnpOrganization.key;
    const key = `${environment}:${orgNum}`;
    if (key === storeKey) {
        return;
    };

    dispatch({ type: DnpActionType.GET_DNP_ORGANIZATION, dnpOrganization: { code: StatusCode.PENDING } });

    InstanceClient.get<DnpOrganization>(`/api/core/dnp/opendata/${environment}/organisations/${orgNum}`)
        .then(res => {
            dispatch({
                type: DnpActionType.GET_DNP_ORGANIZATION,
                dnpOrganization: { code: StatusCode.COMPLETE, data: res.data, key: key },
            });
        })
        .catch(err => {
            dispatch({
                type: DnpActionType.GET_DNP_ORGANIZATION,
                dnpOrganization: { code: StatusCode.ERROR, error: err },
            });
        })
};

export const GetDnpSchoolUnit = (environment: DnpEnvironment, schoolUnitId: string) => (dispatch: Dispatch<Dnp_GetDnpSchoolUnit>) => {
    const storeKey = store.getState().dnp.dnpSchoolUnit.key;
    const key = schoolUnitId;
    if (key === storeKey) {
        return;
    };
    dispatch({ type: DnpActionType.GET_DNP_SCHOOLUNIT, dnpSchoolUnit: { code: StatusCode.PENDING } });

    return InstanceClient.get<DnpOrganizationSchoolUnit>(`/api/core/dnp/opendata/${environment}/schoolunits/${schoolUnitId}`)
        .then(res => {
            dispatch({
                type: DnpActionType.GET_DNP_SCHOOLUNIT,
                dnpSchoolUnit: { code: StatusCode.COMPLETE, data: res.data, key: key },
            });
        })
        .catch(err => {
            dispatch({
                type: DnpActionType.GET_DNP_SCHOOLUNIT,
                dnpSchoolUnit: { code: StatusCode.ERROR, error: err }
            });
        });
};

export const GetDnpActivities = (environment: DnpEnvironment) => (dispatch: Dispatch<Dnp_GetDnpActivities>) => {
    const storeCode = store.getState().dnp.dnpActivities;
    if (storeCode.code === StatusCode.COMPLETE) {
        return;
    };

    dispatch({ type: DnpActionType.GET_DNP_ACTIVITIES, dnpActivities: { code: StatusCode.PENDING } });

    return InstanceClient.get<DnpActivity[]>(`/api/core/dnp/opendata/${environment}/activities`)
        .then(res => {
            dispatch({
                type: DnpActionType.GET_DNP_ACTIVITIES,
                dnpActivities: { code: StatusCode.COMPLETE, data: res.data },
            });
        })
        .catch(err => {
            dispatch({
                type: DnpActionType.GET_DNP_ACTIVITIES,
                dnpActivities: { code: StatusCode.ERROR, error: err },
            });
        });
};

export const GetDnpSchoolTypes = (environment: DnpEnvironment) => (dispatch: Dispatch<Dnp_GetDnpSchoolTypes>): Promise<void> => {
    return InstanceClient.get<DnpSchoolType[]>(`/api/core/dnp/opendata/${environment}/schoolTypes`)
        .then(res => {
            dispatch({
                type: DnpActionType.GET_DNP_SCHOOLTYPES,
                dnpSchoolTypes: { code: StatusCode.COMPLETE, data: res.data }
            })
        })
        .catch(err => {
            dispatch({
                type: DnpActionType.GET_DNP_SCHOOLTYPES,
                dnpSchoolTypes: { code: StatusCode.ERROR, error: err }
            })
        });
};

export const GetMappedDnpActivities = (dnpExportId: string, dnpSchoolUnitId: string) => (dispatch: Dispatch<Dnp_GetMappedDnpActivities>): Promise<void> => {
    dispatch({ type: DnpActionType.GET_MAPPED_ACTIVITIES, mappedActivites: { code: StatusCode.PENDING } });

    const url = `/api/core/dnp/mappedActivities/${dnpExportId}/${dnpSchoolUnitId}`;
    return InstanceClient.get<CoreDnpActivityMappingView>(url)
        .then(res => {

            dispatch({
                type: DnpActionType.GET_MAPPED_ACTIVITIES,
                mappedActivites: { code: StatusCode.COMPLETE, data: res.data }
            });
        })
        .catch(err => {
            dispatch({
                type: DnpActionType.GET_MAPPED_ACTIVITIES,
                mappedActivites: { code: StatusCode.ERROR, error: err }
            });
        });
}

export const BeginSaveActivityMappings = (environment: DnpEnvironment, updatedMappedActivities: CoreDnpExportActivityMapping[]) => (dispatch: Dnp_SaveActivityMappingDispatch) => {
    dispatch({ type: DnpActionType.FINISH_SAVE_ACTIVITY_MAPPINGS, saveActivityMappings: { code: StatusCode.PENDING } })
    dispatch({ type: DnpActionType.BEGIN_SAVE_ACTIVITY_MAPPINGS, saveActivityMappingsState: { code: StatusCode.PENDING } })

    return InstanceClient.get<DnpActivity[]>(`/api/core/dnp/opendata/${environment}/activities`)
        .then(res => {
            const dnpActivityIds = new Set(res.data.map(x => x.id));
            const allMappingsAreValid = updatedMappedActivities.every(x => dnpActivityIds.has(x.dnpActivityId));

            dispatch({
                type: DnpActionType.BEGIN_SAVE_ACTIVITY_MAPPINGS,
                saveActivityMappingsState: {
                    code: StatusCode.COMPLETE, data: {
                        updatedMappedActivities,
                        allMappingsAreValid
                    }
                },
            });
        })
        .catch(err => {
            dispatch({
                type: DnpActionType.BEGIN_SAVE_ACTIVITY_MAPPINGS,
                saveActivityMappingsState: { code: StatusCode.ERROR, error: err },
            });
        });
}

export const SaveActivityMappings = (dnpExportId: string, dnpSchoolUnitId: string, activityMappings: CoreDnpExportActivityMapping[]) => (dispatch: Dnp_SaveActivityMappingDispatch) => {
    dispatch({ type: DnpActionType.BEGIN_SAVE_ACTIVITY_MAPPINGS, saveActivityMappingsState: { code: StatusCode.NONE } })

    InstanceClient.post<CoreDnpActivityMappingView, CoreDnpExportActivityMapping[]>(`/api/core/dnp/activities/${dnpExportId}/${dnpSchoolUnitId}`, activityMappings)
        .then(res => {
            dispatch({ type: DnpActionType.FINISH_SAVE_ACTIVITY_MAPPINGS, saveActivityMappings: { code: StatusCode.COMPLETE, data: res.data } })
        })
        .catch(err => {
            dispatch({ type: DnpActionType.FINISH_SAVE_ACTIVITY_MAPPINGS, saveActivityMappings: { code: StatusCode.ERROR, error: err } })
        });
}

export const AbortSaveActivityMappings = () => (dispatch: Dnp_SaveActivityMappingDispatch) => {
    dispatch({ type: DnpActionType.FINISH_SAVE_ACTIVITY_MAPPINGS, saveActivityMappings: { code: StatusCode.NONE } })
    dispatch({ type: DnpActionType.BEGIN_SAVE_ACTIVITY_MAPPINGS, saveActivityMappingsState: { code: StatusCode.NONE } })
}

export const GetActivitiesBySchool = (dnpSchoolUnitId: string, filter = {}, forceUpdate = false) => (dispatch: Dispatch<Dnp_GetActivitiesBySchool | Dnp_UpdateActivitiesBySchool>) => {
    let x = [];
    for (const [key, value] of Object.entries(filter)) {
        x.push(`${key}-${value}`);
    }
    const filterKey = x.join('&');

    const storeKey = store.getState().dnp.activitiesBySchool?.key;
    const key = `dnpSchoolUnitId-${dnpSchoolUnitId}-${filterKey}`;

    if (key === storeKey && !forceUpdate) {
        return;
    }

    if (!forceUpdate) {
        //Update
        dispatch({ type: DnpActionType.UPDATE_GET_ACTIVITIES_BY_SCHOOL })
    } else {
        dispatch({ type: DnpActionType.GET_ACTIVITIES_BY_SCHOOL, activitiesBySchool: { code: StatusCode.PENDING } });
    }

    const url = `/api/core/dnp/schoolunit/activity/${dnpSchoolUnitId}`;
    return InstanceClient.get<PageResult<CoreDnpActivityListing>>(url, filter)
        .then(res => {
            dispatch({
                type: DnpActionType.GET_ACTIVITIES_BY_SCHOOL,
                activitiesBySchool: {
                    code: StatusCode.COMPLETE,
                    data: res.data,
                    key: key,
                }
            })
        })
        .catch(err => {
            dispatch({
                type: DnpActionType.GET_ACTIVITIES_BY_SCHOOL,
                activitiesBySchool: {
                    code: StatusCode.ERROR,
                    error: err,
                }
            })
        })
};

export const ClearActivitiesBySchool = () => (dispatch: Dispatch<Dnp_GetActivitiesBySchool>) => {
    dispatch({ type: DnpActionType.GET_ACTIVITIES_BY_SCHOOL, activitiesBySchool: { code: StatusCode.PENDING } });
};

export const ClearDnpOrganization = () => (dispatch: Dispatch<Dnp_GetDnpOrganization>) => {
    dispatch({ type: DnpActionType.GET_DNP_ORGANIZATION, dnpOrganization: { code: StatusCode.NONE, data: undefined } })
}

export const ResetSaveActivityMappings = (delay: number) => (dispatch: Dispatch<Dnp_FinishSaveActivityMappings>) => {
    setTimeout(() => dispatch({ type: DnpActionType.FINISH_SAVE_ACTIVITY_MAPPINGS, saveActivityMappings: { code: StatusCode.NONE } }), delay);
}

export const downloadFileForMoa = (moduleId: string) => () => {
    return InstanceClient.download(`/api/core/dnp/${moduleId}/metadata`, `moa-${moduleId}.json`);
}

const initialState: DnpState = {
    dnpOrganization: { code: StatusCode.NONE },
    dnpSchoolUnit: { code: StatusCode.NONE },
    dnpActivities: { code: StatusCode.NONE },
    activitiesBySchool: { code: StatusCode.NONE },
    mappedActivites: { code: StatusCode.NONE },
    saveActivityMappings: { code: StatusCode.NONE },
    dnpSchoolTypes: { code: StatusCode.NONE },
    saveActivityMappingsState: { code: StatusCode.NONE },
};

const finishSaveActivityMappingsReducer = (state: DnpState, action: Dnp_FinishSaveActivityMappings): DnpState => {
    if (action.saveActivityMappings.code === StatusCode.COMPLETE) {
        return {
            ...state,
            saveActivityMappings: {
                code: StatusCode.COMPLETE,
            },
            mappedActivites: {
                code: StatusCode.COMPLETE,
                data: action.saveActivityMappings.data,
            },
        };
    } else {
        return {
            ...state,
            saveActivityMappings: action.saveActivityMappings,
        };
    }
}

export const reducer: Reducer<DnpState, DnpAction> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case DnpActionType.GET_DNP_ORGANIZATION:
            return {
                ...state,
                dnpOrganization: action.dnpOrganization,
            };
        case DnpActionType.GET_DNP_ACTIVITIES:
            return {
                ...state,
                dnpActivities: action.dnpActivities,
            };
        case DnpActionType.GET_MAPPED_ACTIVITIES:
            return {
                ...state,
                mappedActivites: action.mappedActivites,
            };
        case DnpActionType.GET_ACTIVITIES_BY_SCHOOL:
            return {
                ...state,
                activitiesBySchool: action.activitiesBySchool
            };
        case DnpActionType.UPDATE_GET_ACTIVITIES_BY_SCHOOL:
            return {
                ...state,
                activitiesBySchool: { ...state.activitiesBySchool, code: StatusCode.PENDING }
            };
        case DnpActionType.BEGIN_SAVE_ACTIVITY_MAPPINGS:
            return {
                ...state,
                saveActivityMappingsState: action.saveActivityMappingsState,
            };
        case DnpActionType.FINISH_SAVE_ACTIVITY_MAPPINGS:
            return finishSaveActivityMappingsReducer(state, action);
        case DnpActionType.GET_DNP_SCHOOLTYPES:
            return {
                ...state,
                dnpSchoolTypes: action.dnpSchoolTypes,
            };
        case DnpActionType.GET_DNP_SCHOOLUNIT:
            return {
                ...state,
                dnpSchoolUnit: action.dnpSchoolUnit,
            };
        default:
            return state;
    }
}
