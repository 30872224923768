interface Severity {
    id: number,
    name: string,
}

const severityLevels = new Map<number, string>([
    [0, "Ingen"],
    [1, "Information"],
    [2, "Varning"],
    [3, "Fel"],
    [4, "Kritiskt fel"],
]);

export const severityById = (id: number): string | undefined => id ? severityLevels.get(id) : '-';

export const idBySeverity = (severity: string): number | undefined => {
    for (const [key, value] of severityLevels) {
        if (value === severity) {
            return key;
        }
    }
    return undefined;
}

export const severityArray: Severity[] = Array.from(severityLevels).map(x => ({ id: x[0], name: x[1] }));

