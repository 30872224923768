import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { getOneRosterSchoolUnits } from '../../store/OneRoster/OneRoster_SchoolUnitAction';
import StatusCode from '../../util/StatusCode';
import debounce from 'lodash/debounce';
import ActiveToggle from '../misc/ActiveToggle';
import TableHorizontal from '../misc/TableHorizontal';
import * as Helper from '../../util/Helper';
import FilterBottomComponent from '../misc/FilterBottomComponent';
import Search from '../misc/Search';
import RequestParser from '../../util/RequestParser';


const OneRoster_SchoolUnits = () => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();

    const schoolUnits = useSelector(state => state.oneroster_schoolunit.schoolUnits);
    var filter = RequestParser.parseSearchPageRequest(searchParams);

    useEffect(() => {
        if (filter.search) {
            setSearch(filter.search);
        }
        getOneRosterSchoolUnits(filter)(dispatch);
    }, [dispatch]);

    useEffect(() => {
        getOneRosterSchoolUnits(filter, true)(dispatch);
        window.scrollTo(0, 0);
    }, [searchParams]);

    const updateFilter = (name, value) => {
        searchParams.set(name, value);
        if (name !== 'page') {
            searchParams.set('page', 1);
        }

        setSearchParams(searchParams);
    }

    const debouncedSearch = useCallback(
        debounce(search => {
            updateFilter('search', search);
        }, 300), [searchParams]);

    const onSearch = (e) => {
        e.preventDefault();
        let search = e.target.value;
        setSearch(search);
        debouncedSearch(search);
    }

    const clearFilter = () => {
        setSearch('');
        setSearchParams([]);
    }

    const renderBody = () => {
        let onlyActive = filter.onlyActive !== undefined ? filter.onlyActive : true;

        const newList = schoolUnits.data?.map(s => {
            return ({
                id: s.id,
                data: [
                    { value: <Link to={`/oneroster/schoolunit/${s.id}/information`}>{s.name}</Link> },
                    { value: s.active ? 'Ja' : 'Nej' },
                    { value: <Link to={`/oneroster/class/?search=${s.name}`}> {s.classCount} </Link> },
                    { value: <Link to={`/oneroster/user/?search=${s.name}&role=teacher&onlyActive=${onlyActive}`}>{s.teacherCount}</Link> },
                    { value: <Link to={`/oneroster/user/?search=${s.name}&role=student&onlyActive=${onlyActive}`}>{s.studentCount}</Link> },
                    { value: Helper.formatDate(s.created), className: 'nowrap' },
                    { value: Helper.formatDate(s.modified), className: 'nowrap' },
                ]
            })
        }) ?? [];

        return (
            <TableHorizontal
                header={[{ value: 'Namn' }, { value: 'Aktiv' }, { value: 'Avsnitt' }, { value: 'Lärare' }, { value: 'Elever' }, { value: 'Skapad' }, { value: 'Ändrad' }]}
                info={newList}
                error={schoolUnits.code === StatusCode.ERROR}
                searching={schoolUnits.code === StatusCode.PENDING}
                displayName="Skolor"
                noResMsg="Inga skolor hittades"
            />
        )
    }

    const renderFilter = () => (
        <Row>
            <Col sm="6">
                <Search value={search} onChange={onSearch} placeholder="Sök skola" />
            </Col>

            <Col sm="6">
                <ActiveToggle
                    onlyActive={filter.onlyActive ?? null}
                    update={(active) => updateFilter('onlyActive', active)}
                    disabled={schoolUnits.code === StatusCode.PENDING}
                />
            </Col>
        </Row>
    )

    const requestState = Helper.convertToCacheRequestState(schoolUnits);

    return (
        <div className="container schoolunits_oneroster">
            <div className="header--fixed">
                <h1>Skolor</h1>
                {renderFilter()}
            </div>
            <div className="body--scroll padding-40">
                {renderBody()}
            </div>

            <FilterBottomComponent
                clearFilter={clearFilter}
                updateFilter={(value) => updateFilter('page', value)}
                requestState={requestState}
            />

        </div>
    )
}

export default OneRoster_SchoolUnits;
