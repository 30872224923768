import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom'
import { getModules } from '../../store/ModuleAction';
import * as Helper from '../../util/Helper';
import StatusCode from '../../util/StatusCode';
import ModuleType from '../misc/ModuleType';
import ModulePresentation from './components/ModulePresentation';
import TableHorizontal from '../misc/TableHorizontal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { subscribe } from '../../store/NotificationAction';


const ModuleList = () => {

    const dispatch = useDispatch();

    const [isOpen, setOpen] = useState(false);
    const [lastMessageId, setMessageId] = useState(undefined);

    const modules = useSelector(state => state.module.modules)
    const notification = useSelector(state => state.notification);

    useEffect(() => {
        getModules()(dispatch);
        return subscribe(['moduleInstanceStartRequest', 'moduleInstanceStart', 'moduleInstanceComplete'])(dispatch);
    }, [dispatch]);

    useEffect(() => {
        if (modules.code === StatusCode.COMPLETE && lastMessageId !== notification.messageId) {
            setMessageId(notification.messageId);
            getModules()(dispatch);
        }
    })

    const getModuleTypeName = (moduleType) => {
        switch (moduleType) {
            case ModuleType.Import: return "Import";
            case ModuleType.Export: return "Export";
            default: return "Okänd";
        }
    }

    const renderTriggers = (trigger, nextStart) => {
        if (trigger.length > 1) {
            return "Flera schemaläggningar";
        } else if (trigger.length === 1) {
            if (trigger[0].triggerType === 2) {
                return (<span>Efter <Link to={`/module/${trigger[0].runAfterModuleId}/configuration`}>{trigger[0].runAfterModuleTitle}</Link></span>);
            } else if (trigger[0].triggerType === 1) {
                if (nextStart) {
                    return Helper.formatDate(nextStart);
                }
                return "Tidsbaserad schemaläggning";
            }
        } else {
            return "-";
        }
    }

    const renderStatus = (status) => {
        if (status) {
            var isRunning = status.startedAt && !status.completedAt;
            if (status.isInQuarantine) {
                return <span className="margin-right1" title="Modulen är satt i karantän"><FontAwesomeIcon icon="stop-circle" className="icon red" /> Karantän</span>;
            } else if (isRunning) {
                return <span className="margin-right1" title="Modulen körs"><FontAwesomeIcon icon="sync-alt" className="icon green" /> Körs</span>;
            } else if (status.isStartRequested) {
                return <span className="margin-right1" title="Modulen är förfrågad att starta"><FontAwesomeIcon icon="sync-alt" className="icon green" /> Startar</span>;
            }
        }
    }

    const renderMyModules = () => {
        const newList = modules.data?.map(m => ({
            id: m.id,
            data: [
                m.runnable ? { value: <Link to={`/module/${m.id}/status`}>{m.title}</Link> } : { value: m.title },
                { value: m.moduleTypeDisplayName },
                { value: Helper.active(m.active) },
                { value: getModuleTypeName(m.moduleType) },
                { value: renderStatus(m.status) },
                { value: renderTriggers(m.moduleTriggers, m.nextScheduledStart) },
                { value: <Link to={`/module/${m.id}/configuration`}><FontAwesomeIcon color="#7b8898" icon="cog" /></Link>, className: "ml-right" },
            ]
        })) ?? [];

        return (
            <TableHorizontal
                header={[{ value: 'Titel' }, { value: 'Modultyp' }, { value: 'Aktiv' }, { value: 'Riktning' }, { value: 'Status' }, { value: 'Nästa körning' }, { value: 'Konfiguration' }]}
                info={newList}
                error={modules.code === StatusCode.ERROR}
                searching={modules.code === StatusCode.PENDING}
                noResMsg="Inga moduler hittades"
            />
        )
    }

    return <div className="container list-module">
        <div className="header--fixed">
            <h1>Moduler</h1>
            <div>
                <Button color="primary" onClick={() => setOpen(true)}>Skapa ny</Button>
                <ModulePresentation
                    isOpen={isOpen}
                    toggleModal={() => setOpen(!isOpen)}
                />
            </div>
        </div>
        <div className="body--scroll padding-40">
            {renderMyModules()}
        </div>
    </div>
}

export default ModuleList;
