import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, Route, Routes, Navigate } from 'react-router-dom'
import { GetSchoolUnit, SchoolUnitState } from '../../../store/SchoolAction';
import * as Tab from '../../../util/TabValue';
import RenderTabs from '../../misc/Tabs';
import SchoolUnitViewInfo from './SchoolUnitViewInfo';
import SchoolUnitViewTrackings from './SchoolUnitViewTrackings';
import NotFound from '../components/NotFound';
import { CoreSchoolUnit } from '../../../models/CoreSchoolUnit';
import { RequestState } from '../../../models/RequestState';
import StatusCode from '../../../util/StatusCode';
import Loading from '../../misc/Loading';

interface SchoolUnitViewState {
    schools: SchoolUnitState
}

const SchoolUnitView = () => {
    const { schoolId } = useParams();
    const dispatch = useDispatch();
    const school = useSelector<SchoolUnitViewState, RequestState<CoreSchoolUnit>>(state => state.schools.school);

    useEffect(() => {
        if (schoolId !== undefined) {
            GetSchoolUnit(schoolId)(dispatch);
        }
    }, [dispatch, schoolId]);

    const tabs = [
        Tab.Tabs.Information,
        Tab.Tabs.Trackings,
    ];

    if (schoolId === undefined) {
        return;
    }

    switch (school.code) {
        case StatusCode.COMPLETE:
            return (
                <div className="padding-40 container single">
                    <h1><Link to="/schoolunit">Skola</Link> {school.data.title}</h1>

                    <RenderTabs
                        tabs={tabs}
                        id={schoolId}
                        url="schoolunit" />

                    <Routes>
                        <Route>
                            <Route path={Tab.Tabs.Information.url} element={<SchoolUnitViewInfo />} />
                            <Route path={Tab.Tabs.Trackings.url} element={<SchoolUnitViewTrackings />} />
                            <Route path="*" element={<Navigate replace={true} to={`/schoolunit/${schoolId}/${Tab.Tabs.Information.url}`} />} />
                        </Route>
                    </Routes>
                </div>
            );
        case StatusCode.ERROR:
            if (school.error.response?.status === 404) {
                return (
                    <NotFound
                        header="Skola"
                        name="Skolan"
                        link="/schoolunit"
                    />
                );
            } else {
                return <p>{school.error.response?.statusText}</p>
            }
        default:
            return (
                <Loading />
            );
    }
}

export default SchoolUnitView;
