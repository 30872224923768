import React from 'react';
import { Card, CardHeader, CardBody, Collapse, Button } from 'reactstrap';
import ConfigurationComponent from '../ConfigurationComponent';
import { withRouter } from '../../../../components/withRouter';
import { connect } from 'react-redux';
import { GetConsumers } from '../../../../store/ConsumerAction';
import StatusCode from '../../../../util/StatusCode';
import SelectInput from '../../../misc/SelectInput';
import Loading from '../../../misc/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { equvivalent } from '../../../../util/Helper'


class SS12000API extends ConfigurationComponent {
    state = {
        open: true,
        selectedConsumer: undefined,
    }

    componentDidMount() {
        this.props.GetConsumers();
    }

    updateConsumer = (selectedConsumer) => {
        var newUpdates = { ...this.props.updates };

        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);
        var configurationConsumers = [...configuration.consumers];

        var index = configurationConsumers.indexOf(selectedConsumer);
        if (index > -1) {
            configurationConsumers.splice(index, 1);
        } else {
            configurationConsumers.push(selectedConsumer);
        }

        if (equvivalent(this.props.configuration.consumers, configurationConsumers)) {
            delete newUpdates.consumers;
        } else {
            newUpdates.consumers = configurationConsumers;
        }

        this.notifyUpdates(newUpdates);
        this.updateData(newUpdates);
        this.setState({ selectedConsumer: undefined });
    }

    renderConsumers = (optionalConsumers, configurationConsumers) => {
        switch (optionalConsumers.code) {
            case StatusCode.PENDING: {
                return (<Loading />);
            }
            case StatusCode.ERROR: {
                return (<p>Kunde inte hämta API konsumenter</p>);
            }
            case StatusCode.COMPLETE: {
                if (!optionalConsumers.data.length) {
                    return (<p>Det finns inga API konsumenter att välja</p>);
                } else {
                    return (
                        <div className="picker-list-component">
                            {configurationConsumers.map(c => {
                                const found = optionalConsumers.data.find(oc => oc.id === c);
                                const displayName = found?.displayName ?? `Okänd konsument (${c})`
                                return (
                                    <span key={c} className="picker-list-item list">
                                        <span className="displayName">{displayName}</span>
                                        <button onClick={() => this.updateConsumer(c)} title="Radera" className="icon-btn" disabled={this.props.disableInput}><FontAwesomeIcon icon="trash" /></button>
                                    </span>
                                )
                            })}

                            <span className="picker-list-item select">
                                <SelectInput
                                    items={optionalConsumers.data
                                        .filter(c => !configurationConsumers.includes(c.id))
                                        .map(c => ({ value: c.id, text: c.displayName }))}
                                    onChange={(name, value) => this.setState({ selectedConsumer: value })}
                                    name='consumers'
                                    selected={this.state.selectedConsumer}
                                    defaultText='Lägg till API konsument'
                                    disabled={this.props.disableInput}
                                    className='mb-3'
                                />
                                <Button disabled={!this.state.selectedConsumer || this.props.disableInput} className="btn-primary" onClick={() => this.updateConsumer(this.state.selectedConsumer)}>Lägg till</Button>
                            </span>
                        </div>
                    );
                }
            }
        }
    }

    render() {
        const { consumers } = this.props;
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);


        return (
            <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    {this.props.moduleDisplayName}
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>
                        <div style={{ margin: '1rem 0' }}>
                            <h5>API Konsumenter</h5>

                            {this.renderConsumers(consumers, configuration.consumers)}

                        </div>
                    </CardBody>
                </Collapse>
            </Card>
        );
    }
}

const mapStateToProps = state => ({
    consumers: state.consumers.consumers,
})

export default withRouter(connect(mapStateToProps, { GetConsumers })(SS12000API));
