import React from 'react';
import { FormGroup, Card, CardHeader, CardBody, Collapse, Button, FormFeedback, Label, Input } from 'reactstrap';
import ConfigurationComponent from '../ConfigurationComponent';
import EnumInput from '../../../misc/EnumInput';
import BoolInput from '../../../misc/BoolInput';
import NullableIntInputCheckboxText from '../../../misc/NullableIntInputCheckboxText';

const groupTypes = [
    { value: 1, text: 'Klasser med lärare som ägare och elever som medlemmar' },
    { value: 256, text: 'Klasser med elever som medlemmar' },
    { value: 2, text: 'Ämne/kurser' },
    { value: 4, text: 'Mentorsgrupper (funktion ej klar)', disabled: true },
    { value: 8, text: 'Skola med lärare som ägare och elever som medlemmar' },
    { value: 16, text: 'Skola med lärare som medlemmar' },
    { value: 32, text: 'Skola med elever som medlemmar' },
    { value: 64, text: 'Skola med all personal som medlemmar (inkluderar lärare)' },
    { value: 128, text: 'Skola med all personal som ägare och elever som medlemmar (inkluderar lärare)' },
];

const ownerMode = [
    { value: 0, text: 'Strikt: De som lagts till manuellt tas bort' },
    { value: 1, text: 'Passivt: De som läggs till manuellt får ligga kvar' },
];

const memberMode = [
    { value: 0, text: 'Strikt: De som lagts till manuellt tas bort' },
    { value: 1, text: 'Passivt: De som läggs till manuellt får ligga kvar' },
];

const whoCanViewMemberShip = [
    { value: 1, text: 'Alla i domänen' },
    { value: 2, text: 'Gruppens medlemmar' },
    { value: 3, text: 'Gruppens ansvariga' },
];

const whoCanPostMessage = [
    { value: 2, text: 'Gruppens ansvariga och ägare' },
    { value: 3, text: 'Gruppens medlemmar' },
    { value: 4, text: 'Gruppens ägare' },
    { value: 5, text: 'Alla i domänen' },
    { value: 6, text: 'Vem som helst' },
];

const whoCanModerateMembers = [
    { value: 1, text: 'Gruppens medlemmar' },
    { value: 2, text: 'Gruppens ansvariga och ägare' },
    { value: 3, text: 'Gruppens ägare' },
    { value: 4, text: 'Ingen' },
];

const whoCanJoin = [
    { value: 1, text: 'Vem som helst' },
    { value: 2, text: 'Alla i domänen' },
    { value: 3, text: 'Inbjudna' },
    { value: 4, text: 'Kan fråga om att få gå med i gruppen' },
];

const whoCanViewGroup = [
    { value: 1, text: 'Vem som helst' },
    { value: 2, text: 'Alla i domänen' },
    { value: 3, text: 'Alla medlemmar' },
    { value: 4, text: 'Alla ansvariga' },
    { value: 5, text: 'Alla ägare' },
];

class GoogleGroupExport extends ConfigurationComponent {
    state = {
        open: true,
    }

    validate(configuration, active) {
        const validation = [];

        if (active && configuration.groupTypes !== 0) {
            if (configuration.ownerMode !== 0 && configuration.ownerMode !== 1) {
                validation.push('ownerMode')
            }
            if (configuration.memberMode !== 0 && configuration.memberMode !== 1) {
                validation.push('memberMode')
            }
            if (configuration.whoCanViewMembership === 0) {
                validation.push('whoCanViewMembership')
            }
            if (configuration.whoCanModerateMembers === 0) {
                validation.push('whoCanModerateMembers')
            }
            if (configuration.whoCanJoin === 0) {
                validation.push('whoCanJoin')
            }
            if (configuration.whoCanViewGroup === 0) {
                validation.push('whoCanViewGroup')
            }
            if (configuration.whoCanPostMessage === 0) {
                validation.push('whoCanPostMessage')
            }
        }
        if (configuration.inactivePeriod < 0 || configuration.inactivePeriod === null) {
            validation.push('inactivePeriod')
        }
        if (Number.isNaN(configuration.deletePeriod)) {
            validation.push('deletePeriod');
        }

        return validation;
    }

    warnings(update) {
        const warningMessages = [];
        if (update.groupTypes) {
            var propsValue = this.props.configuration.groupTypes;
            var updateValue = update.groupTypes
            //Find out if any grouptype is removed from update
            if (((propsValue ^ updateValue) & propsValue) !== 0) {
                warningMessages.push('Om du tar bort en grupptyp så kommer grupper kopplade till denna att arkiveras')
            }
        }
        return warningMessages;
    }

    render() {
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);
        const validation = this.validate(configuration, this.props.active);
        return (
            <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    {this.props.moduleDisplayName}
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>
                        <div style={{ margin: '1rem 0' }}>
                            <FormGroup>
                                <FormGroup>
                                    <h5>Domännamn för gruppers epostadress</h5>
                                    <Label for="domainName">Välj domännamn som gruppernas epostadress skall skapas med. Domännamnet måste finnas i det Google Customer Account som grupper ska synkas till. Om du är administratör kan du se vilka du kan välja mellan <a target="blank" href="https://admin.google.com/ac/domains/manage">här</a>. Om exempelvis contoso.com är valt skulle epostadressen för klassen 1a att kunna bli 1a_eventfulskolan_abcd@contoso.com</Label>
                                    <Input className="md-w-50 lg-w-25" type="text" name="domainName" id="domainName"
                                        value={configuration.domainName || ''}
                                        onChange={this.updateProperty}
                                        disabled={this.props.disableInput}
                                    />
                                    <FormFeedback>Ange ett domännamn</FormFeedback>
                                </FormGroup>

                                <BoolInput
                                    title='Synkronisera personal till gruppen classroom_teachers'
                                    name='syncClassroomTeacherMembers'
                                    value={configuration.syncClassroomTeacherMembers}
                                    updateValue={(prop, value) => this.updatePropertyValue(prop, value)}
                                    disabled={this.props.disableInput}
                                />

                                <EnumInput
                                    multi={true}
                                    title='Typ av grupper'
                                    name="groupTypes"
                                    value={configuration.groupTypes}
                                    options={groupTypes}
                                    disabled={this.props.disableInput}
                                    onChange={this.updatePropertyValue}
                                    invalid={validation.indexOf('groupTypes') !== -1}
                                    invalidFeedback='Ange minst en grupptyp för att aktivera den här modulen'
                                />

                                <EnumInput
                                    title='Hantera ägare'
                                    name='ownerMode'
                                    value={configuration.ownerMode}
                                    options={ownerMode}
                                    onChange={this.updatePropertyValue}
                                    disabled={this.props.disableInput}
                                    invalid={validation.indexOf('ownerMode') !== -1}
                                    invalidFeedback='Välj hur ägare ska hanteras för att aktivera den här modulen'
                                />

                                <EnumInput
                                    title='Hantera medlemmar'
                                    name='memberMode'
                                    value={configuration.memberMode}
                                    options={memberMode}
                                    onChange={this.updatePropertyValue}
                                    disabled={this.props.disableInput}
                                    invalid={validation.indexOf('memberMode') !== -1}
                                    invalidFeedback='Välj hur medlemmar ska hanteras för att aktivera den här modulen'
                                />

                                <EnumInput
                                    title='Vem kan se gruppens medlemmar'
                                    name='whoCanViewMembership'
                                    value={configuration.whoCanViewMembership}
                                    options={whoCanViewMemberShip}
                                    onChange={this.updatePropertyValue}
                                    disabled={this.props.disableInput}
                                    invalid={validation.indexOf('whoCanViewMembership') !== -1}
                                    invalidFeedback='Ange vem som kan se gruppens medlemmar för att aktivera den här modulen'
                                />

                                <EnumInput
                                    title='Vem får posta i gruppen'
                                    name='whoCanPostMessage'
                                    value={configuration.whoCanPostMessage}
                                    options={whoCanPostMessage}
                                    onChange={this.updatePropertyValue}
                                    disabled={this.props.disableInput}
                                    invalid={validation.indexOf('whoCanPostMessage') !== -1}
                                    invalidFeedback='Ange vem som får posta i gruppen för att aktivera den här modulen'
                                />

                                <EnumInput
                                    title='Vem får hantera gruppens medlemmar'
                                    name='whoCanModerateMembers'
                                    value={configuration.whoCanModerateMembers}
                                    options={whoCanModerateMembers}
                                    onChange={this.updatePropertyValue}
                                    disabled={this.props.disableInput}
                                    invalid={validation.indexOf('whoCanModerateMembers') !== -1}
                                    invalidFeedback='Ange vem som får hantera gruppens medlemmar för att aktivera den här modulen'
                                />

                                <EnumInput
                                    title='Vem får gå med i gruppen'
                                    name='whoCanJoin'
                                    value={configuration.whoCanJoin}
                                    options={whoCanJoin}
                                    onChange={this.updatePropertyValue}
                                    disabled={this.props.disableInput}
                                    invalid={validation.indexOf('whoCanJoin') !== -1}
                                    invalidFeedback='Ange vem som får gå med i gruppen för att aktivera den här modulen'
                                />

                                <EnumInput
                                    title='Vem kan se gruppen och dess meddelande'
                                    name='whoCanViewGroup'
                                    value={configuration.whoCanViewGroup}
                                    options={whoCanViewGroup}
                                    onChange={this.updatePropertyValue}
                                    disabled={this.props.disableInput}
                                    invalid={validation.indexOf('whoCanViewGroup') !== -1}
                                    invalidFeedback='Ange vem som får se gruppen för att aktivera den här modulen'
                                />

                                <BoolInput
                                    title='Inkludera grupper i den globala addresslistan'
                                    name='includeInGlobalAddressList'
                                    value={configuration.includeInGlobalAddressList}
                                    updateValue={(prop, value) => this.updatePropertyValue(prop, value)}
                                    disabled={this.props.disableInput}
                                />

                                <FormGroup>
                                    <h5>Livscykelhantering</h5>
                                    <FormGroup>
                                        <Label for="inactivePeriod">Arkivera (dagar efter inaktivering i FlowSync):</Label>
                                        <Input className="md-w-50 lg-w-25" type="number" min="0" step="1" pattern="[0-9]"
                                            name="inactivePeriod" id="inactivePeriod"
                                            value={configuration.inactivePeriod}
                                            onChange={this.updateProperty}
                                            disabled={this.props.disableInput}
                                            invalid={validation.indexOf('inactivePeriod') !== -1}
                                        />
                                        <FormFeedback>Ange dagar till arkivering</FormFeedback>
                                    </FormGroup>

                                    <NullableIntInputCheckboxText
                                        name="deletePeriod"
                                        value={configuration.deletePeriod}
                                        disabled={this.props.disableInput}
                                        title="Ta bort (dagar efter arkivering i Google Workspace):"
                                        invalidFeedback="Ange dagar till radering efter arkivering av grupper"
                                        invalid={validation.indexOf('deletePeriod') !== -1}
                                        defaultValue={365}
                                        onChange={(k, v) => this.updatePropertyValue(k, v)}
                                        checkboxTitle="Ingen radering"
                                    />
                                </FormGroup>

                            </FormGroup>
                        </div>
                    </CardBody>
                </Collapse>
            </Card>)
    }
}

export default GoogleGroupExport;
