import { OptionItem } from './UtilityTypes'

// --- Enums ---
export enum DisplayNameFormatType {
    DEFAULT = 0,
    COMBINED = 1,
    SEPARATED_REVERSED = 2,
};

export enum DisplayNameSuffixType {
    NONE = 0,
    SCHOOL_TITLE = 1,
    SCHOOL_SHORTNAME_WITH_TITLE_FALLBACK = 2,
    CLASS_TITLE = 3,
    CLASS_TITLE_AND_SCHOOL_TITLE = 4,
    CLASS_TITLE_AND_SCHOOL_SHORTNAME_WITH_TITLE_FALLBACK = 5,
};

export enum UserOrganisationType {
    UNDEFINED = 0,
    NO_ORGANIZATION_SYNC = 1,
    ONLY_PRIMARY = 2,
    ALL_SCHOOLS = 3,
}

export enum EmailAddressPolicyType {
    NONE = 0,
    GENERATED = 1,
    ANONYMOUS = 2,
    EMAILADRESS = 3,
    USERNAME = 4,
};

export enum PasswordPolicyType {
    NONE = 0,
    STATIC = 1,
    RANDOM = 2,
};

export enum StaffOrganizationalUnit {
    NONE = 0,
    STAFF_SWEDISH = 1,
    STAFF_SCHOOL_SWEDISH = 2,
    SCHOOL_STAFF_SWEDISH = 3,
    STAFF_ENGLISH = 4,
    STAFF_SCHOOL_ENGLISH = 5,
    SCHOOL_STAFF_ENGLISH = 6,
    STAFF_SCHOOL_TYPE_SCHOOL_SWEDISH = 7,
    STAFF_SCHOOL_TYPE_SCHOOL_ENGLISH = 8,
}

export enum SuspendedStaffOrganizationalUnit {
    NONE = 0,
    SUSPENDED_USERS_SWEDISH = 1,
    SUSPENDED_STAFF_USERS_SWEDISH = 2,
    SUSPENDED_USERS_ENGLISH = 3,
    SUSPENDED_STAFF_USERS_ENGLISH = 4,
}

export enum StudentsOrganizationalUnit {
    NONE = 0,
    STUDENTS_SCHOOL_CLASS_SWEDISH = 1,
    SCHOOL_STUDENTS_CLASS_SWEDISH = 2,
    STUDENTS_STAGE_SCHOOL_CLASS_SWEDISH = 3,
    STUDENTS_SCHOOL_CLASS_ENGLISH = 4,
    SCHOOL_STUDENTS_CLASS_ENGLISH = 5,
    STUDENTS_STAGE_SCHOOL_CLASS_ENGLISH = 6,
    STUDENTS_SCHOOL_SWEDISH = 7,
    SCHOOL_STUDENTS_SWEDISH = 8,
    STUDENTS_SCHOOL_ENGLISH = 9,
    SCHOOL_STUDENTS_ENGLISH = 10,
    STUDENT_SCHOOL_TYPE_SCHOOL_CLASS_SWEDISH = 11,
    STUDENT_SCHOOL_TYPE_SCHOOL_CLASS_ENGLISH = 12,
}

export enum SuspendedStudentsOrganizationalUnit {
    NONE = 0,
    SUSPENDED_USERS_SWEDISH = 1,
    SUSPENDED_STUDENTS_USERS_SWEDISH = 2,
    SUSPENDED_USERS_ENGLISH = 3,
    SUSPENDED_STUDENTS_USERS_ENGLISH = 4,
}

export enum CustomAttributeType {
    UNDEFINED = 0,
    SCHOOL_UNIT_CODE = 1,
    SOURCE_SYSTEM_ID = 2,
    SOCIALNUMBER = 3,
}

// --- Interfaces ---
export interface ExportConfiguration {
    staffConfiguration: StaffConfiguration
    studentConfiguration: StudentConfiguration
    reportReceivers: string[]
}

export interface StaffConfiguration {
    enabled: boolean,
    deleteAfterDays: number | null
    customAttributes: CustomAttribute[]
    displayNamePolicy: DisplayNamePolicy
    emailAddressPolicy: EmailAddressPolicy
    passwordPolicy: PasswordPolicy
    organizationalUnitPolicy: StaffOrganizationalUnitPolicy
    includeHomePhone: boolean
    includeMobilePhone: boolean
    includeWorkPhone: boolean
    includeHomeAddress: boolean
    includeUserOrganisations: UserOrganisationType
}

export interface StudentConfiguration {
    enabled: boolean,
    deleteAfterDays: number | null
    customAttributes: CustomAttribute[]
    displayNamePolicy: DisplayNamePolicy
    emailAddressPolicy: EmailAddressPolicy
    passwordPolicy: PasswordPolicy
    organizationalUnitPolicy: StudentOrganizationalUnitPolicy
    includeHomePhone: boolean
    includeMobilePhone: boolean
    includeWorkPhone: boolean
    includeHomeAddress: boolean
    includeUserOrganisations: UserOrganisationType
}

export interface DisplayNamePolicy {
    format: DisplayNameFormatType
    suffix: DisplayNameSuffixType | null
}

export interface EmailAddressPolicy {
    type: EmailAddressPolicyType
    domainName: string | null
    includeGivenName: boolean
    givenNameCharacterCount: number | null
    includeFamilyName: boolean
    familyNameCharacterCount: number | null
    separateNamesByDots: boolean
    useSerialNumber: boolean
    serialNumberDelimiter: string | null
    randomCharacterCount: number
    randomNumberCount: number
}

export interface PasswordPolicy {
    type: PasswordPolicyType
    forcePasswordChangeOnNextLogin: boolean
    staticValue: string
    useMonthAndDayFromSocialnumber: boolean
    minLength: number
    maxLength: number
    passwordCharacterSets: number
}

export interface StaffOrganizationalUnitPolicy {
    createMissingOrganisationalUnits: boolean
    organisationalUnit: StaffOrganizationalUnit
    suspendedUsersOrganisationalUnit: SuspendedStaffOrganizationalUnit
}

export interface StudentOrganizationalUnitPolicy {
    createMissingOrganisationalUnits: boolean
    organisationalUnit: StudentsOrganizationalUnit
    suspendedUsersOrganisationalUnit: SuspendedStudentsOrganizationalUnit
}

export interface CustomAttribute {
    schemaName: string
    attributeName: string
    attributeType: CustomAttributeType
    sourceSystemName: string | null
}

// --- Static Values ---
// DisplayName
export const displayNameFormats: OptionItem<DisplayNameFormatType>[] = [
    { value: DisplayNameFormatType.DEFAULT, text: "Default (Vad som läses från FlowSync)" },
    { value: DisplayNameFormatType.COMBINED, text: "Förnamn + Efternamn" },
    { value: DisplayNameFormatType.SEPARATED_REVERSED, text: "Efternamn, Förnamn" },
];

export const studentDisplayNameSuffixes: OptionItem<DisplayNameSuffixType>[] = [
    { value: DisplayNameSuffixType.SCHOOL_TITLE, text: "Skolnamn" },
    { value: DisplayNameSuffixType.SCHOOL_SHORTNAME_WITH_TITLE_FALLBACK, text: "Skolförkortning med fallback på skolnamn" },
    { value: DisplayNameSuffixType.CLASS_TITLE, text: "Klass" },
    { value: DisplayNameSuffixType.CLASS_TITLE_AND_SCHOOL_TITLE, text: "Klass + Skolnamn" },
    { value: DisplayNameSuffixType.CLASS_TITLE_AND_SCHOOL_SHORTNAME_WITH_TITLE_FALLBACK, text: "Klass + Skolförkortning med fallback på skolnamn" },
];

export const staffDisplayNameSuffix: OptionItem<DisplayNameSuffixType>[] = [
    { value: DisplayNameSuffixType.SCHOOL_TITLE, text: "Skolnamn" },
    { value: DisplayNameSuffixType.SCHOOL_SHORTNAME_WITH_TITLE_FALLBACK, text: "Skolförkortning med fallback på skolnamn" },
];

// UserOrganisation
export const userOrganisation: OptionItem<UserOrganisationType>[] = [
    { value: UserOrganisationType.NO_ORGANIZATION_SYNC, text: "Ingen skolkoppling" },
    { value: UserOrganisationType.ONLY_PRIMARY, text: "Endast primär" },
    { value: UserOrganisationType.ALL_SCHOOLS, text: "Alla skolkopplingar" },
];

// EmailaddressPolicy
export const emailAddressPolicyTypeOptions: OptionItem<EmailAddressPolicyType>[] = [
    { value: EmailAddressPolicyType.GENERATED, text: "Generera användarnamn baserat på förnamn och efternamn" },
    { value: EmailAddressPolicyType.ANONYMOUS, text: "Anonymiserad e-postadress" },
    { value: EmailAddressPolicyType.EMAILADRESS, text: "Använd e-postadress från FlowSync" },
    { value: EmailAddressPolicyType.USERNAME, text: "Använd användarnamnet från FlowSync" },
];

// OrganizationalUnitPolicy
// Student
export const studentOrganizationalUnit: OptionItem<StudentsOrganizationalUnit>[] = [
    { value: StudentsOrganizationalUnit.STUDENTS_SCHOOL_CLASS_SWEDISH, text: "Elever/Skola/Klass" },
    { value: StudentsOrganizationalUnit.SCHOOL_STUDENTS_CLASS_SWEDISH, text: "Skola/Elever/Klass" },
    { value: StudentsOrganizationalUnit.STUDENTS_STAGE_SCHOOL_CLASS_SWEDISH, text: "Elever/Stadie/Skola/Klass (För grundskola är stadie F-3, 4-6 och 7-9)" },
    { value: StudentsOrganizationalUnit.STUDENTS_SCHOOL_CLASS_ENGLISH, text: "Student/Skola/Klass" },
    { value: StudentsOrganizationalUnit.SCHOOL_STUDENTS_CLASS_ENGLISH, text: "Skola/Student/Klass" },
    { value: StudentsOrganizationalUnit.STUDENTS_STAGE_SCHOOL_CLASS_ENGLISH, text: "Student/Stadie/Skola/Klass (För grundskola är stadie F-3, 4-6 och 7-9)" },
    { value: StudentsOrganizationalUnit.STUDENTS_SCHOOL_SWEDISH, text: "Elever/Skola" },
    { value: StudentsOrganizationalUnit.SCHOOL_STUDENTS_SWEDISH, text: "Skola/Elever" },
    { value: StudentsOrganizationalUnit.STUDENTS_SCHOOL_ENGLISH, text: "Student/Skola" },
    { value: StudentsOrganizationalUnit.SCHOOL_STUDENTS_ENGLISH, text: "Skola/Student" },
    { value: StudentsOrganizationalUnit.STUDENT_SCHOOL_TYPE_SCHOOL_CLASS_SWEDISH, text: "Elever/Skolform/Skola/Klass" },
    { value: StudentsOrganizationalUnit.STUDENT_SCHOOL_TYPE_SCHOOL_CLASS_ENGLISH, text: "Student/Skolform/Skola/Klass" },
];

export const studentSuspendedOrganizationalUnit: OptionItem<SuspendedStudentsOrganizationalUnit>[] = [
    { value: SuspendedStudentsOrganizationalUnit.SUSPENDED_USERS_SWEDISH, text: "Inaktiva konton" },
    { value: SuspendedStudentsOrganizationalUnit.SUSPENDED_STUDENTS_USERS_SWEDISH, text: "Inaktiva konton/Elever" },
    { value: SuspendedStudentsOrganizationalUnit.SUSPENDED_USERS_ENGLISH, text: "Suspended Users" },
    { value: SuspendedStudentsOrganizationalUnit.SUSPENDED_STUDENTS_USERS_ENGLISH, text: "Suspended Users/Students" },
];

// Staff
export const staffOrganizationalUnit: OptionItem<StaffOrganizationalUnit>[] = [
    { value: StaffOrganizationalUnit.STAFF_SWEDISH, text: "Personal" },
    { value: StaffOrganizationalUnit.STAFF_SCHOOL_SWEDISH, text: "Personal/Skola" },
    { value: StaffOrganizationalUnit.SCHOOL_STAFF_SWEDISH, text: "Skola/Personal" },
    { value: StaffOrganizationalUnit.STAFF_ENGLISH, text: "Staff" },
    { value: StaffOrganizationalUnit.STAFF_SCHOOL_ENGLISH, text: "Staff/Skola" },
    { value: StaffOrganizationalUnit.SCHOOL_STAFF_ENGLISH, text: "Skola/Staff" },
    { value: StaffOrganizationalUnit.STAFF_SCHOOL_TYPE_SCHOOL_SWEDISH, text: "Personal/Skolform/Skola" },
    { value: StaffOrganizationalUnit.STAFF_SCHOOL_TYPE_SCHOOL_ENGLISH, text: "Staff/Skolform/Skola" },
];

export const staffSuspendedOrganizationalUnit: OptionItem<SuspendedStaffOrganizationalUnit>[] = [
    { value: SuspendedStaffOrganizationalUnit.SUSPENDED_USERS_SWEDISH, text: "Inaktiva konton" },
    { value: SuspendedStaffOrganizationalUnit.SUSPENDED_STAFF_USERS_SWEDISH, text: "Inaktiva konton/Personal" },
    { value: SuspendedStaffOrganizationalUnit.SUSPENDED_USERS_ENGLISH, text: "Suspended Users" },
    { value: SuspendedStaffOrganizationalUnit.SUSPENDED_STAFF_USERS_ENGLISH, text: "Suspended Users/Staff" },
];

// PasswordPolicy
export const passwordPolicyTypeOptions: OptionItem<PasswordPolicyType>[] = [
    { value: PasswordPolicyType.STATIC, text: "Statisk" },
    { value: PasswordPolicyType.RANDOM, text: "Slumpmässig" },
];

export const passwordCharacterSets: OptionItem<number>[] = [
    { value: 1, text: "Versaler - A-Z" },
    { value: 2, text: "Gemener - a-z" },
    { value: 4, text: "Siffror - 0-9" },
    { value: 8, text: `Specialtecken - !"#€&/()=?` },
];

// CustomAttributes
export const customAttributeTypes: OptionItem<CustomAttributeType>[] = [
    { value: CustomAttributeType.UNDEFINED, text: "Okänt" },
    { value: CustomAttributeType.SCHOOL_UNIT_CODE, text: "Skolenhetskod" },
    { value: CustomAttributeType.SOURCE_SYSTEM_ID, text: "Externt id" },
    { value: CustomAttributeType.SOCIALNUMBER, text: "Personnummer" },
];