import { ModuleType } from "./CoreModels"

export enum FlowSyncObjectOwnership {
    NEVER = 0,
    POTENTIAL = 1,
    PRIMARY = 2,
}

export enum SourceObjectOwnership {
    NEVER = 0,
    PRIMARY = 1,
}

export enum TrackingOwnership {
    NEVER = 0,
    POTENTIAL = 1,
    PRIMARY = 2,
}

export interface CoreTracking {
    trackingId: string
    active: boolean
    sourceName: string
    sourceEnvironment: string
    sourceObjectType: string
    sourceSystemId: string
    created: string
    modified: string
    inactivated: string
    archived: string
    deleted: string
    nextUpdateCheck: string
    metadata: string
    flowSyncObjectOwnership: FlowSyncObjectOwnership
    sourceObjectOwnership: SourceObjectOwnership
    modules: CoreTrackingModule[]
}

export interface CoreTrackingModule {
    moduleId: string
    moduleName: string
    active: boolean
    moduleType: ModuleType
    trackingOwnership: TrackingOwnership
}