import { Action, Dispatch, Reducer } from 'redux';
import { CoreListStorageContainerFilesRequest, CoreStorageContainerBlob } from '../models/Requests';
import { EmptyRequestState, RequestState } from '../models/RequestState';
import InstanceClient from '../services/InstanceClient';
import StatusCode from '../util/StatusCode';

enum BlobContainerActionType {
    GET_BLOBFILE = 'GET_BLOBFILE',
    DOWNLOAD_BLOBFILE = 'DOWNLOAD_BLOBFILE',
    LIST_BLOBS = 'LIST_BLOBS',
    GET_MODULEINSTANCE_FILE_NAMES = 'GET_MODULEINSTANCE_FILE_NAMES',
    GET_MODULEINSTANCE_FILE = 'GET_MODULEINSTANCE_FILE',
    CLEAR_GET_MODULEINSTANCE_FILE = 'CLEAR_GET_MODULEINSTANCE_FILE',
    CLEAR_BLOB_DOWNLOAD_STATUSES = 'CLEAR_BLOB_DOWNLOAD_STATUSES',
    CLEAR_BLOB_CONTENT = 'CLEAR_BLOB_CONTENT',
}

interface BlobContainer_GetBlobFile extends Action<BlobContainerActionType> {
    type: BlobContainerActionType.GET_BLOBFILE
    blob: BlobContentState
}

interface BlobContainer_DownloadBlobFile extends Action<BlobContainerActionType> {
    type: BlobContainerActionType.DOWNLOAD_BLOBFILE
    downloadState: EmptyRequestState
    blobName: string
}

interface BlobContainer_ListBlobs extends Action<BlobContainerActionType> {
    type: BlobContainerActionType.LIST_BLOBS
    blobs: RequestState<string[]>
}

interface BlobContainer_GetModuleInstanceFileNames extends Action<BlobContainerActionType> {
    type: BlobContainerActionType.GET_MODULEINSTANCE_FILE_NAMES
    fileNames: RequestState<string[]>
}

interface BlobContainer_GetModuleInstanceFile extends Action<BlobContainerActionType> {
    type: BlobContainerActionType.GET_MODULEINSTANCE_FILE
    name: string
    downloadFileStatus: EmptyRequestState
}

interface BlobContainer_ClearGetModuleInstanceFile extends Action<BlobContainerActionType> {
    type: BlobContainerActionType.CLEAR_GET_MODULEINSTANCE_FILE
}

interface BlobContainer_ClearBlobDownloadStatuses extends Action<BlobContainerActionType> {
    type: BlobContainerActionType.CLEAR_BLOB_DOWNLOAD_STATUSES
}

interface BlobContainer_ClearBlobContent extends Action<BlobContainerActionType> {
    type: BlobContainerActionType.CLEAR_BLOB_CONTENT
}

type BlobContainerAction = BlobContainer_GetBlobFile
    | BlobContainer_DownloadBlobFile
    | BlobContainer_ListBlobs
    | BlobContainer_GetModuleInstanceFileNames
    | BlobContainer_GetModuleInstanceFile
    | BlobContainer_ClearGetModuleInstanceFile
    | BlobContainer_ClearBlobDownloadStatuses
    | BlobContainer_ClearBlobContent

export interface BlobContentState {
    name: string
    content: RequestState<string>
}

export interface BlobContainerState {
    // SourceBlob
    blob: BlobContentState
    blobs: RequestState<string[]>
    blobDownloadStatuses: BlobContainerStateDownloadFileStatuses
    // ModuleInstance
    fileNames: RequestState<string[]>
    downloadFileStatuses: BlobContainerStateDownloadFileStatuses
}

export interface BlobContainerStateDownloadFileStatuses {
    [key: string]: EmptyRequestState
}

export const GetBlobFile = (blob: CoreStorageContainerBlob) => (dispatch: Dispatch<BlobContainer_GetBlobFile>) => {
    dispatch({
        type: BlobContainerActionType.GET_BLOBFILE,
        blob: {
            name: blob.blobName,
            content: { code: StatusCode.PENDING },
        }
    });

    InstanceClient.get<string>('/api/core/storagecontainer/blob', blob)
        .then(res => dispatch({
            type: BlobContainerActionType.GET_BLOBFILE,
            blob: {
                name: blob.blobName,
                content: { code: StatusCode.COMPLETE, data: res.data },
            }
        }))
        .catch(err => dispatch({
            type: BlobContainerActionType.GET_BLOBFILE,
            blob: {
                name: blob.blobName,
                content: { code: StatusCode.ERROR, error: err },
            }
        }));
}

export const DownloadBlobFile = (blob: CoreStorageContainerBlob) => (dispatch: Dispatch<BlobContainer_DownloadBlobFile>) => {
    dispatch({
        type: BlobContainerActionType.DOWNLOAD_BLOBFILE,
        downloadState: { code: StatusCode.PENDING },
        blobName: blob.blobName,
    });

    return InstanceClient.get<string>('/api/core/storagecontainer/blob', blob)
        .then((res) => {
            dispatch({
                type: BlobContainerActionType.DOWNLOAD_BLOBFILE,
                downloadState: { code: StatusCode.COMPLETE },
                blobName: blob.blobName,
            })

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            let fileName = blob.blobName;

            const contentDisposition = res.headers['content-disposition'];
            if (contentDisposition) {
                var matches = /filename=([^;]*);/.exec(contentDisposition);
                if (matches !== null && matches[1]) {
                    fileName = matches[1].replace(/['"]/g, '');
                }
            }

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => dispatch({
            type: BlobContainerActionType.DOWNLOAD_BLOBFILE,
            downloadState: { code: StatusCode.ERROR, error: err },
            blobName: blob.blobName,
        }));
}

export const ListStorageContainerBlobs = (listRequest: CoreListStorageContainerFilesRequest) => (dispatch: Dispatch<BlobContainer_ListBlobs>) => {
    dispatch({ type: BlobContainerActionType.LIST_BLOBS, blobs: { code: StatusCode.PENDING } });

    InstanceClient.get<string[]>('/api/core/storagecontainer', listRequest)
        .then((response) => dispatch({
            type: BlobContainerActionType.LIST_BLOBS,
            blobs: { code: StatusCode.COMPLETE, data: response.data }
        }))
        .catch((error) => dispatch({
            type: BlobContainerActionType.LIST_BLOBS,
            blobs: { code: StatusCode.ERROR, error },
        }));
};

export const GetModuleInstanceFileName = (moduleInstanceId: string) => (dispatch: Dispatch<BlobContainer_GetModuleInstanceFileNames>) => {
    dispatch({ type: BlobContainerActionType.GET_MODULEINSTANCE_FILE_NAMES, fileNames: { code: StatusCode.PENDING } });

    InstanceClient.get<string[]>(`/api/core/storagecontainer/${moduleInstanceId}/files`)
        .then(res => dispatch({
            type: BlobContainerActionType.GET_MODULEINSTANCE_FILE_NAMES,
            fileNames: { code: StatusCode.COMPLETE, data: res.data },
        }))
        .catch(err => dispatch({
            type: BlobContainerActionType.GET_MODULEINSTANCE_FILE_NAMES,
            fileNames: { code: StatusCode.ERROR, error: err },
        }));
}

export const DownloadFileForInstanceModule = (moduleInstanceId: string, name: string) => (dispatch: Dispatch<BlobContainer_GetModuleInstanceFile>) => {
    dispatch({ type: BlobContainerActionType.GET_MODULEINSTANCE_FILE, name: name, downloadFileStatus: { code: StatusCode.PENDING } });
    InstanceClient.download(`/api/core/storagecontainer/${moduleInstanceId}/file?name=${encodeURIComponent(name)}`, name)
        .then(res => dispatch({ type: BlobContainerActionType.GET_MODULEINSTANCE_FILE, name: name, downloadFileStatus: { code: StatusCode.COMPLETE } }))
        .catch(error => dispatch({ type: BlobContainerActionType.GET_MODULEINSTANCE_FILE, name: name, downloadFileStatus: { code: StatusCode.ERROR, error } }));
};

export const ClearDownloadFileForInstanceModule = () => (dispatch: Dispatch<BlobContainer_ClearGetModuleInstanceFile>) => {
    dispatch({ type: BlobContainerActionType.CLEAR_GET_MODULEINSTANCE_FILE });
}

export const ClearBlobDownloadStatuses = () => (dispatch: Dispatch<BlobContainer_ClearBlobDownloadStatuses>) => {
    dispatch({ type: BlobContainerActionType.CLEAR_BLOB_DOWNLOAD_STATUSES });
}

export const ClearBlobContent = () => (dispatch: Dispatch<BlobContainer_ClearBlobContent>) => {
    dispatch({ type: BlobContainerActionType.CLEAR_BLOB_CONTENT });
}

const initialState: BlobContainerState = {
    blobs: { code: StatusCode.NONE },
    blob: {
        name: "",
        content: { code: StatusCode.NONE },
    },
    blobDownloadStatuses: {},
    fileNames: { code: StatusCode.NONE },
    downloadFileStatuses: {},
}

export const reducer: Reducer<BlobContainerState, BlobContainerAction> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case BlobContainerActionType.GET_BLOBFILE:
            return {
                ...state,
                blob: action.blob,
            };
        case BlobContainerActionType.DOWNLOAD_BLOBFILE:
            return {
                ...state,
                blobDownloadStatuses: {
                    ...state.blobDownloadStatuses,
                    [action.blobName]: action.downloadState,
                },
            }
        case BlobContainerActionType.LIST_BLOBS:
            return {
                ...state,
                blobs: action.blobs,
            };
        case BlobContainerActionType.GET_MODULEINSTANCE_FILE_NAMES:
            return {
                ...state,
                fileNames: action.fileNames,
            };
        case BlobContainerActionType.GET_MODULEINSTANCE_FILE:
            return {
                ...state,
                downloadFileStatuses: {
                    ...state.downloadFileStatuses,
                    [action.name]: action.downloadFileStatus,
                }
            };
        case BlobContainerActionType.CLEAR_GET_MODULEINSTANCE_FILE:
            return {
                ...state,
                downloadFileStatuses: {}
            };
        case BlobContainerActionType.CLEAR_BLOB_DOWNLOAD_STATUSES:
            return {
                ...state,
                blobDownloadStatuses: {}
            }
        case BlobContainerActionType.CLEAR_BLOB_CONTENT:
            return {
                ...state,
                blob: {
                    name: "",
                    content: { code: StatusCode.NONE },
                }
            }
        default:
            return state;
    }
}