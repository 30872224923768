import { Action, Dispatch, Reducer } from 'redux';
import * as Notifications from '../services/Notifications'

enum NotificationActionType {
    NOTIFICATION = "NOTIFICATION",
}

const INITIAL_STATE = {};

interface Notification_Subscribe extends Action<NotificationActionType> {
    type: NotificationActionType.NOTIFICATION
    notification: Notifications.NotificationServiceMessage
}

type NotificationAction = Notification_Subscribe

export type NotificationState = Partial<Notifications.NotificationServiceMessage>

export const subscribe = (types: string[]) => (dispatch: Dispatch<Notification_Subscribe>) => {
    return Notifications.subscribe(types, (data) => dispatch({ type: NotificationActionType.NOTIFICATION, notification: data }));
}

export const reducer: Reducer<NotificationState, NotificationAction> = (state, action) => {
    state = state || INITIAL_STATE;

    switch (action.type) {
        case NotificationActionType.NOTIFICATION:
            return action.notification;
        default:
            return state;
    }
};