export interface CoreAppToken {
    id: string,
    appRegistration?: string,
    tenant?: string,
    userName?: string,
    createdBy?: string,
    appTokenType: AppTokenType
}

export enum AppTokenType {
    UNDEFINED = 0,
    MICROSOFT_APPLICATION = 1,
    MICROSOFT_DELEGATED = 2,
    GOOGLE_SERVICE_ACCOUNT = 3
}

export interface CoreAppTokenValidation {
    valid: boolean,
    reason?: string,
    description?: string,
    flowSyncUXUri?: string,
    copyLinkUri?: string,
    clientId?: string,
    scopes?: string[],
    appTokenType: AppTokenType
}

export interface CoreAppTokenRequest {
    appRegistration?: string,
    tenant?: string,
    userName?: string
}