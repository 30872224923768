import React from 'react';
import { FormGroup, Label, Input, Card, CardHeader, CardBody, Collapse, Button, FormFeedback } from 'reactstrap';
import ConfigurationComponent from '../ConfigurationComponent'
import Skola24FilterInput from './components/Skola24FilterInput';
import EnumInput from '../../../misc/EnumInput';

const namingStrategy = [
    { value: 1, text: 'Aktivitetens kurs/ämne' },
    { value: 2, text: 'Gruppernas namn' },
    { value: 3, text: 'Gruppernas namn (Aktivitetens kurs/ämne)' },
    { value: 4, text: 'Aktivitetens kurs/ämne (Gruppernas namn)' },
];

class Skola24Schedule extends ConfigurationComponent {
    state = { open: true }

    validate(configuration, active) {
        const validation = [];

        if (active) {
            if (!configuration.hostname || configuration.hostname === "") {
                validation.push('hostname');
            }
            if (!configuration.importIntervalRule || configuration.importIntervalRule === "") {
                validation.push('importIntervalRule');
            }
        }
        return validation;
    }

    render() {
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);
        const validation = this.validate(configuration, this.props.active);

        return (
            <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    {this.props.moduleDisplayName}
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>
                        <div style={{ margin: '1rem 0' }}>
                            <FormGroup>
                                <p>För att utläsning av data ska kunna ske från Skola24 behöver Skola24 informeras om att Eventful ska tilldelas access till lektionsexporten och organisationsexporten. Detta behöver göras av behörig personal inom organisationen som är kund hos Skola24.</p>
                                <p>Skola24 har begränsningar för när en utläsning av schemapositioner får ske, tillåtna utläsningstider (i UTC) enligt nedan.</p>
                                <ul>
                                    <li>Alla dagar utom fredagar: 00.00 - 02:45, 05:15 - 07:00, 17:00 - 00:00</li>
                                    <li>Fredagar: 00.00 - 02:45, 05:15 - 07:00, 17:00 - 18:00</li>
                                </ul>
                            </FormGroup>
                            <FormGroup>
                                <Label for="hostname">Värdnamn</Label>
                                <Input type="text" name="hostname" id="hostname" placeholder="kund.skola24.se" disabled={this.props.disableInput} value={configuration.hostname || ''} onChange={this.updateProperty} invalid={validation.indexOf('hostname') !== -1} autoComplete="off" />
                                <FormFeedback>Värdnamn måste anges för att kunna aktivera importmodulen</FormFeedback>
                            </FormGroup>
                            <FormGroup>
                                <Label for="importIntervalRule">Import intervalls regel</Label>
                                <Input type="select" name="importIntervalRule" id="importIntervalRule" value={configuration.importIntervalRule || 'UpcomingMonth'} onChange={this.updateProperty} disabled={this.props.disableInput}>
                                    <option disabled value="">Välj import intervalls regel</option>
                                    <option value="UpcomingMonth">Kommande månad (30 dagar)</option>
                                    <option value="Upcoming3Months">Kommande 3 månader (90 dagar)</option>
                                    <option value="Upcoming6Months">Kommande 6 månader (180 dagar)</option>
                                    <option value="CurrentSemester">Innevarande termin</option>
                                </Input>
                                <FormFeedback>En import intervalls regel måste väljas för att kunna aktivera modulen</FormFeedback>
                            </FormGroup>

                            <EnumInput
                                title='Namngivning'
                                name='eventNaming'
                                value={configuration.eventNaming}
                                options={namingStrategy}
                                onChange={this.updatePropertyValue}
                                disabled={this.props.disableInput}
                            />

                            <FormGroup>
                                <h5>Filter</h5>
                                <p>Filtrera bort schemapositioner baserat på ämne och/eller kurs.</p>
                                <Skola24FilterInput name="eventFilters" filters={configuration.eventFilters} onChange={this.updateProperty} disabled={this.props.disableInput} />
                            </FormGroup>
                        </div>
                    </CardBody>
                </Collapse>
            </Card>)
    }
}

export default Skola24Schedule;
