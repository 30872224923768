import { Dispatch } from 'react';
import { Action, Reducer } from 'redux';
import { CoreDuty } from '../models/CoreDuty';
import { RequestState } from '../models/RequestState';
import InstanceClient from '../services/InstanceClient';
import StatusCode from '../util/StatusCode';
import store from './store';

enum DutyActionType {
    GET_DUTY = 'GET_DUTY'
}

export interface DutyState {
    duty: RequestState<CoreDuty>
}

interface Duty_GetDuty extends Action<DutyActionType> {
    type: DutyActionType.GET_DUTY
    duty: RequestState<CoreDuty>
}

type DutyAction = Duty_GetDuty;

export const GetDuty = (id: string) => (dispatch: Dispatch<Duty_GetDuty>) => {
    const storeKey = store.getState().duties.duty?.key;
    const key = id;

    if (key === storeKey) {
        return;
    }

    dispatch({ type: DutyActionType.GET_DUTY, duty: { code: StatusCode.PENDING } });

    InstanceClient.get<CoreDuty>(`/api/core/duty/${id}`)
        .then(res =>
            dispatch({
                type: DutyActionType.GET_DUTY,
                duty: { code: StatusCode.COMPLETE, data: res.data, key: key }
            })
        )
        .catch(error =>
            dispatch({
                type: DutyActionType.GET_DUTY,
                duty: { code: StatusCode.ERROR, error }
            })
        );
};

const initialState: DutyState = {
    duty: { code: StatusCode.PENDING },
}

export const reducer: Reducer<DutyState, DutyAction> = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case DutyActionType.GET_DUTY:
            return {
                ...state,
                duty: action.duty,
            };
        default:
            return state;
    }
};

