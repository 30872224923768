import React from 'react';
import { OnChangeCallback } from '../../../../../../util/UtilityTypes';
import CheckboxListItem from '../../../../../misc/CheckboxListItem';
import { CoreHaldorUnifiedSetting } from '../../../../../../models/CoreHaldorModels';

interface HaldorUnifiedSettingProps {
    name: string
    basedOnText: string
    disableEnableSetting: boolean
    value: CoreHaldorUnifiedSetting 
    onChange: OnChangeCallback<CoreHaldorUnifiedSetting>
    disabled?: boolean
}

const HaldorUnifiedSetting: React.FC<HaldorUnifiedSettingProps> = (props) => {

    const onChange: OnChangeCallback<boolean> = (prop, value) => {
        props.onChange(props.name, {
            enabled: prop === 'enabled' ? value : props.value.enabled,
            keep: prop === 'keep' ? value : props.value.keep
        })
    }

    return (        
        <div>
            <CheckboxListItem text={'Baserat på ' + props.basedOnText + ' från elevregistret'}
                id={props.name + '-enabled'}
                name={'enabled'}
                value={props.value.enabled}
                onChange={(e) => onChange(e.target.name, e.target.checked)}
                disabled={props.disableEnableSetting ? true : props.disabled}
            />
            <div style={{ 'marginLeft': '2rem' }}>
                <CheckboxListItem text={'Behåll grupp efter borttagning'}
                    id={props.name + '-keep'}
                    name={'keep'}
                    value={props.value.keep}
                    onChange={(e) => onChange(e.target.name, e.target.checked)}
                    disabled={props.disabled}
                />
            </div>
        </div>        
    )
}

export default HaldorUnifiedSetting;