import React from 'react';
import EnumInput from '../../../../misc/EnumInput';
import { TeamsFixStandardSettings, GroupSettings, MemberSettings, MessagingSettings, SharePointSiteSettings, StandardSettingBehaviour } from '../../../../../models/TeamsFixTypes';

interface TeamsFixStandardSettingsProps {
    teamsFixSettings: TeamsFixStandardSettings
    disabled: boolean
    onChange: (x: TeamsFixStandardSettings) => void
    name: string
}

const TeamsFixStandardSettingsComponent: React.FC<TeamsFixStandardSettingsProps> = (props) => {
    const settings = props.teamsFixSettings;

    const notifyChange = (change: Partial<TeamsFixStandardSettings>) => props.onChange({
        ...settings,
        ...change
    });

    return (<>
        <EnumInput
            title="När ska inställningarna sättas"
            name={props.name + ".settingsBehaviour"}
            value={settings.settingBehaviour}
            options={StandardSettingBehaviour}
            onChange={(n, v) => notifyChange({ settingBehaviour: v })}
            disabled={props.disabled}
            multi={false}
        />

        <EnumInput
            title="Meddelandeinställningar"
            name={props.name + ".messagingSettings"}
            value={settings.messagingSettings}
            options={MessagingSettings}
            onChange={(n, v) => notifyChange({ messagingSettings: v })}
            disabled={props.disabled}
            multi={true}
        />

        <EnumInput
            title="Medlemsinställningar"
            name={props.name + ".memberSettings"}
            value={settings.memberSettings}
            options={MemberSettings}
            onChange={(n, v) => notifyChange({ memberSettings: v })}
            disabled={props.disabled}
            multi={true}
        />

        <EnumInput
            title="SharePoint-inställningar"
            name={props.name + ".sharePointSiteSettings"}
            value={settings.sharePointSiteSettings}
            options={SharePointSiteSettings}
            onChange={(n, v) => notifyChange({ sharePointSiteSettings: v })}
            disabled={props.disabled}
            multi={true}
        />

        <EnumInput
            title="Gruppinställningar"
            name={props.name + ".groupSettings"}
            value={settings.groupSettings}
            options={GroupSettings}
            onChange={(n, v) => notifyChange({ groupSettings: v })}
            disabled={props.disabled}
            multi={true}
        />
    </>);
}

export default TeamsFixStandardSettingsComponent;
