const nameLookup: { [key: number]: string } = {
    1: 'Lgy70',
    2: 'Lgr80',
    3: 'Lpo94',
    4: 'Lpf94',
    5: 'Lpfo98',
    6: 'GR2000',
    7: 'GY2000',
    8: 'GYSAR2000',
    9: 'GYVUX2000',
    10: 'GYVUX2001',
    11: 'GYVUX2002',
    12: 'GR2011',
    13: 'GRSAR2011',
    14: 'SPEC2011',
    15: 'SAM2011',
    16: 'Lvux12',
    17: 'GY2011',
    18: 'GYSAR2013',
    19: 'VU2013',
}

export const getName = (id?: number): string | undefined => {
    if (id) {
        return nameLookup[id] ?? 'Okänd';
    }

    return undefined;
}