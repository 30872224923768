interface GroupType {
    id: number,
    name: string,
}

const groupTypes = new Map<number, string>([
    [1, "Undervisning"],
    [2, "Klass"],
    [3, "Mentor"],
    [4, "Schema"],
    [5, "Avdelning"],
    [6, "Personalgrupp"],
    [7, "Provgrupp"],
    [0, "Övrigt"],
]);

export const GroupTypes: GroupType[] = Array.from(groupTypes).map(x => ({ id: x[0], name: x[1] }));

export const groupTypeFromId = (id: number): string => groupTypes.get(id) ?? 'Saknas';