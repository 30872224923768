import InstanceClient from '../services/InstanceClient';
import StatusCode from '../util/StatusCode';

const LIST_AUDITLOG = 'LIST_AUDITLOG';
const LIST_AUDITLOG_FILTER = 'LIST_AUDITLOG_FILTER';
const LIST_AUDITLOG_UPDATE = 'LIST_AUDITLOG_UPDATE';
const LIST_EVENT_TYPES = 'LIST_EVENT_TYPES';

export const ListAuditLog = (filter = {}, update = false) => dispatch => {
    dispatch({ type: LIST_AUDITLOG_FILTER, filter: filter });

    if (update) {
        dispatch({ type: LIST_AUDITLOG_UPDATE });
    }

    const url = '/api/core/auditLog';
    InstanceClient.get(url, filter)
        .then(res =>
            dispatch({
                type: LIST_AUDITLOG,
                logs: {
                    code: StatusCode.COMPLETE,
                    data: res.data.values,
                    totalCount: res.data.totalCount,
                    pagination: {
                        totalPages: res.data.totalPages,
                        pageSizes: res.data.pageSizes,
                        currentPage: res.data.currentPage,
                    }
                },
            }))
        .catch(err =>
            dispatch({
                type: LIST_AUDITLOG,
                logs: { code: StatusCode.ERROR, error: err }
            }));
}

export const ListAuditLogEventTypes = (dispatchPending = true) => dispatch => {
    if (dispatchPending) {
        dispatch({
            type: LIST_EVENT_TYPES,
            eventTypes: {
                code: StatusCode.PENDING
            }
        });
    }

    InstanceClient.get('/api/core/auditLog/eventTypes')
        .then(resp => dispatch({
            type: LIST_EVENT_TYPES,
            eventTypes: {
                code: StatusCode.COMPLETE,
                data: resp.data,
            }
        }))
        .catch(error => dispatch({
            type: LIST_EVENT_TYPES,
            eventTypes: {
                code: StatusCode.ERROR,
                error,
            }
        }));
}

const initialState = {
    logs: { code: StatusCode.PENDING, },
    filter: {},
    eventTypes: { code: StatusCode.NONE, },
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case LIST_AUDITLOG:
            return {
                ...state,
                logs: action.logs,
            };
        case LIST_AUDITLOG_UPDATE:
            return {
                ...state,
                logs: { ...state.logs, code: StatusCode.PENDING },
            };
        case LIST_AUDITLOG_FILTER:
            return {
                ...state,
                filter: action.filter,
            };
        case LIST_EVENT_TYPES:
            return {
                ...state,
                eventTypes: action.eventTypes,
            };
        default:
            return state;
    }
}

