import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import Pagination from '../../../../../misc/Pagination';
import SaveStatus from '../../../../../misc/SaveStatus';
import StatusCode from '../../../../../../util/StatusCode';
import { CacheRequestState } from '../../../../../../models/RequestState';
import { PageResult } from '../../../../../../models/CoreModels';
import * as Helper from '../../../../../../util/Helper'

type OnChangePageCallback = (name: string, value: number) => void;

interface DnpActivitiesFooterProps<T> {
    updateFilter: OnChangePageCallback
    saveConfiguration: React.MouseEventHandler
    saveStatus: StatusCode
    disabledSave: boolean
    requestState?: CacheRequestState<PageResult<T>>
}

const DnpActivitiesFooter = <T,>(props: DnpActivitiesFooterProps<T>) => {
    const totalCount = Helper.getTotalCount(props.requestState);

    return (
        <div className="filter-bottom dnp-filter-bottom">
            <Row>
                <Col sm="3" className="vertical-center">
                    {totalCount !== undefined && <p className="small-italic">Totalt {totalCount} resultat</p>}
                </Col>
                <Col sm="6">
                    <Pagination requestState={props.requestState} onChangePage={(p) => props.updateFilter('page', p)} />
                </Col>
                <Col sm="3" className="save-box">
                    <SaveStatus
                        saveStatus={props.saveStatus}
                        successMsg="Sparat"
                    />
                    <Button disabled={props.disabledSave} onClick={props.saveConfiguration}>Spara</Button>
                </Col>
            </Row>
        </div>
    )
}

export default DnpActivitiesFooter;
