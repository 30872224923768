import { SchoolYear } from "../../../../../../models/CoreDnpModels";

export const GetSchoolYear = (schoolYear: SchoolYear): number | string | undefined => {
    if (!Object.keys(schoolYear).length) {
        return undefined;
    }
    if (schoolYear.startSchoolYear && schoolYear.endSchoolYear) {
        if (schoolYear.startSchoolYear === schoolYear.endSchoolYear) {
            return schoolYear.startSchoolYear;
        } else {
            return `${schoolYear.startSchoolYear} - ${schoolYear.endSchoolYear}`
        }
    } else {
        return schoolYear.startSchoolYear || schoolYear.endSchoolYear;
    }
}