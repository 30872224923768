import React from 'react';
import { CoreTracking } from "../../../models/CoreTracking"
import GoogleUserTrackingOperationsMenu, { isGoogleUserTracking } from './googleTrackingOperations/GoogleUserTrackingOperationsMenu';
import { FlowSyncAccessPolicy, isAuthorized } from '../../../models/FlowSyncAccessPolicy';
import { MeState } from '../../../store/MeAction';
import { useSelector } from 'react-redux';

enum TrackingType {
    Unknown = 0,
    GoogleUser,
}

interface TrackingOperationsProps {
    tracking: CoreTracking
    onClose?: (success: boolean) => void
}

interface TrackingOperationsState {
    me: MeState
}

const TrackingOperations: React.FC<TrackingOperationsProps> = (props) => {
    const authorizedAccessPolicies = useSelector<TrackingOperationsState, FlowSyncAccessPolicy[]>(state => state.me.me?.authorizedAccessPolicies ?? []);
    const hasMappingPermission = isAuthorized(authorizedAccessPolicies, FlowSyncAccessPolicy.MAP);

    const traackingType = getTrackingType(props.tracking);

    switch (traackingType) {
        case TrackingType.GoogleUser:
            return <GoogleUserTrackingOperationsMenu tracking={props.tracking} onClose={props.onClose} disabled={!hasMappingPermission} />;
        default:
            return null;
    }
}

const getTrackingType = (tracking: CoreTracking): TrackingType => {
    if (isGoogleUserTracking(tracking)) {
        return TrackingType.GoogleUser;
    }

    return TrackingType.Unknown;
}

export default TrackingOperations;