import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { Events } from '../../store/ScheduleSync/ScheduleSync_Event';
import * as Helper from '../../util/Helper';
import ActiveToggle from '../misc/ActiveToggle';
import debounce from 'lodash/debounce';
import Toggle from '../misc/Toggle';
import StatusCode from '../../util/StatusCode';
import TableHorizontal from '../misc/TableHorizontal';
import Search from '../misc/Search';
import FilterBottomComponent from '../misc/FilterBottomComponent';
import RequestParser from '../../util/RequestParser';


const Events_ScheduleSync = () => {
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState('');

    const events = useSelector(state => state.schedulesync_events.events);
    var filter = RequestParser.parseScheduleSyncEventSearchPageRequest(searchParams);

    useEffect(() => {
        if (filter.search) {
            setSearch(filter.search);
        }
        Events(filter)(dispatch);
    }, [dispatch]);

    useEffect(() => {
        Events(filter, true)(dispatch);
        window.scrollTo(0, 0);
    }, [searchParams]);

    const updateFilter = (name, value) => {
        searchParams.set(name, value);
        if (name !== 'page') {
            searchParams.set('page', 1);
        }

        setSearchParams(searchParams);
    }

    const debouncedSearch = useCallback(
        debounce(search => {
            updateFilter('search', search);
        }, 300), [searchParams]);

    const onSearch = (e) => {
        e.preventDefault();
        let search = e.target.value;
        setSearch(search);
        debouncedSearch(search);
    }

    const clearFilter = () => {
        setSearch('');
        setSearchParams([]);
    }

    const renderFilter = () => (
        <Row>
            <Col sm="6">
                <Search value={search} onChange={onSearch} placeholder="Sök schemaposition" />
            </Col>
            <Col sm="6" className="flex dir-column-ml space-between">
                <Toggle
                    header="Visa passerade"
                    searching={events.code === StatusCode.PENDING}
                    checked={filter.onlyUpcomingDates ?? null}
                    update={(active) => updateFilter('onlyUpcomingDates', active)}
                    name='onlyUpcomingDates'
                />
                <ActiveToggle
                    onlyActive={filter.onlyActive ?? null}
                    update={(active) => updateFilter('onlyActive', active)}
                    disabled={events.code === StatusCode.PENDING}
                />
            </Col>
        </Row>
    );

    const renderBody = () => {
        const eventsList = events.data?.map(e => ({
            id: e.eventId,
            data: [
                { value: <Link to={`/schedulesync/event/${e.eventId}`}>{e.title}</Link> },
                { value: Helper.formatDate(e.startTime) },
                { value: Helper.formatDate(e.endTime) },
                { value: e.schoolName },
                { value: e.location },
                { value: e.numberOfOrganizers },
                { value: e.numberOfParticipants },
                { value: Helper.active(e.active) }
            ]
        })) ?? [];

        return (
            <TableHorizontal
                header={[{ value: 'Titel' }, { value: 'Starttid' }, { value: 'Sluttid' }, { value: 'Skola' }, { value: 'Plats' }, { value: 'Antal organisatörer' }, { value: 'Antal deltagare' }, { value: 'Aktiv' }]}
                info={eventsList}
                error={events.code === StatusCode.ERROR}
                searching={events.code === StatusCode.PENDING}
                displayName="Schemapositioner"
                noResMsg="Inga schemapositioner hittades. Testa att justera filtreringen."
            />
        )
    }

    const requestState = Helper.convertToCacheRequestState(events);

    return (
        <div className="container events_schedulesync">
            <div className="header--fixed large-filter">
                <h1>Schemapositioner</h1>
                {renderFilter()}
            </div>
            <div className="body--scroll padding-40">
                {renderBody()}
            </div>

            <FilterBottomComponent
                requestState={requestState}
                clearFilter={clearFilter}
                updateFilter={(value) => updateFilter('page', value)}
            />

        </div>
    )
}

export default Events_ScheduleSync;
