import React from 'react';
import { Input, FormFeedback } from 'reactstrap'
import { Link } from 'react-router-dom'

const exportModuleLinks = (exportModules) => {
    if (exportModules.length > 0) {
        return exportModules.map(em => {
            return <span key={em.id}><Link to={`/module/${em.id}/configuration`} target="_blank">{em.title} </Link></span>;
        });
    }

    return null;
}

export const ExportSchoolUnitCheckbox = (props) => {
    return <React.Fragment>
        <Input type="checkbox"
            disabled={!props.schoolUnit.isActive || !props.isAdmin}
            checked={props.schoolUnit.shouldImport}
            onChange={() => props.onChange({ ...props.schoolUnit, shouldImport: !props.schoolUnit.shouldImport })}
        />
        {props.schoolUnit.title}
    </React.Fragment>;
}

export const ImportSchoolUnitCheckbox = (props) => {
    return <React.Fragment>
        <Input type="checkbox"
            disabled={(!props.schoolUnit.isActive && !props.schoolUnit.shouldImport) || !props.isAdmin}
            checked={props.schoolUnit.shouldImport}
            onChange={() => props.onChange({ ...props.schoolUnit, shouldImport: !props.schoolUnit.shouldImport })}
            invalid={props.validation.some(v => v === `inactive-${props.schoolUnit.id}` || v === `references-${props.schoolUnit.id}`)}
        />
        {props.schoolUnit.title}
        <FormFeedback>
            {props.validation.some(v => v === `inactive-${props.schoolUnit.id}`) && 'Skolan kan inte läsas in då den är inaktiverad i elevregistret'}
            {props.validation.some(v => v === `references-${props.schoolUnit.id}`) && <span>Skolan har referenser till exportmodul, {exportModuleLinks(props.schoolUnit.exportModules)} Justera dessa för att inaktivera skolan.</span>}
        </FormFeedback>
    </React.Fragment>;
}