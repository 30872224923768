const roleTypes = {
    'organizer': 'Organisatör',
    'participant': 'Deltagare',
}

export const RoleTypes = roleTypes;


export const userRoles = (roleList) => {
    let roleTypesList = [];

    Object.entries(roleList).map(([key, value]) => {
        if (value) {
            roleTypesList.push(roleTypes[key])
        }
    })

    if (roleTypesList.length > 1) {
        return roleTypesList.join(', ');
    }
    if (!roleTypesList.length) {
        return '-';
    }
    return roleTypesList;
}

export const userRole = (id) => {
    const role = roleTypes[id];
    if (role) {
        return role;
    }
    return '-';
}
