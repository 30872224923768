import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ActivityState, GetActivity } from '../../../store/ActivityAction';
import TableVertical from '../../misc/TableVertical';
import StatusCode from '../../../util/StatusCode';
import * as Helper from '../../../util/Helper';
import { Link, useParams } from 'react-router-dom'
import { CoreActivity } from '../../../models/CoreActivity';
import { RequestState } from '../../../models/RequestState';

interface State {
    activities: ActivityState
}

const ActivityViewInfo = () => {
    const { activityId } = useParams();
    const dispatch = useDispatch();
    const activity = useSelector<State, RequestState<CoreActivity>>(state => state.activities.activity);

    useEffect(() => {
        if (activityId !== undefined) {
            GetActivity(activityId)(dispatch);
        }
    }, [dispatch, activityId]);

    const renderActivityInfo = () => {
        let activityInfo = [];
        if (activity.code === StatusCode.COMPLETE) {
            const a = activity.data;
            activityInfo.push(
                { header: 'Titel', content: a.displayName },
                { header: 'Kurs', content: a?.course?.title },
                { header: 'Ämne', content: a?.subject?.title },
                { header: 'Startdatum', content: Helper.formatDateOnly(a.startDate) },
                { header: 'Slutdatum', content: Helper.formatDateOnly(a.endDate) },
                { header: 'Id', content: a.id },
                { header: 'Skola', content: <Link to={`/schoolunit/${a.schoolUnit.id}/information`}>{a.schoolUnit.title}</Link> },
                { header: 'Aktivitetstyp', content: Helper.activityType(a.activityType.name) },
                { header: 'Aktiv', content: Helper.active(a.active) },
                { header: 'Skapad', content: Helper.formatDate(a.created) },
                { header: 'Ändrad', content: Helper.formatDate(a.modified) },
                { header: 'Raderad', content: Helper.formatDate(a?.removed) },
                { header: 'Kalenderhändelse krävs', content: a.calendarEventsRequired ? "Ja" : "Nej" },
                { header: 'Kommentar', content: a?.comment },
                { header: 'Minuter planerade', content: a?.minutesPlanned },
                { header: 'Föräldraaktivitet', content: a?.parentActivity },
            )
        }

        return (
            <TableVertical
                header='Information'
                info={activityInfo}
                error={activity.code === StatusCode.ERROR}
                searching={activity.code === StatusCode.PENDING}
            />
        )
    }

    return (<>
        {renderActivityInfo()}
    </>)
}

export default ActivityViewInfo;
