import React from 'react';
import { Card, CardBody, CardHeader, Collapse, Button } from 'reactstrap';
import CheckboxListItem from '../../../misc/CheckboxListItem';
import ConfigurationComponent from '../ConfigurationComponent'

class ExportAdditionalTeam extends ConfigurationComponent {
    state = { open: true };

    warnings(update) {
        const warningMessages = [];

        if (Object.values(update).some(team => !team)) {
            warningMessages.push('Om du tar bort typ av team kommer teamen kopplade till dessa att arkiveras')
        }

        return warningMessages;
    }

    render() {
        const configuration = this.mergeWithUpdates(this.props.configuration, this.props.updates);

        return (
            <Card className="edit_module">
                <CardHeader tag="h2">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => this.setState({ ...this.state, open: !this.state.open })}>{this.state.open ? '-' : '+'}</Button>
                    {this.props.moduleDisplayName}
                </CardHeader>
                <Collapse isOpen={this.state.open}>
                    <CardBody>
                        <CheckboxListItem text='Personalteam (Alla personal på skolan)'
                            name="staffTeam"
                            value={configuration.staffTeam}
                            disabled={this.props.disableInput}
                            onChange={this.updateProperty}
                        />
                        <CheckboxListItem text='Skolteam (Alla elever och lärare på skolan)'
                            name="schoolTeam"
                            value={configuration.schoolTeam}
                            disabled={this.props.disableInput}
                            onChange={this.updateProperty}
                        />
                        <CheckboxListItem text='Avdelningsteam (Alla personal på avdelning inom fsk)'
                            name="departmentTeam"
                            value={configuration.departmentTeam}
                            disabled={this.props.disableInput}
                            onChange={this.updateProperty}
                        />
                        <CheckboxListItem text='Kommunteam (Alla anställda inom skolan i kommunen)'
                            name="staffOrganizationTeam"
                            value={configuration.staffOrganizationTeam}
                            disabled={this.props.disableInput}
                            onChange={this.updateProperty}
                        />
                    </CardBody>
                </Collapse>
            </Card>
        )
    }
};

export default ExportAdditionalTeam;
