import React, { ReactNode, useState } from 'react';
import ModalComponent from '../../misc/ModalComponent';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatusCode from '../../../util/StatusCode';
import { CorePrimarySchoolStatus } from '../../../models/CoreUser';
import { CoreDisplayableRef } from '../../../models/CoreModels';

interface Item {
    primarySchoolStatus: CorePrimarySchoolStatus
    schoolUnit: CoreDisplayableRef
}

interface PrimarySchoolModalProps {
    isOpen: boolean
    toggleModal: () => void
    saveStatus: StatusCode
    deleteOverridenPrimarySchool: () => void
    items: Item[]
    primarySchool: Item | undefined
    update: (updatedSchool: string) => void
    disabled: boolean
    getIcons: (primarySchoolStatus: CorePrimarySchoolStatus) => ReactNode[]
}


const PrimarySchoolModal: React.FC<PrimarySchoolModalProps> = (props: PrimarySchoolModalProps) => {
    const [updatedSchool, setUpdatedSchool] = useState(props.primarySchool?.schoolUnit.id ?? undefined);

    const update = () => {
        if (updatedSchool !== undefined) {
            props.update(updatedSchool);
        }
    }

    return (
        <ModalComponent
            isOpen={props.isOpen}
            toggleModal={props.toggleModal}
            header={<p>Primär skola <a href="https://flowsync.se/help/schooladmin/primarySchool" target="_blank" rel="noreferrer"><FontAwesomeIcon className="documentation-icon fs-color" title="Visa hjälpavsnitt" icon="question-circle" /></a></p>}
            cancel="Stäng"
            confirm="Uppdatera"
            update={() => update()}
            disabled={props.primarySchool?.schoolUnit.id === updatedSchool || props.disabled}
            saveStatus={props.saveStatus}
        >
            {props.items.map((s, i) => (
                <FormGroup check key={i}>
                    <Label><Input type="radio" name="primarySchool" value={s.schoolUnit.id} checked={updatedSchool === s.schoolUnit.id} onChange={e => setUpdatedSchool(e.target.value)} />
                    {s.schoolUnit.title} <span className="ml-2">{props.getIcons(s.primarySchoolStatus)}</span></Label>
                </FormGroup>
            ))}

            <Button disabled={!props.primarySchool?.primarySchoolStatus.overriddenPrimary || props.disabled} onClick={props.deleteOverridenPrimarySchool}>Rensa manuellt vald primär skola</Button>

        </ModalComponent>
    );
}

export default PrimarySchoolModal;
