import InstanceClient from '../services/InstanceClient';
import StatusCode from '../util/StatusCode';

const GET_CONSUMERS = 'GET_CONSUMERS';

export const GetConsumers = () => dispatch => {
    dispatch({ type: GET_CONSUMERS, consumers: { code: StatusCode.PENDING } });
    InstanceClient.get('/api/core/consumer')
        .then(res => dispatch({
            type: GET_CONSUMERS,
            consumers: { code: StatusCode.COMPLETE, data: res.data },
        }))
        .catch(err => dispatch({
            type: GET_CONSUMERS,
            consumers: { code: StatusCode.ERROR, data: err },
        }));
}


const initialState = {
    consumers: { code: StatusCode.NONE },
};

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case GET_CONSUMERS:
            return {
                ...state,
                consumers: action.consumers,
            };
        default:
            return state;
    }
}
