import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link, Route, Routes, Navigate } from 'react-router-dom'
import { Input } from 'reactstrap';
import { User, UserSchedule, UserTrackings } from '../../store/ScheduleSync/ScheduleSync_User';
import * as Helper from '../../util/Helper';
import ActiveToggle from '../misc/ActiveToggle';
import * as ScheduleSyncRoleTypes from '../../util/ScheduleSync/ScheduleSyncRoleTypeUtil';
import StatusCode from '../../util/StatusCode';
import TableHorizontal from '../misc/TableHorizontal';
import ObjectLogs from '../misc/ObjectLogs';
import * as LogTypes from '../../util/FlowSyncLoggerTypes';
import { GetObjectLogs } from '../../store/ModuleAction';
import * as Tab from '../../util/TabValue';
import RenderTabs from '../misc/Tabs';
import TableVertical from '../misc/TableVertical';


const User_ScheduleSync = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const user = useSelector(state => state.schedulesync_users.user);

    useEffect(() => {
        User(userId)(dispatch);
    }, [dispatch]);

    const tabs = [
        Tab.Tabs.Information,
        Tab.Tabs.ObjectLogs,
        Tab.Tabs.Schedule,
        Tab.Tabs.Trackings,
    ];

    const displayName = user?.data?.displayName ? ' / ' + user.data.displayName : '';

    if (user?.error === 400 || user?.error === 404) {
        return <NotFound />;
    }

    return (
        <div className="padding-40 container single">
            <h1><Link to="/schedulesync/user">Användare</Link> {displayName}</h1>

            <RenderTabs
                tabs={tabs}
                id={userId}
                url="schedulesync/user" />

            <Routes>
                <Route path={Tab.Tabs.Information.url} element={<Info />} />
                <Route path={Tab.Tabs.ObjectLogs.url} element={<ObjectLog />} />
                <Route path={Tab.Tabs.Schedule.url} element={<Schedule />} />
                <Route path={Tab.Tabs.Trackings.url} element={<Trackings />} />
                <Route path="*" element={<Navigate replace={true} to={`/schedulesync/user/${userId}/${Tab.Tabs.Information.url}`} />} />
            </Routes>
        </div>
    );
}

export const NotFound = () => {
    return (
        <div className="padding-40 container single">
            <h1><Link to="/schedulesync/user">Användare</Link></h1>
            <p>Användaren hittades inte. Gör en ny <Link to='/schedulesync/user'>sökning</Link></p>
        </div>
    );
}

export const Info = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const user = useSelector(state => state.schedulesync_users.user);

    useEffect(() => {
        User(userId)(dispatch);
    }, [dispatch]);

    const renderInfo = () => {
        let info = [];

        if (user.code === StatusCode.COMPLETE) {
            info.push(
                { header: "Visningsnamn", content: user.data.displayName },
                { header: "Aktiv", content: Helper.active(user.data.active) },
                { header: "E-postadress", content: user.data.emailAddress },
            )
        }

        return (
            <TableVertical
                header='Information'
                info={info}
                error={user.code === StatusCode.ERROR}
                searching={user.code === StatusCode.PENDING}
            />
        )
    }

    return (<>
        {renderInfo()}
    </>)
}

export const ObjectLog = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const objectLogs = useSelector(state => state.module.objectLogs);

    useEffect(() => {
        GetObjectLogs({ objectId: userId, objectSource: LogTypes.Flowsync.SourceName, objectType: LogTypes.Flowsync.ScheduleUser })(dispatch);
    }, [dispatch]);

    return (<>
        <ObjectLogs
            objectLogs={objectLogs}
            noResMsg="Det finns inga loggrader för denna användare den senaste körningen"
        />
    </>);
}

export const Schedule = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const events = useSelector(state => state.schedulesync_users.events);

    const [filter, setFilter] = useState({
        onlyActive: events.filter?.onlyActive ?? true,
        weekDate: events?.filter?.weekDate !== undefined ? Helper.getISODate(events.filter?.weekDate) : Helper.getISODate(new Date())
    });

    useEffect(() => {
        UserSchedule(userId, filter.onlyActive, new Date(filter.weekDate))(dispatch);
    }, [dispatch, filter]);

    const updateWeek = (e) => {
        var value = e.target.value;
        if (!value) {
            value = Helper.getISODate(new Date());
        }
        setFilter({ ...filter, weekDate: value });
    }

    const renderSchedule = () => {
        let newList = [];
        if (events.code === StatusCode.COMPLETE && events.data.length) {
            newList = events.data.map(event => ({
                id: event.eventId,
                data: [
                    { value: <Link to={`/schedulesync/event/${event.eventId}/information`}>{event.title}</Link> },
                    { value: event.location },
                    { value: ScheduleSyncRoleTypes.userRole(event.role) },
                    { value: Helper.active(event.active) },
                    { value: Helper.formatDate(event.startTime) },
                    { value: Helper.formatDate(event.endTime) },
                ]
            }))
        }

        return (
            <TableHorizontal
                header={[{ value: 'Titel' }, { value: 'Plats' }, { value: 'Roll' }, { value: 'Aktiv' }, { value: 'Starttid' }, { value: 'Sluttid' }]}
                info={newList}
                error={events.code === StatusCode.ERROR}
                searching={events.code === StatusCode.PENDING}
                noResMsg="Schemapositioner under vald vecka saknas för användaren"
            />
        )
    }

    return (<>
        <div className="header_member_table large filter-under-text">
            <span className="text">Schemapositioner för vecka</span>
            <Input
                type="date"
                name="week"
                className="input_week"
                value={filter.weekDate}
                onChange={updateWeek}
            />
            <span className="right">
                <ActiveToggle
                    onlyActive={filter.onlyActive ?? null}
                    update={(active) => setFilter({ ...filter, onlyActive: active })}
                    disabled={events.code === StatusCode.PENDING}
                />
            </span>
        </div>
        {renderSchedule()}
    </>)
}

export const Trackings = () => {
    const { userId } = useParams();
    const dispatch = useDispatch();
    const trackings = useSelector(state => state.schedulesync_users.userTrackings);

    useEffect(() => {
        UserTrackings(userId)(dispatch);
    }, [dispatch]);

    const renderTrackings = () => {
        let newList = [];
        let combineTrackings = [];

        if (trackings.code === StatusCode.COMPLETE) {
            trackings.data.exportTrackings.map(et => {
                combineTrackings.push(et)
            })
            combineTrackings.push(trackings.data?.importTracking)

            newList = combineTrackings.map(tracking => ({
                id: tracking.targetId,
                data: [
                    { value: tracking.targetSystem },
                    { value: tracking.targetId },
                    { value: tracking.targetData },
                    { value: Helper.formatDate(tracking.exported) },
                    { value: Helper.active(tracking.active) },
                ]
            }));
        }

        return (<>
            <h5 className="header-menu">Källor</h5>
            <TableHorizontal
                header={[{ value: 'Målsystem' }, { value: 'Identifierare' }, { value: 'Data' }, { value: 'Exporterad' }, { value: 'Aktiv' }]}
                info={newList}
                error={trackings.code === StatusCode.ERROR}
                searching={trackings.code === StatusCode.PENDING}
                noResMsg="Den här användaren saknar källor"
            />
        </>)
    }

    return (<>
        {renderTrackings()}
    </>)
}

export default User_ScheduleSync;