import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Card, CardTitle, CardSubtitle, CardText, Button } from 'reactstrap';
import { getModuleTypes, createModule, clearNewModule, getMyModuleTypes } from '../../../store/ModuleAction';
import StatusCode from '../../../util/StatusCode';
import Loading from '../../misc/Loading'
import CreateModuleModal from './CreateModuleModal';
import { withRouter } from '../../../components/withRouter';


class ModulePresentation extends Component {
    state = {
        moduleType: 'all',
        openCreateNew: false,
        module: {},
        title: '',
    }

    componentDidMount() {
        this.props.getModuleTypes();
    }

    getModuleTypeName = (id) => {
        switch (id) {
            case 1: return 'Import';
            case 2: return 'Export';
            default: return null;
        }
    }

    createModule = async (e) => {
        e.preventDefault();
        let newModule = { title: this.state.title, moduleType: this.state.module.moduleTypeName };

        await this.props.createModule(newModule);

        if (this.props.newModule.code === StatusCode.COMPLETE) {
            this.props.getMyModuleTypes(false);
            const moduleId = this.props.newModule.data.id;
            setTimeout(() => {
                this.setState({ title: '', openCreateNew: false })
                this.props.toggleModal();

                this.props.navigate(`/module/${moduleId}/configuration`);
                this.props.clearNewModule();
            }, 1500)
        }
    }

    updateTitle = (e) => {
        this.setState({ title: e.target.value })
    }

    renderCreateModule = (module) => {
        this.setState({ openCreateNew: !this.state.openCreateNew, module: module })
    }

    toggleModules = (moduleType) => {
        this.setState({ moduleType: moduleType })
    }

    renderToggle = () => {
        const tabs = [
            { name: 'Visa alla', value: 'all' },
            { name: 'Importmoduler', value: 1 },
            { name: 'Exportmoduler', value: 2 },
        ]
        return (
            <div className="module-toggle">
                {tabs.map(x =>
                    <Button key={x.value} className={this.state.moduleType === x.value ? 'active' : ''} onClick={() => this.toggleModules(x.value)}>{x.name}</Button>
                )}
            </div>
        )
    }

    toggleModal = () => {
        this.setState({ openCreateNew: false, title: '' });
        this.props.clearNewModule();
    }

    renderCards = () => {
        const { moduleTypes } = this.props;
        if (moduleTypes.code === StatusCode.PENDING) {
            return (<Loading />);
        }
        if (moduleTypes.code === StatusCode.ERROR) {
            return 'Kunde inte hämta moduler'
        }
        
        return (
            <React.Fragment>
                {this.renderToggle()}
                {moduleTypes.data
                    .filter(m => this.state.moduleType === 'all' || m.moduleType === this.state.moduleType)
                    .map(m =>
                        <Card key={m.id} className="module-presentation-card">
                            {m.vendorLogo ? <img className="logo vendor" src={m.vendorLogo} alt='' /> :
                            <p className="vendorName vendor">{m.vendorName}</p>}
                                <CardTitle tag="h5">{m.title}</CardTitle>
                            <span className="tags">
                                {m.moduleType !== 0 && <CardSubtitle className={this.getModuleTypeName(m.moduleType) + " moduletype"}>{this.getModuleTypeName(m.moduleType)}</CardSubtitle>}
                                {!m.isReleased && <CardSubtitle className="not-released">Ej produktionssatt</CardSubtitle>}
                            </span>
                            <CardText className="description">{m.description}</CardText>
                            <Button onClick={() => this.renderCreateModule(m)} disabled={!m.hasLicense || !this.props.isAdmin} className={!this.props.isAdmin ? 'not-admin' : 'no-license'} color="primary">Skapa</Button>
                        </Card>
                    )}
            </React.Fragment>
        )
    }

    render() {
        return (
            <React.Fragment>
                <Modal size="xl" isOpen={this.props.isOpen} toggle={this.props.toggleModal}>
                    <ModalHeader toggle={this.props.toggleModal}>Välj typ av modul</ModalHeader>
                    <ModalBody className="module-presentation-modal-body">
                        {this.renderCards()}
                    </ModalBody>
                </Modal>

                {this.state.openCreateNew &&
                    <CreateModuleModal
                        isOpen={this.state.openCreateNew}
                        toggleModal={() => this.toggleModal()}
                        module={this.state.module}
                        title={this.state.title}
                        updateTitle={(e) => this.updateTitle(e)}
                        createModule={(e) => this.createModule(e)}
                        saveStatus={this.props.newModule.code}
                    />}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    moduleTypes: state.module.moduleTypes,
    newModule: state.module.createdModule,
    isAdmin: state.me.isAdmin,
})

export default withRouter(connect(mapStateToProps, { getModuleTypes, createModule, clearNewModule, getMyModuleTypes })(ModulePresentation));
