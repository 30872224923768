interface StatusType {
    id: string,
    name: string,
}

const statusTypes = new Map<string, string>([
    ['inactive', 'Inaktiv'],
    ['active', 'Aktiv, ej provisionerad'],
    ['activeProvisioned', 'Aktiv, provisionerad'],
    ['archived', 'Arkiverad'],
]);

export const StatusTypes: StatusType[] = Array.from(statusTypes).map(x => ({ id: x[0], name: x[1] }));

export const statusFromId = (id: string): string => statusTypes.get(id) ?? '-';
